import { ModelAdvertisement } from "@sportsgravyengineering/sg-api-react-sdk";
import { AdvertisementAuditLogCreateDelete } from "./AdvertisementAuditLogCreateDelete";
import { AdvertisementAuditLogUpdate } from "./AdvertisementAuditLogUpdate";
export const AdvertisementAuditLogDetails = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelAdvertisement;
  after: object | ModelAdvertisement;
}) => {
  if (recordType === "UPDATE")
    return <AdvertisementAuditLogUpdate before={before} after={after} />;
  else
    return (
      <AdvertisementAuditLogCreateDelete
        recordType={recordType}
        before={before}
        after={after}
      />
    );
};
