/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container } from "@components/crud/Container";
import { Button, ButtonGroup } from "@mui/material";
import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import {
  ModelConfig,
  useAdminJobTitileTitleIdGet,
  useAdminJobTitleGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useState } from "react";
type Tabs =
  | "Accounts"
  | "Leads"
  | "Opportunities"
  | "Orders"
  | "Billing"
  | "Onboarding & Review";
type OrderTabs = "General" | "Taxes and Fees";
export const CrmSettingAuditLogUpdate = ({
  before,
  after
}: {
  before: object | ModelConfig;
  after: object | ModelConfig;
}) => {
  const tabs = [
    "Accounts",
    "Leads",
    "Opportunities",
    "Orders",
    "Billing",
    "Onboarding & Review"
  ];
  const orderTabs = ["General", "Taxes and Fees"];
  const [orderTab, setOrderTab] = useState("General");
  const [tab, setTab] = useState("Accounts");
  const currentConfig = after as ModelConfig[];
  const oldConfig = before as ModelConfig[];

  const jobTitles = useAdminJobTitleGet();

  useEffect(() => {
    console.log("Current Config", currentConfig);
    console.log("Old Config", oldConfig);
  }, []);

  const compareValues = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj1: Record<string, any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj2: Record<string, any>
  ): boolean => {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return true;
    }
    return Object.keys(obj1).some((key) => obj1[key] !== obj2[key]);
  };

  const formatString = (input: string): string => {
    return input
      .toLowerCase() // Convert the whole string to lowercase
      .split("_") // Split by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "); // Join words with a space
  };

  const getAccountRating = (account: ModelConfig, rating: string) => {
    const ratingMapping: Record<string, string> = {
      AAA: "AAA",
      AA: "AA",
      A: "A",
      B: "B",
      C: "C"
    };

    const mappedType = ratingMapping[rating];

    return account?.value?.find((entry) => entry.type === mappedType) || "";
  };

  const getAccountDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    const currentAccountDetails = currentConfig.find(
      (key) => key.key === "crm.account.org-rating"
    );
    const oldAccountDetails = oldConfig.find(
      (key) => key.key === "crm.account.org-rating"
    );
    const currentAAA = getAccountRating(
      currentAccountDetails as ModelConfig,
      "AAA"
    );
    const oldAAA = getAccountRating(oldAccountDetails as ModelConfig, "AAA");
    const currentAA = getAccountRating(
      currentAccountDetails as ModelConfig,
      "AA"
    );
    const oldAA = getAccountRating(oldAccountDetails as ModelConfig, "AA");
    const currentA = getAccountRating(
      currentAccountDetails as ModelConfig,
      "A"
    );
    const oldA = getAccountRating(oldAccountDetails as ModelConfig, "A");
    const currentB = getAccountRating(
      currentAccountDetails as ModelConfig,
      "B"
    );
    const oldB = getAccountRating(oldAccountDetails as ModelConfig, "B");
    const currentC = getAccountRating(
      currentAccountDetails as ModelConfig,
      "C"
    );
    const oldC = getAccountRating(oldAccountDetails as ModelConfig, "C");

    if (compareValues(currentAAA, oldAAA)) {
      labelValues.push({
        label: `Updated the Rating AAA “No. of Yearly Athlete Registrations” field:`,
        value: [
          {
            value: `${currentAAA?.start} ${currentAAA?.end}` || "N/A",
            oldValue: `${oldAAA?.start} ${oldAAA?.end}` || "N/A"
          }
        ]
      });
    }
    if (compareValues(currentAA, oldAA)) {
      labelValues.push({
        label: `Updated the Rating AA “No. of Yearly Athlete Registrations” field:`,
        value: [
          {
            value: `${currentAA?.start} - ${currentAA?.end}` || "N/A",
            oldValue: `${oldAA?.start} - ${oldAA?.end}` || "N/A"
          }
        ]
      });
    }
    if (compareValues(currentA, oldA)) {
      labelValues.push({
        label: `Updated the Rating A “No. of Yearly Athlete Registrations” field:`,
        value: [
          {
            value: `${currentA?.start} - ${currentA?.end}` || "N/A",
            oldValue: `${oldA?.start} - ${oldA?.end}` || "N/A"
          }
        ]
      });
    }
    if (compareValues(currentB, oldB)) {
      labelValues.push({
        label: `Updated the Rating B “No. of Yearly Athlete Registrations” field:`,
        value: [
          {
            value: `${currentB?.start} - ${currentB?.end}` || "N/A",
            oldValue: `${oldB?.start} - ${oldB?.end}` || "N/A"
          }
        ]
      });
    }
    if (compareValues(currentC, oldC)) {
      labelValues.push({
        label: `Updated the Rating C “No. of Yearly Athlete Registrations” field:`,
        value: [
          {
            value: `${currentC?.start} - ${currentC?.end}` || "N/A",
            oldValue: `${oldC?.start} - ${oldC?.end}` || "N/A"
          }
        ]
      });
    }

    return labelValues;
  };
  const getLeadsDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    const currentLeadEmailDetails = currentConfig.find(
      (key) => key.key === "crm.lead.new-mql-email"
    );
    const oldLeadEmailDetails = oldConfig.find(
      (key) => key.key === "crm.lead.new-mql-email"
    );

    //Will be used later once the owner has more than one possible value
    // const currentLeadOwnerDetails = currentConfig.find(
    //     (key) => key.key === "crm.lead."
    //   );
    //   const oldLeadOwnerDetails = oldConfig.find(
    //     (key) => key.key === "crm.lead."
    //   );

    // if (
    //   compareValues(
    //     currentLeadEmailDetails as ModelConfig,
    //     oldLeadEmailDetails as ModelConfig
    //   )
    // ) {
    //   labelValues.push({
    //     label: `Updated the “Default Lead Owner” field:`,
    //     value: [
    //       {
    //         value: currentLeadOwnerDetails?.[0] || "N/A",
    //         oldValue: oldLeadOwnerDetails?.[0] || "N/A"
    //       }
    //     ]
    //   });
    // }

    if (
      compareValues(
        currentLeadEmailDetails?.value as ModelConfig,
        oldLeadEmailDetails?.value as ModelConfig
      )
    ) {
      labelValues.push({
        label: `Updated the “New MQL Email Notification” field:`,
        value: [
          {
            value: currentLeadEmailDetails?.value?.join(", ") || "N/A",
            oldValue: oldLeadEmailDetails?.value?.join(", ") || "N/A"
          }
        ]
      });
    }
    return labelValues;
  };
  const getOpportunitiesDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    const currentOpportunityRenewalDetails = currentConfig.find(
      (key) => key.key === "crm.opportunity.generate-renewal-days"
    );
    const oldOpportunityRenewalDetails = oldConfig.find(
      (key) => key.key === "crm.opportunity.generate-renewal-days"
    );
    const currentOpportunityPricingTierApprovalDetails = currentConfig.find(
      (key) => key.key === "crm.opportunity.pricing-tier-approval-required"
    );
    const oldOpportunityPricingTierApprovalDetails = oldConfig.find(
      (key) => key.key === "crm.opportunity.pricing-tier-approval-required"
    );

    if (
      (currentOpportunityRenewalDetails?.value as string) !==
      (oldOpportunityRenewalDetails?.value as string)
    ) {
      labelValues.push({
        label: `Updated the “Auto Generate Renewal Opportunities” field:`,
        value: [
          {
            value:
              currentOpportunityRenewalDetails?.options?.find(
                (renewal) =>
                  renewal.value === currentOpportunityRenewalDetails.value
              ).label || "N/A",
            oldValue:
              oldOpportunityRenewalDetails?.options?.find(
                (renewal) =>
                  renewal.value === oldOpportunityRenewalDetails.value
              ).label || "N/A"
          }
        ]
      });
    }

    currentOpportunityPricingTierApprovalDetails?.value?.forEach((_, index) => {
      if (
        currentOpportunityPricingTierApprovalDetails.value?.[index].job !==
        oldOpportunityPricingTierApprovalDetails?.value?.[index].job
      ) {
        labelValues.push({
          label: `Updated Approval Required for Changing Pricing Tier ${index + 1} “Role” field:`,
          value: [
            {
              value:
                jobTitles?.data?.data?.jobTitles?.find(
                  (job) =>
                    job.jobtitleId ===
                    currentOpportunityPricingTierApprovalDetails.value?.[index]
                      .job
                )?.name || "N/A",
              oldValue:
                jobTitles?.data?.data?.jobTitles?.find(
                  (job) =>
                    job.jobtitleId ===
                    oldOpportunityPricingTierApprovalDetails?.value?.[index].job
                )?.name || "N/A"
            }
          ]
        });
      }
      if (
        currentOpportunityPricingTierApprovalDetails.value?.[index]
          .percentage !==
        oldOpportunityPricingTierApprovalDetails?.value?.[index].percentage
      ) {
        labelValues.push({
          label: `Updated Approval Required for Changing Pricing Tier ${index + 1} “Percentage” field:`,
          value: [
            {
              value:
                currentOpportunityPricingTierApprovalDetails.value?.[index]
                  .percentage || "N/A",
              oldValue:
                oldOpportunityPricingTierApprovalDetails?.value?.[index]
                  .percentage || "N/A"
            }
          ]
        });
      }
    });

    return labelValues;
  };
  const getGeneralOrdersDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];

    const currentOrderAtRiskRule1Details = currentConfig.find(
      (key) => key.key === "crm.order.at-risk-rule1"
    );
    const oldOrderAtRiskRule1Details = oldConfig.find(
      (key) => key.key === "crm.order.at-risk-rule1"
    );
    const currentOrderAtRiskRule2Details = currentConfig.find(
      (key) => key.key === "crm.order.at-risk-rule2"
    );
    const oldOrderAtRiskRule2Details = oldConfig.find(
      (key) => key.key === "crm.order.at-risk-rule2"
    );

    if (
      currentOrderAtRiskRule1Details?.value !==
      oldOrderAtRiskRule1Details?.value
    ) {
      labelValues.push({
        label: `Updated the “Order Status - At Risk Designation Rule 1: {  } Business Days After Order Date” field:`,
        value: [
          {
            value: currentOrderAtRiskRule1Details?.value || "N/A",
            oldValue: oldOrderAtRiskRule1Details?.value || "N/A"
          }
        ]
      });
    }
    if (
      currentOrderAtRiskRule2Details?.value !==
      oldOrderAtRiskRule2Details?.value
    ) {
      labelValues.push({
        label: `Updated the “Order Status - At Risk Designation Rule 2: {  } Business Days Before Start Date” field:`,
        value: [
          {
            value: currentOrderAtRiskRule2Details?.value || "N/A",
            oldValue: oldOrderAtRiskRule2Details?.value || "N/A"
          }
        ]
      });
    }

    const currentOrderEmailDetails = currentConfig.find(
      (key) => key.key === "crm.order.email-notification.new-order"
    );
    const oldOrderEmailDetails = oldConfig.find(
      (key) => key.key === "crm.order.email-notification.new-order"
    );

    if (
      compareValues(
        currentOrderEmailDetails?.value as ModelConfig,
        oldOrderEmailDetails?.value as ModelConfig
      )
    ) {
      labelValues.push({
        label: `Updated the “Email Notifications for New Orders” field:`,
        value: [
          {
            value: currentOrderEmailDetails?.value?.join(", ") || "N/A",
            oldValue: oldOrderEmailDetails?.value?.join(", ") || "N/A"
          }
        ]
      });
    }

    const currentOrderReminderDetails = currentConfig.find(
      (key) => key.key === "crm.order.email-notification.reminder-frequency"
    );
    const oldOrderReminderDetails = oldConfig.find(
      (key) => key.key === "crm.order.email-notification.reminder-frequency"
    );

    if (currentOrderReminderDetails?.value !== oldOrderReminderDetails?.value) {
      labelValues.push({
        label: `Updated the “Review and Accept Terms Reminder Email Frequency” field:`,
        value: [
          {
            value:
              formatString(currentOrderReminderDetails?.value as string) ||
              "N/A",
            oldValue:
              formatString(oldOrderReminderDetails?.value as string) || "N/A"
          }
        ]
      });
    }

    return labelValues;
  };
  const getTaxesAndFeesOrderDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    const currentOrderTaxAndFeeDetails = currentConfig.find(
      (key) => key.key === "crm.order.tax-and-fee"
    );
    const oldOrderTaxAndFeeDetails = oldConfig.find(
      (key) => key.key === "crm.order.tax-and-fee"
    );

    currentOrderTaxAndFeeDetails?.value?.forEach((_, index) => {
      const currentTaxAndFee = currentOrderTaxAndFeeDetails.value?.[index];
      const oldTaxAndFee = oldOrderTaxAndFeeDetails?.value?.[index];

      if (currentTaxAndFee.name !== oldTaxAndFee.name) {
        labelValues.push({
          label: `Updated ${currentTaxAndFee.country} - ${currentTaxAndFee.subdivision} Tax/Fee ${index + 1} "Label" field:`,
          value: [
            {
              value: currentTaxAndFee.name || "N/A",
              oldValue: oldTaxAndFee.name || "N/A"
            }
          ]
        });
      }
      if (currentTaxAndFee.type !== oldTaxAndFee.type) {
        labelValues.push({
          label: `Updated ${currentTaxAndFee.country} - ${currentTaxAndFee.subdivision} Tax/Fee ${index + 1} "Type" field:`,
          value: [
            {
              value: currentTaxAndFee.type || "N/A",
              oldValue: oldTaxAndFee.type || "N/A"
            }
          ]
        });
      }
      if (
        currentTaxAndFee.calculationMethod !== oldTaxAndFee.calculationMethod
      ) {
        labelValues.push({
          label: `Updated ${currentTaxAndFee.country} - ${currentTaxAndFee.subdivision} Tax/Fee ${index + 1} "Value" field:`,
          value: [
            {
              value:
                currentTaxAndFee.calculationMethod === "VALUE"
                  ? "Currency"
                  : "Percentage",
              oldValue:
                oldTaxAndFee.calculationMethod === "VALUE"
                  ? "Currency"
                  : "Percentage"
            }
          ]
        });
      }
      if (currentTaxAndFee.rate !== oldTaxAndFee.rate) {
        labelValues.push({
          label: `Updated ${currentTaxAndFee.country} - ${currentTaxAndFee.subdivision} Tax/Fee ${index + 1} "Rate" field:`,
          value: [
            {
              value: currentTaxAndFee.rate || "N/A",
              oldValue: oldTaxAndFee.rate || "N/A"
            }
          ]
        });
      }
    });

    return labelValues;
  };

  const getBillingDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    const currentBillingYearlyMonthlyDetails = currentConfig.find(
      (key) => key.key === "crm.order.billing-option.yearly-monthly"
    );
    const oldBillingYearlyMonthlyDetails = oldConfig.find(
      (key) => key.key === "crm.order.billing-option.yearly-monthly"
    );

    if (
      currentBillingYearlyMonthlyDetails?.value !==
      oldBillingYearlyMonthlyDetails?.value
    ) {
      labelValues.push({
        label: `Updated the “Yearly Subscription - Billed Monthly” field:`,
        value: [
          {
            value: currentBillingYearlyMonthlyDetails?.value ? "On" : "Off",
            oldValue: oldBillingYearlyMonthlyDetails?.value ? "On" : "Off"
          }
        ]
      });
    }

    const currentBillingYearlyMonthlyAthleteDetails = currentConfig.find(
      (key) => key.key === "crm.order.billing-option.yearly-monthly-per-athlete"
    );
    const oldBillingYearlyMonthlyAthleteDetails = oldConfig.find(
      (key) => key.key === "crm.order.billing-option.yearly-monthly-per-athlete"
    );

    if (
      currentBillingYearlyMonthlyAthleteDetails?.value !==
      oldBillingYearlyMonthlyAthleteDetails?.value
    ) {
      labelValues.push({
        label: `Updated the “Yearly Subscription - Billed Monthly Per Athlete Registered” field:`,
        value: [
          {
            value: currentBillingYearlyMonthlyDetails?.value ? "On" : "Off",
            oldValue: oldBillingYearlyMonthlyDetails?.value ? "On" : "Off"
          }
        ]
      });
    }

    const currentBillingYearlyUpfrontDetails = currentConfig.find(
      (key) => key.key === "crm.order.billing-option.upfront"
    );
    const oldBillingYearlyUpfrontDetails = oldConfig.find(
      (key) => key.key === "crm.order.billing-option.upfront"
    );

    if (
      currentBillingYearlyUpfrontDetails?.value !==
      oldBillingYearlyUpfrontDetails?.value
    ) {
      labelValues.push({
        label: `Updated the “Yearly Subscription - Paid Upfront” field:`,
        value: [
          {
            value: currentBillingYearlyUpfrontDetails?.value ? "On" : "Off",
            oldValue: oldBillingYearlyUpfrontDetails?.value ? "On" : "Off"
          }
        ]
      });
    }

    const currentBillingEmailDetails = currentConfig.find(
      (key) => key.key === "crm.order.upfront-discount"
    );
    const oldBillingEmailDetails = oldConfig.find(
      (key) => key.key === "crm.order.upfront-discount"
    );

    if (currentBillingEmailDetails?.value !== oldBillingEmailDetails?.value) {
      labelValues.push({
        label: `Updated the “Email Notifications for Billing” field:`,
        value: [
          {
            value: currentBillingEmailDetails?.value?.toString() || "N/A",
            oldValue: oldBillingEmailDetails?.value?.toString() || "N/A"
          }
        ]
      });
    }

    const currentBillingInvoiceDetails = currentConfig.find(
      (key) => key.key === "crm.order.upcoming-invoice-reminder"
    );
    const oldBillingInvoiceDetails = oldConfig.find(
      (key) => key.key === "crm.order.upcoming-invoice-reminder"
    );

    if (
      currentBillingInvoiceDetails?.value !== oldBillingInvoiceDetails?.value
    ) {
      labelValues.push({
        label: `Updated the “Email Notifications for Billing” field:`,
        value: [
          {
            value: currentBillingInvoiceDetails?.value?.toString() || "N/A",
            oldValue: oldBillingInvoiceDetails?.value?.toString() || "N/A"
          }
        ]
      });
    }
    return labelValues;
  };
  const getOnboardingAndReviewDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    let currentOnboardingDetails = currentConfig.find(
      (key) => key.key === "crm.onboarding.start-date-range"
    );
    let oldOnboardingDetails = oldConfig.find(
      (key) => key.key === "crm.onboarding.start-date-range"
    );

    if (currentOnboardingDetails?.value !== oldOnboardingDetails?.value) {
      labelValues.push({
        label: `Updated the “Onboarding Meeting Requirement: {  } Business Days Before or After Start Date” field:`,
        value: [
          {
            value: currentOnboardingDetails?.value?.toString() || "N/A",
            oldValue: oldOnboardingDetails?.value?.toString() || "N/A"
          }
        ]
      });
    }

    currentOnboardingDetails = currentConfig.find(
      (key) => key.key === "crm.onboarding.session-length"
    );
    oldOnboardingDetails = oldConfig.find(
      (key) => key.key === "crm.onboarding.session-length"
    );

    if (currentOnboardingDetails?.value !== oldOnboardingDetails?.value) {
      labelValues.push({
        label: `Updated the “Onboarding Meeting Length” field:`,
        value: [
          {
            value:
              currentOnboardingDetails?.options?.find(
                (option) => option.value === currentOnboardingDetails?.value
              ).label || "N/A",
            oldValue:
              oldOnboardingDetails?.options?.find(
                (option) => option.value === oldOnboardingDetails?.value
              ).label || "N/A"
          }
        ]
      });
    }

    currentOnboardingDetails = currentConfig.find(
      (key) => key.key === "crm.onboarding.review-frequency"
    );
    oldOnboardingDetails = oldConfig.find(
      (key) => key.key === "crm.onboarding.review-frequency"
    );

    if (currentOnboardingDetails?.value !== oldOnboardingDetails?.value) {
      labelValues.push({
        label: `Updated the “Review Frequency” field:`,
        value: [
          {
            value:
              currentOnboardingDetails?.options?.find(
                (option) => option.value === currentOnboardingDetails?.value
              ).label || "N/A",
            oldValue:
              oldOnboardingDetails?.options?.find(
                (option) => option.value === oldOnboardingDetails?.value
              ).label || "N/A"
          }
        ]
      });
    }

    currentOnboardingDetails = currentConfig.find(
      (key) => key.key === "crm.onboarding.review-date-range"
    );
    oldOnboardingDetails = oldConfig.find(
      (key) => key.key === "crm.onboarding.review-date-range"
    );

    if (currentOnboardingDetails?.value !== oldOnboardingDetails?.value) {
      labelValues.push({
        label: `Updated the “Review Meeting Requirement: {  } Business Days Before or After Suggested Review Date” field:`,
        value: [
          {
            value: currentOnboardingDetails?.value?.toString() || "N/A",
            oldValue: oldOnboardingDetails?.value?.toString() || "N/A"
          }
        ]
      });
    }

    currentOnboardingDetails = currentConfig.find(
      (key) => key.key === "crm.onboarding.review-meeting-length"
    );
    oldOnboardingDetails = oldConfig.find(
      (key) => key.key === "crm.onboarding.review-meeting-length"
    );

    if (currentOnboardingDetails?.value !== oldOnboardingDetails?.value) {
      labelValues.push({
        label: `Updated the “Review Meeting Length” field:`,
        value: [
          {
            value:
              currentOnboardingDetails?.options?.find(
                (option) => option.value === currentOnboardingDetails?.value
              ).label || "N/A",
            oldValue:
              oldOnboardingDetails?.options?.find(
                (option) => option.value === oldOnboardingDetails?.value
              ).label || "N/A"
          }
        ]
      });
    }

    currentOnboardingDetails = currentConfig.find(
      (key) => key.key === "crm.onboarding.email-notification"
    );
    oldOnboardingDetails = oldConfig.find(
      (key) => key.key === "crm.onboarding.email-notification"
    );

    if (
      compareValues(
        currentOnboardingDetails?.value as ModelConfig,
        oldOnboardingDetails?.value as ModelConfig
      )
    ) {
      labelValues.push({
        label: `Updated the “Email Notifications for Onboarding, Review Meetings and Surveys Associated with Orders” field:`,
        value: [
          {
            value: currentOnboardingDetails?.value?.join(", ") || "N/A",
            oldValue: oldOnboardingDetails?.value?.join(", ") || "N/A"
          }
        ]
      });
    }

    currentOnboardingDetails = currentConfig.find(
      (key) => key.key === "crm.survey.nps-frequency"
    );
    oldOnboardingDetails = oldConfig.find(
      (key) => key.key === "crm.survey.nps-frequency"
    );

    if (currentOnboardingDetails?.value !== oldOnboardingDetails?.value) {
      labelValues.push({
        label: `Updated B2B Customer Account Surveys “NPS Survey Frequency” field:`,
        value: [
          {
            value:
              currentOnboardingDetails?.options?.find(
                (option) => option.value === currentOnboardingDetails?.value
              ).label || "N/A",
            oldValue:
              oldOnboardingDetails?.options.find(
                (option) => option.value === oldOnboardingDetails?.value
              ).label || "N/A"
          }
        ]
      });
    }

    currentOnboardingDetails = currentConfig.find(
      (key) => key.key === "crm.survey.nps-frequency-day"
    );
    oldOnboardingDetails = oldConfig.find(
      (key) => key.key === "crm.survey.nps-frequency-day"
    );

    if (currentOnboardingDetails?.value !== oldOnboardingDetails?.value) {
      labelValues.push({
        label: `Updated B2B Customer Account Surveys “NPS Survey Frequency Day of the Week” field:`,
        value: [
          {
            value:
              currentOnboardingDetails?.options?.find(
                (option) => option.value === currentOnboardingDetails?.value
              ).label || "N/A",
            oldValue:
              oldOnboardingDetails?.options.find(
                (option) => option.value === oldOnboardingDetails?.value
              ).label || "N/A"
          }
        ]
      });
    }

    currentOnboardingDetails = currentConfig.find(
      (key) => key.key === "crm.survey.nps-automate-days"
    );
    oldOnboardingDetails = oldConfig.find(
      (key) => key.key === "crm.survey.nps-automate-days"
    );

    if (currentOnboardingDetails?.value !== oldOnboardingDetails?.value) {
      labelValues.push({
        label: `Updated B2B Customer Account Surveys “Automatically Create and Send NPS Survey {  } Business Days After Onboarding Meeting” field:`,
        value: [
          {
            value: currentOnboardingDetails?.value || "N/A",
            oldValue: oldOnboardingDetails?.value || "N/A"
          }
        ]
      });
    }

    currentOnboardingDetails = currentConfig.find(
      (key) => key.key === "crm.survey.csat-frequency"
    );
    oldOnboardingDetails = oldConfig.find(
      (key) => key.key === "crm.survey.csat-frequency"
    );

    if (currentOnboardingDetails?.value !== oldOnboardingDetails?.value) {
      labelValues.push({
        label: `Updated B2B Customer Account Surveys “CSAT Survey Frequency” field:`,
        value: [
          {
            value:
              currentOnboardingDetails?.options?.find(
                (option) => option.value === currentOnboardingDetails?.value
              ).label || "N/A",
            oldValue:
              oldOnboardingDetails?.options?.find(
                (option) => option.value === oldOnboardingDetails?.value
              ).label || "N/A"
          }
        ]
      });
    }

    currentOnboardingDetails = currentConfig.find(
      (key) => key.key === "crm.survey.csat-frequency-day"
    );
    oldOnboardingDetails = oldConfig.find(
      (key) => key.key === "crm.survey.csat-frequency-day"
    );

    if (currentOnboardingDetails?.value !== oldOnboardingDetails?.value) {
      labelValues.push({
        label: `Updated B2B Customer Account Surveys “CSAT Survey Frequency Day of the Week” field:`,
        value: [
          {
            value:
              currentOnboardingDetails?.options?.find(
                (option) => option.value === currentOnboardingDetails?.value
              ).label || "N/A",
            oldValue:
              oldOnboardingDetails?.options?.find(
                (option) => option.value === oldOnboardingDetails?.value
              ).label || "N/A"
          }
        ]
      });
    }

    currentOnboardingDetails = currentConfig.find(
      (key) => key.key === "crm.survey.csat-automate-days"
    );
    oldOnboardingDetails = oldConfig.find(
      (key) => key.key === "crm.survey.csat-automate-days"
    );

    if (currentOnboardingDetails?.value !== oldOnboardingDetails?.value) {
      labelValues.push({
        label: `Updated B2B Customer Account Surveys “Automatically Create and Send CSAT Survey {  } Business Days After Onboarding Meeting” field:`,
        value: [
          {
            value: currentOnboardingDetails?.value || "N/A",
            oldValue: oldOnboardingDetails?.value || "N/A"
          }
        ]
      });
    }

    return labelValues;
  };
  return (
    <Container style={{ marginTop: "10px" }}>
      {/* Main Tabs */}
      <ButtonGroup
        variant="outlined"
        style={{ border: "rgba(215, 221, 229, 1)", paddingBottom: "24px" }}
      >
        {tabs.map((t) => (
          <Button
            key={t}
            variant="outlined"
            style={
              tab === t
                ? {
                    backgroundColor: "#E8EEFF",
                    color: "#2B337A",
                    fontSize: "14px",
                    fontWeight: 600,
                    textTransform: "none"
                  }
                : {
                    color: "#666666",
                    fontSize: "14px",
                    fontWeight: 500,
                    textTransform: "none"
                  }
            }
            onClick={() => setTab(t as Tabs)}
          >
            {t}
          </Button>
        ))}
      </ButtonGroup>

      {/* Tab Content */}
      {tab === "Accounts" && (
        <AuditLogLabelValueList
          recordType="UPDATE"
          labelValues={[...getAccountDetails()]}
        />
      )}

      {tab === "Leads" && (
        <AuditLogLabelValueList
          recordType="UPDATE"
          labelValues={[...getLeadsDetails()]}
        />
      )}

      {tab === "Opportunities" && (
        <AuditLogLabelValueList
          recordType="UPDATE"
          labelValues={[...getOpportunitiesDetails()]}
        />
      )}

      {tab === "Orders" && (
        <>
          {/* Order Tabs */}
          <ButtonGroup
            variant="outlined"
            style={{ border: "rgba(215, 221, 229, 1)", paddingBottom: "24px" }}
          >
            {orderTabs.map((t) => (
              <Button
                key={t}
                variant="outlined"
                style={
                  orderTab === t
                    ? {
                        backgroundColor: "#E8EEFF",
                        color: "#2B337A",
                        fontSize: "14px",
                        fontWeight: 600,
                        textTransform: "none"
                      }
                    : {
                        color: "#666666",
                        fontSize: "14px",
                        fontWeight: 500,
                        textTransform: "none"
                      }
                }
                onClick={() => setOrderTab(t as OrderTabs)}
              >
                {t}
              </Button>
            ))}
          </ButtonGroup>

          {/* Order Tab Content */}
          {orderTab === "General" && (
            <AuditLogLabelValueList
              recordType="UPDATE"
              labelValues={[...getGeneralOrdersDetails()]}
            />
          )}
          {orderTab === "Taxes and Fees" && (
            <AuditLogLabelValueList
              recordType="UPDATE"
              labelValues={[...getTaxesAndFeesOrderDetails()]}
            />
          )}
        </>
      )}

      {tab === "Billing" && (
        <AuditLogLabelValueList
          recordType="UPDATE"
          labelValues={[...getBillingDetails()]}
        />
      )}

      {tab === "Onboarding & Review" && (
        <AuditLogLabelValueList
          recordType="UPDATE"
          labelValues={[...getOnboardingAndReviewDetails()]}
        />
      )}
    </Container>
  );
};
