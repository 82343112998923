import { Container } from "@components/crud/Container";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { ModelSeason } from "@sportsgravyengineering/sg-api-react-sdk";
import { formatDateForDisplay } from "@utils/formatDate";

export const SeasonAuditLogCreateDelete = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "DELETE";
  before: object | ModelSeason;
  after: object | ModelSeason;
}) => {
  const level = (recordType === "CREATE" ? after : before) as ModelSeason;
  return (
    <Container>
      <AuditLogLabelValueList
        recordType={recordType}
        labelValues={[
          {
            label: `"Name" field:`,
            value: [
              {
                value: level.name || ""
              }
            ]
          },
          {
            label: `"Sport" field:`,
            value: [
              {
                value: level?.sport?.name || ""
              }
            ]
          },
          {
            label: `"Start Date" field:`,
            value: [
              {
                value: level.startDate
                  ? formatDateForDisplay(new Date(level.startDate))
                  : ""
              }
            ]
          },
          {
            label: `"End Date" field:`,
            value: [
              {
                value: level.endDate
                  ? formatDateForDisplay(new Date(level.endDate))
                  : ""
              }
            ]
          }
        ]}
      />
    </Container>
  );
};
