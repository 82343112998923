import { ModelPosition } from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { PositionsAuditLogUpdate } from "./PositionsAuditLogUpdate";
import { PositionsAuditLogCreateDelete } from "./PositionsAuditLogCreateDelete";

export const PositionsAuditLogDetails = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelPosition;
  after: object | ModelPosition;
}) => {
  if (recordType === "UPDATE")
    return <PositionsAuditLogUpdate before={before} after={after} />;
  else
    return (
      <PositionsAuditLogCreateDelete
        recordType={recordType}
        before={before}
        after={after}
      />
    );
};
