import { ModelOrder } from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { OrdersAuditLogUpdate } from "./OrdersAuditLogUpdate";
import { OrdersAuditLogCreateDelete } from "./OrdersAuditLogCreateDelete";

export const OrdersAuditLogDetails = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelOrder;
  after: object | ModelOrder;
}) => {
  if (recordType === "UPDATE")
    return <OrdersAuditLogUpdate before={before} after={after} />;
  else
    return (
      <OrdersAuditLogCreateDelete
        recordType={recordType}
        before={before}
        after={after}
      />
    );
};
