import { Container } from "@components/crud/Container";
import { Loader } from "@components/crud/Loader";
import { ModelLevel } from "@sportsgravyengineering/sg-api-react-sdk";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { GENDERS } from "@utils/constants";

export const LevelAuditLogCreateDelete = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "DELETE";
  before: object | ModelLevel;
  after: object | ModelLevel;
}) => {
  const level = (recordType === "CREATE" ? after : before) as ModelLevel;
  return (
    <Container>
      <Loader isLoading={false}>
        <AuditLogLabelValueList
          recordType={recordType}
          labelValues={[
            {
              label: `"Name" field:`,
              value: [
                {
                  value: level.name || ""
                }
              ]
            },
            {
              label: `"Abbreviation" field:`,
              value: [
                {
                  value: level.abbv || ""
                }
              ]
            },
            {
              label: `"Sports" field:`,
              value: [
                {
                  value: level.sports?.map((s) => s.name).join(", ") || ""
                }
              ]
            },
            {
              label: `"Gender" field:`,
              value: [
                {
                  value:
                    level.genders
                      ?.map((g) => GENDERS.find((g1) => g1.value === g)?.label)
                      .join(", ") || ""
                }
              ]
            },
            {
              label: `"Description" field:`,
              value: [
                {
                  value: level.description || ""
                }
              ]
            }
          ]}
        />
      </Loader>
    </Container>
  );
};
