import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { ModelHelpArticle } from "@sportsgravyengineering/sg-api-react-sdk";

export const HelpArticlesAuditLogUpdate = ({
  before,
  after
}: {
  before: object | ModelHelpArticle;
  after: object | ModelHelpArticle;
}) => {
  const currentArticle = after as ModelHelpArticle;
  const oldArticle = before as ModelHelpArticle;

  const getArticleDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    if (currentArticle.headline !== oldArticle.headline) {
      labelValues.push({
        label: `Updated the "Headline" field:`,
        value: [
          {
            value: currentArticle?.headline || "",
            oldValue: oldArticle?.headline || ""
          }
        ]
      });
    }
    //TODO: do not understand what this is supposed to be
    if (currentArticle.headline !== oldArticle.headline) {
      labelValues.push({
        label: `Updated the "Article" field:`,
        value: [
          {
            value: currentArticle?.headline || "",
            oldValue: oldArticle?.headline || ""
          }
        ]
      });
    }
    if (currentArticle.helpCategory?.name !== oldArticle.helpCategory?.name) {
      labelValues.push({
        label: `Updated the "Category" field:`,
        value: [
          {
            value: currentArticle?.helpCategory?.name || "",
            oldValue: oldArticle?.helpCategory?.name || ""
          }
        ]
      });
    }
    if (currentArticle.relatedTo !== oldArticle.relatedTo) {
      labelValues.push({
        label: `Updated the "Related To" field:`,
        value: [
          {
            value: currentArticle?.relatedTo || "",
            oldValue: oldArticle?.relatedTo || ""
          }
        ]
      });
    }
    if (
      currentArticle.displayChannel?.join(", ") !==
      oldArticle?.displayChannel?.join(", ")
    ) {
      labelValues.push({
        label: `Updated the "Display Channel" field:`,
        value: [
          {
            value: currentArticle?.displayChannel?.join(", ") || "",
            oldValue: oldArticle?.displayChannel?.join(", ") || ""
          }
        ]
      });
    }

    return labelValues;
  };

  return (
    <AuditLogLabelValueList
      recordType="UPDATE"
      labelValues={[...getArticleDetails()]}
    />
  );
};
