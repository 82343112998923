import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Tooltip, Typography } from "@mui/material";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  getCellProperty
} from "@silevis/reactgrid";
import * as React from "react";
import { useRef } from "react";

export interface BasicTextCell extends Cell {
  type: "BasicText";
  text: string;
  tooltipText?: string; // New property for tooltip content
}

export class BasicTextTemplate implements CellTemplate<BasicTextCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<BasicTextCell>
  ): Compatible<BasicTextCell> {
    const text = getCellProperty(uncertainCell, "text", "string");
    const tooltipText = getCellProperty(uncertainCell, "tooltipText", "string"); // Removed 4th arg
    const value = parseFloat(text) || 0; // Ensure a numeric value exists

    return { ...uncertainCell, text, tooltipText, value };
  }

  render(cell: Compatible<BasicTextCell>): React.ReactNode {
    const contentRef = useRef<HTMLDivElement>(null);

    return (
      <Box
        ref={contentRef}
        display="flex"
        alignItems="center"
        gap={1}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          height: "100%"
        }}
      >
        <Typography
          variant="body2"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontWeight: "bold"
          }}
        >
          {cell.text}
        </Typography>
        {cell.tooltipText && (
          <Tooltip
            title={
              <Box
                sx={{
                  whiteSpace: "pre-line",
                  p: 1,
                  fontSize: 13,
                  bgcolor: "background.paper",
                  color: "text.primary",
                  borderRadius: 1,
                  maxWidth: 300
                }}
              >
                {cell.tooltipText}
              </Box>
            }
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "background.paper",
                  color: "text.primary",
                  border: "1px solid #ccc", // optional, or remove if you want no border
                  boxShadow: 3
                }
              },
              arrow: {
                sx: {
                  color: "background.paper" // sets arrow to match background
                }
              }
            }}
          >
            <InfoOutlinedIcon sx={{ fontSize: 16, color: "gray" }} />
          </Tooltip>
        )}
      </Box>
    );
  }
}
