import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import {
  ModelAccount,
  ModelOrganizationAccount,
  ModelOrganizationOpportunity,
  useAdminAccountAccountIdActivityChangeLogGet,
  useAdminOrgAccountAccountIdActivityChangeLogGet,
  useDeleteAdminCrmAccountAccountId,
  useDeleteAdminCrmOrgAccountAccountId,
  useGetAdminCrmAccountAccountId,
  useGetAdminCrmOrgAccountAccountId
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useState, SyntheticEvent, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useSnackbar } from "notistack";
import { hasPermission } from "@services/Casbin";
import { Loader } from "@components/crud/Loader";
import { AccountDashboard } from "./AccountDashboard";
import { AccountView } from "./AccountView";
import { AccountContacts } from "./AccountContacts";
import { AccountActivity } from "./AccountActivity";
import { AccountLeads } from "./AccountLeads";
import { AccountOpportunity } from "./AccountOpportunity";
import {
  CRM_ACCOUNT_CATEGORIES,
  ORGANIZATION_TYPE_OPTIONS
} from "@utils/constants";
import formatFullName from "@utils/formatFullName";
import { AccountOrders } from "./AccountOrders";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { capitalize } from "@utils/capitalize";
import { styled } from "@mui/material";

const StyledLinkText = styled("span")`
  color: #007aff;
  cursor: pointer;
  word-wrap: break-word;
`;

export const AccountOverview = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const [permissions, setPermissions] = useState({
    delete: false,
    edit: false,
    view: false
  });
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        organizationId ? "ORGANIZATION" : "SYSTEM",
        organizationId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const view = await checkPermission("crm.accounts", "VIEW");
      const edit = await checkPermission("crm.accounts", "EDIT");
      const del = await checkPermission("crm.accounts", "DELETE");
      setPermissions({ view, edit, delete: del });
      if (!view) navigate("/not-found");
    };
    fetchPermissions();
  }, []);
  const [searchParams, setSearchParams] = useSearchParams();
  const [accountToDelete, setAccountToDelete] = useState<
    (ModelAccount & ModelOrganizationAccount) | undefined
  >(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const { accountId } = useParams();
  const [tab, setTab] = useState(
    searchParams.get("tab") || "Account Dashboard"
  );

  const onTabChange = (event: SyntheticEvent, value: unknown) => {
    setTab(value as string);
    setSearchParams({ tab: value as string });
  };

  useEffect(() => {
    if (searchParams.get("tab")) {
      setTab(searchParams.get("tab") as string);
    }
  }, [searchParams]);

  const { mutateAsync: deleteAsync, isLoading: isDeleting } = organizationId
    ? useDeleteAdminCrmOrgAccountAccountId()
    : useDeleteAdminCrmAccountAccountId();

  const onConfirmDelete = async () => {
    if (!accountToDelete) return;
    try {
      await deleteAsync({ accountId: accountId! });
      enqueueSnackbar("Account deleted successfully", {
        variant: "success"
      });
      setAccountToDelete(undefined);
      navigate("/crm/accounts");
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete account!.", {
        variant: "error"
      });
      setAccountToDelete(undefined);
    }
  };

  const { data: accountData, isLoading: isLoading } = organizationId
    ? useGetAdminCrmOrgAccountAccountId(accountId!)
    : useGetAdminCrmAccountAccountId(accountId!);
  const { data: activitiesLog, isLoading: isLoadingActivityLog } =
    organizationId
      ? useAdminOrgAccountAccountIdActivityChangeLogGet(accountId!)
      : useAdminAccountAccountIdActivityChangeLogGet(accountId!);

  const displayActivities = () => {
    const activityCounts = accountToDelete?.activities?.reduce(
      (acc, activity) => {
        acc[activity.type!] = (acc[activity.type!] || 0) + 1;
        return acc;
      },
      {} as Record<string, number>
    );
    if (!activityCounts || !accountToDelete?.activities?.length) return <></>;

    return (
      <>
        <b>Activities</b>
        <div
          style={{
            maxHeight: accountToDelete?.opportunites?.length
              ? "150px"
              : "350px",
            overflowY: "auto"
          }}
        >
          <ul style={{ marginLeft: "35px" }}>
            {Object.keys(activityCounts).map((key) => (
              <li key={key}>
                <StyledLinkText
                  onClick={() =>
                    window.open(
                      `/crm/accounts/${accountId}?tab=Actitvites&activityType=${capitalize(key)}`,
                      "_blank"
                    )
                  }
                >
                  {activityCounts[key]} {capitalize(key)}
                  {activityCounts[key] > 1 ? "s" : ""}
                </StyledLinkText>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  };

  const getDefaultConfimationDialogProps = () => {
    return {
      close: () => setAccountToDelete(undefined),
      onCancel: () => setAccountToDelete(undefined),
      isConfirming: isDeleting,
      confirmBtnVariant: "admin-error",
      icon: "error"
    };
  };

  return (
    <Loader isLoading={isLoading || isLoadingActivityLog}>
      <Container>
        <Toolbar
          title="View Account"
          secondaryTitle={
            <>
              {accountData?.data?.name}
              {(accountData?.data as ModelOrganizationAccount)?.isTaxExempt && (
                <span
                  style={{
                    backgroundColor: "#E5E5E5",
                    padding: "3px 8px",
                    borderRadius: "4px",
                    marginLeft: "16px",
                    color: "#666"
                  }}
                >
                  Tax Exempt
                </span>
              )}
            </>
          }
          backBtnClick={() => navigate("/crm/accounts")}
          {...(permissions.edit && {
            editBtnClick: () => navigate(`/crm/accounts/${accountId}/edit`)
          })}
          {...(permissions.delete && {
            deleteBtnClick: () => setAccountToDelete(accountData?.data)
          })}
          tabs={{
            tabs: [
              "Account Dashboard",
              "Account Details",
              "Contacts",
              ...(accountData?.data?.activities?.length ? ["Actitvites"] : []),
              ...(!organizationId &&
              !!(accountData?.data as ModelAccount)?.leads?.length
                ? ["Leads"]
                : []),
              ...(accountData?.data?.opportunites?.length
                ? ["Opportunities"]
                : []),
              ...(!organizationId &&
              !!(accountData?.data as ModelAccount)?.orders?.length
                ? ["Orders"]
                : [])
            ],
            onTabChange: onTabChange,
            activeTab: tab
          }}
        />
        {tab == "Account Dashboard" && (
          <AccountDashboard
            accountId={accountId!}
            details={{
              accountId: accountId!,
              name: accountData?.data?.name,
              type:
                CRM_ACCOUNT_CATEGORIES.find(
                  (a) => a.value === accountData?.data?.category
                )?.label || "",
              orgType:
                ORGANIZATION_TYPE_OPTIONS.find(
                  (o) => o.value === accountData?.data?.type
                )?.label || "",
              officeAddress: accountData?.data?.officeAddress,
              billingAddress: accountData?.data?.billingAddress,
              shippingAddress: accountData?.data?.shippingAddress,
              phone: accountData?.data?.phone,
              fax: accountData?.data?.fax,
              email: accountData?.data?.email,
              website: accountData?.data?.website,
              ...(!organizationId
                ? {
                    sports:
                      (accountData?.data as ModelAccount)?.sports?.map(
                        (s) => s.name
                      ) || [],
                    noOfAthletes: (accountData?.data as ModelAccount)
                      ?.numberOfAthletes,
                    bdrOwner: (accountData?.data as ModelAccount)?.bdrOwner
                      ?.person
                      ? formatFullName(
                          (accountData?.data as ModelAccount)?.bdrOwner?.person
                        )
                      : "",
                    aeOwner: (accountData?.data as ModelAccount)?.aeOwner
                      ?.person
                      ? formatFullName(
                          (accountData?.data as ModelAccount)?.aeOwner?.person
                        )
                      : "",
                    csmOwner: (accountData?.data as ModelAccount)?.csmOwner
                      ?.person
                      ? formatFullName(
                          (accountData?.data as ModelAccount)?.csmOwner?.person
                        )
                      : ""
                  }
                : {
                    owner: (accountData?.data as ModelOrganizationAccount)
                      ?.owner?.person
                      ? formatFullName(
                          (accountData?.data as ModelOrganizationAccount)?.owner
                            ?.person
                        )
                      : ""
                  }),
              parent: {
                name: accountData?.data?.parent?.name || "",
                accountId: accountData?.data?.parent?.accountId || ""
              },
              opportunities: accountData?.data?.opportunites,
              activities: activitiesLog?.data || [],
              contacts: accountData?.data?.contacts
            }}
            organizationId={organizationId}
          />
        )}
        {tab == "Account Details" && (
          <AccountView
            accountDetails={accountData?.data}
            organizationId={organizationId}
          />
        )}
        {tab == "Contacts" && (
          <AccountContacts
            contacts={accountData?.data?.contacts}
            account={accountData?.data as ModelAccount}
            onClickAdd={() => {
              navigate(`/crm/contacts/create?accountId=${accountId}`);
            }}
            organizationId={organizationId}
          />
        )}
        {tab == "Actitvites" &&
          accountData?.data?.activities &&
          accountData?.data?.activities.length > 0 && (
            <AccountActivity
              activities={accountData?.data?.activities}
              onClickAdd={(activeTab) => {
                navigate(
                  `/crm/activities/create?accountId=${accountId}&type=${activeTab}`
                );
              }}
              account={accountData?.data as ModelAccount}
            />
          )}
        {tab == "Leads" && (accountData?.data as ModelAccount)?.leads && (
          <AccountLeads
            leads={(accountData?.data as ModelAccount)?.leads}
            onClickAdd={() => {
              navigate(`/crm/leads/create?accountId=${accountId}`);
            }}
          />
        )}
        {tab == "Opportunities" && accountData?.data?.opportunites && (
          <AccountOpportunity
            account={accountData?.data as ModelAccount}
            opportunities={accountData?.data?.opportunites}
            accountContacts={accountData?.data?.contacts}
            organizationId={organizationId}
            onClickAdd={() => {
              navigate(`/crm/opportunities/create?accountId=${accountId}`);
            }}
          />
        )}
        {tab == "Orders" && (accountData?.data as ModelAccount)?.orders && (
          <AccountOrders
            orders={(accountData!.data as ModelAccount).orders!}
            onClickAdd={() =>
              navigate(`/crm/orders/create?accountId=${accountId}`)
            }
          />
        )}
      </Container>

      <ConfirmationDialog
        open={!!accountToDelete && !!organizationId}
        title={
          accountToDelete?.advertisers?.length
            ? "Cannot Delete Account"
            : accountToDelete?.opportunites?.length ||
                accountToDelete?.activities?.length
              ? "Delete Account Warning"
              : "Delete Account?"
        }
        body={
          accountToDelete?.advertisers?.length ? (
            "This Account has been set up as an Advertiser in your organization and therefore cannot be deleted."
          ) : accountToDelete?.opportunites?.length ||
            accountToDelete?.activities?.length ? (
            <div>
              <span>
                This Account currently has 1 or more opportunities or activities
                associated with it.{" "}
                <b>
                  If this Account is deleted, all of the following will be
                  deleted with it.
                </b>
              </span>
              <br />
              <br />
              {!!accountToDelete?.opportunites?.length && (
                <>
                  <b>Opportunities</b>
                  <div
                    style={{
                      maxHeight: "250px",
                      overflowY: "auto"
                    }}
                  >
                    <ul style={{ marginLeft: "35px" }}>
                      {accountToDelete?.opportunites?.map(
                        (opp: ModelOrganizationOpportunity) => (
                          <li key={opp.opportunityId}>
                            <StyledLinkText
                              onClick={() =>
                                window.open(
                                  `/crm/opportunities/${opp.opportunityId}`,
                                  "_blank"
                                )
                              }
                            >
                              {opp.name}
                            </StyledLinkText>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  <br />
                </>
              )}
              {displayActivities()}
            </div>
          ) : (
            <>
              Are you sure you want to delete the account{" "}
              <b>“{accountToDelete?.name}”</b> from your system?
            </>
          )
        }
        {...(accountToDelete?.advertisers?.length
          ? {
              cancelBtnText: "Okay"
            }
          : accountToDelete?.opportunites?.length ||
              accountToDelete?.activities?.length
            ? {
                confirmBtnText: "Confirm",
                cancelBtnText: "Cancel",
                onConfirm: onConfirmDelete
              }
            : {
                onConfirm: onConfirmDelete
              })}
        {...getDefaultConfimationDialogProps()}
      />

      <ConfirmationDialog
        open={!!accountToDelete && !organizationId}
        title={
          accountToDelete?.orders?.length
            ? "Cannot Delete Account"
            : accountToDelete?.opportunites?.length ||
                accountToDelete?.leads?.length ||
                accountToDelete?.activities?.length
              ? "Delete Account Warning"
              : "Delete Account?"
        }
        body={
          accountToDelete?.orders?.length ? (
            <div>
              <span>
                This Account cannot be deleted because it has 1 or more orders
                associated with it.
              </span>
              <br />
              <br />
              {!!accountToDelete?.orders?.length && (
                <>
                  <div
                    style={{
                      maxHeight: "400px",
                      overflowY: "auto"
                    }}
                  >
                    <ul style={{ marginLeft: "25px" }}>
                      {accountToDelete?.orders?.map((order) => (
                        <li key={order.orderId}>
                          <StyledLinkText
                            onClick={() =>
                              window.open(
                                `/crm/orders/${order.orderId}`,
                                "_blank"
                              )
                            }
                          >
                            {order.orderId}
                          </StyledLinkText>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
          ) : accountToDelete?.opportunites?.length ||
            accountToDelete?.leads?.length ||
            accountToDelete?.activities?.length ? (
            <div>
              <span>
                This Account currently has 1 or more leads, opportunities, or
                activities associated with it.{" "}
                <b>
                  If this Account is deleted, all of the following will be
                  deleted with it.
                </b>
              </span>
              <br />
              <br />
              {!!accountToDelete?.opportunites?.length && (
                <>
                  <b>Opportunities</b>
                  <div
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto"
                    }}
                  >
                    <ul style={{ marginLeft: "35px" }}>
                      {accountToDelete?.opportunites?.map(
                        (opp: ModelOrganizationOpportunity) => (
                          <li key={opp.opportunityId}>
                            <StyledLinkText
                              onClick={() =>
                                window.open(
                                  `/crm/opportunities/${opp.opportunityId}`,
                                  "_blank"
                                )
                              }
                            >
                              {opp.name}
                            </StyledLinkText>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  <br />
                </>
              )}
              {!!accountToDelete?.leads?.length && (
                <>
                  <b>Leads</b>
                  <div
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto"
                    }}
                  >
                    <ul style={{ marginLeft: "35px" }}>
                      {accountToDelete?.leads?.map((opp) => (
                        <li key={opp.leadId}>
                          <StyledLinkText
                            onClick={() =>
                              window.open(`/crm/leads/${opp.leadId}`, "_blank")
                            }
                          >
                            {opp.name}
                          </StyledLinkText>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <br />
                </>
              )}
              {displayActivities()}
            </div>
          ) : (
            <>
              Are you sure you want to delete the account{" "}
              <b>“{accountToDelete?.name}”</b> from your system?
            </>
          )
        }
        {...(accountToDelete?.orders?.length
          ? {
              cancelBtnText: "Okay"
            }
          : accountToDelete?.opportunites?.length ||
              accountToDelete?.leads?.length ||
              accountToDelete?.activities?.length
            ? {
                confirmBtnText: "Confirm",
                cancelBtnText: "Cancel",
                onConfirm: onConfirmDelete
              }
            : {
                onConfirm: onConfirmDelete
              })}
        {...getDefaultConfimationDialogProps()}
      />
    </Loader>
  );
};
