import { Container } from "@components/crud/Container";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { ModelCannedMessage } from "@sportsgravyengineering/sg-api-react-sdk";
import { AuditLogLabelValue } from "../types";
import { GENDERS } from "@utils/constants";

export const CannedMessageAuditLogCreateDelete = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "DELETE";
  before: object | ModelCannedMessage;
  after: object | ModelCannedMessage;
}) => {
  const cannedMessage = (
    recordType === "CREATE" ? after : before
  ) as ModelCannedMessage;

  const getLabels = () => {
    const labels: AuditLogLabelValue[] = [];
    if (cannedMessage.genders?.length)
      labels.push({
        label: `"Gender” field:`,
        value: [
          {
            value:
              cannedMessage.genders
                ?.map((g) => GENDERS.find((g1) => g1.value === g)?.label)
                .join(", ") || ""
          }
        ]
      });

    if (cannedMessage.levels?.length)
      labels.push({
        label: `"Levels” field:`,
        value: [
          {
            value: cannedMessage.levels?.map((l) => l.name).join(", ") || ""
          }
        ]
      });

    if (cannedMessage.positions?.length)
      labels.push({
        label: `"Positions” field:`,
        value: [
          {
            value: cannedMessage.positions?.map((p) => p.name).join(", ") || ""
          }
        ]
      });

    if (cannedMessage.skills?.length)
      labels.push({
        label: `"Skills” field:`,
        value: [
          {
            value: cannedMessage.skills?.map((s) => s.name).join(", ") || ""
          }
        ]
      });

    if (cannedMessage.concepts?.length)
      labels.push({
        label: `"Game Concepts” field:`,
        value: [
          {
            value: cannedMessage.concepts?.map((c) => c.name).join(", ") || ""
          }
        ]
      });

    if (cannedMessage.systems?.length)
      labels.push({
        label: `"Game Systems” field:`,
        value: [
          {
            value: cannedMessage.systems?.map((s) => s.name).join(", ") || ""
          }
        ]
      });

    if (cannedMessage.roles?.length)
      labels.push({
        label: `"Roles” field:`,
        value: [
          {
            value: cannedMessage.roles?.map((r) => r.name).join(", ") || ""
          }
        ]
      });

    return labels;
  };

  return (
    <Container>
      <AuditLogLabelValueList
        recordType={recordType}
        labelValues={[
          {
            label: `"Message" field:`,
            value: [
              {
                value: cannedMessage.message || ""
              }
            ]
          },
          {
            label: `"Title" field:`,
            value: [
              {
                value: cannedMessage.title || ""
              }
            ]
          },
          {
            label: `"Sport" field:`,
            value: [
              {
                value: cannedMessage.sport?.name || ""
              }
            ]
          },
          ...getLabels()
        ]}
      />
    </Container>
  );
};
