import {
  ModelGameConcept,
  ModelGameSystem,
  ModelSkill
} from "@sportsgravyengineering/sg-api-react-sdk";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "../types";
import { getIdSet } from "../ViewAuditLog";

export const GameConeptSystemAuditLog = ({
  recordType,
  before,
  after,
  page
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelGameConcept | ModelGameSystem | ModelSkill;
  after: object | ModelGameConcept | ModelGameSystem | ModelSkill;
  page: "GAME_CONCEPT" | "GAME_SYSTEM" | "SKILL";
}) => {
  const game = (recordType !== "DELETE" ? after : before) as
    | ModelGameConcept
    | ModelGameSystem
    | ModelSkill;
  const oldGame = before as ModelGameConcept | ModelGameSystem | ModelSkill;

  const getUpdateLabels = () => {
    const labels: AuditLogLabelValue[] = [];

    if (oldGame.name !== game.name)
      labels.push({
        label: `Updated the "Name" field:`,
        value: [
          {
            value: game.name || "N/A",
            oldValue: oldGame.name || "N/A"
          }
        ]
      });

    if (oldGame.sportId !== game.sportId)
      labels.push({
        label: `Updated the "Sport" field:`,
        value: [
          {
            value: game.sport?.name || "N/A",
            oldValue: oldGame.sport?.name || "N/A"
          }
        ]
      });

    if (
      (game as ModelSkill).parent &&
      page === "SKILL" &&
      (game as ModelSkill).parentId !== (oldGame as ModelSkill).parentId
    )
      labels.push({
        label: `Updated the "Parent Skill" field:`,
        value: [
          {
            value: (game as ModelSkill).parent?.name || "N/A",
            oldValue: (oldGame as ModelSkill).parent?.name || "N/A"
          }
        ]
      });

    if (page !== "SKILL") {
      const newSkillIds = getIdSet(
        (game as ModelGameConcept | ModelGameSystem)?.skills,
        "skillId"
      );
      const oldSkillIds = getIdSet(
        (oldGame as ModelGameConcept | ModelGameSystem)?.skills,
        "skillId"
      );

      const skillIdsChanged =
        newSkillIds.size !== oldSkillIds.size ||
        [...newSkillIds].some((id) => !oldSkillIds.has(id));

      if (skillIdsChanged)
        labels.push({
          label: `Updated the "Skills" field:`,
          value: [
            {
              value:
                (game as ModelGameConcept | ModelGameSystem).skills
                  ?.map((item) => item.name)
                  .join(", ") || "N/A",
              oldValue:
                (oldGame as ModelGameConcept | ModelGameSystem).skills
                  ?.map((item) => item.name)
                  .join(", ") || "N/A"
            }
          ]
        });
    }

    if (page === "GAME_SYSTEM") {
      const newConceptIds = getIdSet(
        (game as ModelGameSystem).gameConcepts,
        "conceptId"
      );
      const oldConceptIds = getIdSet(
        (oldGame as ModelGameSystem).gameConcepts,
        "conceptId"
      );

      const conceptIdsChanged =
        newConceptIds.size !== oldConceptIds.size ||
        [...newConceptIds].some((id) => !oldConceptIds.has(id));

      if (conceptIdsChanged && (game as ModelGameSystem).gameConcepts?.length)
        labels.push({
          label: `Updated the "Game Concepts" field:`,
          value: [
            {
              value:
                (game as ModelGameSystem).gameConcepts
                  ?.map((s) => s.name)
                  .join(", ") || "N/A",
              oldValue:
                (oldGame as ModelGameSystem).gameConcepts
                  ?.map((s) => s.name)
                  .join(", ") || "N/A"
            }
          ]
        });
    }

    const newPositionIds = getIdSet(game?.positions, "positionId");
    const oldPositionIds = getIdSet(oldGame.positions, "positionId");

    const positionIdsChanged =
      newPositionIds.size !== oldPositionIds.size ||
      [...newPositionIds].some((id) => !oldPositionIds.has(id));

    if (positionIdsChanged)
      labels.push({
        label: `Updated the "Positions" field:`,
        value: [
          {
            value: game.positions?.map((item) => item.name).join(", ") || "N/A",
            oldValue:
              oldGame.positions?.map((item) => item.name).join(", ") || "N/A"
          }
        ]
      });

    if (oldGame.description !== game.description)
      labels.push({
        label: `Updated the "Description" field:`,
        value: [
          {
            value: game.description || "N/A",
            oldValue: oldGame.description || "N/A"
          }
        ]
      });

    const newMediaIds = getIdSet(game?.media, "mediaId");
    const oldMediaIds = getIdSet(oldGame.media, "mediaId");

    const mediaIdsChanged =
      newMediaIds.size !== oldMediaIds.size ||
      [...newMediaIds].some((id) => !oldMediaIds.has(id));

    if (mediaIdsChanged) {
      const oldMedia = [...(oldGame.media || [])].sort((a, b) =>
        a.mediaId!.localeCompare(b.mediaId!)
      );
      const newMedia = [...(game.media || [])].sort((a, b) =>
        a.mediaId!.localeCompare(b.mediaId!)
      );

      const maxLength = Math.max(oldMedia.length, newMedia.length);

      for (let i = 0; i < maxLength; i++) {
        const oldMediaItem = oldMedia[i] || { mediaId: "N/A" };
        const newMediaItem = newMedia[i] || { mediaId: "N/A" };
        if (oldMediaItem.mediaId !== newMediaItem.mediaId) {
          labels.push({
            label: `"Photo/Video Content${
              maxLength > 1 ? ` ${i + 1}` : ""
            }" field:`,
            value: [
              {
                isMedia: true,
                value: newMediaItem.mediaId === "N/A" ? "N/A" : newMediaItem,
                oldValue: oldMediaItem.mediaId === "N/A" ? "N/A" : oldMediaItem
              }
            ]
          });
        }
      }
    }

    return labels;
  };

  const getCreateLabels = () => {
    const labels: AuditLogLabelValue[] = [];
    labels.push(
      {
        label: `"Name" field:`,
        value: [
          {
            value: game.name || ""
          }
        ]
      },
      {
        label: `"Sport" field:`,
        value: [
          {
            value: game.sport?.name || ""
          }
        ]
      }
    );

    if ((game as ModelSkill).parent && page === "SKILL")
      labels.push({
        label: `"Parent Skill" field:`,
        value: [
          {
            value: (game as ModelSkill).parent?.name || ""
          }
        ]
      });

    if (game.positions?.length)
      labels.push({
        label: `"Positions" field:`,
        value: [
          {
            value: game.positions?.map((p) => p.name).join(", ") || ""
          }
        ]
      });

    if (
      (game as ModelGameConcept | ModelGameSystem).skills?.length &&
      page !== "SKILL"
    )
      labels.push({
        label: `"Skills" field:`,
        value: [
          {
            value:
              (game as ModelGameConcept | ModelGameSystem).skills
                ?.map((s) => s.name)
                .join(", ") || ""
          }
        ]
      });

    if (
      (game as ModelGameSystem).gameConcepts?.length &&
      page === "GAME_SYSTEM"
    )
      labels.push({
        label: `"Game Concepts" field:`,
        value: [
          {
            value:
              (game as ModelGameSystem).gameConcepts
                ?.map((s) => s.name)
                .join(", ") || ""
          }
        ]
      });

    if (game.description)
      labels.push({
        label: `"Description" field:`,
        value: [
          {
            value: game.description
          }
        ]
      });

    if (game.media?.length)
      game.media.map((media, index) => {
        labels.push({
          label: `"Photo/Video Content${game.media && game.media?.length > 1 ? ` ${index + 1}` : ""}" field:`,
          value: [
            {
              isMedia: true,
              value: media || ""
            }
          ]
        });
      });

    return labels;
  };

  if (recordType === "UPDATE")
    return (
      <AuditLogLabelValueList
        recordType="UPDATE"
        labelValues={[...getUpdateLabels()]}
      />
    );
  else
    return (
      <AuditLogLabelValueList
        recordType={recordType}
        labelValues={[...getCreateLabels()]}
      />
    );
};
