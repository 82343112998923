import { Tabs, styled } from "@mui/material";

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  position: "relative",
  top: 2,
  paddingBottom: 1,
  zIndex: 1,
  overflow: "visible",

  "& .MuiTabs-fixed.MuiTabs-scroller": {
    position: "static"
  },

  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.white.main,
    height: "1px",
    marginBottom: "-1px",
    zIndex: 1,
    transition: "none"
  },

  "& .MuiTab-root": {
    textTransform: "none",
    padding: "8px 20px",

    "&.Mui-selected": {
      backgroundColor: theme.palette.white.main,
      border: `1px solid ${theme.palette.divider}`,
      borderBottom: "1px solid transparent",
      borderRadius: "12px",
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0"
    }
  }
}));
