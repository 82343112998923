/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Backdrop, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import ImagePlaceholder from "@assets/images/imagePlaceholder.png";

export const FeedMediaViewer = (props: { mediaFiles; close; currentIndex }) => {
  const StyledBox = styled(Box)(({ theme }) => ({
    marginLeft: "256px",
    marginTop: "50px",
    position: "fixed",
    backgroundColor: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "90vh",
    height: "70vh",
    zIndex: 9999,
    overflow: "hidden",
    borderRadius: "5px",
    [theme.breakpoints.down("xl")]: {
      marginLeft: "20%"
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px"
    }
  }));

  const [currentIndex, setCurrentIndex] = useState(
    props.mediaFiles.findIndex((item) => item.mediaId === props.currentIndex)
  );

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < props.mediaFiles.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const currentMedia = props.mediaFiles[currentIndex];

  const buttonStyle = {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    padding: "10px",
    border: "none",
    color: "white",
    width: "100px",
    height: "89px",
    cursor: "pointer"
  };

  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={true}
    >
      <StyledBox data-testid="feed-media-viewer">
        {(currentMedia.baseUrl + currentMedia.path).split(".").pop() ===
          "jpg" ||
        (currentMedia.baseUrl + currentMedia.path).split(".").pop() ===
          "jpeg" ||
        (currentMedia.baseUrl + currentMedia.path).split(".").pop() ===
          "png" ? (
          <img
            src={currentMedia.baseUrl + currentMedia.path}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              position: "relative",
              margin: "auto"
            }}
            alt="Image"
            onError={(e) => {
              e.target.src = ImagePlaceholder;
            }}
          />
        ) : (currentMedia.baseUrl + currentMedia.path).split(".").pop() ===
            "mp4" ||
          (currentMedia.baseUrl + currentMedia.path).split(".").pop() ===
            "m3u8" ? (
          <ReactPlayer
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload noremoteplayback noplaybackrate",
                  disablePictureInPicture: true
                }
              }
            }}
            playing={true}
            controls
            height={"100%"}
            width={"100%"}
            url={currentMedia.baseUrl + currentMedia.path}
          />
        ) : null}
        <ChevronLeftIcon
          sx={{
            ...buttonStyle,
            left: 0,
            display: currentIndex === 0 ? "none" : "block"
          }}
          onClick={handlePrevious}
        />
        <ChevronRightIcon
          sx={{
            ...buttonStyle,
            right: 0,
            display:
              currentIndex === props.mediaFiles.length - 1 ? "none" : "block"
          }}
          onClick={handleNext}
        />
        <CloseIcon
          onClick={props.close}
          sx={{
            ...buttonStyle,
            top: 30,
            right: 0,
            height: "55px",
            width: "95px"
          }}
        />
      </StyledBox>
    </Backdrop>
  );
};
