import { ModelTeam } from "@sportsgravyengineering/sg-api-react-sdk";
import { TeamAuditLogUpdateDetails } from "./Teams/TeamAuditLogUpdateDetails";
import { TeamAuditLogCreateDelete } from "./Teams/TeamAuditLogCreateDelete";

export const TeamAuditLogDetails = ({
  recordType,
  before,
  after,
  page
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelTeam;
  after: object | ModelTeam;
  page: "TEAM" | "TRAINING_PROGRAM";
}) => {
  if (recordType === "UPDATE")
    return (
      <TeamAuditLogUpdateDetails before={before} after={after} page={page} />
    );
  else
    return (
      <TeamAuditLogCreateDelete
        recordType={recordType}
        before={before}
        after={after}
        page={page}
      />
    );
};
