import {
  ModelOpportunity,
  ModelOrganizationOpportunity,
  useConfigGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "../types";
import {
  CRM_ACCOUNT_CATEGORIES,
  CRM_ACCOUNT_TYPES,
  CRM_OPPORTUNITY_STAGES,
  CRM_OPPORTUNITY_TYPES,
  LEAD_SOURCES,
  LEAD_STATUS
} from "@utils/constants";
import { formatDateForDisplay } from "@utils/formatDate";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { getCostPerAthlete } from "@utils/GetCostPerAthlete";
import { getCountryFromAddress } from "@utils/getCountryFromAddress";
import { useEffect, useState } from "react";
import { Tier } from "@pages/settings/CRMSetting";
import { Loader } from "@components/crud/Loader";
import formatFullName from "@utils/formatFullName";
import { formatCurrency } from "@utils/formatCurrency";

export const OpportunityAuditLog = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | (ModelOrganizationOpportunity & ModelOpportunity);
  after: object | (ModelOrganizationOpportunity & ModelOpportunity);
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const [pricingTier, setPricingTier] = useState<Tier[]>([]);

  const { data: settings, isLoading: isLoadingConfig } = organizationId
    ? { data: null, isLoading: false }
    : useConfigGet();

  useEffect(() => {
    if (settings && settings.data) {
      const priceTiersList = (settings.data.find(
        (item) => item.key === "crm.order.pricing-tier"
      )?.value || []) as Tier[];
      setPricingTier(priceTiersList);
    }
  }, [settings]);

  const opportunity = (
    recordType !== "DELETE" ? after : before
  ) as ModelOrganizationOpportunity & ModelOpportunity;
  const oldOpportunity = before as ModelOrganizationOpportunity &
    ModelOpportunity;

  const getPriceLabel = (opp: ModelOpportunity) => {
    return `${
      opp.discountedPricePerAthlete
        ? opp.discountedPricePerAthlete.toString()
        : getCostPerAthlete(
            opp.numberOfAthletes!,
            pricingTier.filter(
              (p) =>
                p.countryId ===
                  getCountryFromAddress(opp.account!.officeAddress!) || "US"
            )
          ).toString()
    } Per Athlete, Per Registration`;
  };

  const getCreateLabels = () => {
    const labels: AuditLogLabelValue[] = [];

    labels.push(
      {
        label: `"Name" field:`,
        value: [
          {
            value: opportunity.name || ""
          }
        ]
      },
      ...(!organizationId
        ? [
            {
              label: `"Type" field:`,
              value: [
                {
                  value:
                    CRM_OPPORTUNITY_TYPES.find(
                      (type) => type.value === opportunity.type
                    )?.label || ""
                }
              ]
            },
            {
              label: `"No. of Yearly Athlete Registrations" field 1:`,
              value: [
                {
                  value: opportunity.numberOfAthletes?.toString() || ""
                }
              ]
            },
            {
              label: `"No. of Yearly Athlete Registrations" field 2:`,
              value: [
                {
                  value: getPriceLabel(opportunity)
                }
              ]
            },
            {
              label: `"Estimated Yearly Amount" field:`,
              value: [
                {
                  value: formatCurrency(opportunity.amount)
                }
              ]
            }
          ]
        : []),
      {
        label: `"Stage" field:`,
        value: [
          {
            value:
              CRM_OPPORTUNITY_STAGES.find(
                (stage) => stage.value === opportunity.stage
              )?.label || ""
          }
        ]
      },
      ...(!organizationId
        ? [
            {
              label: `"Date Created" field:`,
              value: [
                {
                  value: opportunity.createdAt
                    ? formatDateForDisplay(new Date(opportunity.createdAt))
                    : ""
                }
              ]
            },
            {
              label: `"Created By" field:`,
              value: [
                {
                  value: formatFullName(opportunity.createdBy?.person)
                }
              ]
            }
          ]
        : []),
      ...(opportunity.closeDate
        ? [
            {
              label: `"Estimated Close Date" field:`,
              value: [
                {
                  value: opportunity.closeDate
                    ? formatDateForDisplay(new Date(opportunity.closeDate))
                    : ""
                }
              ]
            }
          ]
        : []),
      {
        label: `"Opportunity Owner" field:`,
        value: [
          {
            value: `${opportunity.owner?.person?.firstName} ${opportunity.owner?.person?.lastName}`
          }
        ]
      },
      ...(!organizationId
        ? [
            {
              label: `"Status" field:`,
              value: [
                {
                  value:
                    opportunity.approvals?.[0]?.status === "PENDING"
                      ? `Pending Approval by ${opportunity.approvals[0].needsApprovalBy?.name}`
                      : "Approved"
                }
              ]
            }
          ]
        : []),
      ...(!organizationId && opportunity.leads?.length
        ? [
            {
              label: `Lead Details "Name" field:`,
              value: [
                {
                  value: opportunity.leads[0].name || ""
                }
              ]
            },
            {
              label: `Lead Details "Source" field:`,
              value: [
                {
                  value:
                    LEAD_SOURCES.find(
                      (source) =>
                        source.value === opportunity?.leads?.[0].source?.type
                    )?.label || ""
                }
              ]
            },
            {
              label: `Lead Details "Status" field:`,
              value: [
                {
                  value:
                    LEAD_STATUS.find(
                      (status) =>
                        status.value === opportunity?.leads?.[0].status
                    )?.label || ""
                }
              ]
            },
            {
              label: `Lead Details "Lead Owner" field:`,
              value: [
                {
                  value: formatFullName(opportunity.leads[0].owner?.person)
                }
              ]
            }
          ]
        : []),

      {
        label: `Account Details "Account Name" field:`,
        value: [
          {
            value: opportunity.account?.name || ""
          }
        ]
      },
      ...(opportunity.account?.parentId
        ? [
            {
              label: `Account Details "Account Parent" field:`,
              value: [
                {
                  value: opportunity.account.parent?.name || ""
                }
              ]
            }
          ]
        : []),
      {
        label: `Account Details "Account Industry" field:`,
        value: [
          {
            value:
              CRM_ACCOUNT_CATEGORIES.find(
                (category) => category.value === opportunity.account?.category
              )?.label || ""
          }
        ]
      },
      ...(opportunity.account?.type
        ? [
            {
              label: `Account Details "Account Type" field:`,
              value: [
                {
                  value:
                    CRM_ACCOUNT_TYPES.find(
                      (type) => type.value === opportunity.account?.type
                    )?.label || ""
                }
              ]
            }
          ]
        : []),
      {
        label: `Account Details "Account Address" field:`,
        value: [
          {
            value: opportunity.account?.officeAddress || ""
          }
        ]
      },
      {
        label: `Account Details "Account Email" field:`,
        value: [
          {
            value: opportunity.account?.email || ""
          }
        ]
      },
      ...(opportunity.account?.website
        ? [
            {
              label: `Account Details "Account Website" field:`,
              value: [
                {
                  value: opportunity.account?.website || ""
                }
              ]
            }
          ]
        : []),
      ...(!organizationId
        ? [
            {
              label: `Account Details "Sports Offered" field:`,
              value: [
                {
                  value:
                    opportunity.account?.sports
                      ?.map((sport) => sport.name)
                      .join(", ") || ""
                }
              ]
            },
            {
              label: `Account Details "AE Owner" field:`,
              value: [
                {
                  value: formatFullName(
                    opportunity.account?.aeOwner?.user?.person
                  )
                }
              ]
            }
          ]
        : [
            {
              label: `Account Details "Owner" field:`,
              value: [
                {
                  value: formatFullName(opportunity.account?.owner?.person)
                }
              ]
            }
          ]),
      ...(organizationId
        ? [
            {
              label: `Contact Details "Contact Name" field:`,
              value: [
                {
                  value: formatFullName(opportunity.contact) || ""
                }
              ]
            }
          ]
        : [])
    );

    return labels;
  };

  const getUpdateLabels = () => {
    const labels: AuditLogLabelValue[] = [];

    if (opportunity.name !== oldOpportunity.name)
      labels.push({
        label: `Updated the "Name" field:`,
        value: [
          {
            value: opportunity.name || "N/A",
            oldValue: oldOpportunity.name || "N/A"
          }
        ]
      });

    if (
      !organizationId &&
      opportunity.numberOfAthletes !== oldOpportunity.numberOfAthletes
    ) {
      labels.push(
        {
          label: `Updated the "No. of Yearly Athlete Registrations" field 1:`,
          value: [
            {
              value: opportunity.numberOfAthletes?.toString() || "",
              oldValue: oldOpportunity.numberOfAthletes?.toString() || ""
            }
          ]
        },
        ...(getPriceLabel(opportunity) !== getPriceLabel(oldOpportunity)
          ? [
              {
                label: `Updated the "No. of Yearly Athlete Registrations" field 2:`,
                value: [
                  {
                    value: getPriceLabel(opportunity),
                    oldValue: getPriceLabel(oldOpportunity)
                  }
                ]
              }
            ]
          : []),
        {
          label: `Updated the "Estimated Yearly Amount" field:`,
          value: [
            {
              value: formatCurrency(opportunity.amount) || "N/A",
              oldValue: formatCurrency(oldOpportunity.amount) || "N/A"
            }
          ]
        }
      );
    }

    if (opportunity.stage !== oldOpportunity.stage)
      labels.push({
        label: `Updated the "Stage" field:`,
        value: [
          {
            value:
              CRM_OPPORTUNITY_STAGES.find(
                (stage) => stage.value === opportunity.stage
              )?.label || "N/A",
            oldValue:
              CRM_OPPORTUNITY_STAGES.find(
                (stage) => stage.value === oldOpportunity.stage
              )?.label || "N/A"
          }
        ]
      });

    if (opportunity.closeDate !== oldOpportunity.closeDate)
      labels.push({
        label: `Updated the "Estimated Close Date" field:`,
        value: [
          {
            value: opportunity.closeDate
              ? formatDateForDisplay(new Date(opportunity.closeDate))
              : "N/A",
            oldValue: oldOpportunity.closeDate
              ? formatDateForDisplay(new Date(oldOpportunity.closeDate))
              : "N/A"
          }
        ]
      });

    if (opportunity.ownerId !== oldOpportunity.ownerId)
      labels.push({
        label: `Updated the "Opportunity Owner" field:`,
        value: [
          {
            value: `${opportunity.owner?.person?.firstName} ${opportunity.owner?.person?.lastName}`,
            oldValue: `${oldOpportunity.owner?.person?.firstName} ${oldOpportunity.owner?.person?.lastName}`
          }
        ]
      });

    if (!organizationId) {
      const status =
        opportunity.approvals?.[0]?.status === "PENDING"
          ? `Pending Approval by ${opportunity.approvals?.[0].needsApprovalBy?.name}`
          : "Approved";

      const oldStatus =
        oldOpportunity.approvals?.[0]?.status === "PENDING"
          ? `Pending Approval by ${oldOpportunity.approvals?.[0].needsApprovalBy?.name}`
          : "Approved";

      if (status !== oldStatus)
        labels.push({
          label: `Updated the "Status" field:`,
          value: [
            {
              value: status || "N/A",
              oldValue: oldStatus || "N/A"
            }
          ]
        });
    }

    if (opportunity.account?.name !== oldOpportunity.account?.name)
      labels.push({
        label: `Updated the Account Details "Account Name" field:`,
        value: [
          {
            value: opportunity.account?.name || "N/A",
            oldValue: oldOpportunity.account?.name || "N/A"
          }
        ]
      });

    if (opportunity.account?.parentId !== oldOpportunity.account?.parentId)
      labels.push({
        label: `Updated the Account Details "Parent Account" field:`,
        value: [
          {
            value: opportunity.account?.parent?.name || "N/A",
            oldValue: oldOpportunity.account?.parent?.name || "N/A"
          }
        ]
      });

    if (opportunity.account?.category !== oldOpportunity.account?.category)
      labels.push({
        label: `Updated the Account Details "Account Industry" field:`,
        value: [
          {
            value:
              CRM_ACCOUNT_CATEGORIES.find(
                (category) => category.value === opportunity.account?.category
              )?.label || "N/A",
            oldValue:
              CRM_ACCOUNT_CATEGORIES.find(
                (category) =>
                  category.value === oldOpportunity.account?.category
              )?.label || "N/A"
          }
        ]
      });

    if (opportunity.account?.type !== oldOpportunity.account?.type)
      labels.push({
        label: `Updated the Account Details "Account Type" field:`,
        value: [
          {
            value:
              CRM_ACCOUNT_TYPES.find(
                (type) => type.value === opportunity.account?.type
              )?.label || "N/A",
            oldValue:
              CRM_ACCOUNT_TYPES.find(
                (type) => type.value === oldOpportunity.account?.type
              )?.label || "N/A"
          }
        ]
      });

    if (
      opportunity.account?.officeAddress !==
      oldOpportunity.account?.officeAddress
    )
      labels.push({
        label: `Updated the Account Details "Account Address" field:`,
        value: [
          {
            value: opportunity.account?.officeAddress || "N/A",
            oldValue: oldOpportunity.account?.officeAddress || "N/A"
          }
        ]
      });

    if (opportunity.account?.email !== oldOpportunity.account?.email)
      labels.push({
        label: `Updated the Account Details "Account Email" field:`,
        value: [
          {
            value: opportunity.account?.email || "N/A",
            oldValue: oldOpportunity.account?.email || "N/A"
          }
        ]
      });

    if (opportunity.account?.website !== oldOpportunity.account?.website)
      labels.push({
        label: `Updated the Account Details "Account Website" field:`,
        value: [
          {
            value: opportunity.account?.website || "N/A",
            oldValue: oldOpportunity.account?.website || "N/A"
          }
        ]
      });

    if (
      opportunity.account?.ownerId !== oldOpportunity.account?.ownerId &&
      organizationId
    )
      labels.push({
        label: `Updated the Account Details "Account Owner" field:`,
        value: [
          {
            value: formatFullName(opportunity.account?.owner?.person) || "N/A",
            oldValue:
              formatFullName(oldOpportunity.account?.owner?.person) || "N/A"
          }
        ]
      });

    if (
      !organizationId &&
      oldOpportunity.account?.sports?.map((s) => s.name).join(", ") !==
        opportunity.account?.sports?.map((s) => s.name).join(", ")
    )
      labels.push({
        label: `Updated the Account Details "Sports Offered" field:`,
        value: [
          {
            value:
              opportunity.account?.sports
                ?.map((sport) => sport.name)
                .join(", ") || "",
            oldValue:
              oldOpportunity.account?.sports
                ?.map((sport) => sport.name)
                .join(", ") || ""
          }
        ]
      });

    if (
      opportunity.account?.aeOwnerId !== oldOpportunity.account?.aeOwnerId &&
      !organizationId
    )
      labels.push({
        label: `Updated the Account Details "AE Owner" field:`,
        value: [
          {
            value:
              formatFullName(opportunity.account?.aeOwner?.user?.person) ||
              "N/A",
            oldValue:
              formatFullName(oldOpportunity.account?.aeOwner?.user?.person) ||
              "N/A"
          }
        ]
      });

    if (
      opportunity.contact?.firstName !== oldOpportunity.contact?.firstName &&
      organizationId
    )
      labels.push({
        label: `Updated the "Contact Name" field:`,
        value: [
          {
            value: formatFullName(opportunity.contact) || "N/A",
            oldValue: formatFullName(oldOpportunity.contact) || "N/A"
          }
        ]
      });

    return labels;
  };

  return (
    <Loader isLoading={isLoadingConfig}>
      {recordType === "UPDATE" ? (
        <AuditLogLabelValueList
          recordType="UPDATE"
          labelValues={[...getUpdateLabels()]}
        />
      ) : (
        <AuditLogLabelValueList
          recordType={recordType}
          labelValues={[...getCreateLabels()]}
        />
      )}
    </Loader>
  );
};
