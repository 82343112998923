import { Loader } from "@components/crud/Loader";
import { Box, Grid, styled, Typography } from "@mui/material";
import {
  useConfigGet,
  useConfigPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { FormInput } from "@components/FormInput";
import { hasPermission } from "@services/Casbin";
import { Toolbar } from "@components/crud/Toolbar";
import { Edit } from "@mui/icons-material";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Footer } from "@components/crud/Footer";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { FormSelect } from "@components/FormSelect";

const StyledGrid = styled(Grid)`
  padding: 0 24px;
  .toolbar {
    padding: 24px 0 0 0;
  }
`;

const StyledSwitch = styled(Box)`
  display: flex;
  height: 50px;
  width: 100px;
  margin-top: 27px;
  margin-left: 8px;
  border-radius: 6px;
  padding: 4px;
  background: #f5f5f5;
  box-shadow: 0px 1px 2px 0px #0000001a;
  button {
    width: 50%;
    height: 98%;
    padding: 8px;
    border-radius: 4px;
    background: #f5f5f5;
    border: none;
    cursor: pointer;
  }
  button.selected {
    box-shadow: 0px 0px 4px 0px #0000001f;
    background: #ffffff;
  }
`;

const getRecFeedSettingOptions = () => {
  return Array.from({ length: 11 }, (_, i) => ({
    label: i.toString(),
    value: i.toString()
  }));
};

export const FeedSetting = () => {
  const { reset, control, getValues, setValue } = useForm({
    mode: "onTouched"
  });
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [permission, setPermission] = useState(false);
  const [selected, setSelected] = useState("Hr");
  const { data: settings, isLoading: isLoading } = useConfigGet();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if (settings && settings.data) {
      reset({
        "ad-frequency": settings.data.find(
          (item) => item.key === "feed.ad-frequency"
        )?.value,
        "edit-time": (() => {
          const value = settings.data.find(
            (item) => item.key === "feed.edit-time-limit"
          )?.value as string;
          if (value.toString().includes(":")) {
            const parts = value.includes(":") && value.split(":");
            return parts[0] !== "00" ? parts[0] : parts[1];
          } else {
            return value;
          }
        })(),
        "freemium-limit": settings.data.find(
          (s) => s.key === "feed.rec-feed.freemium-min"
        )?.value,
        "paid-limit": settings.data.find(
          (s) => s.key === "feed.rec-feed.paid-min"
        )?.value
      });
    }
    const editTimeLimit = settings?.data.find(
      (item) => item.key === "feed.edit-time-limit"
    )?.value as string;

    if (editTimeLimit && editTimeLimit.toString().includes(":")) {
      const timePart = editTimeLimit.split(":")[1];
      setSelected(timePart !== "00" ? "Min" : "Hr");
    } else {
      setSelected("Hr");
    }
  }, [settings, refreshKey]);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("admin.settings", "EDIT");
      setPermission(edit);
    };
    fetchPermissions();
  }, []);

  const { mutate: save, isLoading: isSaving } = useConfigPut();
  const onSave = () => {
    const data = [
      {
        key: "feed.edit-time-limit",
        value: getValues("feed.edit-time-limit")
      },
      {
        key: "feed.ad-frequency",
        value: getValues("ad-frequency")
      },
      {
        key: "feed.rec-feed.freemium-min",
        value: getValues("freemium-limit")
      },
      {
        key: "feed.rec-feed.paid-min",
        value: getValues("paid-limit")
      }
    ];
    if (settings?.data) {
      save(
        {
          data: {
            configs: data,
            type: "FSGO"
          }
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Saved Successfully!", {
              variant: "success"
            });
            setDisabled(true);
          },
          onError: () => {
            enqueueSnackbar("Failed to save !", {
              variant: "error"
            });
          }
        }
      );
    }
  };
  return (
    <Loader isLoading={isLoading}>
      <StyledGrid item container direction="column">
        {permission && disabled && (
          <Toolbar
            title=""
            addBtnClick={() => setDisabled(false)}
            addBtnLabel="Edit"
            addBtnIcon={<Edit />}
          />
        )}
        <Grid item container xs={12} direction="column" marginBottom="24px">
          <Grid item>
            <Typography
              variant="permissionNames"
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                letterSpacing: "10%",
                lineHeight: "14.52px",
                color: "#2B337A"
              }}
            >
              MY FEED
            </Typography>
          </Grid>

          <HeaderUnderLine width="100%" />
        </Grid>
        <Grid xs={6} data-testid="feed-ad-frequency">
          <FormInput
            disabled={disabled}
            control={control}
            label="Feed Post Ads Frequency"
            name="ad-frequency"
            type="number"
            onBlur={() =>
              setValue("feed.ad-frequency", getValues("ad-frequency"))
            }
          />
        </Grid>
        <Grid
          xs={6}
          sx={{ marginTop: "24px", display: "flex", alignItems: "center" }}
          data-testid="feed-ad-edit-time"
        >
          <FormInput
            disabled={disabled}
            control={control}
            label="Full Edit Post Time Frame"
            name="edit-time"
            type="number"
            onBlur={() =>
              setValue(
                "feed.edit-time-limit",
                selected === "Hr"
                  ? `${getValues("edit-time")}:00`
                  : `00:${getValues("edit-time")}`
              )
            }
          />
          <StyledSwitch>
            <button
              disabled={disabled}
              className={`option ${selected === "Hr" ? "selected" : ""}`}
              onClick={() => {
                setSelected("Hr");
                setValue(
                  "feed.edit-time-limit",
                  `${getValues("edit-time")}:00`
                );
              }}
            >
              Hr
            </button>
            <button
              disabled={disabled}
              className={`option ${selected === "Min" ? "selected" : ""}`}
              onClick={() => {
                setSelected("Min");
                setValue(
                  "feed.edit-time-limit",
                  `00:${getValues("edit-time")}`
                );
              }}
            >
              Min
            </button>
          </StyledSwitch>
        </Grid>

        <Grid
          item
          container
          xs={12}
          direction="column"
          marginBottom="24px"
          marginTop="24px"
        >
          <Grid item>
            <Typography
              variant="permissionNames"
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                letterSpacing: "10%",
                lineHeight: "14.52px",
                color: "#2B337A"
              }}
            >
              RECRUITING FEED
            </Typography>
          </Grid>
          <HeaderUnderLine width="100%" />
        </Grid>

        <Grid xs={6}>
          <FormSelect
            disabled={disabled}
            control={control}
            label="Freemium User Minimum Post Requirement"
            name="freemium-limit"
            onChange={() =>
              setValue(
                "feed.rec-feed.freemium-min",
                getValues("freemium-limit")
              )
            }
            options={getRecFeedSettingOptions()}
            required
          />
        </Grid>
        <Grid
          xs={6}
          sx={{ marginTop: "24px", display: "flex", alignItems: "center" }}
        >
          <FormSelect
            disabled={disabled}
            control={control}
            label="Paid User Minimum Post Requirement"
            name="paid-limit"
            required
            onChange={() =>
              setValue("feed.rec-feed.paid-min", getValues("paid-limit"))
            }
            options={getRecFeedSettingOptions()}
          />
        </Grid>
      </StyledGrid>
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => {
          setRefreshKey((prev) => prev + 1);
          setDisabled(true);
        }}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
      {!disabled && (
        <Footer
          saveBtnClick={onSave}
          isLoading={isSaving}
          isDisabled={isSaving}
          saveBtnLabel="Save"
          cancelBtnClick={() => setOpenCancelDialog(true)}
        />
      )}
    </Loader>
  );
};
