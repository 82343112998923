import Grid from "@mui/system/Unstable_Grid";
import { organizationAtom } from "@recoil/auth";
import { ModelAuditLog } from "@sportsgravyengineering/sg-api-react-sdk";
import { AUDIT_LOG_OPERATIONS } from "@utils/constants";
import formatFullName from "@utils/formatFullName";
import React from "react";
import { useRecoilValue } from "recoil";

const formatDate = (datetimeString: string | undefined | Date) => {
  if (!datetimeString) return "";

  const date = new Date(datetimeString);
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true
  };

  const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(date);

  return formattedDate.replace(",", "");
};

const roleOrder = {
  ADMIN: 1,
  COACH: 2,
  MANAGER: 3,
  PARENT: 4,
  PLAYER: 5
};

export const LogCreatedBy = ({ log }: { log: ModelAuditLog }) => {
  const organizationId = useRecoilValue(organizationAtom);
  const avatarUrl = log?.createdBy?.person?.avatar?.baseUrl
    ? log?.createdBy?.person?.avatar?.baseUrl +
      log?.createdBy?.person?.avatar?.path
    : "";
  const initialsText = `${log.createdBy?.person?.firstName?.charAt(0)}${log.createdBy?.person?.lastName?.charAt(0)}`;
  const roles = log?.createdBy?.roles
    ?.filter((r) =>
      organizationId
        ? r.organizationId === organizationId && r.role?.type === "ORGANIZATION"
        : r.role?.type === "SYSTEM" || r.role?.type === "ORGANIZATION"
    )
    .sort((a, b) => {
      const aliasA = a?.role?.alias || "";
      const aliasB = b?.role?.alias || "";
      return (roleOrder[aliasA] || 0) - (roleOrder[aliasB] || 0);
    });

  const isSGAdmin =
    roles?.every((role) => role.role?.type === "SYSTEM") ||
    !roles ||
    roles.length === 0;

  return (
    <Grid
      container
      direction="row"
      spacing="15px"
      alignItems="center"
      className="log-created-by"
    >
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "48px",
          height: "48px",
          borderRadius: "8px",
          backgroundColor: `#${log.createdBy?.person?.colorCode}`,
          color: "white"
        }}
      >
        {log.createdBy?.person?.avatarId ? (
          <img
            src={avatarUrl}
            style={{
              width: "48px",
              height: "48px",
              borderRadius: "8px",
              zIndex: 2
            }}
            alt={`${log.createdBy?.person?.firstName} ${log.createdBy?.person?.lastName}`}
          />
        ) : (
          <span>{initialsText.toUpperCase()}</span>
        )}
      </Grid>
      <Grid
        container
        direction="column"
        spacing="5px"
        xs={11}
        paddingLeft="16px"
      >
        <Grid container direction="row" spacing="10px">
          <Grid paddingTop="0" paddingBottom="0">
            <span
              style={{
                fontWeight: 700,
                fontSize: "14px",
                ...(isSGAdmin
                  ? {
                      color: "#000",
                      cursor: "default"
                    }
                  : {
                      color: "#007AFF",
                      cursor: "pointer"
                    })
              }}
              onClick={() =>
                !isSGAdmin && window.open(`/users/${log.createdBy?.personId}`)
              }
            >
              {`${formatFullName(log.createdBy?.person)} `}
              {isSGAdmin
                ? "(SportsGravy Admin)"
                : `(${roles?.[0]?.role?.name})`}
            </span>
          </Grid>
          <Grid paddingTop="0" paddingBottom="0">
            <span style={{ color: "#939CA8", fontSize: "14px" }}>
              • {formatDate(log.createdAt)}
            </span>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing="4px" alignItems="center">
          <Grid
            style={{
              ...AUDIT_LOG_OPERATIONS.find((a) => a.value === log.operation)
                ?.style,
              padding: "2px 8px 2px 8px",
              borderRadius: "4px"
            }}
          >
            <span style={{ fontWeight: 600, fontSize: "14px" }}>
              {
                AUDIT_LOG_OPERATIONS.find((a) => a.value === log.operation)
                  ?.label
              }
            </span>
          </Grid>
          <Grid style={{ paddingLeft: "4px" }}>
            <span
              style={{
                fontWeight: 500,
                fontSize: "16px"
              }}
            >
              {log.description}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
