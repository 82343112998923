import { PermissionAction } from "@sportsgravyengineering/sg-api-react-sdk";
import { isEqual } from "@utils/objectFunctions";

const getCoachesWithSubRole = (props) => {
  const temp = props.coachesWithValues
    .filter(
      (coach) =>
        coach.roleId !== props.coachRole.roleId &&
        coach.roleId !== props.coachRole.inheritedFromId
    )
    .map((coach) => {
      const assignedPermissions = coach.permissions || [];
      return {
        ...coach,
        title: "Assinged Permissions",
        permissions: props.permissions.map((permission, idx) => {
          const permissionActions =
            assignedPermissions && assignedPermissions.length
              ? assignedPermissions?.find(
                  (rp) => rp.permissionId === permission.permissionId
                )?.actions || []
              : props.coachRole.children
                  ?.find(
                    (r) =>
                      r.roleId === coach.roleId ||
                      r.inheritedFromId === coach.roleId
                  )
                  ?.permissions?.find(
                    (rp) => rp.permissionId === permission.permissionId
                  )?.actions || [];
          const selections = {};
          if (permission.type === "MULTI") {
            for (const action of permission.actions) {
              selections[`${coach.roleId}__${coach.id}__${idx}__${action}`] =
                permissionActions.includes(action as PermissionAction) ||
                undefined;
            }
          }

          if (permission.type === "BOOL") {
            selections[`${coach.roleId}__${coach.id}__${idx}`] =
              permissionActions.includes(PermissionAction.ON) || false;
          }

          return {
            userRoleId: coach.userRoleId,
            permissionId: permission.permissionId,
            actions: permissionActions,
            selections: selections
          };
        })
      };
    });
  return temp;
};

const getCoachesWithNoSubRoles = (props) => {
  const temp = props.coachesWithValues
    .filter(
      (coach) =>
        coach.roleId === props.coachRole.roleId ||
        coach.roleId === props.coachRole.inheritedFromId
    )
    .map((coach) => {
      const assignedPermissions = coach.permissions || [];
      return {
        ...coach,
        permissions: props.permissions.map((permission, idx) => {
          const selections = {};
          const permissionActions =
            assignedPermissions && assignedPermissions.length
              ? assignedPermissions?.find(
                  (rp) => rp.permissionId === permission.permissionId
                )?.actions || []
              : props.coachRole.permissions?.find(
                  (rp) => rp.permissionId === permission.permissionId
                )?.actions || [];

          if (permission.type === "MULTI") {
            for (const action of permission.actions) {
              selections[`${coach.roleId}__${coach.id}__${idx}__${action}`] =
                permissionActions.includes(action as PermissionAction) ||
                undefined;
            }
          }

          if (permission.type === "BOOL") {
            selections[`${coach.roleId}__${coach.id}__${idx}`] =
              permissionActions.includes(PermissionAction.ON) || false;
          }

          return {
            userRoleId: undefined,
            permissionId: permission.permissionId,
            actions: permissionActions,
            selections: selections
          };
        })
      };
    });
  return temp;
};

const getManagersWithSubRoles = (props) => {
  const temp = props.managerWithValues
    .filter(
      (manager) =>
        manager.roleId !== props.managerRole.roleId &&
        manager.roleId !== props.managerRole.inheritedFromId
    )
    .map((manager) => {
      const assignedPermissions = manager.permissions || [];
      return {
        ...manager,
        permissions: props.permissions.map((permission, idx) => {
          const permissionActions =
            assignedPermissions && assignedPermissions.length
              ? assignedPermissions?.find(
                  (rp) => rp.permissionId === permission.permissionId
                )?.actions
              : props.managerRole.children
                  ?.find(
                    (r) =>
                      r.roleId === manager.roleId ||
                      r.inheritedFromId === manager.roleId
                  )
                  ?.permissions?.find(
                    (rp) => rp.permissionId === permission.permissionId
                  )?.actions || [];
          const selections = {};
          if (permission.type === "MULTI") {
            for (const action of permission.actions) {
              selections[
                `${manager.roleId}__${manager.id}__${idx}__${action}`
              ] =
                permissionActions?.includes(action as PermissionAction) ||
                undefined;
            }
          }
          if (permission.type === "BOOL") {
            selections[`${manager.roleId}__${manager.id}__${idx}`] =
              permissionActions?.includes(PermissionAction.ON) || false;
          }
          return {
            userRoleId: undefined,
            permissionId: permission.permissionId,
            actions: permissionActions,
            selections: selections
          };
        })
      };
    });
  return temp;
};

const getManagersWithNoSubRoles = (props) => {
  const temp = props.managerWithValues
    .filter(
      (manager) =>
        manager.roleId === props.managerRole.roleId ||
        manager.roleId === props.managerRole.inheritedFromId
    )
    .map((manager) => {
      const assignedPermissions = manager.permissions || [];
      return {
        ...manager,
        permissions: props.permissions.map((permission, idx) => {
          const permissionActions =
            assignedPermissions && assignedPermissions.length
              ? assignedPermissions?.find(
                  (rp) => rp.permissionId === permission.permissionId
                )?.actions || []
              : props.managerRole.permissions?.find(
                  (rp) => rp.permissionId === permission.permissionId
                )?.actions || [];
          const selections = {};
          if (permission.type === "MULTI") {
            for (const action of permission.actions) {
              selections[
                `${manager.roleId}__${manager.id}__${idx}__${action}`
              ] =
                permissionActions.includes(action as PermissionAction) ||
                undefined;
            }
          }
          if (permission.type === "BOOL") {
            selections[`${manager.roleId}__${manager.id}__${idx}`] =
              permissionActions.includes(PermissionAction.ON) || false;
          }

          return {
            userRoleId: undefined,
            permissionId: permission.permissionId,
            actions: permissionActions,
            selections: selections
          };
        })
      };
    });
  return temp;
};
const getParents = (props) => {
  const temp = props.playersWithValues
    .map((player) => player.parent)
    .filter(Boolean)
    .map((parent) => {
      const parentAssignedPermissions = parent?.permissions || [];
      return {
        ...parent,
        permissions: props.permissions.map((permission, idx) => {
          const selections = {};
          const permissionActions =
            parentAssignedPermissions && parentAssignedPermissions.length
              ? parentAssignedPermissions?.find(
                  (rp) => rp.permissionId === permission.permissionId
                )?.actions || []
              : props.parentRole.permissions?.find(
                  (rp) => rp.permissionId === permission.permissionId
                )?.actions || [];

          if (permission.type === "MULTI") {
            for (const action of permission.actions) {
              selections[`${parent.roleId}__${parent.id}__${idx}__${action}`] =
                permissionActions.includes(action as PermissionAction) ||
                undefined;
            }
          }

          if (permission.type === "BOOL") {
            selections[`${parent.roleId}__${parent.id}__${idx}`] =
              permissionActions.includes(PermissionAction.ON) || false;
          }

          return {
            userRoleId: undefined,
            permissionId: permission.permissionId,
            actions: permissionActions,
            selections: selections
          };
        })
      };
    });
  return temp;
};

const getPlayers = (props) => {
  const temp = props.playersWithValues.map((player) => {
    const playerAssignedPermissions = player.permissions || [];
    return {
      ...player,
      permissions: props.permissions.map((permission, idx) => {
        const selections = {};
        const permissionActions =
          playerAssignedPermissions && playerAssignedPermissions.length
            ? playerAssignedPermissions?.find(
                (rp) => rp.permissionId === permission.permissionId
              )?.actions || []
            : props.playerRole.permissions?.find(
                (rp) => rp.permissionId === permission.permissionId
              )?.actions || [];

        if (permission.type === "MULTI") {
          for (const action of permission.actions) {
            selections[`${player.roleId}__${player.id}__${idx}__${action}`] =
              permissionActions?.includes(action as PermissionAction) || false;
          }
        }

        if (permission.type === "BOOL") {
          selections[`${player.roleId}__${player.id}__${idx}`] =
            permissionActions?.includes(PermissionAction.ON) || false;
        }
        return {
          userRoleId: undefined,
          permissionId: permission.permissionId,
          actions: permissionActions,
          selections: selections
        };
      })
    };
  });
  return temp;
};

const getDefaultPermissions = (props) => {
  const rolePermission = [];

  rolePermission.push({
    ...props.coachRole,
    permissions: props.permissions.map((permission, idx) => {
      const permissionActions =
        props.coachRole.permissions?.find(
          (rp) => rp.permissionId === permission.permissionId
        )?.actions || [];

      const selections = {};
      if (permission.type === "MULTI") {
        permission.actions.forEach((action) => {
          selections[`${props.coachRole.roleId}__${idx}__${action}`] =
            permissionActions?.includes(action as PermissionAction) || false;
        });
      }

      if (permission.type === "BOOL") {
        selections[`${props.coachRole.roleId}__${idx}`] =
          permissionActions?.includes(PermissionAction.ON) || false;
      }

      return {
        userRoleId: undefined,
        permissionId: permission.permissionId,
        selections: selections,
        actions:
          props.coachRole.permissions?.find(
            (rp) => rp.permissionId === permission.permissionId
          )?.actions || []
      };
    })
  });

  rolePermission.push({
    ...props.managerRole,
    permissions: props.permissions.map((permission, idx) => {
      const permissionActions =
        props.managerRole.permissions?.find(
          (rp) => rp.permissionId === permission.permissionId
        )?.actions || [];

      const selections = {};
      if (permission.type === "MULTI") {
        permission.actions.forEach((action) => {
          selections[`${props.managerRole.roleId}__${idx}__${action}`] =
            permissionActions?.includes(action as PermissionAction) || false;
        });
      }

      if (permission.type === "BOOL") {
        selections[`${props.managerRole.roleId}__${idx}`] =
          permissionActions?.includes(PermissionAction.ON) || false;
      }

      return {
        userRoleId: undefined,
        permissionId: permission.permissionId,
        selections: selections,
        actions: props.managerRole.permissions?.find(
          (rp) => rp.permissionId === permission.permissionId
        )?.actions
      };
    })
  });

  rolePermission.push({
    ...props.playerRole,
    permissions: props.permissions.map((permission, idx) => {
      const permissionActions =
        props.playerRole.permissions?.find(
          (rp) => rp.permissionId === permission.permissionId
        )?.actions || [];

      const selections = {};
      if (permission.type === "MULTI") {
        permission.actions.forEach((action) => {
          selections[`${props.playerRole.roleId}__${idx}__${action}`] =
            permissionActions?.includes(action as PermissionAction) || false;
        });
      }

      if (permission.type === "BOOL") {
        selections[`${props.playerRole.roleId}__${idx}`] =
          permissionActions?.includes(PermissionAction.ON) || false;
      }

      return {
        userRoleId: undefined,
        permissionId: permission.permissionId,
        actions: permissionActions,
        selections: selections
      };
    })
  });

  rolePermission.push({
    ...props.parentRole,
    permissions: props.permissions.map((permission, idx) => {
      const permissionActions =
        props.parentRole.permissions?.find(
          (rp) => rp.permissionId === permission.permissionId
        )?.actions || [];

      const selections = {};
      if (permission.type === "MULTI") {
        permission.actions.forEach((action) => {
          selections[`${props.parentRole.roleId}__${idx}__${action}`] =
            permissionActions?.includes(action as PermissionAction) || false;
        });
      }

      if (permission.type === "BOOL") {
        selections[`${props.parentRole.roleId}__${idx}`] =
          permissionActions?.includes(PermissionAction.ON) || false;
      }

      return {
        userRoleId: undefined,
        selections: selections,
        permissionId: permission.permissionId,
        actions: permissionActions
      };
    })
  });
  // repeat for sub roles
  props.coachSubRoles.forEach((subRole) => {
    rolePermission.push({
      ...subRole,
      permissions: props.permissions.map((permission, idx) => {
        const permissionActions =
          subRole.permissions?.find(
            (rp) => rp.permissionId === permission.permissionId
          )?.actions || [];

        const selections = {};
        if (permission.type === "MULTI") {
          permission.actions.forEach((action) => {
            selections[`${subRole.roleId}__${idx}__${action}`] =
              permissionActions?.includes(action as PermissionAction) || false;
          });
        }

        if (permission.type === "BOOL") {
          selections[`${subRole.roleId}__${idx}`] =
            permissionActions?.includes(PermissionAction.ON) || false;
        }

        return {
          userRoleId: undefined,
          permissionId: permission.permissionId,
          actions: permissionActions,
          selections: selections
        };
      })
    });
  });

  props.managerSubRoles.forEach((subRole) => {
    rolePermission.push({
      ...subRole,
      permissions: props.permissions.map((permission, idx) => {
        const permissionActions =
          subRole.permissions?.find(
            (rp) => rp.permissionId === permission.permissionId
          )?.actions || [];

        const selections = {};
        if (permission.type === "MULTI") {
          permission.actions.forEach((action) => {
            selections[`${subRole.roleId}__${idx}__${action}`] =
              permissionActions?.includes(action as PermissionAction) || false;
          });
        }

        if (permission.type === "BOOL") {
          selections[`${subRole.roleId}__${idx}`] =
            permissionActions?.includes(PermissionAction.ON) || false;
        }

        return {
          userRoleId: undefined,
          permissionId: permission.permissionId,
          actions: permissionActions,
          selections: selections
        };
      })
    });
  });

  return rolePermission;
};
export const generatePermissionValues = (props) => {
  const coachesWithSubRole = getCoachesWithSubRole(props);
  let updatedCoachesValues = [
    ...coachesWithSubRole,
    ...props.coachesWithValues.filter(
      (coach) =>
        coach.roleId === props.coachRole.roleId ||
        coach.roleId === props.coachRole.inheritedFromId
    )
  ];
  if (!isEqual(updatedCoachesValues, props.coachesWithValues)) {
    props.coachesWithValues = updatedCoachesValues;
  }
  const coachesWithNoSubRole = getCoachesWithNoSubRoles(props);
  updatedCoachesValues = [
    ...coachesWithNoSubRole,
    ...props.coachesWithValues.filter(
      (coach) =>
        coach.roleId !== props.coachRole.roleId &&
        coach.roleId !== props.coachRole.inheritedFromId
    )
  ];
  const subRoleToCoachMap = props.coachSubRoles.reduce((acc, subRole) => {
    acc[subRole.roleId!] = coachesWithSubRole.filter(
      (coach) => coach.roleId === subRole.roleId
    );
    if (acc[subRole.roleId!].length === 0) {
      delete acc[subRole.roleId!];
    }
    return acc;
  }, {});
  const managersWithSubRole = getManagersWithSubRoles(props);
  let updatedManagerValues = [
    ...managersWithSubRole,
    ...props.managerWithValues.filter(
      (manager) =>
        manager.roleId === props.managerRole.roleId ||
        manager.roleId === props.managerRole.inheritedFromId
    )
  ];
  if (!isEqual(updatedManagerValues, props.managerWithValues)) {
    props.managerWithValues = updatedManagerValues;
  }
  const managersWithNoSubRole = getManagersWithNoSubRoles(props);
  updatedManagerValues = [
    ...managersWithNoSubRole,
    ...props.managerWithValues.filter(
      (manager) =>
        manager.roleId !== props.managerRole.roleId &&
        manager.roleId !== props.managerRole.inheritedFromId
    )
  ];

  const subRoleToManagerMap = props.managerSubRoles.reduce((acc, subRole) => {
    acc[subRole.roleId!] = managersWithSubRole.filter(
      (manager) => manager.roleId === subRole.roleId
    );
    if (acc[subRole.roleId!].length === 0) {
      delete acc[subRole.roleId!];
    }
    return acc;
  }, {});

  const parents = getParents(props);
  let updatedPlayers = [
    ...props.playersWithValues
      .filter((player) => player.parent)
      .map((player) => {
        return {
          ...player,
          parent: parents.find((parent) => parent.id === player.parent?.id)
        };
      }),
    ...props.playersWithValues.filter((player) => !player.parent)
  ];
  if (!isEqual(updatedPlayers, props.playersWithValues)) {
    props.playersWithValues = updatedPlayers;
  }
  const players = getPlayers(props);
  updatedPlayers = [players];

  const defaultPermissions = getDefaultPermissions(props);
  return {
    updatedCoachesValues,
    subRoleToCoachMap,
    updatedManagerValues,
    subRoleToManagerMap,
    updatedPlayers,
    defaultPermissions,
    coachesWithSubRole,
    coachesWithNoSubRole,
    managersWithNoSubRole,
    managersWithSubRole,
    parents,
    players
  };
};
