import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import {
  ModelLead,
  ModelLeadAccount
} from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";

export const LeadsAuditLogCreateDelete = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelLead;
  after: object | ModelLead;
}) => {
  const leadDetails = (recordType === "CREATE" ? after : before) as ModelLead;
  const getLeadDetails = () => {
    const leadAccount = leadDetails.account as ModelLeadAccount;
    const labelValues: AuditLogLabelValue[] = [];
    labelValues.push({
      label: `"Name" field:`,
      value: [
        {
          value: leadDetails?.name || ""
        }
      ]
    });
    labelValues.push({
      label: `"Source" field:`,
      value: [
        {
          value: leadDetails?.source?.name || ""
        }
      ]
    });
    labelValues.push({
      label: `"Status" field:`,
      value: [
        {
          value: leadDetails?.status || ""
        }
      ]
    });
    labelValues.push({
      label: `"Lead Owner" field:`,
      value: [
        {
          value: leadDetails?.owner?.name || ""
        }
      ]
    });
    labelValues.push({
      label: `Account Details "Account Name" field:`,
      value: [
        {
          value: leadAccount?.name || ""
        }
      ]
    });
    labelValues.push({
      label: `Account Details "Account Parent" field:`,
      value: [
        {
          value: leadAccount?.parent?.name || ""
        }
      ]
    });
    labelValues.push({
      label: `Account Details "Account Industry" field:`,
      value: [
        {
          value: leadAccount?.industry?.name || ""
        }
      ]
    });
    labelValues.push({
      label: `Account Details "Account Type" field:`,
      value: [
        {
          value: leadAccount?.type || ""
        }
      ]
    });
    labelValues.push({
      label: `Account Details "Account Address" field:`,
      value: [
        {
          value: leadAccount?.officeAddress || ""
        }
      ]
    });
    labelValues.push({
      label: `Account Details "Account Email" field:`,
      value: [
        {
          value: leadAccount?.email || ""
        }
      ]
    });
    labelValues.push({
      label: `Account Details "Account Website" field:`,
      value: [
        {
          value: leadAccount?.website || ""
        }
      ]
    });
    labelValues.push({
      label: `Account Details "Sports Offered" field:`,
      value: [
        {
          value: leadAccount?.sports?.map((sport) => sport.name).join(",") || ""
        }
      ]
    });
    labelValues.push({
      label: `Account Details "No. of Yearly Athlete Registrations" field:`,
      value: [
        {
          value: leadAccount?.numberOfAthletes || ""
        }
      ]
    });
    labelValues.push({
      label: `Account Details "AE owner" field:`,
      value: [
        {
          value: leadAccount?.aeOwner || ""
        }
      ]
    });

    return labelValues;
  };
  return (
    <AuditLogLabelValueList
      recordType={recordType}
      labelValues={[...getLeadDetails()]}
    />
  );
};
