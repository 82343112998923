import { ModelImportTemplateProvider } from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { ImportTemplateProviderAuditLogUpdate } from "./ImportTemplateProviderAuditLogUpdate";
import { ImportTemplateProviderAuditLogCreateDelete } from "./ImportTemplateProviderAuditLogCreateDelete";

export const ImportTemplateProviderAuditLogDetails = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelImportTemplateProvider;
  after: object | ModelImportTemplateProvider;
}) => {
  if (recordType === "UPDATE")
    return (
      <ImportTemplateProviderAuditLogUpdate before={before} after={after} />
    );
  else
    return (
      <ImportTemplateProviderAuditLogCreateDelete
        recordType={recordType}
        before={before}
        after={after}
      />
    );
};
