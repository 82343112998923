import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { ModelAccount } from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";

export const AccountsAuditLogCreateDelete = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "DELETE";
  before: object | ModelAccount;
  after: object | ModelAccount;
}) => {
  const accountDetails = (
    recordType === "CREATE" ? after : before
  ) as ModelAccount;

  const getAccountDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];

    labelValues.push({
      label: `"Name" field:`,
      value: [
        {
          value: accountDetails?.name || ""
        }
      ]
    });

    labelValues.push({
      label: `"Parent" field:`,
      value: [
        {
          value: accountDetails?.parent?.name || ""
        }
      ]
    });

    labelValues.push({
      label: `"Industry" field:`,
      value: [
        {
          value: accountDetails?.category || ""
        }
      ]
    });

    labelValues.push({
      label: `"Type" field:`,
      value: [
        {
          value: accountDetails?.type || ""
        }
      ]
    });

    labelValues.push({
      label: `"BDR Owner" field:`,
      value: [
        {
          value: `${accountDetails?.bdrOwner?.person?.firstName || ""} ${
            accountDetails?.bdrOwner?.person?.lastName || ""
          }`.trim()
        }
      ]
    });

    labelValues.push({
      label: `"AE Owner" field:`,
      value: [
        {
          value: `${accountDetails?.aeOwner?.person?.firstName || ""} ${
            accountDetails?.aeOwner?.person?.lastName || ""
          }`.trim()
        }
      ]
    });

    labelValues.push({
      label: `"CSM Owner" field:`,
      value: [
        {
          value: `${accountDetails?.csmOwner?.person?.firstName || ""} ${
            accountDetails?.csmOwner?.person?.lastName || ""
          }`.trim()
        }
      ]
    });

    labelValues.push({
      label: `Address "Office Address" field:`,
      value: [
        {
          value: accountDetails?.officeAddress || ""
        }
      ]
    });

    labelValues.push({
      label: `Address "Billing Address is the same as Office Address" field:`,
      value: [
        {
          value:
            accountDetails?.billingAddress === accountDetails?.officeAddress
              ? "On"
              : "Off"
        }
      ]
    });

    labelValues.push({
      label: `Address "Billing Address" field:`,
      value: [
        {
          value: accountDetails?.billingAddress || ""
        }
      ]
    });

    labelValues.push({
      label: `Address "Shipping Address is the same as Office Address" field:`,
      value: [
        {
          value:
            accountDetails?.shippingAddress === accountDetails?.officeAddress
              ? "On"
              : "Off"
        }
      ]
    });

    labelValues.push({
      label: `Address "Shipping Address" field:`,
      value: [
        {
          value: accountDetails?.shippingAddress || ""
        }
      ]
    });

    accountDetails?.contacts?.forEach((contact, index) => {
      if (contact?.contact) {
        const contactIndex = index + 1; // 1-based index

        labelValues.push({
          label: `Contact ${contactIndex} "Phone" field:`,
          value: [
            {
              value: contact?.contact?.phone || ""
            }
          ]
        });

        labelValues.push({
          label: `Contact ${contactIndex} "FAX" field:`,
          value: [
            {
              value: contact?.contact?.whatsappNumber || "" // Assuming WhatsApp number could serve as fax
            }
          ]
        });

        labelValues.push({
          label: `Contact ${contactIndex} "Email" field:`,
          value: [
            {
              value: contact?.contact?.email || ""
            }
          ]
        });

        labelValues.push({
          label: `Contact ${contactIndex} "Website" field:`,
          value: [
            {
              value: contact?.contact?.links?.[0]?.link || "" // Assuming first link is the website
            }
          ]
        });
      }
    });

    const accountSports = accountDetails.sports;

    labelValues.push({
      label: `Additional Info "Sports Offered" field:`,
      value: [
        {
          value: accountSports?.map((sport) => sport.name).join(", ") || ""
        }
      ]
    });
    labelValues.push({
      label: `Additional Info "No. of Athletes" field:`,
      value: [
        {
          value: accountDetails?.numberOfAthletes?.toString() || ""
        }
      ]
    });
    labelValues.push({
      label: `Additional Info "Notes" field:`,
      value: [
        {
          value: accountDetails?.notes || ""
        }
      ]
    });

    accountDetails?.links?.forEach((link, index) => {
      const linkIndex = index + 1; // 1-based index

      labelValues.push({
        label: `Related Links ${linkIndex} "URL" field:`,
        value: [
          {
            value: link?.link || ""
          }
        ]
      });

      labelValues.push({
        label: `Related Links ${linkIndex} "Name" field:`,
        value: [
          {
            value: link?.type || "Other" // Defaulting to "Other" if type is not provided
          }
        ]
      });

      // Only add "Other Name" if altName is present
      if (link?.altName) {
        labelValues.push({
          label: `Related Links ${linkIndex} "Other Name" field:`,
          value: [
            {
              value: link.altName
            }
          ]
        });
      }
    });

    return labelValues;
  };

  return (
    <AuditLogLabelValueList
      recordType={recordType}
      labelValues={[...getAccountDetails()]}
    />
  );
};
