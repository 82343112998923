import { Container } from "@components/crud/Container";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "../types";
import { ModelCannedMessage } from "@sportsgravyengineering/sg-api-react-sdk";
import { GENDERS } from "@utils/constants";
import { getIdSet } from "../ViewAuditLog";

export const CannedMessageAuditLogUpdate = ({
  before,
  after
}: {
  before: object | ModelCannedMessage;
  after: object | ModelCannedMessage;
}) => {
  const cannedMessage = after as ModelCannedMessage;
  const oldCannedMessage = before as ModelCannedMessage;
  const getLabelValues = () => {
    const labelValues: AuditLogLabelValue[] = [];
    if (cannedMessage.message !== oldCannedMessage.message)
      labelValues.push({
        label: `Updated the "Message" field:`,
        value: [
          {
            value: cannedMessage.message || "",
            oldValue: oldCannedMessage.message || ""
          }
        ]
      });

    if (cannedMessage.title !== oldCannedMessage.title)
      labelValues.push({
        label: `Updated the "Title" field:`,
        value: [
          {
            value: cannedMessage.title || "",
            oldValue: oldCannedMessage.title || ""
          }
        ]
      });

    if (cannedMessage.sportId !== oldCannedMessage.sportId)
      labelValues.push({
        label: `Updated the "Sport" field:`,
        value: [
          {
            value: cannedMessage.sportId || "",
            oldValue: oldCannedMessage.sportId || ""
          }
        ]
      });

    const gendersChanged =
      (cannedMessage.genders?.length || 0) !==
        (oldCannedMessage.genders?.length || 0) ||
      new Set(cannedMessage.genders).size !==
        new Set(oldCannedMessage.genders).size ||
      cannedMessage.genders?.some(
        (gender) => !oldCannedMessage.genders?.includes(gender)
      );
    if (gendersChanged)
      labelValues.push({
        label: `Updated the "Gender" field:`,
        value: [
          {
            value:
              cannedMessage.genders
                ?.map((g) => GENDERS.find((g1) => g1.value === g)?.label)
                .join(", ") || "",
            oldValue:
              oldCannedMessage.genders
                ?.map((g) => GENDERS.find((g1) => g1.value === g)?.label)
                .join(", ") || ""
          }
        ]
      });

    const newLevelIds = getIdSet(cannedMessage?.levels, "levelId");
    const oldLevelIds = getIdSet(oldCannedMessage?.levels, "levelId");

    const levelIdsChanged =
      newLevelIds.size !== oldLevelIds.size ||
      [...newLevelIds].some((id) => !oldLevelIds.has(id));

    if (levelIdsChanged) {
      labelValues.push({
        label: `Updated the "Levels" field:`,
        value: [
          {
            value:
              cannedMessage.levels?.map((item) => item.name).join(", ") ||
              "N/A",
            oldValue:
              oldCannedMessage.levels?.map((item) => item.name).join(", ") ||
              "N/A"
          }
        ]
      });
    }

    const newPositionIds = getIdSet(cannedMessage?.positions, "positionId");
    const oldPositionIds = getIdSet(oldCannedMessage?.positions, "positionId");

    const positionIdsChanged =
      newPositionIds.size !== oldPositionIds.size ||
      [...newPositionIds].some((id) => !oldPositionIds.has(id));

    if (positionIdsChanged) {
      labelValues.push({
        label: `Updated the "Positions" field:`,
        value: [
          {
            value:
              cannedMessage.positions?.map((item) => item.name).join(", ") ||
              "N/A",
            oldValue:
              oldCannedMessage.positions?.map((item) => item.name).join(", ") ||
              "N/A"
          }
        ]
      });
    }

    const newSkillIds = getIdSet(cannedMessage?.skills, "skillId");
    const oldSkillIds = getIdSet(oldCannedMessage?.skills, "skillId");

    const skillIdsChanged =
      newSkillIds.size !== oldSkillIds.size ||
      [...newSkillIds].some((id) => !oldSkillIds.has(id));

    if (skillIdsChanged) {
      labelValues.push({
        label: `Updated the "Skills" field:`,
        value: [
          {
            value:
              cannedMessage.skills?.map((item) => item.name).join(", ") ||
              "N/A",
            oldValue:
              oldCannedMessage.skills?.map((item) => item.name).join(", ") ||
              "N/A"
          }
        ]
      });
    }

    const newConceptIds = getIdSet(cannedMessage?.concepts, "conceptId");
    const oldConceptIds = getIdSet(oldCannedMessage?.concepts, "conceptId");

    const conceptsIdsChanged =
      newConceptIds.size !== oldConceptIds.size ||
      [...newConceptIds].some((id) => !oldConceptIds.has(id));

    if (conceptsIdsChanged) {
      labelValues.push({
        label: `Updated the "Game Concepts" field:`,
        value: [
          {
            value:
              cannedMessage.concepts?.map((item) => item.name).join(", ") ||
              "N/A",
            oldValue:
              oldCannedMessage.concepts?.map((item) => item.name).join(", ") ||
              "N/A"
          }
        ]
      });
    }

    const newSystemIds = getIdSet(cannedMessage?.systems, "systemId");
    const oldSystemIds = getIdSet(oldCannedMessage?.systems, "systemId");

    const systemIdsChanged =
      newSystemIds.size !== oldSystemIds.size ||
      [...newSystemIds].some((id) => !oldSystemIds.has(id));

    if (systemIdsChanged) {
      labelValues.push({
        label: `Updated the "Game Systems" field:`,
        value: [
          {
            value:
              cannedMessage.systems?.map((item) => item.name).join(", ") ||
              "N/A",
            oldValue:
              oldCannedMessage.systems?.map((item) => item.name).join(", ") ||
              "N/A"
          }
        ]
      });
    }

    const newRoleIds = getIdSet(cannedMessage?.roles, "roleId");
    const oldRoleIds = getIdSet(oldCannedMessage?.roles, "roleId");

    const roleIdsChanged =
      newRoleIds.size !== oldRoleIds.size ||
      [...newRoleIds].some((id) => !oldRoleIds.has(id));

    if (roleIdsChanged) {
      labelValues.push({
        label: `Updated the "Roles" field:`,
        value: [
          {
            value:
              cannedMessage.roles?.map((item) => item.name).join(", ") || "N/A",
            oldValue:
              oldCannedMessage.roles?.map((item) => item.name).join(", ") ||
              "N/A"
          }
        ]
      });
    }

    return labelValues;
  };
  return (
    <Container style={{ marginTop: "10px" }}>
      <AuditLogLabelValueList
        recordType="UPDATE"
        labelValues={[...getLabelValues()]}
      />
    </Container>
  );
};
