import { ModelOrganizationSportLocation } from "@sportsgravyengineering/sg-api-react-sdk";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "../types";

export const SportLocationAuditLog = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelOrganizationSportLocation;
  after: object | ModelOrganizationSportLocation;
}) => {
  const sportLocation = (
    recordType !== "DELETE" ? after : before
  ) as ModelOrganizationSportLocation;
  const oldSportLocation = before as ModelOrganizationSportLocation;

  const getUpdateLabels = () => {
    const labels: AuditLogLabelValue[] = [];
    const oldAddress = [
      oldSportLocation.lines,
      oldSportLocation.locality,
      oldSportLocation.province,
      oldSportLocation.country,
      oldSportLocation.postalCode
    ].join(", ");

    const newAddress = [
      sportLocation.lines,
      sportLocation.locality,
      sportLocation.province,
      sportLocation.country,
      sportLocation.postalCode
    ]
      .filter((s) => s !== "")
      .join(", ");

    if (oldAddress !== newAddress)
      labels.push({
        label: `Updated the "Location" field:`,
        value: [
          {
            value: newAddress,
            oldValue: oldAddress
          }
        ]
      });

    if (oldSportLocation.name !== sportLocation.name)
      labels.push({
        label: `Updated the "Name" field:`,
        value: [
          {
            value: sportLocation.name || "",
            oldValue: oldSportLocation.name || ""
          }
        ]
      });

    return labels;
  };

  if (recordType === "UPDATE")
    return (
      <AuditLogLabelValueList
        recordType="UPDATE"
        labelValues={[...getUpdateLabels()]}
      />
    );
  else
    return (
      <AuditLogLabelValueList
        recordType={recordType}
        labelValues={[
          {
            label: `"Location" field:`,
            value: [
              {
                value:
                  [
                    sportLocation.lines,
                    sportLocation.locality,
                    sportLocation.province,
                    sportLocation.country,
                    sportLocation.postalCode
                  ]
                    .filter((s) => s !== "")
                    .join(", ") || ""
              }
            ]
          },
          {
            label: `"Name" field:`,
            value: [
              {
                value: sportLocation?.name || ""
              }
            ]
          }
        ]}
      />
    );
};
