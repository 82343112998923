import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import {
  ModelAccount,
  ModelUser
} from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";

export const AccountsAuditLogUpdate = ({
  before,
  after
}: {
  before: object | ModelAccount;
  after: object | ModelAccount;
}) => {
  const currentAccount = after as ModelAccount;
  const oldAccount = before as ModelAccount;
  const getAccountDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];

    if (currentAccount.name !== oldAccount.name) {
      labelValues.push({
        label: `"Name" field:`,
        value: [
          {
            value: currentAccount?.name || "",
            oldValue: oldAccount?.name || ""
          }
        ]
      });
    }

    if (currentAccount.parent?.name !== oldAccount.parent?.name) {
      labelValues.push({
        label: `"Parent" field:`,
        value: [
          {
            value: currentAccount?.parent?.name || "",
            oldValue: oldAccount?.parent?.name || ""
          }
        ]
      });
    }

    if (currentAccount.category !== oldAccount.category) {
      labelValues.push({
        label: `"Industry" field:`,
        value: [
          {
            value: currentAccount?.category || "",
            oldValue: oldAccount?.category || ""
          }
        ]
      });
    }

    if (currentAccount.type !== oldAccount.type) {
      labelValues.push({
        label: `"Type" field:`,
        value: [
          {
            value: currentAccount?.type || "",
            oldValue: oldAccount?.type || ""
          }
        ]
      });
    }

    const getOwnerName = (owner?: ModelUser) =>
      `${owner?.person?.firstName || ""} ${owner?.person?.lastName || ""}`.trim();

    if (
      getOwnerName(currentAccount.bdrOwner) !==
      getOwnerName(oldAccount.bdrOwner)
    ) {
      labelValues.push({
        label: `"BDR Owner" field:`,
        value: [
          {
            value: getOwnerName(currentAccount.bdrOwner),
            oldValue: getOwnerName(oldAccount.bdrOwner)
          }
        ]
      });
    }

    if (
      getOwnerName(currentAccount.aeOwner) !== getOwnerName(oldAccount.aeOwner)
    ) {
      labelValues.push({
        label: `"AE Owner" field:`,
        value: [
          {
            value: getOwnerName(currentAccount.aeOwner),
            oldValue: getOwnerName(oldAccount.aeOwner)
          }
        ]
      });
    }

    if (
      getOwnerName(currentAccount.csmOwner) !==
      getOwnerName(oldAccount.csmOwner)
    ) {
      labelValues.push({
        label: `"CSM Owner" field:`,
        value: [
          {
            value: getOwnerName(currentAccount.csmOwner),
            oldValue: getOwnerName(oldAccount.csmOwner)
          }
        ]
      });
    }

    if (currentAccount.officeAddress !== oldAccount.officeAddress) {
      labelValues.push({
        label: `Address "Office Address" field:`,
        value: [
          {
            value: currentAccount?.officeAddress || "",
            oldValue: oldAccount?.officeAddress || ""
          }
        ]
      });
    }

    if (currentAccount.billingAddress !== oldAccount.billingAddress) {
      labelValues.push({
        label: `Address "Billing Address" field:`,
        value: [
          {
            value: currentAccount?.billingAddress || "",
            oldValue: oldAccount?.billingAddress || ""
          }
        ]
      });
    }

    if (currentAccount.shippingAddress !== oldAccount.shippingAddress) {
      labelValues.push({
        label: `Address "Shipping Address" field:`,
        value: [
          {
            value: currentAccount?.shippingAddress || "",
            oldValue: oldAccount?.shippingAddress || ""
          }
        ]
      });
    }

    currentAccount?.contacts?.forEach((contact, index) => {
      const oldContact = oldAccount?.contacts?.[index]?.contact;
      if (!contact?.contact || !oldContact) return;

      const contactIndex = index + 1;

      if (contact.contact.phone !== oldContact.phone) {
        labelValues.push({
          label: `Contact ${contactIndex} "Phone" field:`,
          value: [
            {
              value: contact.contact.phone || "",
              oldValue: oldContact.phone || ""
            }
          ]
        });
      }

      if (contact.contact.whatsappNumber !== oldContact.whatsappNumber) {
        labelValues.push({
          label: `Contact ${contactIndex} "FAX" field:`,
          value: [
            {
              value: contact.contact.whatsappNumber || "",
              oldValue: oldContact.whatsappNumber || ""
            }
          ]
        });
      }

      if (contact.contact.email !== oldContact.email) {
        labelValues.push({
          label: `Contact ${contactIndex} "Email" field:`,
          value: [
            {
              value: contact.contact.email || "",
              oldValue: oldContact.email || ""
            }
          ]
        });
      }

      if (contact.contact.links?.[0]?.link !== oldContact.links?.[0]?.link) {
        labelValues.push({
          label: `Contact ${contactIndex} "Website" field:`,
          value: [
            {
              value: contact.contact.links?.[0]?.link || "",
              oldValue: oldContact.links?.[0]?.link || ""
            }
          ]
        });
      }
    });

    if (
      currentAccount.sports?.map((sport) => sport.name).join(", ") !==
      oldAccount.sports?.map((sport) => sport.name).join(", ")
    ) {
      labelValues.push({
        label: `Additional Info "Sports Offered" field:`,
        value: [
          {
            value:
              currentAccount.sports?.map((sport) => sport.name).join(", ") ||
              "",
            oldValue:
              oldAccount.sports?.map((sport) => sport.name).join(", ") || ""
          }
        ]
      });
    }

    if (currentAccount.numberOfAthletes !== oldAccount.numberOfAthletes) {
      labelValues.push({
        label: `Additional Info "No. of Athletes" field:`,
        value: [
          {
            value: currentAccount?.numberOfAthletes?.toString() || "",
            oldValue: oldAccount?.numberOfAthletes?.toString() || ""
          }
        ]
      });
    }

    if (currentAccount.notes !== oldAccount.notes) {
      labelValues.push({
        label: `Additional Info "Notes" field:`,
        value: [
          {
            value: currentAccount?.notes || "",
            oldValue: oldAccount?.notes || ""
          }
        ]
      });
    }

    currentAccount?.links?.forEach((link, index) => {
      const oldLink = oldAccount?.links?.[index];
      if (!link || !oldLink) return;

      const linkIndex = index + 1;

      if (link.link !== oldLink.link) {
        labelValues.push({
          label: `Related Links ${linkIndex} "URL" field:`,
          value: [
            {
              value: link.link || "",
              oldValue: oldLink.link || ""
            }
          ]
        });
      }

      if (link.type !== oldLink.type) {
        labelValues.push({
          label: `Related Links ${linkIndex} "Name" field:`,
          value: [
            {
              value: link.type || "Other",
              oldValue: oldLink.type || "Other"
            }
          ]
        });
      }

      if (link.altName !== oldLink.altName) {
        labelValues.push({
          label: `Related Links ${linkIndex} "Other Name" field:`,
          value: [
            {
              value: link.altName || "",
              oldValue: oldLink.altName || ""
            }
          ]
        });
      }
    });

    return labelValues;
  };

  return (
    <AuditLogLabelValueList
      recordType="UPDATE"
      labelValues={[...getAccountDetails()]}
    />
  );
};
