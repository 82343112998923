import { Container } from "@components/crud/Container";
import { Button, ButtonGroup } from "@mui/material";
import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import {
  ModelAppVersion,
  ModelConfig,
  ModelSubscriptionPlan
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useState } from "react";

type Tabs = "App Version" | "Live Streaming" | "Subscription and Billing";

export const MobileConfigAuditLogUpdate = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object;
  after: object;
}) => {
  const tabs = ["App Version", "Live Streaming", "Subscription and Billing"];
  const [tab, setTab] = useState<Tabs>("App Version");

  const getAppVersionDetails = () => {
    const labels: AuditLogLabelValue[] = [];

    const config = after as ModelAppVersion;
    const oldConfig = before as ModelAppVersion;

    if (!Array.isArray(config) && !Array.isArray(oldConfig)) {
      if (config.platform === "IOS") {
        if (config?.version !== oldConfig?.version) {
          labels.push({
            label: `Updated IOS “Version Number” field:`,
            value: [
              {
                value: config?.version || "",
                oldValue: oldConfig?.version || ""
              }
            ]
          });
        }

        if (config?.buildNumber !== oldConfig?.buildNumber) {
          labels.push({
            label: `Updated IOS “Build Number” field:`,
            value: [
              {
                value: config?.buildNumber?.toString() || "",
                oldValue: oldConfig?.buildNumber?.toString() || ""
              }
            ]
          });
        }

        if (config?.isMandatory !== oldConfig?.isMandatory) {
          labels.push({
            label: `Updated IOS “Mandatory Update” field:`,
            value: [
              {
                value: config?.isMandatory ? "On" : "Off",
                oldValue: oldConfig?.isMandatory ? "On" : "Off"
              }
            ]
          });
        }
      } else {
        if (config?.version !== oldConfig?.version) {
          labels.push({
            label: `Updated AND “Version Number” field:`,
            value: [
              {
                value: config?.version || "",
                oldValue: oldConfig?.version || ""
              }
            ]
          });
        }

        if (config?.buildNumber !== oldConfig?.buildNumber) {
          labels.push({
            label: `Updated AND “Build Number” field:`,
            value: [
              {
                value: config?.buildNumber?.toString() || "",
                oldValue: oldConfig?.buildNumber?.toString() || ""
              }
            ]
          });
        }

        if (config?.isMandatory !== oldConfig?.isMandatory) {
          labels.push({
            label: `Updated AND “Mandatory Update” field:`,
            value: [
              {
                value: config?.isMandatory ? "On" : "Off",
                oldValue: oldConfig?.isMandatory ? "On" : "Off"
              }
            ]
          });
        }
      }
    }

    return labels;
  };
  const getLiveStreamingDetails = () => {
    const labels: AuditLogLabelValue[] = [];
    const config = after as ModelConfig[];
    const oldConfig = before as ModelConfig[];

    if (Array.isArray(config) && Array.isArray(oldConfig)) {
      let liveStreamConfig = config.find(
        (config) => config.key === "live-stream.go-live-button"
      );
      let oldLiveStreamConfig = oldConfig.find(
        (config) => config.key === "live-stream.go-live-button"
      );

      if (liveStreamConfig?.value !== oldLiveStreamConfig?.value) {
        labels.push({
          label: `Updated the “Show Go Live button before an event’s start time” field:`,
          value: [
            {
              value: `${liveStreamConfig?.value?.toString()} Min` || "",
              oldValue: `${oldLiveStreamConfig?.value?.toString()} Min` || ""
            }
          ]
        });
      }

      liveStreamConfig = config.find(
        (config) =>
          config.key === "live-stream.inactive-stream-cancellation-time"
      );
      oldLiveStreamConfig = oldConfig.find(
        (config) =>
          config.key === "live-stream.inactive-stream-cancellation-time"
      );

      if (liveStreamConfig?.value !== oldLiveStreamConfig?.value) {
        labels.push({
          label: `Updated the “Automatically Cancel Live Streams after of inactivity” field:`,
          value: [
            {
              value: `${liveStreamConfig?.value?.toString()} Hr` || "",
              oldValue: `${oldLiveStreamConfig?.value?.toString()} Hr` || ""
            }
          ]
        });
      }

      liveStreamConfig = config.find(
        (config) => config.key === "live-stream.pre-roll-ad-length"
      );
      oldLiveStreamConfig = oldConfig.find(
        (config) => config.key === "live-stream.pre-roll-ad-length"
      );

      if (liveStreamConfig?.value !== oldLiveStreamConfig?.value) {
        const convertToHoursMinutes = (timeString) => {
          const [minutes, seconds] = timeString.split(":").map(Number);
          return minutes > 0
            ? `${minutes} Min ${seconds} Sec`
            : `${seconds} Sec`;
        };
        labels.push({
          label: `Updated the “Length of Pre Roll Advertising” field:`,
          value: [
            {
              value: liveStreamConfig?.value
                ? `${convertToHoursMinutes(liveStreamConfig?.value?.toString())}`
                : "N/A",
              oldValue: oldLiveStreamConfig?.value
                ? `${convertToHoursMinutes(oldLiveStreamConfig?.value?.toString())}`
                : "N/A"
            }
          ]
        });
      }

      liveStreamConfig = config.find(
        (config) => config.key === "live-stream.default-live-stream-image"
      );
      oldLiveStreamConfig = oldConfig.find(
        (config) => config.key === "live-stream.default-live-stream-image"
      );

      if (liveStreamConfig?.value !== oldLiveStreamConfig?.value) {
        labels.push({
          label: `Updated the “Default Live Stream Image” field:`,
          value: [
            {
              value: liveStreamConfig?.value || "",
              oldValue: oldLiveStreamConfig?.value || "",
              isMedia: true
            }
          ]
        });
      }

      liveStreamConfig = config.find(
        (config) => config.key === "live-stream.skip-rule"
      );
      oldLiveStreamConfig = oldConfig.find(
        (config) => config.key === "live-stream.skip-rule"
      );

      if (liveStreamConfig?.value !== oldLiveStreamConfig?.value) {
        labels.push({
          label: `Updated the “Paid Subscriber Skip Rule” field:`,
          value: [
            {
              value:
                `${liveStreamConfig?.value?.toString()} ${parseInt(liveStreamConfig?.value as string) > 1 ? "Intermissions" : "Intermission"}` ||
                "",
              oldValue:
                `${oldLiveStreamConfig?.value?.toString()} ${parseInt(oldLiveStreamConfig?.value as string) > 1 ? "Intermissions" : "Intermission"}` ||
                ""
            }
          ]
        });
      }
    }

    return labels;
  };
  const getSubAndBillingDetails = () => {
    const labels: AuditLogLabelValue[] = [];
    const config = after as ModelConfig[];
    const oldConfig = before as ModelConfig[];

    if (Array.isArray(config) && Array.isArray(oldConfig)) {
      let subConfig = config.find(
        (config) => config.key === "SUBSCRIPTION_PLANS"
      );
      let oldSubConfig = oldConfig.find(
        (config) => config.key === "SUBSCRIPTION_PLANS"
      );

      //#region Free Plan
      const configFreePlan = subConfig?.value?.find(
        (plan) => plan.planId === "plan.free"
      ) as ModelSubscriptionPlan;
      const oldConfigFreePlan = oldSubConfig?.value?.find(
        (plan) => plan.planId === "plan.free"
      ) as ModelSubscriptionPlan;

      if (configFreePlan.name !== oldConfigFreePlan.name) {
        labels.push({
          label: `Updated Free Subscription Plan “Plan Name” field:`,
          value: [
            {
              value: configFreePlan.name || "N/A",
              oldValue: oldConfigFreePlan.name || "N/A"
            }
          ]
        });
      }
      if (configFreePlan.monthlyPrice !== oldConfigFreePlan.monthlyPrice) {
        labels.push({
          label: `Updated Free Subscription Plan “Monthly Price” field:`,
          value: [
            {
              value: "$ " + configFreePlan.monthlyPrice?.toString() || "N/A",
              oldValue:
                "$ " + oldConfigFreePlan.monthlyPrice?.toString() || "N/A"
            }
          ]
        });
      }
      if (configFreePlan.yearlyPrice !== oldConfigFreePlan.yearlyPrice) {
        labels.push({
          label: `Updated Free Subscription Plan “Yearly Price” field:`,
          value: [
            {
              value: "$ " + configFreePlan.yearlyPrice?.toString() || "N/A",
              oldValue:
                "$ " + oldConfigFreePlan.yearlyPrice?.toString() || "N/A"
            }
          ]
        });
      }
      let configFreeFeatures = configFreePlan.features?.find(
        (feat) => feat.featureKey === "recruting-feed-view-limit"
      );
      let oldConfigFreeFeatures = oldConfigFreePlan.features?.find(
        (feat) => feat.featureKey === "recruting-feed-view-limit"
      );

      if (configFreeFeatures?.isLimited !== oldConfigFreeFeatures?.isLimited) {
        labels.push({
          label: `Updated Free Subscription Plan “Limit Recruiting Feed Views” field:`,
          value: [
            {
              value: configFreeFeatures?.isLimited ? "On" : "Off",
              oldValue: oldConfigFreeFeatures?.isLimited ? "On" : "Off"
            }
          ]
        });
      }

      if (
        configFreeFeatures?.limitValue !== oldConfigFreeFeatures?.limitValue
      ) {
        labels.push({
          label: `Updated Free Subscription Plan “Limit Allowed On Feed Views” field:`,
          value: [
            {
              value: configFreeFeatures?.limitValue || "N/A",
              oldValue: oldConfigFreeFeatures?.limitValue || "N/A"
            }
          ]
        });
      }

      configFreeFeatures = configFreePlan.features?.find(
        (feat) => feat.featureKey === "recruting-feed-post-limit"
      );
      oldConfigFreeFeatures = oldConfigFreePlan.features?.find(
        (feat) => feat.featureKey === "recruting-feed-post-limit"
      );

      if (configFreeFeatures?.isLimited !== oldConfigFreeFeatures?.isLimited) {
        labels.push({
          label: `Updated Free Subscription Plan “Limit Recruiting Feed Posts” field:`,
          value: [
            {
              value: configFreeFeatures?.isLimited ? "On" : "Off",
              oldValue: oldConfigFreeFeatures?.isLimited ? "On" : "Off"
            }
          ]
        });
      }

      if (
        configFreeFeatures?.limitValue !== oldConfigFreeFeatures?.limitValue
      ) {
        labels.push({
          label: `Updated Free Subscription Plan “Limit Allowed On Recruiting Feed Posts” field:`,
          value: [
            {
              value: configFreeFeatures?.limitValue || "N/A",
              oldValue: oldConfigFreeFeatures?.limitValue || "N/A"
            }
          ]
        });
      }

      configFreeFeatures = configFreePlan.features?.find(
        (feat) => feat.featureKey === "cloud-storage"
      );
      oldConfigFreeFeatures = oldConfigFreePlan.features?.find(
        (feat) => feat.featureKey === "cloud-storage"
      );

      if (
        configFreeFeatures?.limitValue !== oldConfigFreeFeatures?.limitValue
      ) {
        labels.push({
          label: `Updated Free Subscription Plan “Limit Allowed On Cloud Storage” field:`,
          value: [
            {
              value: configFreeFeatures?.limitValue || "N/A",
              oldValue: oldConfigFreeFeatures?.limitValue || "N/A"
            }
          ]
        });
      }

      configFreeFeatures = configFreePlan.features?.find(
        (feat) => feat.featureKey === "user-accounts"
      );
      oldConfigFreeFeatures = oldConfigFreePlan.features?.find(
        (feat) => feat.featureKey === "user-accounts"
      );

      if (
        configFreeFeatures?.limitValue !== oldConfigFreeFeatures?.limitValue
      ) {
        labels.push({
          label: `Updated Free Subscription Plan “No. of Users Covered” field:`,
          value: [
            {
              value: configFreeFeatures?.limitValue || "N/A",
              oldValue: oldConfigFreeFeatures?.limitValue || "N/A"
            }
          ]
        });
      }

      configFreeFeatures = configFreePlan.features?.find(
        (feat) => feat.featureKey === "video-curation"
      );
      oldConfigFreeFeatures = oldConfigFreePlan.features?.find(
        (feat) => feat.featureKey === "video-curation"
      );

      if (
        configFreeFeatures?.limitValue !== oldConfigFreeFeatures?.limitValue
      ) {
        labels.push({
          label: `Updated Free Subscription Plan “Max Video Length” field:`,
          value: [
            {
              value:
                `${configFreeFeatures?.limitValue} ${configFreeFeatures?.limitUnit}` ||
                "N/A",
              oldValue:
                `${oldConfigFreeFeatures?.limitValue} ${oldConfigFreeFeatures?.limitUnit}` ||
                "N/A"
            }
          ]
        });
      }

      //#endregion

      //#region Personal Plan
      const configPersonalPlan = subConfig?.value?.find(
        (plan) => plan.planId === "plan.personal"
      ) as ModelSubscriptionPlan;
      const oldConfigPersonalPlan = oldSubConfig?.value?.find(
        (plan) => plan.planId === "plan.personal"
      ) as ModelSubscriptionPlan;

      if (configPersonalPlan.name !== oldConfigPersonalPlan.name) {
        labels.push({
          label: `Updated Personal Subscription Plan “Plan Name” field:`,
          value: [
            {
              value: configPersonalPlan.name || "N/A",
              oldValue: oldConfigPersonalPlan.name || "N/A"
            }
          ]
        });
      }
      if (
        configPersonalPlan.monthlyPrice !== oldConfigPersonalPlan.monthlyPrice
      ) {
        labels.push({
          label: `Updated Personal Subscription Plan “Monthly Price” field:`,
          value: [
            {
              value:
                "$ " + configPersonalPlan.monthlyPrice?.toString() || "N/A",
              oldValue:
                "$ " + oldConfigPersonalPlan.monthlyPrice?.toString() || "N/A"
            }
          ]
        });
      }
      if (
        configPersonalPlan.yearlyPrice !== oldConfigPersonalPlan.yearlyPrice
      ) {
        labels.push({
          label: `Updated Personal Subscription Plan “Yearly Price” field:`,
          value: [
            {
              value: "$ " + configPersonalPlan.yearlyPrice?.toString() || "N/A",
              oldValue:
                "$ " + oldConfigPersonalPlan.yearlyPrice?.toString() || "N/A"
            }
          ]
        });
      }
      //Break
      let configPersonalFeatures = configPersonalPlan.features?.find(
        (feat) => feat.featureKey === "recruting-feed-view-limit"
      );
      let oldConfigPersonalFeatures = oldConfigPersonalPlan.features?.find(
        (feat) => feat.featureKey === "recruting-feed-view-limit"
      );

      if (
        configPersonalFeatures?.isLimited !==
        oldConfigPersonalFeatures?.isLimited
      ) {
        labels.push({
          label: `Updated Personal Subscription Plan “Limit Recruiting Feed Views” field:`,
          value: [
            {
              value: configPersonalFeatures?.isLimited ? "On" : "Off",
              oldValue: oldConfigPersonalFeatures?.isLimited ? "On" : "Off"
            }
          ]
        });
      }

      if (
        configPersonalFeatures?.limitValue !==
        oldConfigPersonalFeatures?.limitValue
      ) {
        labels.push({
          label: `Updated Personal Subscription Plan “Limit Allowed On Feed Views” field:`,
          value: [
            {
              value: configPersonalFeatures?.limitValue || "N/A",
              oldValue: oldConfigPersonalFeatures?.limitValue || "N/A"
            }
          ]
        });
      }

      //Break

      configPersonalFeatures = configPersonalPlan.features?.find(
        (feat) => feat.featureKey === "recruting-feed-post-limit"
      );
      oldConfigPersonalFeatures = oldConfigPersonalPlan.features?.find(
        (feat) => feat.featureKey === "recruting-feed-post-limit"
      );

      if (
        configPersonalFeatures?.isLimited !==
        oldConfigPersonalFeatures?.isLimited
      ) {
        labels.push({
          label: `Updated Personal Subscription Plan “Limit Recruiting Feed Posts” field:`,
          value: [
            {
              value: configPersonalFeatures?.isLimited ? "On" : "Off",
              oldValue: oldConfigPersonalFeatures?.isLimited ? "On" : "Off"
            }
          ]
        });
      }

      if (
        configPersonalFeatures?.limitValue !==
        oldConfigPersonalFeatures?.limitValue
      ) {
        labels.push({
          label: `Updated Personal Subscription Plan “Limit Allowed On Recruiting Feed Posts” field:`,
          value: [
            {
              value: configPersonalFeatures?.limitValue || "N/A",
              oldValue: oldConfigPersonalFeatures?.limitValue || "N/A"
            }
          ]
        });
      }

      configPersonalFeatures = configPersonalPlan.features?.find(
        (feat) => feat.featureKey === "user-accounts"
      );
      oldConfigPersonalFeatures = oldConfigPersonalPlan.features?.find(
        (feat) => feat.featureKey === "user-accounts"
      );

      if (
        configPersonalFeatures?.limitValue !==
        oldConfigPersonalFeatures?.limitValue
      ) {
        labels.push({
          label: `Updated Personal Subscription Plan “No. of Users Covered” field:`,
          value: [
            {
              value: configPersonalFeatures?.limitValue || "N/A",
              oldValue: oldConfigPersonalFeatures?.limitValue || "N/A"
            }
          ]
        });
      }

      configPersonalFeatures = configPersonalPlan.features?.find(
        (feat) => feat.featureKey === "video-curation"
      );
      oldConfigPersonalFeatures = oldConfigPersonalPlan.features?.find(
        (feat) => feat.featureKey === "video-curation"
      );

      if (
        configPersonalFeatures?.limitValue !==
        oldConfigPersonalFeatures?.limitValue
      ) {
        labels.push({
          label: `Updated Personal Subscription Plan “Max Video Length” field:`,
          value: [
            {
              value:
                `${configPersonalFeatures?.limitValue} ${configPersonalFeatures?.limitUnit}` ||
                "N/A",
              oldValue:
                `${oldConfigPersonalFeatures?.limitValue} ${oldConfigPersonalFeatures?.limitUnit}` ||
                "N/A"
            }
          ]
        });
      }

      //#endregion

      //#region Family Plan
      const configFamilyPlan = subConfig?.value?.find(
        (plan) => plan.planId === "plan.family"
      ) as ModelSubscriptionPlan;
      const oldConfigFamilyPlan = oldSubConfig?.value?.find(
        (plan) => plan.planId === "plan.family"
      ) as ModelSubscriptionPlan;

      if (configFamilyPlan.name !== oldConfigFamilyPlan.name) {
        labels.push({
          label: `Updated Family Subscription Plan “Plan Name” field:`,
          value: [
            {
              value: configFamilyPlan.name || "N/A",
              oldValue: oldConfigFamilyPlan.name || "N/A"
            }
          ]
        });
      }
      if (configFamilyPlan.monthlyPrice !== oldConfigFamilyPlan.monthlyPrice) {
        labels.push({
          label: `Updated Family Subscription Plan “Monthly Price” field:`,
          value: [
            {
              value: "$ " + configFamilyPlan.monthlyPrice?.toString() || "N/A",
              oldValue:
                "$ " + oldConfigFamilyPlan.monthlyPrice?.toString() || "N/A"
            }
          ]
        });
      }
      if (configFamilyPlan.yearlyPrice !== oldConfigFamilyPlan.yearlyPrice) {
        labels.push({
          label: `Updated Family Subscription Plan “Yearly Price” field:`,
          value: [
            {
              value: "$ " + configFamilyPlan.yearlyPrice?.toString() || "N/A",
              oldValue:
                "$ " + oldConfigFamilyPlan.yearlyPrice?.toString() || "N/A"
            }
          ]
        });
      }
      //Break
      let configFamilyFeatures = configFamilyPlan.features?.find(
        (feat) => feat.featureKey === "recruting-feed-view-limit"
      );
      let oldConfigFamilyFeatures = oldConfigFamilyPlan.features?.find(
        (feat) => feat.featureKey === "recruting-feed-view-limit"
      );

      if (
        configFamilyFeatures?.isLimited !== oldConfigFamilyFeatures?.isLimited
      ) {
        labels.push({
          label: `Updated Family Subscription Plan “Limit Recruiting Feed Views” field:`,
          value: [
            {
              value: configFamilyFeatures?.isLimited ? "On" : "Off",
              oldValue: oldConfigFamilyFeatures?.isLimited ? "On" : "Off"
            }
          ]
        });
      }

      if (
        configFamilyFeatures?.limitValue !== oldConfigFamilyFeatures?.limitValue
      ) {
        labels.push({
          label: `Updated Family Subscription Plan “Limit Allowed On Feed Views” field:`,
          value: [
            {
              value: configFamilyFeatures?.limitValue || "N/A",
              oldValue: oldConfigFamilyFeatures?.limitValue || "N/A"
            }
          ]
        });
      }

      //Break

      configFamilyFeatures = configFamilyPlan.features?.find(
        (feat) => feat.featureKey === "recruting-feed-post-limit"
      );
      oldConfigFamilyFeatures = oldConfigFamilyPlan.features?.find(
        (feat) => feat.featureKey === "recruting-feed-post-limit"
      );

      if (
        configFamilyFeatures?.isLimited !== oldConfigFamilyFeatures?.isLimited
      ) {
        labels.push({
          label: `Updated Family Subscription Plan “Limit Recruiting Feed Posts” field:`,
          value: [
            {
              value: configFamilyFeatures?.isLimited ? "On" : "Off",
              oldValue: oldConfigFamilyFeatures?.isLimited ? "On" : "Off"
            }
          ]
        });
      }

      if (
        configFamilyFeatures?.limitValue !== oldConfigFamilyFeatures?.limitValue
      ) {
        labels.push({
          label: `Updated Family Subscription Plan “Limit Allowed On Recruiting Feed Posts” field:`,
          value: [
            {
              value: configFamilyFeatures?.limitValue || "N/A",
              oldValue: oldConfigFamilyFeatures?.limitValue || "N/A"
            }
          ]
        });
      }

      configFamilyFeatures = configFamilyPlan.features?.find(
        (feat) => feat.featureKey === "user-accounts"
      );
      oldConfigFamilyFeatures = oldConfigFamilyPlan.features?.find(
        (feat) => feat.featureKey === "user-accounts"
      );

      if (
        configFamilyFeatures?.limitValue !== oldConfigFamilyFeatures?.limitValue
      ) {
        labels.push({
          label: `Updated Family Subscription Plan “No. of Users Covered” field:`,
          value: [
            {
              value: configFamilyFeatures?.limitValue || "N/A",
              oldValue: oldConfigFamilyFeatures?.limitValue || "N/A"
            }
          ]
        });
      }

      configFamilyFeatures = configFamilyPlan.features?.find(
        (feat) => feat.featureKey === "video-curation"
      );
      oldConfigFamilyFeatures = oldConfigFamilyPlan.features?.find(
        (feat) => feat.featureKey === "video-curation"
      );

      if (
        configFamilyFeatures?.limitValue !== oldConfigFamilyFeatures?.limitValue
      ) {
        labels.push({
          label: `Updated Family Subscription Plan “Max Video Length” field:`,
          value: [
            {
              value:
                `${configFamilyFeatures?.limitValue} ${configFamilyFeatures?.limitUnit}` ||
                "N/A",
              oldValue:
                `${oldConfigFamilyFeatures?.limitValue} ${oldConfigFamilyFeatures?.limitUnit}` ||
                "N/A"
            }
          ]
        });
      }
      //#endregion

      //Non Subscription plan configs
      subConfig = config.find(
        (config) =>
          config.key === "subscription-payments.failed-renewal-attempt2-days"
      );
      oldSubConfig = oldConfig.find(
        (config) =>
          config.key === "subscription-payments.failed-renewal-attempt2-days"
      );

      if (subConfig?.value !== oldSubConfig?.value) {
        labels.push({
          label: `Updated Subscription Payments“Failed Renewal Subscription Payment - 2nd Attempt” field:`,
          value: [
            {
              value: subConfig?.value?.toString() + " Days" || "N/A",
              oldValue: oldSubConfig?.value?.toString() + " Days" || "N/A"
            }
          ]
        });
      }

      subConfig = config.find(
        (config) =>
          config.key === "subscription-payments.failed-renewal-final-days"
      );
      oldSubConfig = oldConfig.find(
        (config) =>
          config.key === "subscription-payments.failed-renewal-final-days"
      );

      if (subConfig?.value !== oldSubConfig?.value) {
        labels.push({
          label: `Updated Subscription Payments “Failed Renewal Subscription Payment - Final Attempt” field:`,
          value: [
            {
              value: subConfig?.value?.toString() + " Days" || "N/A",
              oldValue: oldSubConfig?.value?.toString() + " Days" || "N/A"
            }
          ]
        });
      }

      subConfig = config.find(
        (config) => config.key === "general.default-subscription-plan"
      );
      oldSubConfig = oldConfig.find(
        (config) => config.key === "general.default-subscription-plan"
      );

      if (subConfig?.value !== oldSubConfig?.value) {
        labels.push({
          label: `Updated General “Subscription Plan Default” field:`,
          value: [
            {
              value:
                subConfig?.options?.find(
                  (val) => val.value === subConfig?.value
                ).label || "N/A",
              oldValue:
                oldSubConfig?.options?.find(
                  (val) => val.value === oldSubConfig?.value
                ).label || "N/A"
            }
          ]
        });
      }

      subConfig = config.find(
        (config) => config.key === "general.subscription-plan-sort-order"
      );
      oldSubConfig = oldConfig.find(
        (config) => config.key === "general.subscription-plan-sort-order"
      );

      const subConfigToMap = Object.fromEntries(
        subConfig?.options?.map((opt) => [opt.value, opt.label])
      );
      const oldSubConfigToMap = Object.fromEntries(
        oldSubConfig?.options?.map((opt) => [opt.value, opt.label])
      );

      const subConfigMap = subConfig?.value
        ?.map((val) => subConfigToMap[val])
        .join(", ");
      const oldSubConfigMap = oldSubConfig?.value
        ?.map((val) => oldSubConfigToMap[val])
        .join(", ");

      if (subConfigMap !== oldSubConfigMap) {
        labels.push({
          label: `Updated General “Subscription Plan Sort Order” field:`,
          value: [
            {
              value: subConfigMap || "N/A",
              oldValue: oldSubConfigMap || "N/A"
            }
          ]
        });
      }
    }

    return labels;
  };
  return (
    <Container>
      <ButtonGroup
        variant="outlined"
        style={{ border: "rgba(215, 221, 229, 1)", paddingBottom: "24px" }}
      >
        {tabs.map((t) => {
          return (
            <Button
              key={t}
              variant="outlined"
              style={
                tab === t
                  ? {
                      backgroundColor: "#E8EEFF",
                      color: "#2B337A",
                      fontSize: "14px",
                      fontWeight: 600,
                      textTransform: "none"
                    }
                  : {
                      color: "#666666",
                      fontSize: "14px",
                      fontWeight: 500,
                      textTransform: "none"
                    }
              }
              onClick={() => {
                setTab(t as Tabs);
              }}
            >
              {t}
            </Button>
          );
        })}
      </ButtonGroup>
      {tab === "App Version" ? (
        <AuditLogLabelValueList
          key={tab}
          recordType={recordType}
          labelValues={getAppVersionDetails()}
        />
      ) : tab === "Live Streaming" ? (
        <AuditLogLabelValueList
          key={tab}
          recordType={recordType}
          labelValues={getLiveStreamingDetails()}
        />
      ) : tab === "Subscription and Billing" ? (
        <AuditLogLabelValueList
          key={tab}
          recordType={recordType}
          labelValues={getSubAndBillingDetails()}
        />
      ) : null}
    </Container>
  );
};
