import { ModelDepartment } from "@sportsgravyengineering/sg-api-react-sdk";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "../types";
import { formatPhoneWithCountryCode } from "@utils/phoneFormatters";
import { getIdSet } from "../ViewAuditLog";

export const DepartmentAuditLog = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelDepartment;
  after: object | ModelDepartment;
}) => {
  const department = (
    recordType !== "DELETE" ? after : before
  ) as ModelDepartment;
  const oldDepartment = before as ModelDepartment;

  const getCreateLabels = () => {
    const labels: AuditLogLabelValue[] = [];

    labels.push({
      label: `"Name" field:`,
      value: [{ value: department.name || "" }]
    });

    if (department.email)
      labels.push({
        label: `"SportsGravy Email" field:`,
        value: [{ value: department.email || "" }]
      });

    if (department.twilioNumber)
      labels.push({
        label: `"SportsGravy Number" field:`,
        value: [
          {
            value:
              formatPhoneWithCountryCode(department.twilioNumber?.number) || ""
          }
        ]
      });

    labels.push({
      label: `"Forward To" field:`,
      value: [
        {
          value: department.forwardToJobs?.length
            ? "Individual Jobs"
            : "Call Center"
        }
      ]
    });

    if (department.forwardToJobs?.length)
      labels.push({
        label: `"Participating Jobs" field:`,
        value: [
          {
            value: department.forwardToJobs?.map((j) => j.name).join(", ") || ""
          }
        ]
      });

    return labels;
  };

  const getUpdateLabels = () => {
    const labels: AuditLogLabelValue[] = [];

    if (oldDepartment.name !== department.name)
      labels.push({
        label: `Updated the "Name" field:`,
        value: [
          {
            value: department.name || "N/A",
            oldValue: oldDepartment.name || "N/A"
          }
        ]
      });

    if (oldDepartment.email !== department.email)
      labels.push({
        label: `Updated the "SportsGravy Email" field:`,
        value: [
          {
            value: department.email || "N/A",
            oldValue: oldDepartment.email || "N/A"
          }
        ]
      });

    if (oldDepartment.twilioNumber?.number !== department.twilioNumber?.number)
      labels.push({
        label: `Updated the "SportsGravy Number" field:`,
        value: [
          {
            value:
              formatPhoneWithCountryCode(department.twilioNumber?.number) ||
              "N/A",
            oldValue:
              formatPhoneWithCountryCode(oldDepartment.twilioNumber?.number) ||
              "N/A"
          }
        ]
      });

    if (
      (oldDepartment.forwardToJobs?.length === 0 ||
        department.forwardToJobs?.length === 0) &&
      oldDepartment.forwardToJobs?.length !== department.forwardToJobs?.length
    )
      labels.push({
        label: `Updated the "Forward To" field:`,
        value: [
          {
            value: department.forwardToJobs?.length
              ? "Individual Jobs"
              : "Call Center",
            oldValue: oldDepartment.forwardToJobs?.length
              ? "Individual Jobs"
              : "Call Center"
          }
        ]
      });

    const newForwardToJobs = getIdSet(department?.forwardToJobs, "jobtitleId");
    const oldForwardToJobs = getIdSet(
      oldDepartment?.forwardToJobs,
      "jobtitleId"
    );

    const forwardToJobChanged =
      newForwardToJobs.size !== oldForwardToJobs.size ||
      [...newForwardToJobs].some((id) => !oldForwardToJobs.has(id));

    if (forwardToJobChanged) {
      labels.push({
        label: `Updated the "Participating Jobs" field:`,
        value: [
          {
            value:
              department.forwardToJobs?.map((j) => j.name).join(", ") || "N/A",
            oldValue:
              oldDepartment.forwardToJobs?.map((j) => j.name).join(", ") ||
              "N/A"
          }
        ]
      });
    }

    return labels;
  };

  if (recordType === "UPDATE")
    return (
      <AuditLogLabelValueList
        recordType="UPDATE"
        labelValues={[...getUpdateLabels()]}
      />
    );
  else
    return (
      <AuditLogLabelValueList
        recordType={recordType}
        labelValues={getCreateLabels()}
      />
    );
};
