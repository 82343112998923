import { ModelOrganizationUpload } from "@sportsgravyengineering/sg-api-react-sdk";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";

export const ImportPlayerAuditLogDetails = ({
  after
}: {
  after: object | ModelOrganizationUpload;
}) => {
  const importPlayer = after as ModelOrganizationUpload;
  return (
    <AuditLogLabelValueList
      recordType="CREATE"
      labelValues={[
        {
          label: `"For" field:`,
          value: [
            {
              value: importPlayer.for === "TEAM" ? "Teams" : "Training Programs"
            }
          ]
        },
        {
          label: `"Sports" field:`,
          value: [
            {
              value: importPlayer.sport?.name || ""
            }
          ]
        },
        ...(importPlayer.season
          ? [
              {
                label: `"Season" field:`,
                value: [
                  {
                    value: importPlayer.season?.name || ""
                  }
                ]
              }
            ]
          : []),
        {
          label: `"Template File" field:`,
          value: [
            {
              value: importPlayer.fileName || ""
            }
          ]
        }
      ]}
    />
  );
};
