import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Toolbar } from "@components/crud/Toolbar";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "@components/crud/Form";
import { OpportunityDetailsForm } from "./OpportunityDetailsForm";
import formatFullName from "@utils/formatFullName";
import { organizationAtom, profileAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import {
  adminCrmLeadLeadIdGet,
  ContactResponse,
  getAdminCrmAccountAccountId,
  getAdminCrmAccountAccountIdContact,
  getAdminCrmOrgAccountAccountId,
  getAdminCrmOrgAccountAccountIdContact,
  ModelAccount,
  ModelPerson,
  useAdminCrmOpportunityPost,
  useAdminCrmOrgOpportunityPost,
  useAdminJobTitleGet,
  useConfigGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import { hasPermission } from "@services/Casbin";
import { Tier } from "@pages/settings/CRMSetting";
import { getCostPerAthlete, getJobForAmount } from "@utils/GetCostPerAthlete";
import { Loader } from "@components/crud/Loader";
import { getCountryFromAddress } from "@utils/getCountryFromAddress";
import { OrderApproval } from "@pages/settings/crm/CRMApprovalSettings";

export const OpportunityCreate = () => {
  const navigate = useNavigate();
  const form = useForm({
    mode: "onTouched",
    shouldUnregister: true
  });

  const {
    reset,
    getValues,
    formState: { isValid }
  } = form;

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        organizationId ? "ORGANIZATION" : "SYSTEM",
        organizationId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("crm.opportunities", "ADD");
      if (!create) navigate("/not-found");
    };
    fetchPermissions();
  }, []);

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [opportunityOwner, setOpportunityOwner] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("US");
  const [selectedContact, setSelectedContact] = useState("");
  const [customCostOfAthlete, setCustomCostOfAthlete] = useState<
    number | undefined
  >(undefined);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [orderApprovalNeeded, setOrderApprovalNeeded] = useState(false);
  const [discountWithinRange, setDiscountWithinRange] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const leadId = searchParams.get("leadId");
  const accountId = searchParams.get("accountId");
  const [isLoading, setIsLoading] = useState(false);

  const [contacts, setContacts] = useState<
    {
      label: string;
      value: string;
      isPrimary: boolean;
      jobTitle: string;
      workEmail: string;
      workPhone: string;
      details: ContactResponse;
    }[]
  >([]);

  const userName = formatFullName(
    useRecoilValue(profileAtom)?.person as ModelPerson
  );
  const organizationId = useRecoilValue(organizationAtom);
  const userId = useRecoilValue(profileAtom)?.userId || "";

  const [pricingTier, setPricingTier] = useState<Tier[]>([]);
  const [minOrderApprovalAmount, setMinOrderApprovalAmount] =
    useState<number>(Infinity);
  const [orderApprovalsData, setOrderApprovalsData] = useState<OrderApproval[]>(
    []
  );
  const { data: settings, isLoading: isLoadingConfig } = organizationId
    ? { data: null, isLoading: false }
    : useConfigGet();
  const { data: jobTitlesData, isLoading: isLoadingJobs } = !organizationId
    ? useAdminJobTitleGet()
    : { data: null, isLoading: false };
  useEffect(() => {
    if (settings && settings.data) {
      const priceTiersList = (settings.data.find(
        (item) => item.key === "crm.order.pricing-tier"
      )?.value || []) as Tier[];
      setPricingTier(priceTiersList);
      const orderApprovalsData = (settings.data.find(
        (item) => item.key === "crm.order.approvals-required"
      )?.value || []) as OrderApproval[];

      const minApprovalAmount = orderApprovalsData.reduce((min, current) => {
        const currentAmount = parseFloat(
          current.amount?.replace(/,/g, "") || "10000000000"
        );
        return currentAmount < min ? currentAmount : min;
      }, Infinity);
      setMinOrderApprovalAmount(minApprovalAmount);
      setOrderApprovalsData(orderApprovalsData);
    }
  }, [settings]);

  useEffect(() => {
    reset({
      createdBy: userName || "",
      createdAt: new Date(),
      type: "NEW_BUSINESS",
      name: "New Business Opportunity for {{Account Name}}",
      status: "Approved"
    });
  }, []);

  useEffect(() => {
    if (pricingTier.length > 0) {
      setCustomCostOfAthlete(undefined);
      const cost = getCostPerAthlete(
        parseInt(getValues().noOfAthletes) || 0,
        pricingTier.filter((p) => p.countryId === selectedCountry),
        true
      ) as {
        cost: number;
        setupCost: number;
      };
      const totalAmount = (
        (parseFloat(getValues().noOfAthletes) || 0) * cost.cost +
        (getValues().type === "NEW_BUSINESS" ? cost.setupCost : 0)
      ).toFixed(2);

      form.setValue("amount", totalAmount, { shouldDirty: true });
      form.setValue(
        "costOfAthletesPeryear",
        `$ ${cost.cost} Per Athlete, Per Registration`,
        {
          shouldDirty: true
        }
      );
      setCustomCostOfAthlete(undefined);
      setOrderApprovalNeeded(parseFloat(totalAmount) > minOrderApprovalAmount);
      if (parseFloat(totalAmount) > minOrderApprovalAmount)
        form.setValue(
          "status",
          `Pending Approval by ${
            jobTitlesData?.data?.jobTitles?.find(
              (j) =>
                j.jobtitleId ===
                getJobForAmount(
                  parseFloat(totalAmount),
                  orderApprovalsData as {
                    job: string;
                    amount: string;
                  }[]
                )
            )?.name || "SportsGravy Manager"
          }`
        );
      else form.setValue("status", "Approved");
    }
  }, [selectedCountry, pricingTier]);

  useEffect(() => {
    const fetchData = async () => {
      if (leadId) {
        setIsLoading(true);
        const data = await adminCrmLeadLeadIdGet(leadId);
        setIsLoading(false);
        if (data.data.accountId) {
          const account = data.data.account;
          const contact = data.data.contact;
          reset({
            account: {
              name: account?.name || "",
              parent: account?.parent?.name || "",
              category: account?.category,
              type: account?.type,
              officeAddress: account?.officeAddress,
              email: account?.email,
              website: account?.website,
              sportsOffered: account?.sports?.map((sp) => sp.sportId) || [],
              noOfAthletes: account?.numberOfAthletes,
              aeOwner: account?.aeOwner?.person
                ? formatFullName(account?.aeOwner?.person)
                : ""
            },
            contact: {
              firstName: contact?.firstName,
              lastName: contact?.lastName,
              personalEmail: contact?.email,
              homeAddress: contact?.location,
              phoneType: "MOB",
              phone: contact?.phone,
              whatsappNumber: contact?.whatsappNumber
            },
            createdBy: userName || "",
            createdAt: new Date(),
            type: "NEW_BUSINESS",
            costOfAthletesPeryear: `$ ${getCostPerAthlete(
              account?.numberOfAthletes || 0,
              pricingTier.filter(
                (p) =>
                  p.countryId ===
                    getCountryFromAddress(account!.officeAddress!) || "US"
              )
            )} Per Athlete, Per Registration`,
            name: `New Business Opportunity for ${account?.name}`,
            status: "Approved"
          });
          setSelectedAccount(data.data.accountId);
          setSelectedCountry(
            getCountryFromAddress(account!.officeAddress!) || "US"
          );
          // setSelectedContact(data.data.contactId);
        }
      }
    };
    fetchData();
  }, [leadId]);

  useEffect(() => {
    const fetchData = async () => {
      if (accountId) {
        setIsLoading(true);
        const data = organizationId
          ? await getAdminCrmOrgAccountAccountId(accountId)
          : await getAdminCrmAccountAccountId(accountId);
        setIsLoading(false);
        if (data.data.accountId) {
          const account = data.data;
          reset({
            account: {
              name: account?.name || "",
              parent: account?.parent?.name || "",
              category: account?.category,
              type: account?.type,
              officeAddress: account?.officeAddress,
              email: account?.email,
              website: account?.website,
              ...(!organizationId && {
                sportsOffered:
                  (account as ModelAccount)?.sports?.map((sp) => sp.sportId) ||
                  [],
                noOfAthletes: (account as ModelAccount)?.numberOfAthletes,
                aeOwner: (account as ModelAccount)?.aeOwner?.person
                  ? formatFullName(account?.aeOwner?.person)
                  : ""
              })
            },
            createdBy: userName || "",
            createdAt: new Date(),
            type: "NEW_BUSINESS",
            ...(!organizationId && {
              costOfAthletesPeryear: `$ ${getCostPerAthlete(
                (account as ModelAccount)?.numberOfAthletes || 0,
                pricingTier.filter(
                  (p) =>
                    p.countryId ===
                      getCountryFromAddress(account!.officeAddress!) || "US"
                )
              )} Per Athlete, Per Registration`,
              name: `New Business Opportunity for ${account?.name}`,
              status: "Approved"
            })
          });
          setSelectedAccount(data.data.accountId);
          setSelectedCountry(
            getCountryFromAddress(account!.officeAddress!) || "US"
          );
        }
      }
    };
    fetchData();
  }, [accountId]);

  const { mutate: save, isLoading: isSaving } = organizationId
    ? useAdminCrmOrgOpportunityPost()
    : useAdminCrmOpportunityPost();
  const onSave = (resetInstreadOfRoute: boolean) => {
    const data = {
      name: getValues().name!,
      type: getValues().type!,
      accountId: selectedAccount,
      contactId: selectedContact,
      ownerId: opportunityOwner,
      stage: getValues().stage!,
      ...(!organizationId
        ? {
            numberOfAthletes: parseInt(getValues().noOfAthletes)!,
            amount: Number(
              parseFloat(
                getValues()?.amount?.toString()?.replace(/,/g, "")
              ).toFixed(2)
            )
          }
        : {
            organizationId: organizationId,
            amount: 0
          }),
      ...(getValues().closeDate && {
        closeDate: new Date(getValues().closeDate!)
      }),
      ...(leadId && {
        leadId: leadId
      }),
      createdById: userId!,
      ...(customCostOfAthlete && {
        discountedPricePerAthlete: customCostOfAthlete
      })
    };
    save(
      {
        data: data
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Opportunity Created successfully!", {
            variant: "success"
          });
          if (resetInstreadOfRoute) {
            reset({
              createdBy: userName || "",
              createdAt: new Date(),
              type: "NEW_BUSINESS",
              costOfAthletesPeryear: ``,
              status: "Approved"
            });
            setOpportunityOwner("");
            setSelectedContact("");
            setSelectedAccount("");
          } else {
            navigate("/crm/opportunities");
          }
        },
        onError: () => {
          enqueueSnackbar("Failed to Create Opportunity!", {
            variant: "error"
          });
        }
      }
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedAccount) {
        try {
          const data = organizationId
            ? await getAdminCrmOrgAccountAccountIdContact(selectedAccount)
            : await getAdminCrmAccountAccountIdContact(selectedAccount);
          setContacts(
            data.data.map((c) => ({
              label: `${c?.contact?.firstName} ${c?.contact?.lastName}`,
              value: c.contactId as string,
              isPrimary: !!c?.isPrimary,
              jobTitle: c.jobTitle || "",
              workEmail: c.workEmail || "",
              workPhone: c.workPhone || "",
              details: c?.contact as ContactResponse
            }))
          );
          const primaryOrFirstContact =
            data.data.find((c) => c?.isPrimary)?.contactId ||
            data.data?.[0]?.contactId;
          setSelectedContact(primaryOrFirstContact || "");
        } catch (error) {
          setContacts([]);
        }
      }
    };
    fetchData();
  }, [selectedAccount]);

  return (
    <Container>
      <Toolbar title="Add Opportunity" />
      <Loader isLoading={isLoadingConfig || isLoadingJobs || isLoading}>
        <Form>
          <OpportunityDetailsForm
            form={form}
            opportunityOwner={opportunityOwner}
            setOpportunityOwner={setOpportunityOwner}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
            contacts={contacts}
            setContacts={setContacts}
            customCost={customCostOfAthlete}
            setCustomCost={setCustomCostOfAthlete}
            discountWithinRange={discountWithinRange}
            setDiscountWithinRange={setDiscountWithinRange}
            setOrderApprovalNeeded={setOrderApprovalNeeded}
            organizationId={organizationId}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
          />
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          if (
            !organizationId &&
            (orderApprovalNeeded ||
              (customCostOfAthlete && !discountWithinRange))
          ) {
            setOpenWarningModal(true);
          } else onSave(false);
        }}
        saveAndNewBtnClick={() => {
          if (
            !organizationId &&
            (orderApprovalNeeded ||
              (customCostOfAthlete && !discountWithinRange))
          ) {
            setOpenWarningModal(true);
          } else onSave(true);
        }}
        isDisabled={!isValid}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/crm/opportunities")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
      <ConfirmationDialog
        title="Approval Required"
        body={
          !orderApprovalNeeded
            ? "Your requested discount for this opportunity requires approval by a SportsGravy manager. Are you sure you would like to submit this for approval?"
            : "Your opportunity will require approval by a SportsGravy Manager due to the size of the estimated yearly amount. Are you sure you would like to submit this for approval?"
        }
        open={openWarningModal}
        close={() => setOpenWarningModal(false)}
        onCancel={() => setOpenWarningModal(false)}
        onConfirm={() => onSave(false)}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
