import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { ModelHelpCategory } from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";

export const HelpCategoriesAuditLogUpdate = ({
  before,
  after
}: {
  before: object | ModelHelpCategory;
  after: object | ModelHelpCategory;
}) => {
  const currentCategory = after as ModelHelpCategory;
  const oldCategory = before as ModelHelpCategory;

  const getCategoryDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    if (currentCategory.name !== oldCategory.name) {
      labelValues.push({
        label: `Updated the "Name" field:`,
        value: [
          {
            value: currentCategory?.name || "",
            oldValue: oldCategory?.name || ""
          }
        ]
      });
    }
    return labelValues;
  };
  return (
    <AuditLogLabelValueList
      recordType="UPDATE"
      labelValues={[...getCategoryDetails()]}
    />
  );
};
