import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Toolbar } from "@components/crud/Toolbar";
import {
  AdminDepartmentPostPutRequest,
  useAdminDepartmentPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DepartmentForm } from "./DepartmentDetailsForm";

export const DepartmentCreate = () => {
  const navigate = useNavigate();
  const form = useForm({
    mode: "onTouched"
  });

  const {
    getValues,
    formState: { isValid }
  } = form;

  const { mutate: save, isLoading: isSaving } = useAdminDepartmentPost();
  const onSave = async () => {
    const values = {
      ...getValues()
    };
    values.sgNumber = values.sgNumber
      ? `+${values.sgNumber.replace(/\D/g, "")}`
      : null;
    save(
      {
        data: values as AdminDepartmentPostPutRequest
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Department Created successfully!", {
            variant: "success"
          });
          navigate("/departments");
        },
        onError: () => {
          enqueueSnackbar("Failed to Create Department!", {
            variant: "error"
          });
        }
      }
    );
  };
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  return (
    <Container>
      <Toolbar title="Add Department" />
      <DepartmentForm form={form} />
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          onSave();
        }}
        isDisabled={!isValid}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/departments")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
