import { UseFormReturn } from "react-hook-form";
import { ProgramDateAndTime } from "./ProgramDateAndTime";
import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";

export const ProgramDateAndTimeManager = ({
  form,
  disabled,
  generatedComponents,
  isEditing
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any, undefined>;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  generatedComponents: any;
  isEditing?: boolean;
}) => {
  const { setValue } = form;
  const [components, setComponents] = useState(
    generatedComponents || [
      {
        clearable: false,
        onlyTime: false,
        startDate: undefined,
        endDate: undefined,
        startTime: undefined,
        endTime: undefined,
        customRepeat: {}
      }
    ]
  );
  const createNewComponent = (component) => {
    return {
      clearable: component.clearable,
      onlyTime: component.onlyTime,
      startDate: undefined,
      endDate: undefined,
      startTime: undefined,
      endTime: undefined,
      customRepeat: {}
    };
  };

  useEffect(() => {
    if (generatedComponents) {
      setComponents(generatedComponents);
      generatedComponents.map((c, idx) => {
        if (!c.onlyTime) {
          setValue(`startDate[${idx}]`, c.startDate);
          setValue(`endDate[${idx}]`, c.endDate);
        }
        setValue(`startTime[${idx}]`, c.startTime);
        setValue(`endTime[${idx}]`, c.endTime);
      });
    }
  }, [generatedComponents]);

  useEffect(() => {
    setValue("dateTimeComponents", components, {
      shouldDirty: true
    });
  }, [components]);

  const addComponent = (component, index) => {
    const newComponent = createNewComponent(component);
    setComponents((prev) => {
      const newComponents = [...prev];
      newComponents.splice(index + 1, 0, newComponent);
      return newComponents;
    });
  };

  const deleteComponent = (index) => {
    setComponents((prev) => {
      const newComponents = prev.filter((component, i) => i !== index);
      return newComponents;
    });
  };
  return (
    <>
      <Grid item container direction="row" spacing="24px">
        <Grid item xs={6}>
          <Typography
            style={{
              color: "#00000",
              fontSize: "12px",
              font: "inter",
              lineHeight: "14.52px",
              letterSpacing: "10%",
              fontWeight: 400
            }}
          >
            DATE & TIME
          </Typography>
        </Grid>
      </Grid>
      {components.map((component, index) => {
        const isLast = components.length - 1 === index;
        return (
          <Grid
            key={index}
            item
            container
            direction="column"
            spacing="24px"
            data-testid={"PROGRAM_DATE_TIME_" + index + "_CONTAINER"}
          >
            <ProgramDateAndTime
              index={index}
              form={form}
              disabled={disabled}
              component={component}
              isEditing={isEditing}
              onChangeInput={(type, event) => {
                setComponents((prev) => {
                  const newComponents = [...prev];
                  newComponents[index] = {
                    ...newComponents[index],
                    [type]: event
                  };
                  return newComponents;
                });
              }}
              clearable={component.clearable}
              onlyTime={component.onlyTime}
              isLastElement={isLast}
              isImmediateChildDateTime={
                !isLast && !components[index + 1].onlyTime
              }
              onAddElement={(component, index) => {
                addComponent(component, index);
              }}
              onDeleteElement={(i) => {
                deleteComponent(i);
              }}
            />
          </Grid>
        );
      })}
    </>
  );
};
