import { ModelUser } from "@sportsgravyengineering/sg-api-react-sdk";
import { UsersAuditLogUpdate } from "./UsersAuditLogUpdate";
import { UsersAuditLogCreateDelete } from "./UsersAuditLogCreateDelete";

export const UsersAuditLogDetails = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelUser;
  after: object | ModelUser;
}) => {
  if (recordType === "UPDATE")
    return <UsersAuditLogUpdate before={before} after={after} />;
  else
    return (
      <UsersAuditLogCreateDelete
        recordType={recordType}
        before={before}
        after={after}
      />
    );
};
