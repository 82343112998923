import { Container } from "@components/crud/Container";
import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { ACTIVITY_TYPES } from "@pages/crm/activities/ActivityAdd";
import {
  ModelActivity,
  ModelOrganizationActivity,
  ModelOrganizationContact,
  ModelUser
} from "@sportsgravyengineering/sg-api-react-sdk";
import {
  ACTIVITY_RELATED_TO,
  ACTIVITY_STATUS_TYPES,
  CRM_ACTIVITY_CALL_OUTCOMES,
  CRM_ACTIVITY_NEXT_ACTIONS,
  CRM_CALL_DIRECTIONS
} from "@utils/constants";
import { formatDateForDisplay, formatTimeTo12h } from "@utils/formatDate";
import formatFullName from "@utils/formatFullName";

export const ActivityAuditLogCreateDelete = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "DELETE";
  before: object | (ModelOrganizationActivity & ModelActivity);
  after: object | (ModelOrganizationActivity & ModelActivity);
}) => {
  const activity = (
    recordType === "CREATE" ? after : before
  ) as ModelOrganizationActivity & ModelActivity;

  const getLabels = () => {
    const activityType = ACTIVITY_TYPES.find(
      (type) => type.value === activity.type
    )?.label;
    const releatedTo = ACTIVITY_RELATED_TO.find(
      (relatedTo) => relatedTo.value === activity.relatesTo
    )?.label;
    const labels: AuditLogLabelValue[] = [];

    labels.push(
      {
        label: `${activityType} "Related To" field:`,
        value: [
          {
            value: releatedTo || ""
          }
        ]
      },
      {
        label: `${activityType} "Select ${releatedTo}" field:`,
        value: [
          {
            value:
              activity.relatesTo === "ACCOUNT"
                ? activity.account?.name || ""
                : activity.relatesTo === "CONTACT"
                  ? formatFullName(activity.contact)
                  : activity.relatesTo === "OPPORTUNITY"
                    ? activity.opportunity?.name || ""
                    : activity.relatesTo === "LEAD"
                      ? activity.lead?.name || ""
                      : activity.relatesTo === "ORDER"
                        ? activity?.opportunity?.name || ""
                        : ""
          }
        ]
      },
      {
        label: `${activityType} "Date" field:`,
        value: [
          {
            value: activity.date
              ? formatDateForDisplay(new Date(activity.date))
              : ""
          }
        ]
      },
      {
        label: `${activityType} "Time" field:`,
        value: [
          {
            value: activity.date ? formatTimeTo12h(new Date(activity.date)) : ""
          }
        ]
      },
      {
        label: `${activityType} "Time Zone" field:`,
        value: [
          {
            value: activity?.timezone || ""
          }
        ]
      }
    );

    if (activity.type === "TASK") {
      labels.push({
        label: `${activityType} "Status" field:`,
        value: [
          {
            value:
              ACTIVITY_STATUS_TYPES.find(
                (status) => status.value === activity.status
              )?.label || ""
          }
        ]
      });
      if (activity.status === "ON_HOLD")
        labels.push({
          label: `${activityType} "Reason for Onhold" field:`,
          value: [
            {
              value: activity.reason || ""
            }
          ]
        });
    }

    if (activity.type === "CALL")
      labels.push(
        {
          label: `${activityType} "Participants" field:`,
          value: [
            {
              value: [
                ...(activity.internalParicipants || []),
                ...(activity.externalParticipants || [])
              ]
                .map((p: ModelOrganizationContact & ModelUser) =>
                  formatFullName(p.contactId ? p : p.person)
                )
                .join(", ")
            }
          ]
        },
        {
          label: `${activityType} "Call Direction" field:`,
          value: [
            {
              value:
                CRM_CALL_DIRECTIONS.find(
                  (direction) => direction.value === activity.call?.type
                )?.label || ""
            }
          ]
        },
        {
          label: `${activityType} "Call Outcome" field:`,
          value: [
            {
              value:
                CRM_ACTIVITY_CALL_OUTCOMES.find(
                  (direction) => direction.value === activity.call?.outcome
                )?.label || ""
            }
          ]
        },
        {
          label: `${activityType} "Next Action" field:`,
          value: [
            {
              value:
                CRM_ACTIVITY_NEXT_ACTIONS.find(
                  (direction) => direction.value === activity.nextAction
                )?.label || ""
            }
          ]
        },
        {
          label: `${activityType} "Date" field:`,
          value: [
            {
              value: activity.nextActionDate
                ? formatDateForDisplay(new Date(activity.nextActionDate))
                : ""
            }
          ]
        },
        {
          label: `${activityType} "Time" field:`,
          value: [
            {
              value: activity.nextActionDate
                ? formatTimeTo12h(new Date(activity.nextActionDate))
                : ""
            }
          ]
        }
      );

    if (activity.type === "EMAIL") {
      labels.push({
        label: `${activityType} "Email To" field:`,
        value: [
          {
            value: (activity.email?.to || [])
              .map(
                (participant) => `${participant?.name} - ${participant?.email}`
              )
              .join(", ")
          }
        ]
      });
      if (activity.email?.cc?.length || activity.email?.bcc?.length)
        labels.push({
          label: `${activityType} "Add CC/BCC" field:`,
          value: [
            {
              value: "On"
            }
          ]
        });
      if (activity.email?.cc?.length)
        labels.push({
          label: `${activityType} "Email CC" field:`,
          value: [
            {
              value: activity.email?.cc
                .map(
                  (participant) =>
                    `${participant?.name} - ${participant?.email}`
                )
                .join(", ")
            }
          ]
        });

      if (activity.email?.bcc?.length)
        labels.push({
          label: `${activityType} "Email BCC" field:`,
          value: [
            {
              value: activity.email?.bcc
                .map(
                  (participant) =>
                    `${participant?.name} - ${participant?.email}`
                )
                .join(", ")
            }
          ]
        });

      labels.push(
        {
          label: `${activityType} "Subject" field:`,
          value: [
            {
              value: activity.email?.subject || ""
            }
          ]
        },
        {
          label: `${activityType} "Email Content" field:`,
          value: [
            {
              value: activity.email?.body || ""
            }
          ]
        }
      );
    }

    if (activity.type === "MEETING") {
      labels.push({
        label: `${activityType} "Participants" field:`,
        value: [
          {
            value: [
              ...(activity.internalParicipants || []),
              ...(activity.externalParticipants || [])
            ]
              .map((p: ModelOrganizationContact & ModelUser) =>
                formatFullName(p.contactId ? p : p.person)
              )
              .join(", ")
          }
        ]
      });
    }

    if (activity.notes)
      labels.push({
        label: `${activityType} "Notes" field:`,
        value: [
          {
            value: activity.notes || "",
            isRickText: activity.type
              ? ["MEETING", "TASK"].includes(activity.type)
              : false
          }
        ]
      });

    if (activity?.managerFeedback)
      labels.push({
        label: `${activityType} "Manager Feedback" field:`,
        value: [
          {
            value: activity?.managerFeedback || ""
          }
        ]
      });

    return labels;
  };

  return (
    <Container>
      <AuditLogLabelValueList
        recordType={recordType}
        labelValues={getLabels()}
      />
    </Container>
  );
};
