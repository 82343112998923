import { Container } from "@components/crud/Container";

import { Button, ButtonGroup } from "@mui/material";
import { ModelOrganization } from "@sportsgravyengineering/sg-api-react-sdk";
import { formatPhoneWithCountryCode } from "@utils/phoneFormatters";
import { useState } from "react";

import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { capitalize } from "@utils/capitalize";
import {
  ORGANIZATION_OFFERINGS_OPTIONS,
  ORGANIZATION_TYPE_OPTIONS
} from "@utils/constants";
import formatFullName from "@utils/formatFullName";

export type Tabs =
  | "Organization Details"
  | "Sports Details"
  | "Contact Details";

export const OrganizationDetailsAuditLogCreateDelete = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "DELETE";
  before: object | ModelOrganization;
  after: object | ModelOrganization;
}) => {
  const tabs = ["Organization Details", "Sports Details", "Contact Details"];
  const [tab, setTab] = useState("Organization Details");

  const organizationDetails: ModelOrganization =
    recordType === "CREATE" ? after : before;

  const getSportsOffered = (): string => {
    if (!organizationDetails?.sports || organizationDetails.sports.length === 0)
      return "";

    return organizationDetails.sports
      .map((sport) => sport?.sport?.name)
      .join(", ");
  };

  const genOfficeAddress = () => {
    if (!organizationDetails) return "";
    const { lines, country, locality, province, postalCode } =
      organizationDetails;
    return `${lines?.[0]}|${lines?.[1] || ""}|${locality}|${
      province
    }|${postalCode}|${country}`;
  };

  const getOrganizationDetails = () => {
    const labels: AuditLogLabelValue[] = [];
    labels.push(
      {
        label: `"Avatar" field:`,
        value: [
          {
            value: organizationDetails?.avatar || "",
            isMedia: true
          }
        ]
      },
      {
        label: `"Name" field:`,
        value: [
          {
            value: organizationDetails?.name || ""
          }
        ]
      },
      {
        label: `"Abbreviation" field:`,
        value: [
          {
            value: organizationDetails?.abbreviation || ""
          }
        ]
      },
      {
        label: `"Type" field:`,
        value: [
          {
            value:
              ORGANIZATION_TYPE_OPTIONS.find(
                (option) => option.value === organizationDetails?.type
              )?.label || ""
          }
        ]
      },
      {
        label: `"Offering" field:`,
        value: [
          {
            value:
              ORGANIZATION_OFFERINGS_OPTIONS.find(
                (option) => option.value === organizationDetails?.offering
              )?.label || ""
          }
        ]
      },
      {
        label: `"Phone" field:`,
        value: [
          {
            value: organizationDetails?.phone
              ? formatPhoneWithCountryCode(organizationDetails?.phone) || ""
              : ""
          }
        ]
      },
      {
        label: `"Email" field:`,
        value: [
          {
            value: organizationDetails?.email || ""
          }
        ]
      },
      ...(organizationDetails.website
        ? [
            {
              label: `"Website" field:`,
              value: [
                {
                  value: organizationDetails?.website || ""
                }
              ]
            }
          ]
        : []),
      {
        label: `"Upload Athletes/Players Template Provider" field:`,
        value: [
          {
            value: organizationDetails?.importTemplateProvider?.name || ""
          }
        ]
      },
      {
        label: `"Upload Athletes/Players Template Provider Version" field:`,
        value: [
          {
            value: organizationDetails?.importTemplateVersion?.version || ""
          }
        ]
      },
      {
        label: `Office Address "Country" field:`,
        value: [
          {
            value: organizationDetails?.country || ""
          }
        ]
      },
      {
        label: `Office Address "Address Line 1" field:`,
        value: [
          {
            value: organizationDetails?.lines?.[0] || ""
          }
        ]
      },
      ...(organizationDetails?.lines?.[1]
        ? [
            {
              label: `Office Address "Address Line 2" field:`,
              value: [
                {
                  value: organizationDetails?.lines?.[1] || ""
                }
              ]
            }
          ]
        : []),
      {
        label: `Office Address "City" field:`,
        value: [
          {
            value: organizationDetails?.locality || ""
          }
        ]
      },
      {
        label: `Office Address "State/Province" field:`,
        value: [
          {
            value: organizationDetails?.province || ""
          }
        ]
      },
      {
        label: `Office Address "Zip Code" field:`,
        value: [
          {
            value: organizationDetails?.postalCode || ""
          }
        ]
      },
      ...(genOfficeAddress() === organizationDetails?.billingAddress
        ? [
            {
              label: `"Billing Address is the same as Office Address" field:`,
              value: [
                {
                  value: "On"
                }
              ]
            }
          ]
        : [
            {
              label: `Billing Address "Country" field:`,
              value: [
                {
                  value:
                    organizationDetails?.billingAddress?.split("|")[5] || ""
                }
              ]
            },
            {
              label: `Billing Address "Address Line 1" field:`,
              value: [
                {
                  value:
                    organizationDetails?.billingAddress?.split("|")[0] || ""
                }
              ]
            },
            ...(organizationDetails?.billingAddress?.split("|")[1]
              ? [
                  {
                    label: `Billing Address "Address Line 2" field:`,
                    value: [
                      {
                        value:
                          organizationDetails?.billingAddress?.split("|")[1] ||
                          ""
                      }
                    ]
                  }
                ]
              : []),
            {
              label: `Billing Address "City" field:`,
              value: [
                {
                  value:
                    organizationDetails?.billingAddress?.split("|")[2] || ""
                }
              ]
            },
            {
              label: `Billing Address "State/Province" field:`,
              value: [
                {
                  value:
                    organizationDetails?.billingAddress?.split("|")[3] || ""
                }
              ]
            },
            {
              label: `Billing Address "Zip Code" field:`,
              value: [
                {
                  value:
                    organizationDetails?.billingAddress?.split("|")[4] || ""
                }
              ]
            }
          ]),
      ...(genOfficeAddress() === organizationDetails?.shippingAddress
        ? [
            {
              label: `"Shipping Address is the same as Office Address" field:`,
              value: [
                {
                  value: "On"
                }
              ]
            }
          ]
        : [
            {
              label: `Shipping Address "Country" field:`,
              value: [
                {
                  value:
                    organizationDetails?.shippingAddress?.split("|")[5] || ""
                }
              ]
            },
            {
              label: `Shipping Address "Address Line 1" field:`,
              value: [
                {
                  value:
                    organizationDetails?.shippingAddress?.split("|")[0] || ""
                }
              ]
            },
            ...(organizationDetails?.shippingAddress?.split("|")[1]
              ? [
                  {
                    label: `Shipping Address "Address Line 2" field:`,
                    value: [
                      {
                        value:
                          organizationDetails?.shippingAddress?.split("|")[1] ||
                          ""
                      }
                    ]
                  }
                ]
              : []),
            {
              label: `Shipping Address "City" field:`,
              value: [
                {
                  value:
                    organizationDetails?.shippingAddress?.split("|")[2] || ""
                }
              ]
            },
            {
              label: `Shipping Address "State/Province" field:`,
              value: [
                {
                  value:
                    organizationDetails?.shippingAddress?.split("|")[3] || ""
                }
              ]
            },
            {
              label: `Shipping Address "Zip Code" field:`,
              value: [
                {
                  value:
                    organizationDetails?.shippingAddress?.split("|")[4] || ""
                }
              ]
            }
          ]),
      {
        label: `"Live Streaming Revenue Share" field:`,
        value: [
          {
            value: `${organizationDetails?.revenueShare?.toString()}%`
          }
        ]
      }
    );
    return labels;
  };

  const getSportsDetails = () => {
    if (
      !organizationDetails?.sports ||
      organizationDetails.sports.length === 0
    ) {
      return [];
    }

    const labelValues: AuditLogLabelValue[] = [];

    organizationDetails.sports.forEach((sport) => {
      const offeredGenders: ("MALE" | "FEMALE")[] = sport?.offeredFor || [];
      labelValues.push({
        label: `${sport?.sport?.name} "Offered For" field:`,
        value: [
          { value: offeredGenders.map((g) => capitalize(g)).join(", ") || "" }
        ]
      });

      (["MALE", "FEMALE"] as const).forEach((gender) => {
        if (offeredGenders.includes(gender) && sport.sport?.isIntervalBased) {
          const formattedGender = capitalize(gender);

          labelValues.push(
            {
              label: `${sport?.sport?.name} "${formattedGender} Singular Interval" field:`,
              value: [
                {
                  value:
                    gender === "MALE"
                      ? (sport.maleSingularInterval as string)
                      : (sport.femaleSingularInterval as string)
                }
              ]
            },
            {
              label: `${sport?.sport?.name} "${formattedGender} Plural Interval" field:`,
              value: [
                {
                  value:
                    gender === "MALE"
                      ? (sport.malePluralInterval as string)
                      : (sport.femalePluralInterval as string)
                }
              ]
            },
            {
              label: `${sport?.sport?.name} "${formattedGender} Interval Abbreviation" field:`,
              value: [
                {
                  value:
                    gender === "MALE"
                      ? (sport.maleIntervalAbbreviation as string)
                      : (sport.femaleIntervalAbbreviation as string)
                }
              ]
            }
          );
        }
      });
      if (sport.governingBodyId)
        labelValues.push({
          label: `${sport?.sport?.name} "Who are you governed by?" field:`,
          value: [
            {
              value: `${sport.governingBody?.name} - ${sport.governingBody?.abbreviation || ""}`
            }
          ]
        });
    });

    return labelValues;
  };

  const getContactDetails = () => {
    const labels: AuditLogLabelValue[] = [];
    labels.push(
      {
        label: `"Primary Contact" section:`,
        value: [
          {
            value: formatFullName(organizationDetails.primaryContact)
          }
        ]
      },
      ...(organizationDetails.secondaryContactId
        ? [
            {
              label: `"Secondary Contact" section:`,
              value: [
                {
                  value: formatFullName(organizationDetails.secondaryContact)
                }
              ]
            }
          ]
        : [])
    );
    return labels;
  };

  return (
    <Container>
      <ButtonGroup
        variant="outlined"
        style={{ border: "rgba(215, 221, 229, 1)", paddingBottom: "24px" }}
      >
        {tabs.map((t) => {
          return (
            <Button
              key={t}
              variant="outlined"
              style={
                tab === t
                  ? {
                      backgroundColor: "#E8EEFF",
                      color: "#2B337A",
                      fontSize: "14px",
                      fontWeight: 600,
                      textTransform: "none"
                    }
                  : {
                      color: "#666666",
                      fontSize: "14px",
                      fontWeight: 500,
                      textTransform: "none"
                    }
              }
              onClick={() => {
                setTab(t as Tabs);
              }}
            >
              {t}
            </Button>
          );
        })}
      </ButtonGroup>
      {tab === "Organization Details" ? (
        <AuditLogLabelValueList
          key={tab}
          recordType={recordType}
          labelValues={[...getOrganizationDetails()]}
        />
      ) : tab === "Sports Details" ? (
        <AuditLogLabelValueList
          key={tab}
          recordType={recordType}
          labelValues={[
            {
              label: `"Sports Offered" field:`,
              value: [
                {
                  value: getSportsOffered()
                }
              ]
            },
            {
              label: `"Are you governed by an organization?"`,
              value: [
                {
                  value:
                    organizationDetails?.governingBody ||
                    organizationDetails?.sports?.[0]?.governingBodyId
                      ? "Yes"
                      : "No"
                }
              ]
            },
            ...(organizationDetails?.governingBodyId ||
            organizationDetails?.sports?.[0]?.governingBodyId
              ? [
                  ...(!organizationDetails?.sports?.[0]?.governingBodyId
                    ? [
                        {
                          label: `"Who are you governed by?" field:`,
                          value: [
                            {
                              value:
                                `${organizationDetails?.governingBody?.name} - ${organizationDetails?.governingBody?.abbreviation}` ||
                                ""
                            }
                          ]
                        }
                      ]
                    : [
                        {
                          label: `"Allow governing body by sport?"`,
                          value: [
                            {
                              value: organizationDetails?.sports?.[0]
                                .governingBody
                                ? "Yes"
                                : "No"
                            }
                          ]
                        }
                      ])
                ]
              : []),
            ...getSportsDetails()
          ]}
        />
      ) : (
        tab === "Contact Details" && (
          <AuditLogLabelValueList
            key={tab}
            recordType={recordType}
            labelValues={[...getContactDetails()]}
          />
        )
      )}
    </Container>
  );
};
