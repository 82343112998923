import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useCannedMessagePost,
  useAdminSportGet,
  cannedMessageWrapperGet,
  Gender
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "../../recoil/auth";

export const CannedMessageCreate = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const organizationId = useRecoilValue(organizationAtom);
  const [positionOptions, setPositionOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [conceptOptions, setConceptOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [levelsOptions, setLevelsOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [skillsOptions, setSkillsOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [systemsOptions, setSystemsOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [roleOptions, setRoleOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [genderOptions] = useState<{ label: string; value: string }[]>([
    { label: "Female", value: Gender.FEMALE },
    { label: "Male", value: Gender.MALE }
  ]);

  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });

  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
    setValue
  } = useForm({
    mode: "onTouched"
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId
      })) || [],
    [sports]
  );
  useEffect(() => {
    if (sportOptions.length == 1) {
      setValue("sportId", sportOptions[0].value);
      loadOptions(sportOptions[0].value);
    }
  }, [sportOptions]);
  const loadOptions = async (inputValue) => {
    const data = await cannedMessageWrapperGet({
      sportId: inputValue as string,
      organizationId
    });
    setPositionOptions([
      ...(data.data.positions?.map((position) => ({
        label: position.name!,
        value: position.positionId!
      })) || [])
    ]);
    setConceptOptions([
      ...(data.data.concepts?.map((concepts) => ({
        label: concepts.name!,
        value: concepts.conceptId!
      })) || [])
    ]);
    setLevelsOptions([
      ...(data.data.levels?.map((levels) => ({
        label: levels.name!,
        value: levels.levelId!
      })) || [])
    ]);
    setSkillsOptions([
      ...(data.data.skills?.map((skills) => ({
        label: skills.name!,
        value: skills.skillId!
      })) || [])
    ]);
    setSystemsOptions([
      ...(data.data.systems?.map((systems) => ({
        label: systems.name!,
        value: systems.systemId!
      })) || [])
    ]);
    setRoleOptions([
      ...(data.data.roles?.map((role) => ({
        label: role.name!,
        value: role.roleId!
      })) || [])
    ]);
  };

  const { mutate: save, isLoading: isSaving } = useCannedMessagePost();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    (formValues) => {
      const values = {
        ...formValues,
        organizationId
      };
      save(
        {
          data: values
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Canned message added successfully!", {
              variant: "success"
            });
            if (resetInsteadOfRoute) {
              reset();
            } else {
              navigate("/canned-messages");
            }
          },
          onError: () => {
            enqueueSnackbar("Failed to add canned message!", {
              variant: "error"
            });
          }
        }
      );
    };

  return (
    <Container>
      <Toolbar title="Add Canned Message" />
      <Form>
        <Grid container spacing={3}>
          <Grid xs={12} md={12} data-testid="CREATE_CANNED_MESSAGE">
            <FormInput
              control={control}
              name="message"
              type="text"
              label="Message"
              required={true}
              rules={{
                required: "Message is required"
              }}
            />
          </Grid>
          <Grid xs={12} md={12} data-testid="CREATE_CANNED_TITLE">
            <FormInput
              control={control}
              name="title"
              type="text"
              label="Title"
              required={true}
              rules={{
                required: "Title is required"
              }}
            />
          </Grid>
          <Grid container xs={12} md={12} data-testid="CREATE_CANNED_SPORT">
            <Grid xs={12} md={6}>
              <FormSelect
                control={control}
                name="sportId"
                label="Sport"
                required={true}
                options={sportOptions}
                isLoading={isSportLoading}
                disabled={sportOptions.length == 1}
                onChange={(e) => {
                  loadOptions(e.target.value);
                }}
                rules={{
                  required: "Sport is required"
                }}
              />
            </Grid>
          </Grid>

          <Grid xs={12} md={6} data-testid="CREATE_CANNED_GENDER">
            <FormMultiSelect
              control={control}
              name="genders"
              label="Genders"
              required={false}
              options={genderOptions}
            />
          </Grid>
          <Grid xs={12} md={6} data-testid="CREATE_CANNED_LEVEL">
            <FormMultiSelect
              control={control}
              name="levels"
              label="Levels"
              required={false}
              options={levelsOptions}
            />
          </Grid>
          <Grid xs={12} md={6} data-testid="CREATE_CANNED_POSITIONS">
            <FormMultiSelect
              control={control}
              name="positions"
              label="Positions"
              required={false}
              options={positionOptions}
            />
          </Grid>
          <Grid xs={12} md={6} data-testid="CREATE_CANNED_SKILLS">
            <FormMultiSelect
              control={control}
              name="skills"
              label="Skills"
              required={false}
              options={skillsOptions}
            />
          </Grid>
          <Grid xs={12} md={6} data-testid="CREATE_CANNED_CONCEPTS">
            <FormMultiSelect
              control={control}
              name="concepts"
              label="Game Concepts"
              required={false}
              options={conceptOptions}
            />
          </Grid>
          <Grid xs={12} md={6} data-testid="CREATE_CANNED_SYSTEMS">
            <FormMultiSelect
              control={control}
              name="systems"
              label="Game Systems"
              required={false}
              options={systemsOptions}
            />
          </Grid>
          <Grid xs={12} md={6} data-testid="CREATE_CANNED_ROLES">
            <FormMultiSelect
              control={control}
              name="roles"
              label="Roles"
              required={false}
              options={roleOptions}
            />
          </Grid>
        </Grid>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler(false))}
        saveAndNewBtnClick={handleSubmit(saveHandler(true))}
        isDisabled={!isValid || isSaving}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/canned-messages")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
