import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import {
  ModelAccount,
  ModelOrder
} from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";

export const OrdersAuditLogCreateDelete = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelOrder;
  after: object | ModelOrder;
}) => {
  const orderDetails = (recordType === "CREATE" ? after : before) as ModelOrder;
  const getOrderDetails = () => {
    const orderAccount = orderDetails.account as ModelAccount;
    const labelValues: AuditLogLabelValue[] = [];

    labelValues.push({
      label: `"Order Submitted By" field:`,
      value: [
        {
          value:
            `${orderDetails?.submittedBy?.person?.firstName} ${orderDetails?.submittedBy?.person?.lastName}` ||
            ""
        }
      ]
    });
    labelValues.push({
      label: `"Order Date" field:`,
      value: [{ value: orderDetails?.orderDate?.toLocaleDateString() || "" }]
    });
    labelValues.push({
      label: `"Product" field:`,
      value: [{ value: orderDetails?.product || "" }]
    });
    labelValues.push({
      label: `"Order Type" field:`,
      value: [{ value: orderDetails?.orderType || "" }]
    });
    labelValues.push({
      label: `"Start Date" field:`,
      value: [
        { value: orderDetails?.originalStartDate?.toLocaleDateString() || "" }
      ]
    });
    labelValues.push({
      label: `"End Date" field:`,
      value: [
        { value: orderDetails?.originalEndDate?.toLocaleDateString() || "" }
      ]
    });
    labelValues.push({
      label: `"No. of Yearly Athlete Registrations" field 1:`,
      value: [
        { value: orderDetails?.initialNumberOfAthletes?.toString() || "" }
      ]
    });
    labelValues.push({
      label: `"No. of Yearly Athlete Registrations" field 2:`,
      value: [
        {
          value: `${orderDetails?.ratePerAthlete || ""} Per Athlete, Per Registration`
        }
      ]
    });
    labelValues.push({
      label: `"Estimated Yearly Amount" field:`,
      value: [
        { value: `$${orderDetails?.totalAmount?.toLocaleString() || ""}` }
      ]
    });
    labelValues.push({
      label: `"Billing Option" field:`,
      value: [{ value: orderDetails?.billingType || "" }]
    });
    labelValues.push({
      label: `"Tax Exempt" field:`,
      value: [{ value: orderDetails?.status || "" }]
    });
    labelValues.push({
      label: `"Notes" field:`,
      value: [{ value: orderDetails?.notes || "" }]
    });

    // Account Details
    labelValues.push({
      label: `Account Details "Account Name" field:`,
      value: [{ value: orderAccount?.name || "" }]
    });
    labelValues.push({
      label: `Account Details "Account Parent" field:`,
      value: [{ value: orderAccount?.parent?.name || "" }]
    });
    labelValues.push({
      label: `Account Details "Account Category" field:`,
      value: [{ value: orderAccount?.category || "" }]
    });
    labelValues.push({
      label: `Account Details "Account Type" field:`,
      value: [{ value: orderAccount?.type || "" }]
    });
    labelValues.push({
      label: `Account Details "Account Address" field:`,
      value: [{ value: orderAccount?.officeAddress || "" }]
    });
    labelValues.push({
      label: `Account Details "Account Email" field:`,
      value: [{ value: orderAccount?.email || "" }]
    });
    labelValues.push({
      label: `Account Details "Account Website" field:`,
      value: [{ value: orderAccount?.website || "" }]
    });
    labelValues.push({
      label: `Account Details "Account Owner" field:`,
      value: [
        {
          value:
            `${orderAccount?.aeOwner?.person?.firstName} ${orderAccount?.aeOwner?.person?.lastName}` ||
            ""
        }
      ]
    });

    return labelValues;
  };

  return (
    <AuditLogLabelValueList
      recordType={recordType}
      labelValues={[...getOrderDetails()]}
    />
  );
};
