import { Container } from "@components/crud/Container";
import { Button, ButtonGroup } from "@mui/material";
import { ModelOrganizationSetting } from "@sportsgravyengineering/sg-api-react-sdk";
import { useState } from "react";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "../types";
import { useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { TaxFee } from "@pages/settings/LiveStreamTaxFees";
import { capitalize } from "@utils/capitalize";

type Tabs = "Dashboard" | "Calendar" | "Teams/Programs" | "Live Streaming";

type DefaultSportImageValue = {
  sportId: string;
  url: string;
  mediaId: string;
};

export const SettingAuditLogUpdate = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelOrganizationSetting[];
  after: object | ModelOrganizationSetting[];
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);

  const organization = organizations.find(
    (org) => org.organizationId === organizationId
  );
  const tabs = ["Dashboard", "Calendar", "Teams/Programs", "Live Streaming"];
  const [tab, setTab] = useState<Tabs>("Dashboard");
  const oldSetting = before as ModelOrganizationSetting[];
  const setting = after as ModelOrganizationSetting[];

  const getDashboardLabels = () => {
    const labels: AuditLogLabelValue[] = [];
    const newDashboardSettings = setting.filter((s) =>
      s.settingId?.includes("org-dashboard")
    );
    const oldDashboardSettings = oldSetting.filter((s) =>
      s.settingId?.includes("org-dashboard")
    );

    const changedSettings = newDashboardSettings.filter((s) => {
      const oldSetting = oldDashboardSettings.find(
        (os) => os.settingId === s.settingId
      );
      if (!oldSetting) return true;
      return oldSetting?.value !== s.value;
    });

    changedSettings.map((s) => {
      const options = s.setting?.options as Array<{
        label: string;
        value: string;
      }>;
      labels.push({
        label: `Updated the "${s.setting?.description ?? s.setting?.name}" field:`,
        value: [
          {
            oldValue:
              options.find(
                (o) =>
                  o.value ===
                  oldDashboardSettings.find(
                    (os) => os.settingId === s.settingId
                  )?.value
              )?.label || "N/A",
            value: options.find((o) => o.value === s.value)?.label || "N/A"
          }
        ]
      });
    });

    return labels;
  };

  const getTeamLables = () => {
    const labels: AuditLogLabelValue[] = [];
    const newSetting = setting.filter((s) =>
      s.settingId?.includes("org-teams-programs")
    );
    const updatedSetting = oldSetting.filter((s) =>
      s.settingId?.includes("org-teams-programs")
    );

    const changedSettings = newSetting.filter((s) => {
      const oldSetting = updatedSetting.find(
        (os) => os.settingId === s.settingId
      );
      if (!oldSetting) return true;
      return oldSetting?.value !== s.value;
    });

    changedSettings.map((s) => {
      const options = s.setting?.options as Array<{
        label: string;
        value: string;
      }>;
      labels.push({
        label: `Updated the "${s.setting?.description ?? s.setting?.name}" field:`,
        value: [
          {
            oldValue:
              options.find(
                (o) =>
                  o.value ===
                  updatedSetting.find((os) => os.settingId === s.settingId)
                    ?.value
              )?.label || "N/A",
            value: options.find((o) => o.value === s.value)?.label || "N/A"
          }
        ]
      });
    });

    return labels;
  };

  const getCalendarLabels = () => {
    const labels: AuditLogLabelValue[] = [];
    const newSetting = setting.filter((s) => s.settingId?.includes("general"));
    const updatedSetting = oldSetting.filter((s) =>
      s.settingId?.includes("general")
    );

    const changedSettings = newSetting.filter((s) => {
      const oldSetting = updatedSetting.find(
        (os) => os.settingId === s.settingId
      );
      if (!oldSetting) return true;
      return oldSetting?.value !== s.value;
    });

    changedSettings.map((s) => {
      const options = s.setting?.options as Array<{
        label: string;
        value: string;
      }>;
      labels.push({
        label: `Updated the "${s.setting?.description ?? s.setting?.name}" field:`,
        value: [
          {
            oldValue:
              options.find(
                (o) =>
                  o.value ===
                  updatedSetting.find((os) => os.settingId === s.settingId)
                    ?.value
              )?.label || "N/A",
            value: options.find((o) => o.value === s.value)?.label || "N/A"
          }
        ]
      });
    });

    return labels;
  };

  const getLiveStreamingLabels = () => {
    const labels: AuditLogLabelValue[] = [];

    const newSetting = setting.filter((s) =>
      s.settingId?.includes("org-live-stream")
    );
    const updatedSetting = oldSetting.filter((s) =>
      s.settingId?.includes("org-live-stream")
    );

    const oldAllowPublicSharing = updatedSetting.find(
      (s) => s.settingId === "org-live-stream.allow-public-sharing"
    );
    const newAllowPublicSharing = newSetting.find(
      (s) => s.settingId === "org-live-stream.allow-public-sharing"
    );

    if (oldAllowPublicSharing?.value !== newAllowPublicSharing?.value)
      labels.push({
        label: `Updated General "Allow live streams to be shared with the public" field:`,
        value: [
          {
            oldValue: oldAllowPublicSharing?.value ? "Yes" : "No",
            value: newAllowPublicSharing?.value ? "Yes" : "No"
          }
        ]
      });

    const oldAllowPreGame = updatedSetting.find(
      (s) => s.settingId === "org-live-stream.allow-pre-game"
    );
    const newAllowPreGame = newSetting.find(
      (s) => s.settingId === "org-live-stream.allow-pre-game"
    );

    if (oldAllowPreGame?.value !== newAllowPreGame?.value)
      labels.push({
        label: `Updated General "Allow Scheduled Pre Game" field:`,
        value: [
          {
            oldValue: oldAllowPreGame?.value ? "Yes" : "No",
            value: newAllowPreGame?.value ? "Yes" : "No"
          }
        ]
      });

    const oldAllowPostGame = updatedSetting.find(
      (s) => s.settingId === "org-live-stream.allow-post-game"
    );
    const newAllowPostGame = newSetting.find(
      (s) => s.settingId === "org-live-stream.allow-post-game"
    );

    if (oldAllowPostGame?.value !== newAllowPostGame?.value)
      labels.push({
        label: `Updated General "Allow Scheduled Post Game" field:`,
        value: [
          {
            oldValue: oldAllowPostGame?.value ? "Yes" : "No",
            value: newAllowPostGame?.value ? "Yes" : "No"
          }
        ]
      });

    const oldCancellationTime = updatedSetting.find(
      (s) => s.settingId === "org-live-stream.inactive-stream-cancellation-time"
    );

    const newCancellationTime = newSetting.find(
      (s) => s.settingId === "org-live-stream.inactive-stream-cancellation-time"
    );

    if (oldCancellationTime?.value !== newCancellationTime?.value)
      labels.push({
        label: `Updated General "Automatically Cancel Live Streams after of inactivity" field:`,
        value: [
          {
            oldValue: oldCancellationTime?.value
              ? `${oldCancellationTime?.value} Hr`
              : "N/A",
            value: newCancellationTime?.value
              ? `${newCancellationTime?.value} Hr`
              : "N/A"
          }
        ]
      });

    const oldAdvertiserApproval = updatedSetting.find(
      (s) => s.settingId === "org-live-stream.advertiser-approval-required"
    );

    const newAdvertiserApproval = newSetting.find(
      (s) => s.settingId === "org-live-stream.advertiser-approval-required"
    );

    if (oldAdvertiserApproval?.value !== newAdvertiserApproval?.value)
      labels.push({
        label: `Updated Advertisers "Require Advertiser Approval for Advertisements" field:`,
        value: [
          {
            oldValue: oldAdvertiserApproval?.value ? "Yes" : "No",
            value: newAdvertiserApproval?.value ? "Yes" : "No"
          }
        ]
      });

    const oldApprovalOnLive = updatedSetting.find(
      (s) => s.settingId === "org-live-stream.advertisement-live-on-approval"
    );

    const newApprovalOnLive = newSetting.find(
      (s) => s.settingId === "org-live-stream.advertisement-live-on-approval"
    );

    if (oldApprovalOnLive?.value !== newApprovalOnLive?.value)
      labels.push({
        label: `Updated Advertisers "Upon advertisement approval automatically set advertisement status to active" field:`,
        value: [
          {
            oldValue: oldApprovalOnLive?.value ? "Yes" : "No",
            value: newApprovalOnLive?.value ? "Yes" : "No"
          }
        ]
      });

    organization?.sports?.map((sport) => {
      const oldImages = updatedSetting.filter(
        (s) => s.settingId === `org-live-stream.default-live-stream-images`
      )?.[0].value as DefaultSportImageValue[];
      const newImages = newSetting.filter(
        (s) => s.settingId === `org-live-stream.default-live-stream-images`
      )?.[0].value as DefaultSportImageValue[];

      const oldSportImage = oldImages.find(
        (s) => s.sportId === sport.sportId
      ) as DefaultSportImageValue;

      const newSportImage = newImages.find(
        (s) => s.sportId === sport.sportId
      ) as DefaultSportImageValue;

      if (
        oldSportImage?.mediaId !== newSportImage?.mediaId &&
        !(!oldSportImage?.mediaId && !newSportImage?.mediaId)
      ) {
        labels.push({
          label: `Updated Advertisers "Default ${sport?.sport?.name} Live Stream Image" field:`,
          value: [
            {
              oldValue: oldSportImage?.mediaId
                ? {
                    baseUrl: oldSportImage?.url,
                    path: "",
                    type: "IMAGE"
                  }
                : "N/A",
              value: newSportImage?.mediaId
                ? {
                    baseUrl: newSportImage?.url,
                    path: "",
                    type: "IMAGE"
                  }
                : "N/A",
              isMedia: true
            }
          ]
        });
      }
    });

    const oldTaxFees = updatedSetting.filter(
      (s) => s.settingId === "org-live-stream.taxes-and-fees"
    )?.[0].value as TaxFee[];

    const newTaxFees = newSetting.filter(
      (s) => s.settingId === "org-live-stream.taxes-and-fees"
    )?.[0].value as TaxFee[];

    const newAdded = newTaxFees.filter(
      (n) => !oldTaxFees.find((o) => o.name === n.name)
    );

    const existing = newTaxFees.filter((n) =>
      oldTaxFees.find((o) => o.name === n.name)
    );
    const removed = oldTaxFees.filter(
      (o) =>
        !newTaxFees.find(
          (n) =>
            n.name === o.name &&
            n.type === o.type &&
            n.calculationMethod === o.calculationMethod &&
            n.rate === o.rate
        )
    );

    newAdded.map((n, i) => {
      labels.push({
        label: `Updated Taxes And Fees Tax/Fee ${i + 1}:`,
        value: [
          {
            subText: "Label",
            value: n.name,
            oldValue: "N/A"
          },
          {
            subText: "Type",
            value: capitalize(n.type),
            oldValue: "N/A"
          },
          {
            subText: "Value",
            value: capitalize(n.calculationMethod),
            oldValue: "N/A"
          },
          {
            subText: "Percentage/Currency",
            value:
              n.calculationMethod === "PERCENTAGE"
                ? `${n.rate}%`
                : `$${n.rate}`,
            oldValue: "N/A"
          }
        ]
      });
    });

    existing.map((n, i) => {
      const old = oldTaxFees.find((o) => o.name === n.name);
      labels.push({
        label: `Updated Taxes And Fees Tax/Fee ${newAdded.length + i + 1}:`,
        value: [
          ...(n.name !== old?.name
            ? [
                {
                  subText: "Label",
                  value: n.name,
                  oldValue: old?.name
                }
              ]
            : []),
          ...(n.type !== old?.type
            ? [
                {
                  subText: "Type",
                  value: n.type ? capitalize(n.type) : "N/A",
                  oldValue: old?.type ? capitalize(old?.type) : "N/A"
                }
              ]
            : []),
          ...(n.calculationMethod !== old?.calculationMethod
            ? [
                {
                  subText: "Value",
                  value: n.calculationMethod
                    ? capitalize(n.calculationMethod)
                    : "N/A",
                  oldValue: old?.calculationMethod
                    ? capitalize(old?.calculationMethod)
                    : "N/A"
                }
              ]
            : []),
          ...(n.rate !== old?.rate ||
          n.calculationMethod !== old?.calculationMethod
            ? [
                {
                  subText: "Percentage/Currency",
                  value:
                    n.calculationMethod === "PERCENTAGE"
                      ? `${n.rate}%`
                      : `$${n.rate}`,
                  oldValue:
                    old?.calculationMethod === "PERCENTAGE"
                      ? `${old?.rate}%`
                      : `$${old?.rate}`
                }
              ]
            : [])
        ]
      });
    });

    removed.map((n, i) => {
      labels.push({
        label: `Updated Taxes And Fees Tax/Fee ${newAdded.length + existing.length + i + 1}:`,
        value: [
          {
            subText: "Label",
            value: "N/A",
            oldValue: n.name || "N/A"
          },
          {
            subText: "Type",
            value: "N/A",
            oldValue: capitalize(n.type)
          },
          {
            subText: "Value",
            value: "N/A",
            oldValue: capitalize(n.calculationMethod)
          },
          {
            subText: "Percentage/Currency",
            oldValue:
              n.calculationMethod === "PERCENTAGE"
                ? `${n.rate}%`
                : `$${n.rate}`,
            value: "N/A"
          }
        ]
      });
    });

    return labels.filter((l) => l.value.length > 0);
  };

  return (
    <Container>
      <ButtonGroup
        variant="outlined"
        style={{ border: "rgba(215, 221, 229, 1)", paddingBottom: "24px" }}
      >
        {tabs.map((t) => {
          return (
            <Button
              key={t}
              variant="outlined"
              style={
                tab === t
                  ? {
                      backgroundColor: "#E8EEFF",
                      color: "#2B337A",
                      fontSize: "14px",
                      fontWeight: 600,
                      textTransform: "none"
                    }
                  : {
                      color: "#666666",
                      fontSize: "14px",
                      fontWeight: 500,
                      textTransform: "none"
                    }
              }
              onClick={() => {
                setTab(t as Tabs);
              }}
            >
              {t}
            </Button>
          );
        })}
      </ButtonGroup>
      {tab === "Dashboard" ? (
        <AuditLogLabelValueList
          key={tab}
          recordType={recordType}
          labelValues={getDashboardLabels()}
        />
      ) : tab === "Calendar" ? (
        <AuditLogLabelValueList
          key={tab}
          recordType={recordType}
          labelValues={getCalendarLabels()}
        />
      ) : tab === "Live Streaming" ? (
        <AuditLogLabelValueList
          recordType={recordType}
          labelValues={getLiveStreamingLabels()}
          key={tab}
        />
      ) : (
        <AuditLogLabelValueList
          key={tab}
          recordType={recordType}
          labelValues={getTeamLables()}
        />
      )}
    </Container>
  );
};
