import { Container } from "@components/crud/Container";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import {
  AdminSponsorshipLevelIdGetResponse,
  ModelSponsorshipAdPlacementPercentage
} from "@sportsgravyengineering/sg-api-react-sdk";
import { AuditLogLabelValue } from "../types";
import { formatCurrency } from "@utils/formatCurrency";

const convertFloatToNumber = (number: number | undefined) => {
  if (number === undefined) return "";
  return (number * 100).toFixed(0);
};

export const SponsorshipLevelAuditCreateDelete = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "DELETE";
  before: object | AdminSponsorshipLevelIdGetResponse;
  after: object | AdminSponsorshipLevelIdGetResponse;
}) => {
  const level = (
    recordType === "CREATE" ? after : before
  ) as AdminSponsorshipLevelIdGetResponse;

  const getLabelValues = () => {
    const labelValues: AuditLogLabelValue[] = [];
    const adPlacementPercentages = level.adPlacementPercentages || [];
    labelValues.push({
      label: `"Name" field:`,
      value: [
        {
          value: level.sponsorshipLevel?.name || ""
        }
      ]
    });

    labelValues.push({
      label: `"Amount" field:`,
      value: [
        {
          value: formatCurrency(level.sponsorshipLevel?.amount)
        }
      ]
    });

    labelValues.push({
      label: `"Is Active" field:`,
      value: [
        {
          value: level.sponsorshipLevel?.isActive ? "Active" : "Inactive"
        }
      ]
    });

    if (level.sponsorshipLevel?.sponsoredByEnabled) {
      labelValues.push({
        label: `Ad Placements “Sponsored By Ad - Live Stream Event Feed Posts” field:`,
        value: [
          {
            value: level.sponsorshipLevel?.sponsoredByEnabled ? "On" : "Off"
          }
        ]
      });
      labelValues.push({
        label: `Sponsored By Ad - Live Stream Event Feed Posts “Probability Percentage of being Displayed” field:`,
        value:
          adPlacementPercentages
            ?.concat(
              level.sponsorshipLevel as ModelSponsorshipAdPlacementPercentage
            )
            ?.map((placement) => ({
              subText: placement.name,
              value: convertFloatToNumber(placement.sponsoredByPercentage)
            })) || []
      });
    }

    if (level.sponsorshipLevel?.outStreamEnabled) {
      labelValues.push({
        label: `Ad Placements “Out-Stream Ad - Live Stream Intermissions” field:`,
        value: [
          {
            value: level.sponsorshipLevel?.outStreamEnabled ? "On" : "Off"
          }
        ]
      });
      labelValues.push({
        label: `Out-Stream Ad - View Live Stream Screen “Probability Percentage of being Displayed” field:`,
        value:
          adPlacementPercentages
            ?.concat(
              level.sponsorshipLevel as ModelSponsorshipAdPlacementPercentage
            )
            ?.map((placement) => ({
              subText: placement.name,
              value: convertFloatToNumber(placement.outStreamPercentage)
            })) || []
      });
    }

    if (level.sponsorshipLevel?.inStreamEnabled)
      labelValues.push({
        label: `Ad Placements "In-Stream Ad - Live Stream Intermissions” field:`,
        value: [
          {
            value: level.sponsorshipLevel?.inStreamEnabled ? "On" : "Off"
          }
        ]
      });

    if (level.sponsorshipLevel?.inStreamMaxDuration)
      labelValues.push({
        label: `In-Stream Ad - Live Stream Intermissions “Max Duration of In-Stream Video” field:`,
        value: [
          {
            value: level.sponsorshipLevel?.inStreamMaxDuration
              ? `${level.sponsorshipLevel?.inStreamMaxDuration} seconds`
              : "N/A"
          }
        ]
      });

    return labelValues;
  };

  return (
    <Container>
      <AuditLogLabelValueList
        recordType={recordType}
        labelValues={[...getLabelValues()]}
      />
    </Container>
  );
};
