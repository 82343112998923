import { Container } from "@components/crud/Container";
import { Button, ButtonGroup } from "@mui/material";
import {
  ModelTeam,
  ModelTrainingProgram,
  useAdminPermissionGet,
  useAdminPositionGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useState } from "react";
import { AuditLogLabelValueList } from ".././components/AuditLogLabelvalueList";
import { GENDERS, TrainingProgramTypeOptions } from "@utils/constants";
import { PlayerOption } from "@pages/teams-programs/components/PlayerSelectionForm";
import { CoachOption } from "@pages/teams-programs/components/CoachSelectionForm";
import { ManagerOption } from "@pages/teams-programs/components/ManagerSelectionForm";
import { AuditLogLabelValue } from ".././types";
import { Loader } from "@components/crud/Loader";
import { generatePermissionValues } from ".././services";
import { capitalize } from "@utils/capitalize";
import { arraysEqual } from "@utils/ArrayFunctions";
import { TrainingProgramDate } from "@pages/teams-programs/training-programs/ProgramDetailsForm";
import { convertToAmPm, formatDateForDisplay } from "@utils/formatDate";
import convertRruleToMessage from "@utils/ConvertRruleToMessage";

type Tabs = "Team Details" | "Players" | "Coaches" | "Managers" | "Permissions";

export const TeamAuditLogUpdateDetails = ({
  before,
  after,
  page
}: {
  before: object | (ModelTeam & ModelTrainingProgram);
  after: object | (ModelTeam & ModelTrainingProgram);
  page: "TEAM" | "TRAINING_PROGRAM";
}) => {
  const tabs = [
    page === "TEAM" ? "Team Details" : "Program Details",
    page === "TEAM" ? "Players" : "Athletes",
    "Coaches",
    "Managers",
    "Permissions"
  ];
  const [tab, setTab] = useState(
    page === "TEAM" ? "Team Details" : "Program Details"
  );
  const oldTeam = before as ModelTeam & ModelTrainingProgram;
  const currentTeam = after as ModelTeam & ModelTrainingProgram;
  const teamRoles = currentTeam.roles;
  const { isLoading: isLoading, data: positionsResponse } = useAdminPositionGet(
    {
      sportId: currentTeam?.sportId
    }
  );
  const { isLoading: isLoadingPermissions, data: permissionsResponse } =
    useAdminPermissionGet();
  const [playersWithValues, setPlayersWithValues] = useState<PlayerOption[]>(
    []
  );
  const [coachesWithValues, setCoachesWithValues] = useState<CoachOption[]>([]);
  const [managerWithValues, setManagerWithValues] = useState<ManagerOption[]>(
    []
  );
  const [oldPlayersWithValues, setOldPlayersWithValues] = useState<
    PlayerOption[]
  >([]);
  const [oldCoachesWithValues, setOldCoachesWithValues] = useState<
    CoachOption[]
  >([]);
  const [oldManagerWithValues, setOldManagerWithValues] = useState<
    ManagerOption[]
  >([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [permissionValues, setPermissionValues] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [oldPermissionValues, setOldPermissionValues] = useState<any>({});
  const getCoachesOrManagers = (alias, team) => {
    if (!team) return;
    const invitedUsers = team.invites?.filter(
      (item) => item.invitedFor![0]?.alias === alias
    );
    const activeUsers = team.userRoles?.filter(
      (item) => item.role?.alias === alias
    );
    let invites: CoachOption[] = [],
      accepted: CoachOption[] = [];

    if (activeUsers) {
      accepted = activeUsers.map((user) => {
        const formattedPerson = {
          label: `${user?.user?.person?.firstName} ${user?.user?.person?.lastName}`,
          id: user!.user!.personId!,
          ...user,
          role: alias,
          personId: user!.user!.personId!,
          value: user!.user!.personId!,
          roleId: user.roleId!,
          permissions: user?.permissionOverrides,
          status: "ACTIVE"
        };
        return formattedPerson;
      });
    }
    if (invitedUsers) {
      invites = invitedUsers
        .filter((iu) => !accepted.find((a) => a.id === iu!.person!.personId))
        .map((user) => {
          const formattedPerson = {
            label: `${user?.person?.firstName} ${user?.person?.lastName}`,
            id: user!.person!.personId!,
            value: user!.person!.personId!,
            personId: user!.person!.personId!,
            ...user,
            role: alias,
            roleId: user.invitedFor![0].roleId!,
            status: "PENDING",
            permissions: Object.keys(user.metadata as object).includes(
              "permissions"
            )
              ? (user.metadata as object)["permissions"]
              : []
          };
          return formattedPerson;
        });
    }
    return [...invites, ...accepted];
  };
  const getPlayers = (team) => {
    if (!team) return;
    const selectedPlayersInvite = team.invites?.filter(
      (item) => item.invitedFor![0]?.alias === "PLAYER"
    );
    const addedPlayers = team.userRoles?.filter(
      (item) => item.role?.alias === "PLAYER"
    );

    const getParentObject = (parentId) => {
      const invitedParent = team.invites?.find(
        (invite) => invite?.personId === parentId
      );
      if (invitedParent)
        return {
          id: invitedParent.personId!,
          personId: invitedParent.personId!,
          value: invitedParent.personId!,
          label: `${invitedParent?.person?.firstName} ${invitedParent?.person?.lastName}`,
          ...invitedParent,
          roleId: invitedParent!.invitedFor![0].roleId!,
          permissions: Object.keys(invitedParent.metadata as object).includes(
            "permissions"
          )
            ? (invitedParent.metadata as object)["permissions"]
            : []
        };
      const addedParent = team.userRoles?.find(
        (player) => player?.user?.personId === parentId
      );
      if (addedParent)
        return {
          id: addedParent.user!.personId!,
          personId: addedParent.user?.person?.personId as string,
          value: addedParent.user!.personId!,
          label: `${addedParent?.user?.person?.firstName} ${addedParent?.user?.person?.lastName}`,
          ...addedParent,
          roleId: addedParent.roleId!,
          permissions: addedParent?.permissionOverrides
        };
    };

    let invites: PlayerOption[] = [],
      accepted: PlayerOption[] = [];
    if (addedPlayers) {
      accepted = addedPlayers.map((player) => {
        const isChild = player?.user?.person?.guardians?.length;
        const formattedPerson = {
          label: `${player?.user?.person?.firstName} ${player?.user?.person?.lastName}`,
          id: player!.user!.personId!,
          personId: player!.user!.personId!,
          value: player!.user!.personId!,
          gender: player?.user?.person?.gender,
          roleId: player.roleId!,
          ...player,
          permissions: player?.permissionOverrides,
          parent: isChild
            ? getParentObject(
                player?.user?.person?.guardians![0].guardian?.personId
              )
            : undefined,
          status: isChild ? "CHILD_ACCEPTED" : "USER_ACCEPTED"
        };
        return formattedPerson;
      });
    }
    if (selectedPlayersInvite) {
      invites = selectedPlayersInvite
        .filter((iu) => !accepted.find((a) => a.id === iu!.person!.personId))
        .map((player) => {
          const checkIfParentAccepted = (parentId) => {
            if (!addedPlayers || !addedPlayers.length) return false;
            const accepted = addedPlayers.findIndex(
              (player) => player!.user!.personId === parentId
            );
            if (accepted !== -1) return true;
            return false;
          };
          const isChild = player?.person?.guardians?.length;
          const formattedPerson = {
            label: `${player?.person?.firstName} ${player?.person?.lastName}`,
            id: player!.person!.personId!,
            value: player!.person!.personId!,
            gender: player?.person?.gender,
            roleId: player.invitedFor![0].roleId!,
            ...player,
            personId: player!.person!.personId!,
            status: isChild
              ? checkIfParentAccepted(
                  player?.person?.guardians![0].guardian?.personId
                )
                ? "PARENT_ACCEPTED"
                : "CHILD_PENDING"
              : "USER_PENDING",
            parent: isChild
              ? getParentObject(
                  player?.person?.guardians![0].guardian?.personId
                )
              : undefined,
            permissions: Object.keys(player.metadata as object).includes(
              "permissions"
            )
              ? ((player.metadata as object)["permissions"] as [])
              : []
          };
          return formattedPerson;
        });
    }
    return [...invites, ...accepted];
  };

  useEffect(() => {
    if (currentTeam) {
      const playersSelected = getPlayers(currentTeam);
      if (playersSelected) setPlayersWithValues([...playersSelected]);
      const coachesSelected = getCoachesOrManagers("COACH", currentTeam);
      if (coachesSelected) setCoachesWithValues([...coachesSelected]);
      const managersSelected = getCoachesOrManagers("MANAGER", currentTeam);
      if (managersSelected) setManagerWithValues([...managersSelected]);
    }
  }, [currentTeam]);
  useEffect(() => {
    if (oldTeam) {
      const playersSelected = getPlayers(oldTeam);
      if (playersSelected) setOldPlayersWithValues([...playersSelected]);
      const coachesSelected = getCoachesOrManagers("COACH", oldTeam);
      if (coachesSelected) setOldCoachesWithValues([...coachesSelected]);
      const managersSelected = getCoachesOrManagers("MANAGER", oldTeam);
      if (managersSelected) setOldManagerWithValues([...managersSelected]);
    }
  }, [oldTeam]);
  const getPlayerMetaDataUpdateLabels = () => {
    const labelvalues = [] as AuditLogLabelValue[];
    const playersAdded = playersWithValues.filter(
      (p) => !oldPlayersWithValues.find((o) => o.id === p.id)
    );
    const playersRemoved = oldPlayersWithValues.filter(
      (p) => !playersWithValues.find((o) => o.id === p.id)
    );
    if (playersAdded.length) {
      labelvalues.push({
        label: page === "TEAM" ? `Added Players:` : `Added Athletes:`,
        hideBadge: true,
        value: [
          {
            value: playersAdded
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((p) => p.label)
              .join(", ")
          }
        ]
      });
    }
    if (playersRemoved.length) {
      labelvalues.push({
        label: page === "TEAM" ? `Removed Players:` : `Removed Athletes:`,
        hideBadge: true,
        value: [
          {
            value: playersRemoved
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((p) => p.label)
              .join(", ")
          }
        ]
      });
    }
    const positions = positionsResponse?.data?.positions;
    playersWithValues
      .sort((a, b) => a.label.localeCompare(b.label))
      .map((p) => {
        const name = p.label;
        const oldPlayer = oldPlayersWithValues.find((o) => o.id === p.id);
        const position = positions
          ? p.metadata?.positions
              ?.map(
                (pos) => positions.find((po) => po.positionId === pos)!.name
              )
              .join(", ") || "N/A"
          : null;
        const oldPosition = positions
          ? oldPlayer?.metadata?.positions
              ?.map(
                (pos) => positions.find((po) => po.positionId === pos)!.name
              )
              .join(", ") || "N/A"
          : null;
        const height = p.metadata?.height?.split("'");
        const heightTxt =
          height && height?.[0] !== ""
            ? `${height?.[0]}ft ${height?.[1]}in`
            : "N/A";
        const oldHeight = oldPlayer?.metadata?.height?.split("'");
        const oldHeightText = oldHeight
          ? `${oldHeight?.[0]}ft ${oldHeight?.[1]}in`
          : "N/A";
        const weight = p.metadata?.weight;
        const oldWeight = oldPlayer?.metadata?.weight;
        if (position !== oldPosition) {
          labelvalues.push({
            label: `Updated ${name} "Position" field:`,
            value: [
              {
                value: position || "N/A",
                oldValue: oldPosition || "N/A"
              }
            ]
          });
        }
        if (heightTxt !== oldHeightText) {
          labelvalues.push({
            label: `Updated ${name} "Height" field:`,
            value: [
              {
                value: heightTxt,
                oldValue: oldHeightText
              }
            ]
          });
        }
        if (weight !== oldWeight) {
          labelvalues.push({
            label: `Updated ${name} "Weight" field:`,
            value: [
              {
                value: weight ? `${weight}lb` : "N/A",
                oldValue: oldWeight ? `${oldWeight}lb` : "N/A"
              }
            ]
          });
        }
      });
    return labelvalues;
  };
  const getCoachesManagersMetaDataUpdateLabels = (
    alias: "COACH" | "MANAGER"
  ) => {
    const labelvalues = [] as AuditLogLabelValue[];
    const coachesAdded =
      alias === "COACH"
        ? coachesWithValues.filter(
            (p) => !oldCoachesWithValues.find((o) => o.id === p.id)
          )
        : managerWithValues.filter(
            (p) => !oldManagerWithValues.find((o) => o.id === p.id)
          );
    const coachesRemoved =
      alias === "COACH"
        ? oldCoachesWithValues.filter(
            (p) => !coachesWithValues.find((o) => o.id === p.id)
          )
        : oldManagerWithValues.filter(
            (p) => !managerWithValues.find((o) => o.id === p.id)
          );
    if (coachesAdded.length) {
      labelvalues.push({
        label: `Added ${alias === "COACH" ? "Coaches" : "Managers"}:`,
        hideBadge: true,
        value: [
          {
            value: coachesAdded
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((p) => p.label)
              .join(", ")
          }
        ]
      });
    }
    if (coachesRemoved.length) {
      labelvalues.push({
        label: `Removed ${alias === "COACH" ? "Coaches" : "Managers"}:`,
        hideBadge: true,
        value: [
          {
            value: coachesRemoved
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((p) => p.label)
              .join(", ")
          }
        ]
      });
    }
    (alias === "COACH" ? coachesWithValues : managerWithValues)
      .sort((a, b) => a.label.localeCompare(b.label))
      .map((p) => {
        const name = p.label;
        const oldCoach = (
          alias === "COACH" ? oldCoachesWithValues : oldManagerWithValues
        ).find((o) => o.id === p.id);
        const title = p.metadata?.title || "N/A";
        const oldTitle = oldCoach?.metadata?.title || "N/A";

        if (title !== oldTitle) {
          labelvalues.push({
            label: `Updated ${name} "Title" field:`,
            value: [
              {
                value: title,
                oldValue: oldTitle
              }
            ]
          });
        }
        if (p.roleId !== oldCoach?.roleId) {
          const isMainRole = !!teamRoles?.find(
            (r) => r.roleId === p.roleId || r.inheritedFromId === p.roleId
          );
          const subRole = !isMainRole
            ? teamRoles
                ?.filter((r) => r.alias === p.role)?.[0]
                ?.children?.find(
                  (r) => r.inheritedFromId === p.roleId || r.roleId === p.roleId
                )?.name
            : null;
          const oldSubRole = teamRoles
            ?.filter((r) => r.alias === oldCoach?.role)?.[0]
            ?.children?.find(
              (r) =>
                r.inheritedFromId === oldCoach?.roleId ||
                r.roleId === oldCoach?.roleId
            )?.name;
          if (subRole) {
            labelvalues.push({
              label: `Updated ${name} "Sub Role" field:`,
              value: [
                {
                  value: subRole || "N/A",
                  oldValue: oldSubRole || "N/A"
                }
              ]
            });
          }
        }
      });
    return labelvalues;
  };
  useEffect(() => {
    if (permissionsResponse?.data) {
      const permissionsList = () => {
        const children = permissionsResponse.data.find((permission) =>
          page === "TEAM"
            ? permission.permissionId === "team"
            : permission.permissionId === "training-programs"
        )?.children;
        return (
          children?.filter((permission) =>
            permission.permissionId?.includes("associated")
          ) || []
        );
      };
      const coachesSubRoles = teamRoles
        ?.find((item) => item.alias === "COACH")
        ?.children?.map((item) => {
          return {
            ...item,
            label: item?.name,
            value: item?.roleId
          };
        });
      const managerSubRoles = teamRoles
        ?.find((item) => item.alias === "MANAGER")
        ?.children?.map((item) => {
          return {
            ...item,
            label: item?.name,
            value: item?.roleId
          };
        });
      const result = generatePermissionValues({
        permissions: permissionsList(),
        coachesWithValues: coachesWithValues || [],
        coachRole: teamRoles!.find((r) => r.alias === "COACH"),
        coachSubRoles: coachesSubRoles || [],
        managerWithValues: managerWithValues || [],
        managerRole: teamRoles!.find((r) => r.alias === "MANAGER"),
        managerSubRoles: managerSubRoles || [],
        playersWithValues: playersWithValues || [],
        playerRole: teamRoles!.find((r) => r.alias === "PLAYER"),
        parentRole: teamRoles!.find((r) => r.alias === "PARENT")
      });
      setPermissionValues(result);
    }
  }, [
    permissionsResponse,
    coachesWithValues,
    playersWithValues,
    managerWithValues,
    teamRoles
  ]);
  useEffect(() => {
    if (
      permissionsResponse?.data &&
      oldPlayersWithValues &&
      oldManagerWithValues &&
      oldCoachesWithValues
    ) {
      const permissionsList = () => {
        const children = permissionsResponse.data.find(
          (permission) => permission.permissionId === "team"
        )?.children;
        return (
          children?.filter((permission) =>
            permission.permissionId?.includes("associated")
          ) || []
        );
      };
      const coachesSubRoles = teamRoles
        ?.find((item) => item.alias === "COACH")
        ?.children?.map((item) => {
          return {
            ...item,
            label: item?.name,
            value: item?.roleId
          };
        });
      const managerSubRoles = teamRoles
        ?.find((item) => item.alias === "MANAGER")
        ?.children?.map((item) => {
          return {
            ...item,
            label: item?.name,
            value: item?.roleId
          };
        });
      const result = generatePermissionValues({
        permissions: permissionsList(),
        coachesWithValues: oldCoachesWithValues || [],
        coachRole: teamRoles!.find((r) => r.alias === "COACH"),
        coachSubRoles: coachesSubRoles || [],
        managerWithValues: oldManagerWithValues || [],
        managerRole: teamRoles!.find((r) => r.alias === "MANAGER"),
        managerSubRoles: managerSubRoles || [],
        playersWithValues: oldPlayersWithValues || [],
        playerRole: teamRoles!.find((r) => r.alias === "PLAYER"),
        parentRole: teamRoles!.find((r) => r.alias === "PARENT")
      });
      setOldPermissionValues(result);
    }
  }, [
    permissionsResponse,
    oldCoachesWithValues,
    oldPlayersWithValues,
    oldManagerWithValues,
    teamRoles
  ]);
  const populateUpdatedPermissionsByRole = (
    userList,
    oldList,
    isSubRole: boolean,
    alias: string
  ) => {
    const labelvalues = [] as AuditLogLabelValue[];
    userList
      .sort((a, b) => a.label.localeCompare(b.label))
      .map((c) => {
        const permissions = c.permissions;
        const oldPermissions = oldList.find(
          (o) => o.id === c.id && o.roleId === c.roleId
        )?.permissions;
        const name = c.label;
        const role = isSubRole
          ? teamRoles
              ?.filter((t) => t.alias === alias)?.[0]
              ?.children?.find(
                (t) => t.roleId === c.roleId || t.inheritedFromId === c.roleId
              )?.name
          : capitalize(alias);
        const teamPermissions = permissions?.find((p) =>
          page === "TEAM"
            ? p.permissionId === "team.associated-teams"
            : p.permissionId === "training-programs.associated-programs"
        );
        const oldTeamPermissions = oldPermissions?.find((p) =>
          page === "TEAM"
            ? p.permissionId === "team.associated-teams"
            : p.permissionId === "training-programs.associated-programs"
        );
        const postPermission = permissions?.find((p) =>
          page === "TEAM"
            ? p.permissionId === "team.associated-post"
            : p.permissionId === "training-programs.associated-post"
        );
        const oldPostPermission = oldPermissions?.find((p) =>
          page === "TEAM"
            ? p.permissionId === "team.associated-post"
            : p.permissionId === "training-programs.associated-post"
        );
        const commentPermission = permissions?.find((p) =>
          page === "TEAM"
            ? p.permissionId === "team.associated-comment"
            : p.permissionId === "training-programs.associated-comment"
        );
        const oldCommentPermission = oldPermissions?.find((p) =>
          page === "TEAM"
            ? p.permissionId === "team.associated-comment"
            : p.permissionId === "training-programs.associated-comment"
        );
        const sharePermission = permissions?.find((p) =>
          page === "TEAM"
            ? p.permissionId === "team.associated-share"
            : p.permissionId === "training-programs.associated-share"
        );
        const oldSharePermission = oldPermissions?.find((p) =>
          page === "TEAM"
            ? p.permissionId === "team.associated-share"
            : p.permissionId === "training-programs.associated-share"
        );
        if (
          teamPermissions?.actions?.length !==
          oldTeamPermissions?.actions?.length
        ) {
          labelvalues.push({
            label: `Updated ${name} ${role}  "${page === "TEAM" ? "Team" : "Training Program"}" permission:`,
            value: ["ALL", "VIEW", "EDIT", "DELETE"].map((t) => ({
              subText: capitalize(t),
              value: teamPermissions?.actions?.find((a) => a === t)
                ? "On"
                : "Off",
              oldValue: oldTeamPermissions
                ? oldTeamPermissions?.actions?.find((a) => a === t)
                  ? "On"
                  : "Off"
                : "N/A"
            }))
          });
        }
        if (
          postPermission?.actions?.length !== oldPostPermission?.actions?.length
        ) {
          labelvalues.push({
            label: `Updated ${name} ${role} "Post and Live Stream on behalf of this ${page === "TEAM" ? "team" : "training program"}" permission:`,
            value: [
              {
                value: capitalize(postPermission?.actions?.join(", ") || "Off"),
                oldValue: oldPostPermission
                  ? capitalize(oldPostPermission?.actions?.join(", ") || "Off")
                  : "N/A"
              }
            ]
          });
        }
        if (
          commentPermission.actions?.length !==
          oldCommentPermission?.actions?.length
        ) {
          labelvalues.push({
            label: `Updated ${name} ${role} "Comment on Posts and Live Streams for this ${page === "TEAM" ? "team" : "training program"}" permission:`,
            value: [
              {
                value: capitalize(
                  commentPermission?.actions?.join(", ") || "Off"
                ),
                oldValue: oldCommentPermission
                  ? capitalize(
                      oldCommentPermission?.actions?.join(", ") || "Off"
                    )
                  : "N/A"
              }
            ]
          });
        }
        if (
          sharePermission.actions?.length !==
          oldSharePermission?.actions?.length
        ) {
          labelvalues.push({
            label: `Updated ${name} ${role} "Share Posts and Live Streams for this ${page === "TEAM" ? "team" : "training program"}" permission:`,
            value: [
              {
                value: capitalize(
                  sharePermission?.actions?.join(", ") || "Off"
                ),
                oldValue: oldSharePermission
                  ? capitalize(oldSharePermission?.actions?.join(", ") || "Off")
                  : "N/A"
              }
            ]
          });
        }
      });
    return labelvalues;
  };
  const getPermissionLabels = (permissionsList, oldPermissionsList = []) => {
    const labelvalues = [] as AuditLogLabelValue[];
    const {
      coachesWithNoSubRole,
      coachesWithSubRole,
      managersWithNoSubRole,
      managersWithSubRole,
      parents,
      players
    } = permissionsList;

    const {
      coachesWithNoSubRole: oldCoachesWithNoSubRole,
      coachesWithSubRole: oldCoachesWithSubRole,
      managersWithNoSubRole: oldManagersWithNoSubRole,
      managersWithSubRole: oldManagersWithSubRole,
      parents: oldParents,
      players: oldPlayers
    } = oldPermissionsList;
    labelvalues.push(
      ...populateUpdatedPermissionsByRole(
        coachesWithNoSubRole,
        oldCoachesWithNoSubRole,
        false,
        "COACH"
      )
    );
    labelvalues.push(
      ...populateUpdatedPermissionsByRole(
        coachesWithSubRole,
        oldCoachesWithSubRole,
        true,
        "COACH"
      )
    );
    labelvalues.push(
      ...populateUpdatedPermissionsByRole(
        managersWithNoSubRole,
        oldManagersWithNoSubRole,
        false,
        "MANAGER"
      )
    );
    labelvalues.push(
      ...populateUpdatedPermissionsByRole(
        managersWithSubRole,
        oldManagersWithSubRole,
        true,
        "MANAGER"
      )
    );
    labelvalues.push(
      ...populateUpdatedPermissionsByRole(players, oldPlayers, false, "PLAYER")
    );
    labelvalues.push(
      ...populateUpdatedPermissionsByRole(parents, oldParents, false, "PARENT")
    );

    return labelvalues;
  };
  const getTeamDetaiilsLabels = () => {
    const labelValues: AuditLogLabelValue[] = [];
    if (currentTeam?.name !== oldTeam?.name)
      labelValues.push({
        label: `Updated the "Name" field:`,
        value: [
          {
            value: currentTeam?.name || "",
            oldValue: oldTeam?.name || ""
          }
        ]
      });
    if (page === "TRAINING_PROGRAM" && currentTeam.type !== oldTeam.type)
      labelValues.push({
        label: `Updated the "Type" field:`,
        value: [
          {
            value:
              TrainingProgramTypeOptions.find(
                (t) => t.value === currentTeam.type
              )?.label || "N/A",
            oldValue:
              TrainingProgramTypeOptions.find((t) => t.value === oldTeam.type)
                ?.label || "N/A"
          }
        ]
      });
    if (currentTeam?.sport?.name !== oldTeam?.sport?.name)
      labelValues.push({
        label: `Updated the "Sport" field:`,
        value: [
          {
            value: currentTeam?.sport?.name || "",
            oldValue: oldTeam?.sport?.name || ""
          }
        ]
      });
    if (currentTeam?.season?.name !== oldTeam?.season?.name && page === "TEAM")
      labelValues.push({
        label: `Updated the "Season" field:`,
        value: [
          {
            value: currentTeam?.season?.name || "",
            oldValue: oldTeam?.season?.name || ""
          }
        ]
      });
    if (!arraysEqual(currentTeam?.genders || [], oldTeam?.genders || []))
      labelValues.push({
        label: `Updated the "Gender" field:`,
        value: [
          {
            value:
              currentTeam?.genders
                ?.map((g) => GENDERS.find((c) => c.value === g)!.label)
                .join(",") || "",
            oldValue:
              oldTeam?.genders
                ?.map((g) => GENDERS.find((c) => c.value === g)!.label)
                .join(",") || ""
          }
        ]
      });
    if (currentTeam?.level?.name !== oldTeam?.level?.name)
      labelValues.push({
        label: `Updated the "Level" field:`,
        value: [
          {
            value: currentTeam?.level?.name || "",
            oldValue: oldTeam?.level?.name || ""
          }
        ]
      });

    if (page === "TRAINING_PROGRAM") {
      const dates = currentTeam.dates as TrainingProgramDate[];

      dates?.map((date, index) => {
        const oldDate = oldTeam.dates?.[index] as TrainingProgramDate;
        labelValues.push(
          ...(oldDate?.startDate !== date?.startDate
            ? [
                {
                  label: `Updated Date & Time ${dates.length > 1 ? index + 1 : ""} "Start Date" field:`,
                  value: [
                    {
                      value: formatDateForDisplay(new Date(date.startDate)),
                      oldValue: oldDate
                        ? formatDateForDisplay(new Date(oldDate.startDate))
                        : "N/A"
                    }
                  ]
                }
              ]
            : []),
          ...(oldDate?.endDate !== date?.endDate
            ? [
                {
                  label: `Updated Date & Time ${dates.length > 1 ? index + 1 : ""} "End Date" field:`,
                  value: [
                    {
                      value: formatDateForDisplay(new Date(date.endDate)),
                      oldValue: oldDate
                        ? formatDateForDisplay(new Date(oldDate.endDate))
                        : "N/A"
                    }
                  ]
                }
              ]
            : [])
        );
        date.timings.flatMap((timing, i) => {
          const oldTimimg = oldDate?.timings?.[i];
          const oldRRule = convertRruleToMessage(oldTimimg?.customRepeat ?? {});
          const rRule = convertRruleToMessage(timing.customRepeat ?? {});

          const oldRRuleText = oldRRule.isCustomText
            ? oldRRule.customText
            : oldRRule.freqName === "DNR"
              ? "Does not repeat"
              : `Repeats ${oldRRule.freqName?.toLowerCase()}`;

          const rRuleText = rRule.isCustomText
            ? rRule.customText
            : rRule.freqName === "DNR"
              ? "Does not repeat"
              : `Repeats ${rRule.freqName?.toLowerCase()}`;

          labelValues.push(
            ...(oldTimimg?.startTime !== timing?.startTime
              ? [
                  {
                    label: `Updated Date & Time ${dates.length > 1 ? index + 1 : ""} "Start Time${date.timings.length > 1 ? ` ${i + 1}` : ""}" field:`,
                    value: [
                      {
                        value: convertToAmPm(timing.startTime),
                        oldValue: oldTimimg
                          ? convertToAmPm(oldTimimg.startTime)
                          : "N/A"
                      }
                    ]
                  }
                ]
              : []),
            ...(oldTimimg?.endTime !== timing?.endTime
              ? [
                  {
                    label: `Updated Date & Time ${dates.length > 1 ? index + 1 : ""} "End Time${date.timings.length > 1 ? ` ${i + 1}` : ""}" field:`,
                    value: [
                      {
                        value: convertToAmPm(timing.endTime),
                        oldValue: oldTimimg
                          ? convertToAmPm(oldTimimg.endTime)
                          : "N/A"
                      }
                    ]
                  }
                ]
              : []),
            ...(oldRRule?.customText !== rRule?.customText ||
            oldRRule?.freqName !== rRule?.freqName ||
            !oldDate ||
            !oldTimimg
              ? [
                  {
                    label: `Updated Date & Time ${dates.length > 1 ? index + 1 : ""} "Repeat${date.timings.length > 1 ? ` ${i + 1}` : ""}" field:`,
                    value: [
                      {
                        value: rRuleText,
                        oldValue: oldTimimg ? oldRRuleText : "N/A"
                      }
                    ]
                  }
                ]
              : [])
          );
        });
      });
    }

    return labelValues;
  };
  return (
    <Loader isLoading={isLoading || isLoadingPermissions}>
      <Container>
        <ButtonGroup
          variant="outlined"
          style={{ border: "rgba(215, 221, 229, 1)", paddingBottom: "24px" }}
        >
          {tabs.map((t) => {
            return (
              <Button
                key={t}
                variant="outlined"
                style={
                  tab === t
                    ? {
                        backgroundColor: "#E8EEFF",
                        color: "#2B337A",
                        fontSize: "14px",
                        fontWeight: 600,
                        textTransform: "none"
                      }
                    : {
                        color: "#666666",
                        fontSize: "14px",
                        fontWeight: 500,
                        textTransform: "none"
                      }
                }
                onClick={() => {
                  setTab(t as Tabs);
                }}
              >
                {t}
              </Button>
            );
          })}
        </ButtonGroup>
        {tab === "Team Details" || tab === "Program Details" ? (
          <AuditLogLabelValueList
            recordType="UPDATE"
            labelValues={[...getTeamDetaiilsLabels()]}
          />
        ) : tab === "Players" || tab === "Athletes" ? (
          <AuditLogLabelValueList
            recordType="UPDATE"
            labelValues={[...getPlayerMetaDataUpdateLabels()]}
          />
        ) : tab === "Coaches" ? (
          <AuditLogLabelValueList
            recordType="UPDATE"
            labelValues={[...getCoachesManagersMetaDataUpdateLabels("COACH")]}
          />
        ) : tab === "Managers" ? (
          <AuditLogLabelValueList
            recordType="UPDATE"
            labelValues={[...getCoachesManagersMetaDataUpdateLabels("MANAGER")]}
          />
        ) : (
          tab === "Permissions" && (
            <AuditLogLabelValueList
              recordType="UPDATE"
              labelValues={[
                ...getPermissionLabels(permissionValues, oldPermissionValues)
              ]}
            />
          )
        )}
      </Container>
    </Loader>
  );
};
