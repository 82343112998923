import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  styled,
  TextField
} from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { ModelPerson } from "@sportsgravyengineering/sg-api-react-sdk";
import { getUsers } from "@services/Network";
import formatFullName from "@utils/formatFullName";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";

const ProfileThumbnail = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "24px",
  height: "24px",
  borderRadius: "4px",
  color: theme.palette.white.main,
  fontSize: "10px"
}));

const StyledPersonOption = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "8px",
  padding: "8px 16px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F5F5F5",
    color: theme.palette.primary.main
  }
}));

const getInitialsText = (person?: ModelPerson | null) =>
  person ? `${person?.firstName?.[0]}${person?.lastName?.[0]}` : "";

const PersonOption = ({
  person,
  onClick
}: {
  person: ModelPerson | null;
  onClick: () => void;
}) => {
  const fullName = formatFullName(person);
  const avatarUrl = person?.avatar?.baseUrl
    ? person?.avatar?.baseUrl + person?.avatar?.path
    : undefined;
  return (
    <StyledPersonOption onClick={onClick}>
      <Grid>
        <ProfileThumbnail
          style={{
            backgroundColor: `#${person?.colorCode}`
          }}
        >
          {avatarUrl ? (
            <img
              src={avatarUrl}
              style={{
                width: "24px",
                height: "24px",
                borderRadius: "4px"
              }}
              alt={`${person?.firstName} ${person?.lastName}`}
            />
          ) : (
            <span>{getInitialsText(person).toUpperCase()}</span>
          )}
        </ProfileThumbnail>
      </Grid>
      {fullName}
    </StyledPersonOption>
  );
};

export const UserSearch = ({
  setSelectedUser,
  selectedUser
}: {
  setSelectedUser: Dispatch<SetStateAction<ModelPerson[]>>;
  selectedUser: ModelPerson[] | null;
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const [userSearch, setUserSearch] = useState("");
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const onSearch = (event) => {
    setUserSearch(event.target.value);
  };
  const { data: addUserResults, isFetching: isFetchingUsers } = getUsers(
    {
      ...(userSearch && { textSearch: encodeURIComponent(userSearch) }),
      organizationId: organizationId
    },
    { staleTime: Infinity, enabled: !!userSearch?.length }
  );
  const addUserOptions = [...addUserResults];
  return (
    <Autocomplete
      open={userMenuOpen}
      multiple
      disableClearable
      onOpen={() => setUserMenuOpen(true)}
      onClose={() => setUserMenuOpen(false)}
      value={selectedUser || []}
      getOptionLabel={(option) =>
        option ? `${option.firstName} ${option.lastName}` : ""
      }
      isOptionEqualToValue={(option, value) =>
        option.personId === value.personId
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Search"
          onChange={onSearch}
        />
      )}
      filterOptions={(options) =>
        userMenuOpen && !isFetchingUsers ? options : []
      }
      renderTags={(value, getTagProps) =>
        value.map((option, index: number) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              {...tagProps}
              variant="outlined"
              label={formatFullName(option)}
              onDelete={() => {
                setSelectedUser((prev) => {
                  if (!prev) {
                    return [];
                  }
                  return prev.filter(
                    (user) => user.personId !== option.personId
                  );
                });
              }}
              icon={
                <div
                  style={{
                    borderRadius: "50%",
                    height: "20px",
                    width: "20px",
                    backgroundColor: `#${option.colorCode}`,
                    textAlign: "center"
                  }}
                >
                  {option.avatarId ? (
                    <img
                      style={{
                        borderRadius: "50%",
                        height: "20px",
                        width: "20px"
                      }}
                      src={
                        option?.avatar?.baseUrl
                          ? option?.avatar?.baseUrl + option?.avatar?.path
                          : undefined
                      }
                    />
                  ) : (
                    <span
                      style={{
                        fontSize: "10px",
                        color: "#fff"
                      }}
                    >
                      {getInitialsText(option).toUpperCase()}
                    </span>
                  )}
                </div>
              }
              style={{
                background: "#F0F0F0",
                border: "none",
                padding: "6px 3px",
                fontWeight: 600
              }}
              key={key}
            />
          );
        })
      }
      filterSelectedOptions
      renderOption={(props, option: ModelPerson) => {
        return (
          <div key={option?.personId}>
            <PersonOption
              {...props}
              key={option?.personId}
              person={option}
              onClick={() => {
                setSelectedUser((prev) => {
                  if (!prev) {
                    return [option];
                  }
                  return [...prev, option];
                });
                setUserSearch("");
              }}
            />
          </div>
        );
      }}
      options={addUserOptions.concat(selectedUser) || []}
      loading={isFetchingUsers}
      loadingText="Loading..."
    />
  );
};
