import { Container } from "@components/crud/Container";
import { Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FormSelect } from "@components/FormSelect";
import { Loader } from "@components/crud/Loader";
import { FeatureCostOverview } from "./components/FeatureCostOverview";
import { SprintCardOverview } from "./components/SprintCardOverview";
import { SprintCardBreakdown } from "./components/SprintCardBreakdown";
import { useSearchParams } from "react-router-dom";
import { FeatureCostBreakdown } from "./components/FeatureCostBreakdown";
import { SprintCarryForwardTrend } from "./components/SprintCarryForwardTrend";
import { useQuery } from "@tanstack/react-query";
import {
  adminDirectoryGet,
  adminFeatureGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { TimeLogged } from "./components/TimeLogged";

const ProductEngineeringFilter = [
  {
    label: "Feature Cost Overview",
    value: "FCO"
  },
  {
    label: "Feature Cost Breakdown",
    value: "FCB"
  },
  {
    label: "Sprint Card Overview",
    value: "SCO"
  },
  {
    label: "Sprint Card Breakdown",
    value: "SCB"
  },
  {
    label: "Sprint Carry Forward Trend",
    value: "SCT"
  },
  {
    label: "Time Logged",
    value: "TL"
  }
];

const CardTypes = [
  {
    label: "Type: All",
    value: "ALL"
  },
  {
    label: "Type: Story",
    value: "STORY"
  },
  {
    label: "Type: Bug",
    value: "BUG"
  },
  {
    label: "Type: Task",
    value: "TASK"
  }
];

export const ProductEngineering = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [platfrom, setPlatform] = useState<
    "WEB" | "API" | "DEV" | "AND" | "IOS" | "ALL"
  >("IOS");
  const [resourceFilter, setResourceFilter] = useState<string>("ALL");
  const [featureFilter, setFeatureFilter] = useState<string>("ALL");
  const [filter, setFilter] = useState<string>("FCO");
  const [cardType, setCardType] = useState<"STORY" | "TASK" | "BUG" | "ALL">(
    "ALL"
  );

  const { data: companyDirectory, isLoading: isLoading } = useQuery(
    ["companyDirectory"],
    () =>
      adminDirectoryGet({
        pageSize: 1000
      }),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: true
    }
  );

  const { data: features, isLoading: isFeatureLoading } = useQuery(
    ["features"],
    () =>
      adminFeatureGet({
        flatten: true,
        sortField: "name",
        sortDirection: "asc",
        pageSize: 3000
      }),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: true
    }
  );

  const resourcesOptions = useMemo(() => {
    let options: Array<{ label: string; value: string }> = [];
    if (companyDirectory?.data.persons) {
      options = companyDirectory.data.persons
        .filter((resource) => resource.sportsgravyUser)
        .map((resource) => ({
          label: `${resource.firstName} ${resource.lastName}`,
          value: resource?.sportsgravyUser?.jiraAccountId
        }));
    }
    options.unshift({ label: "All", value: "ALL" });
    return options;
  }, [companyDirectory?.data]);

  const featureOptions = useMemo(() => {
    let options: Array<{
      label: string;
      value: string;
      platforms: ("WEB" | "API" | "DEV" | "AND" | "IOS")[];
    }> = [];
    if (features && features.data.features) {
      options = features.data?.features
        .filter(
          (f) =>
            f.iosEpicId ||
            f.andEpicId ||
            f.webEpicId ||
            f.apiEpicId ||
            f.devOpsEpicId
        )
        .map((feature) => ({
          label: feature.name as string,
          value: feature.featureId as string,
          platforms: [
            feature.iosEpicId ? "IOS" : "",
            feature.andEpicId ? "AND" : "",
            feature.webEpicId ? "WEB" : "",
            feature.apiEpicId ? "API" : "",
            feature.devOpsEpicId ? "DEV" : ""
          ].filter((p) => p)
        }));
    }
    options.unshift({
      label: "All",
      value: "ALL",
      platforms: ["IOS", "AND", "WEB", "API", "DEV"]
    });
    return options;
  }, [features]);

  const generatePlatformOptions = (value: string) => {
    let platforms: string[] = [];
    if (value === "ALL") {
      platforms = ["IOS", "AND", "WEB", "API", "DEV"];
    } else {
      const feature = featureOptions.find((f) => f.value === value);
      if (feature) {
        platforms = feature.platforms;
      }
    }
    return platforms.map((platform) => ({
      label: platform === "DEV" ? "DEVOPS" : platform,
      value: platform
    }));
  };

  useEffect(() => {
    if (searchParams.get("resourceId")) {
      setResourceFilter(searchParams.get("resourceId") as string);
      setFilter("SCB");
    }
    if (searchParams.get("report")) {
      setFilter(searchParams.get("report") as string);
      setResourceFilter("ALL");
    }
    if (searchParams.get("featureId")) {
      setFeatureFilter(searchParams.get("featureId") as string);
      setFilter("FCB");
    }
  }, [searchParams]);

  useEffect(() => {
    setPlatform(
      featureOptions.find((f) => f.value === featureFilter)?.platforms[0] ||
        "IOS"
    );
  }, [featureFilter]);

  return (
    <Loader isLoading={isLoading || isFeatureLoading}>
      <Container>
        <Grid container paddingLeft="40px" columnSpacing={2}>
          <Grid item marginTop="16px" xs={5}>
            <FormSelect
              name="filter"
              value={filter}
              options={ProductEngineeringFilter}
              onChange={(e) => {
                setFilter(e.target.value);
                setSearchParams({ report: e.target.value });
                if (e.target.value == "FCO") setFeatureFilter("ALL");
              }}
            />
          </Grid>
          <Grid item marginTop="16px" xs={6}></Grid>
        </Grid>
        {!["SCO", "SCT"].includes(filter) && (
          <Grid container paddingLeft="40px" marginTop="16px" columnSpacing={2}>
            {["FCB"].includes(filter) && (
              <Grid item xs={3}>
                <FormSelect
                  name="featureFilter"
                  value={featureFilter}
                  options={
                    platfrom != "ALL"
                      ? featureOptions.filter((f) =>
                          f.platforms.includes(platfrom)
                        )
                      : featureOptions
                  }
                  onChange={(e) => {
                    setFeatureFilter(e.target.value);
                  }}
                />
              </Grid>
            )}
            {["FCB", "FCO"].includes(filter) && (
              <Grid item xs={2}>
                <FormSelect
                  name="platformFilter"
                  onChange={(e) => {
                    setPlatform(e.target.value);
                  }}
                  options={generatePlatformOptions(
                    filter === "FCB" ? featureFilter : "ALL"
                  )}
                />
              </Grid>
            )}
            {["FCB", "FCO", "SCB"].includes(filter) && (
              <Grid item xs={3}>
                <FormSelect
                  name="resourceFilter"
                  value={resourceFilter}
                  options={resourcesOptions}
                  onChange={(e) => {
                    setResourceFilter(e.target.value);
                  }}
                />
              </Grid>
            )}
            {filter == "FCB" && (
              <Grid item xs={2}>
                <FormSelect
                  name="cardType"
                  options={CardTypes}
                  value={cardType}
                  onChange={(e) => {
                    setCardType(e.target.value);
                  }}
                />
              </Grid>
            )}
          </Grid>
        )}
        {((filter === "FCB" && featureFilter !== "ALL") ||
          (filter === "SCB" && resourceFilter !== "ALL") ||
          ["FCO", "SCO", "SCT", "TL"].includes(filter)) && (
          <Grid item marginLeft="25px" padding="15px" marginRight="25px">
            {filter == "FCO" && (
              <FeatureCostOverview
                platform={platfrom}
                resourceId={resourceFilter}
              />
            )}
            {filter == "FCB" && (
              <FeatureCostBreakdown
                platform={platfrom}
                resourceId={resourceFilter}
                cardType={cardType}
                featureId={featureFilter}
              />
            )}
            {filter == "SCO" && <SprintCardOverview />}
            {filter == "SCB" && (
              <SprintCardBreakdown resourceId={resourceFilter} />
            )}
            {filter == "SCT" && <SprintCarryForwardTrend />}
            {filter == "TL" && <TimeLogged />}
          </Grid>
        )}
      </Container>
    </Loader>
  );
};
