import { Container } from "@components/crud/Container";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "../types";
import {
  AdminSponsorshipLevelIdGetResponse,
  ModelSponsorshipAdPlacementPercentage
} from "@sportsgravyengineering/sg-api-react-sdk";
import { formatCurrency } from "@utils/formatCurrency";

const convertFloatToNumber = (number: number | undefined) => {
  if (number === undefined) return "";
  return (number * 100).toFixed(0);
};

export const SponsorshipLevelAuditUpdate = ({
  before,
  after
}: {
  before: object | AdminSponsorshipLevelIdGetResponse;
  after: object | AdminSponsorshipLevelIdGetResponse;
}) => {
  const level = after as AdminSponsorshipLevelIdGetResponse;
  const oldLevel = before as AdminSponsorshipLevelIdGetResponse;

  const getLabelValues = () => {
    const labelValues: AuditLogLabelValue[] = [];
    const adPlacementPercentages = level.adPlacementPercentages || [];
    const oldAdPlacementPercentages = oldLevel.adPlacementPercentages || [];
    if (level.sponsorshipLevel?.name !== oldLevel.sponsorshipLevel?.name)
      labelValues.push({
        label: `Updated the "Name" field:`,
        value: [
          {
            value: level.sponsorshipLevel?.name || "",
            oldValue: oldLevel.sponsorshipLevel?.name || ""
          }
        ]
      });

    if (level.sponsorshipLevel?.amount !== oldLevel.sponsorshipLevel?.amount)
      labelValues.push({
        label: `Updated the "Amount" field:`,
        value: [
          {
            value: formatCurrency(level.sponsorshipLevel?.amount),
            oldValue: formatCurrency(oldLevel.sponsorshipLevel?.amount)
          }
        ]
      });

    if (
      level.sponsorshipLevel?.isActive !== oldLevel.sponsorshipLevel?.isActive
    )
      labelValues.push({
        label: `Updated the "Is Active" field:`,
        value: [
          {
            value: level.sponsorshipLevel?.isActive ? "Active" : "Inactive",
            oldValue: oldLevel.sponsorshipLevel?.isActive
              ? "Active"
              : "Inactive"
          }
        ]
      });

    if (
      level.sponsorshipLevel?.sponsoredByEnabled !==
      oldLevel.sponsorshipLevel?.sponsoredByEnabled
    )
      labelValues.push({
        label: `Updated the Ad Placements “Sponsored By Ad - Live Stream Event Feed Posts” field:`,
        value: [
          {
            value: level.sponsorshipLevel?.sponsoredByEnabled ? "On" : "Off",
            oldValue: oldLevel.sponsorshipLevel?.sponsoredByEnabled
              ? "On"
              : "Off"
          }
        ]
      });

    const updatedSponsoredByValues = adPlacementPercentages
      .concat(level.sponsorshipLevel as ModelSponsorshipAdPlacementPercentage)
      ?.map((placement) => {
        const oldPlacement = oldAdPlacementPercentages
          .concat(
            oldLevel.sponsorshipLevel as ModelSponsorshipAdPlacementPercentage
          )
          .find((old) => old.sponsorshipId === placement.sponsorshipId);

        const oldPercentage = convertFloatToNumber(
          oldPlacement?.sponsoredByPercentage
        );
        const newPercentage = convertFloatToNumber(
          placement.sponsoredByPercentage
        );

        if (newPercentage !== oldPercentage) {
          return {
            subText: placement.name || "",
            value: newPercentage,
            oldValue: oldPercentage
          };
        }
        return null;
      })
      .filter(Boolean);

    if (updatedSponsoredByValues.length > 0) {
      labelValues.push({
        label: `Updated the Sponsored By Ad - Live Stream Event Feed Posts “Probability Percentage of being Displayed” field:`,
        value: updatedSponsoredByValues
      });
    }

    if (
      level.sponsorshipLevel?.outStreamEnabled !==
      oldLevel.sponsorshipLevel?.outStreamEnabled
    )
      labelValues.push({
        label: `Updated the Ad Placements “Out-Stream Ad - Live Stream Intermissions” field:`,
        value: [
          {
            value: level.sponsorshipLevel?.outStreamEnabled ? "On" : "Off",
            oldValue: oldLevel.sponsorshipLevel?.outStreamEnabled ? "On" : "Off"
          }
        ]
      });

    const updatedOutStreamValues = adPlacementPercentages
      .concat(level.sponsorshipLevel as ModelSponsorshipAdPlacementPercentage)
      ?.map((placement) => {
        const oldPlacement = oldAdPlacementPercentages
          .concat(
            oldLevel.sponsorshipLevel as ModelSponsorshipAdPlacementPercentage
          )
          .find((old) => old.sponsorshipId === placement.sponsorshipId);

        const oldPercentage = convertFloatToNumber(
          oldPlacement?.outStreamPercentage
        );
        const newPercentage = convertFloatToNumber(
          placement.outStreamPercentage
        );

        if (newPercentage !== oldPercentage) {
          return {
            subText: placement.name || "",
            value: newPercentage,
            oldValue: oldPercentage
          };
        }
        return null;
      })
      .filter(Boolean);

    if (updatedOutStreamValues.length > 0) {
      labelValues.push({
        label: `Updated the Out-Stream Ad - View Live Stream Screen “Probability Percentage of being Displayed” field:`,
        value: updatedOutStreamValues
      });
    }

    if (
      level.sponsorshipLevel?.inStreamEnabled !==
      oldLevel.sponsorshipLevel?.inStreamEnabled
    )
      labelValues.push({
        label: `Updated the Ad Placements "In-Stream Ad - Live Stream Intermissions” field:`,
        value: [
          {
            value: level.sponsorshipLevel?.inStreamEnabled ? "On" : "Off",
            oldValue: oldLevel.sponsorshipLevel?.inStreamEnabled ? "On" : "Off"
          }
        ]
      });

    if (
      level.sponsorshipLevel?.inStreamMaxDuration !==
      oldLevel.sponsorshipLevel?.inStreamMaxDuration
    )
      labelValues.push({
        label: `Updated In-Stream Ad - Live Stream Intermissions “Max Duration of In-Stream Video” field:`,
        value: [
          {
            value: level.sponsorshipLevel?.inStreamMaxDuration
              ? `${level.sponsorshipLevel?.inStreamMaxDuration} seconds`
              : "N/A",
            oldValue: oldLevel.sponsorshipLevel?.inStreamMaxDuration
              ? `${oldLevel.sponsorshipLevel?.inStreamMaxDuration} seconds`
              : "N/A"
          }
        ]
      });

    return labelValues;
  };
  return (
    <Container style={{ marginTop: "10px" }}>
      <AuditLogLabelValueList
        recordType="UPDATE"
        labelValues={[...getLabelValues()]}
      />
    </Container>
  );
};
