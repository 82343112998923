/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { ToolTip } from "@components/ToolTip";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@components/crud/Accordian";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Toolbar as MUIToolbar, styled, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Grid from "@mui/material/Unstable_Grid2";
import {
  eventUploadState,
  organizationAtom,
  organizationsAtom
} from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { getSeasons, getTeams } from "@services/Network";

import { uploadMediaUsingUploadParams } from "@services/customNetworkCalls";
import {
  ModelOrganizationCalendarUpload,
  useAdminCalendarUploadPost,
  useAdminCalendarUploadStatusGet,
  useLookupSGCalendarField
} from "@sportsgravyengineering/sg-api-react-sdk";
import { setPropertyRecursive } from "@utils/objectFunctions";
import papaparse from "papaparse";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CustomProgressBar } from "@components/CustomProgressBar";
import { EventMapperGrid } from "./components/EventMapperGrid";
import { capitalizeFirstCharacter } from "@utils/capitalize";
import { useSnackbar } from "notistack";

const StyledFormInputWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",

  "& .MuiInputAdornment-root": {
    display: "none"
  },

  "& .MuiFileInput-input::placeholder": {
    color: theme.palette.text.secondary,
    fontStyle: "italic",
    fontSize: "100px",
    opacity: 1
  },

  "& .MuiFormHelperText-root": {
    marginTop: theme.spacing(1),
    fontSize: "0.875rem",
    color: theme.palette.text.secondary
  }
}));

export const CalendarImportEvents = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const setEventUpload = useSetRecoilState(eventUploadState);
  const organizationId = useRecoilValue(organizationAtom);
  const [usedFields, setUsedFields] = React.useState<string[]>([]);
  const [availableFields, setAvailableFields] = React.useState<string[]>([]);
  const [teamsOptions, setTeamsOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [disabledFields, setDisabledFields] = React.useState<string[]>([]);
  const [fieldsSet, setFieldsSet] = React.useState<string[]>([]);
  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
  const [selectableFields, setSelectableFields] = React.useState<
    { label: string; value: string }[]
  >([]);
  const [fields, setFields] = React.useState<string[]>([]);
  const [fileType, setFileType] = React.useState<string>("");
  const [csvFile, setCsvFile] = React.useState<File | undefined>(undefined);
  const [showProgressBar, setShowProgressBar] = React.useState<boolean>(false);
  const [showGridMapping, setShowGridMapping] = React.useState<boolean>(false);
  const [uploadStarted, setUploadStarted] = useState(false);
  const [uploadId, setUploadId] = useState<string>();
  const [elapsedTime, setElapsedTime] = useState(0);
  const elapsedTimeRef = useRef<number | null>(null);

  useEffect(() => {
    const permission = hasPermission(
      "ORGANIZATION",
      organizationId!,
      "general.calendar-import" as string,
      "ON" as string
    );
    permission.then((res) => {
      if (!res) {
        navigate("/not-found");
      }
    });
  }, []);

  const formMethods = useForm({
    mode: "onTouched",
    defaultValues: {
      sports: "",
      season: "",
      teams: [""],
      csvTemplate: csvFile,
      description: "",
      providerName: "",
      version: "",
      fieldMap: {}
    }
  });

  const selectedSeason = formMethods.watch("season");
  const selectedSport = formMethods.watch("sports");
  const selectedTeams = formMethods.watch("teams");

  const tooltipTexts: Record<string, string> = {
    "event.title": "Please enter a title for the event.",
    "event.type": `
Please select one of the following options:

General Event
A scheduled activity, such as a meeting or ceremony. NOTE: This event type does not include live streaming.

Game/Match
A competitive event between teams or individuals with rules, scoring, and outcomes.

Athletic Event
A sports competition, including track and field, races, or multi-sport contests.

Scrimmage
An informal or practice game to simulate real match conditions.

Practice
A structured session to develop skills, strategies, and teamwork.

Training Session
A workout or drill-focused session to improve fitness and performance.
`,
    "event.team": "Please select the team associated with the event.",
    "event.isHome":
      "This field is required for Game/Match, Scrimmage, and Athletic Event types",
    "event.isPrivate":
      "Please enter “Yes” or “No” box to keep this event private. It will not be visible to the public and will only appear on the calendars of shared guests.",
    "event.start": "Please enter the exact start date and time of the event.",
    "event.end": "Please enter the exact end date and time of the event.",
    "event.location":
      "Please provide the event's correct location from the Google Maps search results. Both the name of the athletic facility or the detailed address work.",
    "event.liveStream":
      "Please select “Yes” or “No” for whether or not your organization plans to open viewership of this event's live stream to all SportsGravy users.",
    "event.opponent":
      "Please enter the opposing team name for this game/match or scrimmage event. If this is an athletic event, please enter the opposing organization names.",
    "event.isPublic":
      "Please select “Yes” or “No” for whether or not your organization plans to open viewership of this event's live stream to all SportsGravy users.",
    "event.shareWith": "Please select “Team” or “Organization”",
    "event.description":
      "If you want to provide a detailed description of the event, please enter the description in the field below"
  };

  const { mutate, isLoading: isSaving } = useAdminCalendarUploadPost();
  const { data: mappingResult, isFetched: mappingFetched } =
    useLookupSGCalendarField();
  const organizationData = useRecoilValue(organizationsAtom).find(
    (org) => org.organizationId === organizationId
  );
  const { data: organizationSeasonData } = getSeasons(
    {
      organizationId: organizationId,
      pageSize: 1000
    },
    {
      query: {
        staleTime: Infinity
      }
    }
  );
  const getSelectedSeasonId = () => {
    const selectedSeasonData = organizationSeasonData?.find(
      (season) => season.name?.toLowerCase() === selectedSeason?.toLowerCase()
    );
    return selectedSeasonData?.seasonId || null;
  };
  const getSelectedSeasonDate = () => {
    const selectedSeasonData = organizationSeasonData?.find(
      (season) => season.name?.toLowerCase() === selectedSeason?.toLowerCase()
    );
    return `${new Date(selectedSeasonData?.startDate).getFullYear()}-${new Date(selectedSeasonData?.endDate).getFullYear()}`;
  };

  const { data: organizationTeamsData } = getTeams(
    {
      organizationId: organizationId,
      seasonId: getSelectedSeasonId(),
      pageSize: 1000
    },
    {
      query: {
        staleTime: Infinity,
        enabled: !!selectedSport && !!selectedSeason
      }
    }
  );

  const importFields = useMemo(
    () => mappingResult?.data || {},
    [mappingResult]
  );

  const { data: uploadStatus, refetch: statusRefetch } =
    useAdminCalendarUploadStatusGet(
      {
        organizationId: organizationId!
      },
      {
        query: {
          enabled: uploadStarted,
          refetchInterval: false
        }
      }
    );

  const downloadProcessedFile = async (
    downloadStatus: ModelOrganizationCalendarUpload
  ) => {
    const { baseUrl, s3Key } = downloadStatus;
    if (!baseUrl || !s3Key) return;
    try {
      const response = await fetch(`${baseUrl}${s3Key}`);
      if (!response.ok) throw new Error("Failed to fetch file");

      const blob = await response.blob();
      const downloadedFile = new File([blob], "processed_events.csv", {
        type: "text/csv"
      });

      // ✅ Keep showProgressBar true until the file is fully processed
      setCsvFile(downloadedFile);
      setUploadId(downloadStatus.calendarUploadId);

      // ✅ Delay turning off progress bar to ensure smooth transition
      setTimeout(() => {
        setShowProgressBar(false);
        setShowGridMapping(true);
      }, 200); // Adjust timing as needed to prevent flicker
    } catch (error) {
      console.error("Error downloading processed file:", error);
    }
  };

  useEffect(() => {
    if (!uploadStarted) return; // ✅ Don't run unless "Continue" was clicked

    let timerId: NodeJS.Timeout | null = null;

    const startTimer = () => {
      if (!elapsedTimeRef.current) {
        elapsedTimeRef.current = Date.now();
        setElapsedTime(0);
      }
      timerId = setInterval(() => {
        setElapsedTime(
          Math.floor((Date.now() - elapsedTimeRef.current!) / 1000)
        );
      }, 1000);
    };

    const stopTimer = () => {
      if (timerId) clearInterval(timerId);
      elapsedTimeRef.current = null;
    };

    const checkStatus = async () => {
      try {
        const latestResponse = await statusRefetch(); // ✅ Fetch latest response
        const latestStatus = latestResponse?.data?.data; // ✅ Extract data correctly

        const { status, progress } =
          latestStatus as ModelOrganizationCalendarUpload; // ✅ Now safe to access

        if (status === "PROCESSING" || status === "PENDING") {
          if (!elapsedTimeRef.current) startTimer();
          setTimeout(checkStatus, 5000);
        } else if (status === "PROCESSED" && progress === 1) {
          stopTimer();
          downloadProcessedFile(latestStatus);
          setUploadStarted(false);
        }
      } catch (error) {
        console.error("❌ Error fetching upload status:", error);
      }
    };

    checkStatus();

    return () => {
      if (timerId) clearTimeout(timerId);
      stopTimer();
    };
  }, [uploadStarted]);

  const handleSystemFields = (systemFields: string[]) => {
    setSelectableFields((prevSelectableFields) => {
      const updatedFields = [...prevSelectableFields];
      const newUsedFields = [...usedFields];

      systemFields.forEach((fieldName) => {
        const formattedFieldName = `SG_${fieldName.replace(/\s+/g, "_")}`;
        const fieldKey = Object.keys(mappingResult!.data!["event"].fields).find(
          (key) => mappingResult!.data!["event"].fields[key].name === fieldName
        );

        if (fieldKey) {
          const formPath = `fieldMap.event.${fieldKey}.header`;
          const formPathSystemField = `fieldMap.event.${fieldKey}.isSystemField`;
          const availablePath = `fieldMap.event.${fieldKey}.available`;

          const isAvailable = formMethods.getValues(availablePath);

          if (isAvailable) {
            formMethods.setValue(formPath, formattedFieldName);
            formMethods.setValue(formPathSystemField, true);
          } else {
            formMethods.setValue(formPath, "");
          }

          if (
            !updatedFields.some((field) => field.value === formattedFieldName)
          ) {
            updatedFields.push({
              label: formattedFieldName,
              value: formattedFieldName
            });
          }

          if (!newUsedFields.includes(formattedFieldName)) {
            newUsedFields.push(formattedFieldName);
          }
        }
      });

      setUsedFields(newUsedFields);
      return updatedFields;
    });
  };

  const csvTemplateChangeHandler = (
    file: any,
    field: { onChange: (arg0: { target: { value: any } }) => void }
  ) => {
    if (!file) {
      setSelectableFields([]);
      setCsvFile(undefined);
      field.onChange({ target: { value: undefined } });
      return;
    }
    if (file.type == "text/csv") {
      papaparse.parse(file, {
        header: true,
        dynamicTyping: false,
        complete: (results: { meta: { fields: any[] } }, file: any) => {
          const fields =
            results?.meta?.fields?.map((v: any) => ({ label: v, value: v })) ||
            [];
          const validKeys = Object.keys(mappingResult!.data!["event"].fields)
            .filter(
              (key) => !mappingResult!.data!["event"].fields[key].isSystemField
            )
            .map((key) =>
              mappingResult!.data!["event"].fields[key].name.replace(
                /\bEvent\b\s*/gi,
                ""
              )
            );

          const mustHaveFields = [
            "Title",
            "Start Date and Time",
            "End Date and Time",
            "Location",
            "Description"
          ];
          const fallbackMapping: Record<string, string[]> = {
            Title: ["title", "summary", "event name"],
            "Start Date and Time": ["start", "start time", "start date"],
            "End Date and Time": ["end", "end time", "end date"],
            Location: ["location", "venue", "place"],
            Description: ["description", "details", "notes"]
          };

          const nonSystemFields = fields.filter((field) =>
            validKeys.includes(field.value)
          );
          const systemFields = Object.values(
            mappingResult!.data!["event"].fields
          )
            .filter((field) => field.isSystemField)
            .map((field) => field.name);

          const presentFieldLabels = new Set(
            nonSystemFields.map((field) => field.label)
          );
          const missingFields = mustHaveFields.filter(
            (requiredField) => !presentFieldLabels.has(requiredField)
          );

          const alternativeFields = fields.filter(
            (field) => !systemFields.includes(field.value)
          );
          missingFields.forEach((missingField) => {
            const possibleMatches = fallbackMapping[missingField] || [];
            const matchedField = alternativeFields.find((field) =>
              possibleMatches.includes(field.value.toLowerCase())
            );

            if (matchedField) {
              nonSystemFields.push({
                label: missingField,
                value: matchedField.value
              });
            } else {
              nonSystemFields.push({
                label: missingField,
                value: `Unmapped_${missingField.replace(/\s+/g, "_")}`
              });
            }
          });

          setFields((results?.meta?.fields as string[]) || []);
          setSelectableFields(nonSystemFields);
          setCsvFile(file);
          field.onChange({ target: { value: file } });
          handleSystemFields(systemFields);
        }
      });
      setFileType("csv");
    }
    if (file.type == "text/calendar") {
      setFileType("ics");
      setCsvFile(file);
      field.onChange({ target: { value: file } });
    }
  };

  const resetUsedFields = (existingValue = "", newValue = "") => {
    const values = formMethods.getValues("fieldMap");
    const usedFieldValues = Object.entries(values).flatMap(([, fieldGroup]) =>
      Object.values(fieldGroup)
        .map((field) => field.header)
        .filter((header) => header && header !== existingValue)
    );

    if (newValue && !usedFieldValues.includes(newValue)) {
      usedFieldValues.push(newValue);
    }

    setUsedFields(usedFieldValues);
  };

  const saveHandler = (formValues: { csvTemplate: any; fieldMap: any }) => {
    setShowGridMapping(false); // Reset before a new upload starts
    setShowProgressBar(false); // Ensure progress bar starts fresh
    setUploadStarted(false);
    for (const key in formValues.fieldMap) {
      for (const key2 in formValues.fieldMap[key]) {
        const fieldEntry = formValues.fieldMap[key][key2];

        if (!fieldEntry.header || fieldEntry.header.startsWith("SG_")) {
          delete formValues.fieldMap[key][key2];
        } else {
          fieldEntry.available = true;
          fieldEntry.canBeEmpty = !fieldEntry.isRequired;
        }
      }
      if (Object.keys(formValues.fieldMap[key]).length === 0) {
        delete formValues.fieldMap[key];
      }
    }

    if (mappingResult?.data?.["event"]?.fields && formValues?.fieldMap?.event) {
      Object.keys(formValues.fieldMap.event).forEach((fieldKey) => {
        if (mappingResult.data!["event"].fields[fieldKey]) {
          formValues.fieldMap.event[fieldKey].isSystemField =
            mappingResult.data!["event"].fields[fieldKey].isSystemField ||
            false;
        }
        if (
          mappingResult.data!["event"].fields[fieldKey].isSystemField === false
        ) {
          formMethods.setValue(
            `fieldMap.event.${fieldKey}.isSystemField`,
            false
          );
        }
      });
    }

    const seasonId = getSelectedSeasonId();
    const selectedTeams = formMethods.getValues("teams");
    const teamsArray = Array.isArray(selectedTeams)
      ? selectedTeams
      : [selectedTeams];

    const selectedSport =
      organizationData?.sports?.find(
        (sportItem) =>
          sportItem?.sport?.name?.trim().toLowerCase() ===
          formMethods.getValues("sports")?.trim().toLowerCase()
      )?.sportId || null;

    const values = {
      headers: fields || [],
      fieldMap: formValues.fieldMap,
      fileName: csvFile?.name || "",
      organizationId: organizationId! || "",
      fileType: fileType,
      teams: teamsArray,
      sportId: selectedSport,
      seasonId: seasonId || ""
    };

    setEventUpload({
      teams: Array.isArray(selectedTeams)
        ? selectedTeams
            .map((teamId) => {
              const team = organizationTeamsData?.find(
                (t) => t.teamId === teamId
              );
              return team ? team.name : "Unknown Team";
            })
            .filter(Boolean)
            .join(", ")
        : organizationTeamsData?.find((t) => t.teamId === selectedTeams)?.name,
      sport: capitalizeFirstCharacter(
        formMethods.getValues("sports").toLowerCase() || ""
      ),
      season: `${capitalizeFirstCharacter(formMethods.getValues("season").toLowerCase() || "")} ${selectedSeason ? getSelectedSeasonDate() : ""}`
    });

    setUploadStarted(false);
    mutate(
      { data: values },
      {
        onSuccess: async (data) => {
          enqueueSnackbar("Succesfully Uploaded File!", {
            variant: "success"
          });
          uploadMediaUsingUploadParams(
            csvFile as File,
            data.data?.uploadParams
          );
          setShowProgressBar(true);
          setUploadStarted(true);
        },
        onError: () => {
          enqueueSnackbar("Failed to Upload File", {
            variant: "error"
          });
        }
      }
    );
  };

  const sportsOptions = useMemo(() => {
    const options =
      organizationData?.sports?.map((sport) => ({
        label: sport?.sport?.name || "Unknown",
        value: sport?.sport?.name?.toUpperCase() || "UNKNOWN"
      })) || [];

    return options;
  }, [organizationData]);

  useEffect(() => {
    if (organizationData?.sports?.length === 1 && !selectedSport) {
      const sportName = organizationData.sports[0].sport?.name?.toUpperCase();
      if (sportName) {
        formMethods.setValue("sports", sportName, { shouldDirty: true });
      }
    }
  }, [organizationData, selectedSport, formMethods]);

  const seasonOptions = useMemo(() => {
    const selectedSport = formMethods.getValues("sports");
    if (!selectedSport || !organizationSeasonData) return [];
    return organizationSeasonData
      .filter((season) => season?.sport?.name?.toUpperCase() === selectedSport)
      .map((season) => ({
        label: season.name || "Unknown Season",
        value: season.name?.toUpperCase() || "UNKNOWN_SEASON"
      }));
  }, [formMethods.getValues("sports"), organizationSeasonData]);

  useEffect(() => {
    formMethods.setValue("season", "");
    formMethods.setValue("teams", [""]);
    setTeamsOptions([]);
  }, [selectedSport]);

  useEffect(() => {
    if (seasonOptions.length === 1) {
      formMethods.setValue("season", seasonOptions[0].value);
    }
  }, [seasonOptions]);

  useEffect(() => {
    if (!selectedSeason || !organizationTeamsData) {
      if (teamsOptions.length > 0) {
        setTeamsOptions([]);
      }
      const currentTeams = formMethods.getValues("teams");
      if (currentTeams.length > 0 && currentTeams[0] !== "") {
        formMethods.setValue("teams", [""], { shouldDirty: true });
      }
      return;
    }

    const allTeamIds = organizationTeamsData
      .map((team) => team.teamId)
      .join(",");

    const formattedTeams = organizationTeamsData.map((team) => ({
      label: team?.name || "Unknown Team",
      value: team?.teamId || "UNKNOWN_TEAM"
    }));

    if (JSON.stringify(teamsOptions) !== JSON.stringify(formattedTeams)) {
      if (formattedTeams.length === 1) {
        formMethods.setValue("teams", [formattedTeams[0].value], {
          shouldDirty: true
        });
        setTeamsOptions(formattedTeams);
      } else if (formattedTeams.length > 1) {
        setTeamsOptions([
          { label: "All Teams", value: allTeamIds },
          ...formattedTeams
        ]);
      } else {
        setTeamsOptions([]);
      }
    }
  }, [selectedSeason, organizationTeamsData]);

  useEffect(() => {
    const currentValues = formMethods.getValues();

    const disabledFields: string[] = [];
    const availableFields: string[] = [];
    const fieldsSet: string[] = [];
    const mappingDefaults: any = {};
    for (const key in importFields) {
      if (importFields[key].isMandatory) {
        fieldsSet.push(key);
        disabledFields.push(key);
        mappingDefaults[key] = {
          heading: importFields[key].isMandatory
        };
      }
      if (!importFields[key].isMandatory) {
        fieldsSet.push(key);
        mappingDefaults[key] = {
          heading: true
        };
      }
      for (const fieldKey in importFields[key].fields) {
        if (importFields[key].fields[fieldKey].isMandatory) {
          disabledFields.push(importFields[key].fields[fieldKey].key);
          availableFields.push(importFields[key].fields[fieldKey].key);
        }
        setPropertyRecursive(
          mappingDefaults,
          importFields[key].fields[fieldKey].key,
          {
            available: importFields[key].fields[fieldKey].isMandatory,
            isRequired: importFields[key].fields[fieldKey].isMandatory,
            header: ""
          }
        );
      }
    }
    setDisabledFields(disabledFields);
    setAvailableFields(availableFields);
    setFieldsSet(fieldsSet);
    formMethods.reset({
      csvTemplate: csvFile,
      fieldMap:
        Object.keys(currentValues?.fieldMap).length > 0
          ? currentValues?.fieldMap
          : Object.keys(formMethods.formState.defaultValues?.fieldMap || {})
                .length > 0
            ? formMethods.formState.defaultValues?.fieldMap
            : mappingDefaults
    });
  }, [importFields]);

  const tableHeader = () => {
    return (
      <TableHead sx={{ backgroundColor: "#F8FAFC" }}>
        <TableRow>
          <TableCell>
            <TableSortLabel>
              <Typography variant="tableHeader">Available</Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel>
              <Typography variant="tableHeader">SportsGravy Fields</Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel>
              <Typography variant="tableHeader">
                Template File Columns
              </Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel>
              <Typography variant="tableHeader">Value is Required</Typography>
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };
  const tableRowInput = (
    { name, isEmptyAllowed, isMandatory, key, isSystemField },
    disabled
  ) => {
    const correspondingSGValue = `SG_${name.replace(/\s+/g, "_")}`;
    return (
      fieldsSet.includes(key.split(".")[0]) && (
        <TableRow key={key}>
          <TableCell sx={{ paddingLeft: 4 }}>
            <FormCheckbox
              name={`fieldMap.${key}.available`}
              control={formMethods.control}
              disabled={disabled || disabledFields.includes(key)}
              onChange={(e) => {
                if (!e.target.checked) {
                  // @ts-ignore
                  formMethods.setValue(`fieldMap.${key}.header`, "");
                  // @ts-ignore
                  formMethods.setValue(`fieldMap.${key}.isRequired`, false);
                  setAvailableFields((prev) => prev.filter((f) => f !== key));
                  setFieldsSet(fieldsSet.filter((f) => f !== key));
                  resetUsedFields();
                  formMethods.reset({
                    ...formMethods.getValues(),
                    fieldMap: {
                      ...formMethods.getValues().fieldMap,
                      [key]: {
                        ...formMethods.getValues().fieldMap[key],
                        header: ""
                      }
                    }
                  });
                } else {
                  if (key.split(".")[1] === "liveStream") {
                    setUsedFields((prev) => [...prev, correspondingSGValue]);
                  }
                  setAvailableFields((prev) => [...prev, key]);
                  if (isSystemField) {
                    formMethods.setValue(
                      `fieldMap.${key}.header`,
                      correspondingSGValue
                    );
                  }
                }
              }}
            />
          </TableCell>
          <TableCell>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body1">{name}</Typography>
              <ToolTip
                title={
                  <Typography sx={{ whiteSpace: "pre-line" }}>
                    {tooltipTexts[key]}
                  </Typography>
                }
              >
                <InfoOutlinedIcon sx={{ fontSize: 18, color: "gray" }} />
              </ToolTip>
            </Box>
          </TableCell>
          <TableCell data-testid={"import-event-select" + key}>
            <FormSelect
              name={`fieldMap.${key}.header`}
              options={selectableFields.filter(
                (f) =>
                  f.value === "" ||
                  ((isSystemField
                    ? f.value.startsWith("SG_")
                    : !f.value.startsWith("SG_")) &&
                    (!usedFields.includes(f.value) ||
                      f.value ===
                        formMethods.getValues(`fieldMap.${key}.header`)))
              )}
              control={formMethods.control}
              required={
                !disabled && (!!isMandatory || availableFields.includes(key))
              }
              disabled={
                disabled ||
                !availableFields.includes(key) ||
                formMethods
                  .getValues(`fieldMap.${key}.header` as any)
                  ?.startsWith("SG_")
              }
              rules={
                !disabled && isMandatory
                  ? {
                      required: `${name} is required`
                    }
                  : !disabled && availableFields.includes(key)
                    ? {
                        required: `${name} is required when marked as available ${availableFields.includes(
                          key
                        )}`
                      }
                    : {}
              }
              onChange={(e) => {
                if (!isSystemField) {
                  const existingValue = formMethods.getValues(
                    `fieldMap.${key}.header`
                  );
                  const newValue = e.target.value;
                  if (newValue === "") {
                    setFieldsSet(fieldsSet.filter((f) => f !== key));
                    setUsedFields((prev) =>
                      prev.filter((f) => f !== existingValue)
                    );
                  } else {
                    if (!fieldsSet.includes(key))
                      setFieldsSet([...fieldsSet, key]);
                    if (!usedFields.includes(newValue))
                      setUsedFields([...usedFields, newValue]);
                  }

                  formMethods.setValue(`fieldMap.${key}.header`, newValue);
                  resetUsedFields(existingValue, newValue);
                }
              }}
            />
          </TableCell>
          <TableCell sx={{ paddingLeft: 6.85 }}>
            <FormCheckbox
              name={`fieldMap.${key}.isRequired`}
              control={formMethods.control}
              disabled={
                !isEmptyAllowed ||
                isMandatory ||
                disabled ||
                disabledFields.includes(key)
              }
              onChange={(e) => {
                // @ts-ignore
                formMethods.setValue(
                  `fieldMap.${key}.isRequired`,
                  e.target.checked
                );
              }}
            />
          </TableCell>
        </TableRow>
      )
    );
  };
  return (
    <Container>
      <Toolbar
        title="Import Team Events"
        secondaryTitle="Please Note: This import only supports team related events"
      />

      {showGridMapping && (
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          sx={{ ml: 3, mb: 3, mt: -2 }}
        >
          {/* Required Value Indicator */}
          <Box display="flex" alignItems="center" gap={1}>
            <Box
              sx={{
                width: 16,
                height: 16,
                backgroundColor: "#FEE2E2",
                border: "1px solid #F87171",
                borderRadius: "4px"
              }}
            />
            <Typography variant="body2" color="text.primary">
              Required Value
            </Typography>
          </Box>

          {/* Disabled Indicator */}
          <Box display="flex" alignItems="center" gap={1}>
            <Box
              sx={{
                width: 16,
                height: 16,
                backgroundColor: "#E5E7EB",
                border: "1px solid #9CA3AF",
                borderRadius: "4px"
              }}
            />
            <Typography variant="body2" color="text.primary">
              Disabled
            </Typography>
          </Box>
        </Box>
      )}

      {showProgressBar ? (
        uploadStatus?.data &&
        (uploadStatus.data.status === "PROCESSING" ||
          uploadStatus.data.status === "PENDING") && (
          <CustomProgressBar
            title={`For ${
              Array.isArray(selectedTeams)
                ? selectedTeams
                    .map((teamId) => {
                      const team = organizationTeamsData?.find(
                        (t) => t.teamId === teamId
                      );
                      return team ? team.name : "Unknown Team";
                    })
                    .filter(Boolean)
                    .join(", ")
                : organizationTeamsData?.find((t) => t.teamId === selectedTeams)
                    ?.name
            } • ${capitalizeFirstCharacter(formMethods.getValues("sports").toLowerCase() || "")} • ${capitalizeFirstCharacter(formMethods.getValues("season").toLowerCase() || "")} ${selectedSeason ? getSelectedSeasonDate() : ""} • ${csvFile?.name || "Unknown File"}`}
            percentageCompleted={
              (uploadStatus.data.progress! * uploadStatus.data.recordCount!) /
              100
            }
            recordsProcessed={uploadStatus.data.progress!}
            totalRecords={uploadStatus.data.recordCount!}
            elapsedTime={`${Math.floor(elapsedTime / 60)
              .toString()
              .padStart(2, "0")}:${(elapsedTime % 60)
              .toString()
              .padStart(2, "0")}`}
          />
        )
      ) : showGridMapping ? (
        <FormProvider {...formMethods}>
          <EventMapperGrid
            file={csvFile as File}
            fileType={fileType}
            usedFields={usedFields}
            teamsOptions={teamsOptions}
            uploadId={uploadId}
          />
        </FormProvider>
      ) : (
        <>
          <Loader isLoading={!mappingFetched}>
            <Form gap="24px">
              <Grid container spacing={3}>
                <Grid xs={6}>
                  <FormSelect
                    control={formMethods.control}
                    name="sports"
                    label="Sports"
                    rules={{ required: "Sport is required" }}
                    required={true}
                    disabled={sportsOptions.length <= 1}
                    options={sportsOptions}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormSelect
                    control={formMethods.control}
                    name="season"
                    label="Season"
                    required={true}
                    rules={{ required: "Season is required" }}
                    options={seasonOptions}
                    disabled={seasonOptions.length <= 1}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid xs={6}>
                  <FormSelect
                    control={formMethods.control}
                    name="teams"
                    label="Teams"
                    required={true}
                    rules={{ required: "Team is required" }}
                    options={teamsOptions}
                    disabled={teamsOptions.length <= 1}
                    onChange={(event) => {
                      const selectedValue = event.target.value;

                      if (selectedValue === "ALL_TEAMS") {
                        const allTeamIds = organizationTeamsData.map(
                          (team) => team.teamId
                        );
                        formMethods.setValue("fieldMap.teams", allTeamIds, {
                          shouldDirty: true
                        });
                      } else {
                        formMethods.setValue(
                          "fieldMap.teams",
                          [selectedValue],
                          {
                            shouldDirty: true
                          }
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid xs={6} data-testid="import-file">
                  <StyledFormInputWrapper>
                    <FormInput
                      control={formMethods.control}
                      name="csvTemplate"
                      type="file"
                      label="Import File"
                      required={true}
                      rules={{ required: "Import File is required" }}
                      // @ts-ignore
                      onChange={csvTemplateChangeHandler}
                      value={csvFile}
                      InputProps={{
                        accept: ".ics, .csv"
                      }}
                      TextProps={{
                        helperText: csvFile
                          ? ""
                          : "Supported formats: .ICS, .CSV"
                      }}
                    />
                  </StyledFormInputWrapper>
                </Grid>
                <Grid xs={12} container spacing={1}>
                  {fileType === "csv" &&
                    mappingFetched &&
                    selectableFields.length > 0 &&
                    Object.keys(importFields).length &&
                    Object.keys(importFields).map((key, index) => {
                      return (
                        <Grid xs={12} key={`${index}_${key}`}>
                          <Accordion expanded={fieldsSet.includes(key)}>
                            <AccordionSummary>
                              <MUIToolbar
                                sx={{
                                  padding: "0 !important"
                                }}
                              >
                                <FormCheckbox
                                  label={importFields[key].name}
                                  labelTypographyProps={{
                                    variant: "h6",
                                    sx: { opacity: 0.6 }
                                  }}
                                  name={`fieldMap.${key}.heading`}
                                  control={formMethods.control}
                                  disabled={disabledFields.includes(key)}
                                  onChange={(e) => {
                                    if (!e.target.checked) {
                                      setFieldsSet(
                                        fieldsSet.filter((f) => f !== key)
                                      );
                                      for (const fieldKey in importFields[key]
                                        .fields) {
                                        formMethods.setValue(
                                          // @ts-ignore
                                          `fieldMap.${importFields[key].fields[fieldKey].key}.header`,
                                          ""
                                        );
                                        formMethods.setValue(
                                          // @ts-ignore
                                          `fieldMap.${importFields[key].fields[fieldKey].key}.isRequired`,
                                          false
                                        );
                                        formMethods.setValue(
                                          // @ts-ignore
                                          `fieldMap.${importFields[key].fields[fieldKey].key}.available`,
                                          false
                                        );
                                      }
                                      resetUsedFields();
                                    } else {
                                      for (const fieldKey in importFields[key]
                                        .fields) {
                                        formMethods.setValue(
                                          // @ts-ignore
                                          `fieldMap.${importFields[key].fields[fieldKey].key}.isRequired`,
                                          importFields[key].fields[fieldKey]
                                            .isMandatory
                                        );
                                        formMethods.setValue(
                                          // @ts-ignore
                                          `fieldMap.${importFields[key].fields[fieldKey].key}.available`,
                                          importFields[key].fields[fieldKey]
                                            .isMandatory
                                        );
                                      }

                                      if (!fieldsSet.includes(key)) {
                                        setFieldsSet([...fieldsSet, key]);
                                      }
                                    }
                                  }}
                                />
                              </MUIToolbar>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Table>
                                {tableHeader()}
                                <TableBody
                                  sx={{
                                    "& tr": {
                                      borderBottom:
                                        "2px solid rgba(226, 232, 240, .75)"
                                    },
                                    "& tr:last-child": { borderBottom: 0 }
                                  }}
                                >
                                  {Object.keys(importFields[key].fields).map(
                                    (fieldKey: string) =>
                                      tableRowInput(
                                        importFields[key].fields[fieldKey],
                                        !fieldsSet.includes(key)
                                      )
                                  )}
                                </TableBody>
                              </Table>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </Form>
          </Loader>

          <Footer
            cancelBtnClick={() => setOpenCancelDialog(true)}
            saveBtnClick={formMethods.handleSubmit(saveHandler)}
            saveBtnLabel="Continue"
            isDisabled={
              !formMethods.formState.isValid ||
              isSaving ||
              !mappingFetched ||
              !selectedSport ||
              !selectedSeason ||
              !formMethods.watch("csvTemplate") ||
              !selectedTeams ||
              selectedTeams.length === 0 ||
              selectedTeams[0] === ""
            }
            isLoading={isSaving}
          />
          <ConfirmationDialog
            title="Cancel Import Event?"
            body="Are you sure you want to cancel importing events?"
            open={openCancelDialog}
            close={() => setOpenCancelDialog(false)}
            onCancel={() => setOpenCancelDialog(false)}
            onConfirm={() => navigate(`/calendar`)}
            cancelBtnText="Cancel"
            confirmBtnText="Confirm"
          />
        </>
      )}
    </Container>
  );
};
