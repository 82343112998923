import { Loader } from "@components/crud/Loader";
import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { organizationAtom } from "@recoil/auth";
import {
  ModelPerson,
  RoleType,
  useAdminRoleGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalize } from "@utils/capitalize";
import { useRecoilValue } from "recoil";

export const UsersAuditLogUpdate = ({
  before,
  after
}: {
  before: object | ModelPerson;
  after: object | ModelPerson;
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const currentUser = after as ModelPerson;
  const oldUser = before as ModelPerson;

  const { data: roleOptionsRequest, isLoading: isRoleOptionsLoading } =
    useAdminRoleGet({
      organizationId,
      type: organizationId ? RoleType.ORGANIZATION : undefined,
      pageSize: "100",
      includeChildren: true
    });

  const roleOptions = () => {
    if (!roleOptionsRequest?.data?.roles) {
      return [];
    }
    const FSGORoles = roleOptionsRequest.data.roles.filter(
      (role) => role.type === "SYSTEM"
    );
    return FSGORoles.map((role) => {
      return {
        label:
          (role.name as string) + (role.type === "SYSTEM" ? " (System)" : ""),
        value: role.roleId as string,
        children: role.children
          ? role.children.filter((child) => child.type === "SYSTEM")
          : [],
        role: role
      };
    });
  };

  const getUserDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];

    if (currentUser?.phones !== oldUser?.photo) {
      labelValues.push({
        label: `Update the "Avatar" field:`,
        value: [
          {
            value: currentUser?.photo || "",
            oldValue: oldUser?.photo || "",
            isMedia: true
          }
        ]
      });
    }

    if (currentUser?.firstName !== oldUser?.firstName) {
      labelValues.push({
        label: `Updated the "First Name" field:`,
        value: [
          {
            value: currentUser?.firstName || "",
            oldValue: oldUser?.firstName || ""
          }
        ]
      });
    }

    if (currentUser?.middleName !== oldUser?.middleName) {
      labelValues.push({
        label: `Updated the "Middle Name" field:`,
        value: [
          {
            value: currentUser?.middleName || "",
            oldValue: oldUser?.middleName || ""
          }
        ]
      });
    }

    if (currentUser?.lastName !== oldUser?.lastName) {
      labelValues.push({
        label: `Updated the "Last Name" field:`,
        value: [
          {
            value: currentUser?.lastName || "",
            oldValue: oldUser?.lastName || ""
          }
        ]
      });
    }

    if (currentUser?.suffix !== oldUser?.suffix) {
      labelValues.push({
        label: `Updated the "Suffix" field:`,
        value: [
          {
            value: currentUser?.suffix || "",
            oldValue: oldUser?.suffix || ""
          }
        ]
      });
    }

    if (currentUser?.email !== oldUser?.email) {
      labelValues.push({
        label: `Updated the "Email" field:`,
        value: [
          {
            value: currentUser?.email || "",
            oldValue: oldUser?.email || ""
          }
        ]
      });
    }

    if (
      new Date(currentUser?.birthedAt).toLocaleDateString("en-US") !==
      new Date(oldUser?.birthedAt).toLocaleDateString("en-US")
    ) {
      labelValues.push({
        label: `Updated the "Date of Birth" field:`,
        value: [
          {
            value:
              new Date(currentUser?.birthedAt).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric"
              }) || "",
            oldValue:
              new Date(oldUser?.birthedAt).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric"
              }) || ""
          }
        ]
      });
    }

    if (currentUser.user) {
      if (
        currentUser.user?.roleAliasBans?.length ||
        oldUser.user?.roleAliasBans?.length
      ) {
        labelValues.push({
          label: `Updated Ban "Ban this user form Organization" field:`,
          value: [
            {
              value: currentUser.user?.roleAliasBans?.length ? "On" : "Off",
              oldValue: oldUser.user?.roleAliasBans?.length ? "On" : "Off"
            }
          ]
        });
        labelValues.push({
          label: `Updated Ban "For the following roles" field:`,
          value: [
            {
              value:
                currentUser.user?.roleAliasBans
                  ?.map((role) => (role.alias ? capitalize(role.alias) : ""))
                  .join(", ") || "N/A",
              oldValue:
                oldUser.user?.roleAliasBans
                  ?.map((role) => (role.alias ? capitalize(role.alias) : ""))
                  .join(", ") || "N/A"
            }
          ]
        });
        labelValues.push({
          label: `Updated Ban "Reason" field:`,
          value: [
            {
              value: currentUser.user?.roleAliasBans?.[0]?.reason || "N/A",
              oldValue: oldUser.user?.roleAliasBans?.[0]?.reason || "N/A"
            }
          ]
        });
      }

      const rolesValues: AuditLogLabelValue[] = [];
      roleOptions().map((role) => {
        const isInvited =
          currentUser.user?.roles?.find(
            (userRole) => userRole.roleId === role.value
          ) ||
          currentUser.invites?.find((invite) =>
            invite.invitedFor?.find(
              (invitedFor) => invitedFor.roleId === role.value
            )
          );

        rolesValues.push({
          label: `Updated Roles & Sub Roles "${role.label}" field:`,
          value: [
            {
              value: isInvited ? "On" : "Off",
              oldValue:
                oldUser.user?.roles?.find(
                  (userRole) => userRole.roleId === role.value
                ) ||
                oldUser.invites?.some((invite) =>
                  invite.invitedFor?.some(
                    (invitedFor) => invitedFor.roleId === role.value
                  )
                )
                  ? "On"
                  : "Off"
            }
          ]
        });
        role.children.map((child) => {
          const isInvited =
            currentUser.user?.roles?.find(
              (userRole) => userRole.roleId === child.roleId
            ) ||
            currentUser.invites?.find((invite) =>
              invite.invitedFor?.find(
                (invitedFor) => invitedFor.roleId === child.roleId
              )
            );
          rolesValues.push({
            label: `Updated Roles & Sub Roles "${child.name}" field:`,
            value: [
              {
                value: isInvited ? "On" : "Off",
                oldValue: !isInvited ? "Off" : "On"
              }
            ]
          });
        });
      });

      const filtered = rolesValues.filter(
        (role) => role.value[0].value !== role.value[0].oldValue
      );
      labelValues.push(...filtered);
    }

    currentUser.guardians?.forEach((guardian, index) => {
      const oldGuardian = oldUser.guardians?.[index];
      if (
        !oldGuardian ||
        guardian.guardian?.firstName !== oldGuardian.guardian?.firstName
      ) {
        labelValues.push({
          label: `Updated Parent Guardian "First Name" field:`,
          value: [
            {
              value: guardian.guardian?.firstName || "",
              oldValue: oldGuardian?.guardian?.firstName || ""
            }
          ]
        });
      }
      if (
        !oldGuardian ||
        guardian.guardian?.lastName !== oldGuardian.guardian?.lastName
      ) {
        labelValues.push({
          label: `Updated Parent Guardian "Last Name" field:`,
          value: [
            {
              value: guardian.guardian?.lastName || "",
              oldValue: oldGuardian?.guardian?.lastName || ""
            }
          ]
        });
      }
      if (
        !oldGuardian ||
        guardian.guardian?.email !== oldGuardian.guardian?.email
      ) {
        labelValues.push({
          label: `Updated Parent Guardian "Email" field:`,
          value: [
            {
              value: guardian.guardian?.email || "",
              oldValue: oldGuardian?.guardian?.email || ""
            }
          ]
        });
      }
    });

    return labelValues;
  };

  return (
    <Loader isLoading={isRoleOptionsLoading}>
      <AuditLogLabelValueList
        recordType="UPDATE"
        labelValues={[...getUserDetails()]}
      />
    </Loader>
  );
};
