import { Container } from "@components/crud/Container";
import { Button, ButtonGroup } from "@mui/material";
import { ModelConfig } from "@sportsgravyengineering/sg-api-react-sdk";
import { useState } from "react";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "../types";
import { convertToAmPm } from "@utils/formatDate";
import { CrmSettingAuditLogDetails } from "../FSGO/settings/CRM/CrmSettingAuditLogDetails";
type Tabs = "Dashboard" | "Feed" | "Live Streaming" | "Marketing" | "CRM";

export const FSGOConfigAuditLogUpdate = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelConfig[];
  after: object | ModelConfig[];
}) => {
  const tabs = ["Dashboard", "Feed", "Live Streaming", "Marketing", "CRM"];
  const [tab, setTab] = useState<Tabs>("Dashboard");
  const setting = after as ModelConfig[];
  const oldSetting = before as ModelConfig[];

  const getDashboardLabels = () => {
    const labels: AuditLogLabelValue[] = [];
    const newDashboardSettings = setting.filter((s) =>
      s.key?.includes("dashboard")
    );
    const oldDashboardSettings = oldSetting.filter((s) =>
      s.key?.includes("dashboard")
    );

    const changedSettings = newDashboardSettings.filter((s) => {
      const oldSetting = oldDashboardSettings.find((os) => os.key === s.key);
      if (!oldSetting) return true;
      return oldSetting?.value !== s.value;
    });

    changedSettings.map((s) => {
      const options = s?.options as Array<{
        label: string;
        value: string;
      }>;
      labels.push({
        label: `Updated the "${s?.name}" field:`,
        value: [
          {
            oldValue:
              options.find(
                (o) =>
                  o.value ===
                  oldDashboardSettings.find((os) => os.key === s.key)?.value
              )?.label || "N/A",
            value: options.find((o) => o.value === s.value)?.label || "N/A"
          }
        ]
      });
    });

    return labels;
  };

  const getFeedLabels = () => {
    const labels: AuditLogLabelValue[] = [];
    const settingMap = [
      {
        key: "feed.ad-frequency",
        name: "Feed Post Ads Frequency"
      },
      {
        key: "feed.edit-time-limit",
        name: "Full Edit Post Time Frame"
      },
      {
        key: "feed.rec-feed.freemium-min",
        name: "Freemium User Minimum Post Requirement"
      },
      {
        key: "feed.rec-feed.paid-min",
        name: "Paid User Minimum Post Requirement"
      }
    ];
    const newDashboardSettings = setting.filter((s) => s.key?.includes("feed"));
    const oldDashboardSettings = oldSetting.filter((s) =>
      s.key?.includes("feed")
    );

    const changedSettings = newDashboardSettings.filter((s) => {
      const oldSetting = oldDashboardSettings.find((os) => os.key === s.key);
      if (!oldSetting) return true;
      return oldSetting?.value !== s.value;
    });

    changedSettings.map((s) => {
      const setting = settingMap.find((ss) => ss.key === s.key);
      let value = s.value;
      let oldValue = oldDashboardSettings.find((os) => os.key === s.key)?.value;

      if (s.key === "feed.edit-time-limit") {
        const nValue = (s.value as string).split(":");
        const oValue = (
          oldDashboardSettings.find((os) => os.key === s.key)?.value as string
        ).split(":");
        console.log(nValue, oValue, value, oldValue);
        value = nValue[0] === "00" ? `${nValue[1]} Min` : `${nValue[0]} Hr`;
        oldValue = oValue[0] === "00" ? `${oValue[1]} Min` : `${oValue[0]} Hr`;
      }

      labels.push({
        label: `Updated the "${setting?.name}" field:`,
        value: [
          {
            value: value || "N/A",
            oldValue: oldValue || "N/A"
          }
        ]
      });
    });

    return labels;
  };

  const getLiveStreamingLabels = () => {
    const labels: AuditLogLabelValue[] = [];
    const settingMap = [
      {
        key: "live-stream.advertiser-approval-required",
        name: "Require Advertiser Approval for Advertisements"
      },
      {
        key: "live-stream.advertisement-live-on-approval",
        name: "Upon advertisement approval automatically set advertisement status to active"
      }
    ];
    const newDashboardSettings = setting.filter((s) =>
      settingMap.some((ss) => ss.key === s.key)
    );
    const oldDashboardSettings = oldSetting.filter((s) =>
      settingMap.some((ss) => ss.key === s.key)
    );

    const changedSettings = newDashboardSettings.filter((s) => {
      const oldSetting = oldDashboardSettings.find((os) => os.key === s.key);
      if (!oldSetting) return true;
      return oldSetting?.value !== s.value;
    });

    changedSettings.map((s) => {
      const setting = settingMap.find((ss) => ss.key === s.key);
      const value = s.value;
      const oldValue = oldDashboardSettings.find(
        (os) => os.key === s.key
      )?.value;

      labels.push({
        label: `Updated the "${setting?.name}" field:`,
        value: [
          {
            value: value ? "On" : "Off",
            oldValue: oldValue ? "On" : "Off"
          }
        ]
      });
    });

    return labels;
  };

  const getMarketingLabels = () => {
    const labels: AuditLogLabelValue[] = [];
    const settingMap = [
      {
        key: "marketing.start-time-pre-break",
        name: "Start Time (Before Break)"
      },
      {
        key: "marketing.end-time-pre-break",
        name: "End Time (Before Break)"
      },
      {
        key: "marketing.start-time-post-break",
        name: "Start Time (After Break)"
      },
      {
        key: "marketing.end-time-post-break",
        name: "End Time (After Break)"
      },
      {
        key: "marketing.time-zone",
        name: "Time Zone"
      },
      {
        key: "marketing.demo-duration",
        name: "Duration"
      }
    ];
    const newDashboardSettings = setting.filter((s) =>
      settingMap.some((ss) => ss.key === s.key)
    );
    const oldDashboardSettings = oldSetting.filter((s) =>
      settingMap.some((ss) => ss.key === s.key)
    );

    const changedSettings = newDashboardSettings.filter((s) => {
      const oldSetting = oldDashboardSettings.find((os) => os.key === s.key);
      if (!oldSetting) return true;
      return oldSetting?.value !== s.value;
    });

    changedSettings.sort(
      (a, b) =>
        settingMap.findIndex((ss) => ss.key === a.key) -
        settingMap.findIndex((ss) => ss.key === b.key)
    );

    changedSettings.map((s) => {
      const setting = settingMap.find((ss) => ss.key === s.key);
      let value = s.value;
      let oldValue = oldDashboardSettings.find((os) => os.key === s.key)?.value;

      if (s.key === "marketing.demo-duration") {
        const nValue = s.value as number;
        const oValue = oldDashboardSettings.find((os) => os.key === s.key)
          ?.value as number;
        value = nValue === 60 ? "1 Hour" : `${nValue} Minutes`;
        oldValue = oValue === 60 ? "1 Hour" : `${oValue} Minutes`;
      }

      if (
        !["marketing.time-zone", "marketing.demo-duration"].includes(s.key!)
      ) {
        value = convertToAmPm(s.value as string);
        oldValue = convertToAmPm(
          oldDashboardSettings.find((os) => os.key === s.key)?.value as string
        );
      }

      labels.push({
        label: `Updated the "${setting?.name}" field:`,
        value: [
          {
            value: value || "N/A",
            oldValue: oldValue || "N/A"
          }
        ]
      });
    });

    return labels;
  };

  return (
    <Container>
      <ButtonGroup
        variant="outlined"
        style={{ border: "rgba(215, 221, 229, 1)", paddingBottom: "24px" }}
      >
        {tabs.map((t) => {
          return (
            <Button
              key={t}
              variant="outlined"
              style={
                tab === t
                  ? {
                      backgroundColor: "#E8EEFF",
                      color: "#2B337A",
                      fontSize: "14px",
                      fontWeight: 600,
                      textTransform: "none"
                    }
                  : {
                      color: "#666666",
                      fontSize: "14px",
                      fontWeight: 500,
                      textTransform: "none"
                    }
              }
              onClick={() => {
                setTab(t as Tabs);
              }}
            >
              {t}
            </Button>
          );
        })}
      </ButtonGroup>
      {tab === "Dashboard" ? (
        <AuditLogLabelValueList
          key={tab}
          recordType={recordType}
          labelValues={getDashboardLabels()}
        />
      ) : tab === "Feed" ? (
        <AuditLogLabelValueList
          key={tab}
          recordType={recordType}
          labelValues={getFeedLabels()}
        />
      ) : tab === "Live Streaming" ? (
        <AuditLogLabelValueList
          key={tab}
          recordType={recordType}
          labelValues={getLiveStreamingLabels()}
        />
      ) : tab === "Marketing" ? (
        <AuditLogLabelValueList
          key={tab}
          recordType={recordType}
          labelValues={getMarketingLabels()}
        />
      ) : tab === "CRM" ? (
        <CrmSettingAuditLogDetails before={before} after={after} />
      ) : null}
    </Container>
  );
};
