import { Container } from "@components/crud/Container";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "../types";
import { ModelSeason } from "@sportsgravyengineering/sg-api-react-sdk";
import { formatDateForDisplay } from "@utils/formatDate";

export const SeasonAuditLogUpdate = ({
  before,
  after
}: {
  before: object | ModelSeason;
  after: object | ModelSeason;
}) => {
  const season = after as ModelSeason;
  const oldSeason = before as ModelSeason;
  const getLabelValues = () => {
    const labelValues: AuditLogLabelValue[] = [];
    if (season.name !== oldSeason.name)
      labelValues.push({
        label: `Updated the "Name" field:`,
        value: [
          {
            value: season.name || "",
            oldValue: oldSeason.name || ""
          }
        ]
      });

    if (season.sportId !== oldSeason.sportId)
      labelValues.push({
        label: `Updated the "Sport" field:`,
        value: [
          {
            value: season.sport?.name || "",
            oldValue: oldSeason.sport?.name || ""
          }
        ]
      });

    if (season.startDate !== oldSeason.startDate)
      labelValues.push({
        label: `Updated the "Start Date" field:`,
        value: [
          {
            value: season.startDate
              ? formatDateForDisplay(new Date(season.startDate))
              : "",
            oldValue: oldSeason.startDate
              ? formatDateForDisplay(new Date(oldSeason.startDate))
              : ""
          }
        ]
      });

    if (season.endDate !== oldSeason.endDate)
      labelValues.push({
        label: `Updated the "End Date" field:`,
        value: [
          {
            value: season.endDate
              ? formatDateForDisplay(new Date(season.endDate))
              : "",
            oldValue: oldSeason.endDate
              ? formatDateForDisplay(new Date(oldSeason.endDate))
              : ""
          }
        ]
      });

    return labelValues;
  };
  return (
    <Container style={{ marginTop: "10px" }}>
      <AuditLogLabelValueList
        recordType="UPDATE"
        labelValues={[...getLabelValues()]}
      />
    </Container>
  );
};
