import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { ModelPosition } from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";

export const PositionsAuditLogUpdate = ({
  before,
  after
}: {
  before: object | ModelPosition;
  after: object | ModelPosition;
}) => {
  const currentPosition = after as ModelPosition;
  const oldPosition = before as ModelPosition;

  const getPositionDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    if (currentPosition.name !== oldPosition.name) {
      labelValues.push({
        label: `Updated the "Name" field:`,
        value: [
          {
            value: currentPosition.name || "N/A",
            oldValue: oldPosition.name || "N/A"
          }
        ]
      });
    }
    if (currentPosition.abbreviation !== oldPosition.abbreviation) {
      labelValues.push({
        label: `Updated the "Abbreviation" field:`,
        value: [
          {
            value: currentPosition.abbreviation || "N/A",
            oldValue: oldPosition.abbreviation || "N/A"
          }
        ]
      });
    }
    if (currentPosition?.sport?.name !== oldPosition?.sport?.name) {
      labelValues.push({
        label: `Updated the "Sport" field:`,
        value: [
          {
            value: currentPosition?.sport?.name || "N/A",
            oldValue: oldPosition?.sport?.name || "N/A"
          }
        ]
      });
    }
    if (currentPosition.parent?.name !== oldPosition.parent?.name) {
      labelValues.push({
        label: `Updated the "Parent Position" field:`,
        value: [
          {
            value: currentPosition.parent?.name || "N/A",
            oldValue: oldPosition.parent?.name || "N/A"
          }
        ]
      });
    }

    return labelValues;
  };
  return (
    <AuditLogLabelValueList
      recordType="UPDATE"
      labelValues={[...getPositionDetails()]}
    />
  );
};
