import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useAdminSportLocationLocationIdGet,
  useAdminSportLocationLocationIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { organizationAtom } from "../../recoil/auth";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { AddressAutocomplete } from "@components/AddressAutocomplete";
import MapIcon from "../../assets/icons/mapIcon.svg";
import { Typography } from "@mui/material";
import GoogleMap from "@components/GoogleMap";
import { FormInput } from "@components/FormInput";
import { Loader } from "@components/crud/Loader";
import { capitalize } from "@utils/capitalize";
export const SportLocationEdit = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { locationId } = useParams();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [address, setAddress] = useState("");
  const [openAddressPicker, setOpenAddressPicker] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [organizationId] = useRecoilState(organizationAtom);
  const closeAddressChange = () => {
    setOpenAddressPicker(false);
  };
  const {
    handleSubmit,
    setFocus,
    control,
    formState: { isValid },
    reset,
    setValue,
    trigger,
    getValues
  } = useForm({
    mode: "onTouched"
  });
  const { data: locationDetails, isLoading: isLoading } =
    useAdminSportLocationLocationIdGet(locationId!);

  const { mutate: save, isLoading: isSaving } =
    useAdminSportLocationLocationIdPut();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      const values = {
        ...formValues.addressComponents,
        name: formValues.name,
        organizationId
      };
      save(
        {
          data: values,
          locationId: locationId!
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Sport Location edited successfully!", {
              variant: "success"
            });
            if (resetInsteadOfRoute) {
              reset();
            } else {
              navigate(`/sport-locations/${locationId}`);
            }
          },
          onError: () => {
            enqueueSnackbar("Failed to edit sport location!", {
              variant: "error"
            });
          }
        }
      );
    };
  const onAddressChanges = (changedAddress) => {
    const streetNumber = changedAddress.find((c) =>
      c.types.includes("street_number")
    );
    const route = changedAddress.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name || ""} ${
      route?.long_name || ""
    }`;
    const country = changedAddress.find((c) => c.types.includes("country"));
    const state = changedAddress.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = changedAddress.find(
      (c) =>
        c.types.includes("locality") ||
        c.types.includes("sublocality") ||
        c.types.includes("postal_town")
    );
    const zip = changedAddress.find((c) => c.types.includes("postal_code"));

    setValue("addressComponents", {
      postalCode: zip?.short_name || zip?.long_name || "",
      locality: city?.short_name || city?.long_name || "",
      province: state.short_name || "",
      country: country.short_name || "",
      lines: [address1]
    });
    setValue("name", "");
    trigger("name");
  };
  const formattedAddress = (address) => {
    setAddress(address);
    setValue("location", address);
    trigger("location");
    setFocus("location");
    setSaveDisabled(getValues().name === "");
  };
  const selectAddressSuggestion = (place) => {
    setValue("name", place?.name);
    trigger("name");
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name || ""} ${
      route?.long_name || ""
    }`;
    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find(
      (c) =>
        c.types.includes("locality") ||
        c.types.includes("sublocality") ||
        c.types.includes("postal_town")
    );
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));
    setValue("addressComponents", {
      postalCode: zip?.short_name || zip?.long_name || "",
      locality: city?.short_name || city?.long_name || "",
      province: state.short_name || "",
      country: country.short_name || "",
      lines: [address1]
    });
    setValue("location", place.formatted_address);
    trigger("location");
    setAddress(place.formatted_address);
    setSaveDisabled(false);
  };
  useEffect(() => {
    if (locationDetails) {
      const data = locationDetails.data;
      const formattedAdress = `${data.lines?.join(", ")}, ${data.locality}, ${
        data.province
      }, ${data.country}, ${data.postalCode}`;
      reset(
        {
          name: data.name,
          location: formattedAdress,
          addressComponents: {
            postalCode: data.postalCode || "",
            locality: data.locality || "",
            province: data.province || "",
            country: data.country || "",
            lines: data.lines
          }
        },
        { keepDirty: true }
      );
      setAddress(formattedAdress);
    }
  }, [locationDetails]);
  const setPlace = (place) => {
    setValue("name", place, { shouldValidate: true });
    trigger("name");
    setSaveDisabled(false);
  };
  useEffect(() => {
    setValue("location", address);
    trigger("location");
  }, [address]);
  return (
    <Container>
      <Toolbar title="Edit Sports Location" />
      <Loader isLoading={isLoading}>
        <Form>
          <Grid data-testid="sportLocation-edit-form" container spacing={3}>
            <Grid xs={12} md={12} container alignItems="center">
              <Grid xs={11} data-testid="sportLocation-location">
                <AddressAutocomplete
                  name="location"
                  control={control}
                  selectSuggestion={selectAddressSuggestion}
                  rules={{
                    required: "Location is required"
                  }}
                  label="Location"
                  required
                  onChange={(e) => {
                    setSaveDisabled(true);
                    setAddress(e);
                  }}
                  trigger={trigger}
                />
              </Grid>
              <Grid>
                <Typography>&nbsp;</Typography>
                <img
                  data-testid="map-icon"
                  src={MapIcon}
                  alt="Map Icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpenAddressPicker(true)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            md={12}
            alignItems="center"
            container
            direction="row"
            marginTop="20px"
          >
            <Grid xs={6} data-testid="sportLocation-name">
              <FormInput
                control={control}
                name="name"
                rules={{
                  required: "Name is required"
                }}
                onChange={(e) => {
                  //@ts-ignore
                  if (e.target.value !== "") setSaveDisabled(false);
                  //@ts-ignore
                  setValue("name", capitalize(e.target.value));
                }}
                label="Name"
                type="text"
                required
              />
            </Grid>
          </Grid>
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler(false))}
        isDisabled={!isValid || isSaving || saveDisabled}
        isLoading={isSaving || isLoading}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/sport-locations")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
      {openAddressPicker && (
        <GoogleMap
          close={closeAddressChange}
          onAddressChange={onAddressChanges}
          formattedAddress={formattedAddress}
          address={address}
          setPlace={setPlace}
        />
      )}
    </Container>
  );
};
