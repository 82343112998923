import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { ModelFaq } from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";

export const FaqAuditLogCreateDelete = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "DELETE";
  before: object | ModelFaq;
  after: object | ModelFaq;
}) => {
  const faqDetails = (recordType === "CREATE" ? after : before) as ModelFaq;

  const getFaqDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    labelValues.push({
      label: `"Question" field:`,
      value: [
        {
          value: faqDetails?.question || ""
        }
      ]
    });
    labelValues.push({
      label: `"Answer" field:`,
      value: [
        {
          value: faqDetails?.answer || ""
        }
      ]
    });
    labelValues.push({
      label: `"Category" field:`,
      value: [
        {
          value: faqDetails?.helpCategory?.name || ""
        }
      ]
    });
    labelValues.push({
      label: `"Related To" field:`,
      value: [
        {
          value: faqDetails?.relatedTo || ""
        }
      ]
    });
    labelValues.push({
      label: `"Display Channel" field:`,
      value: [
        {
          value: faqDetails?.displayChannel.join(", ") || ""
        }
      ]
    });
    return labelValues;
  };

  return (
    <AuditLogLabelValueList
      recordType={recordType}
      labelValues={[...getFaqDetails()]}
    />
  );
};
