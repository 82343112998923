import { ReactComponent as DashboardSvg } from "@assets/icons/dashboard.svg";
import { ReactComponent as GameConceptSvg } from "@assets/icons/gameConcepts.svg";
import { ReactComponent as GameSystemSvg } from "@assets/icons/gameSystems.svg";
import { ReactComponent as ImportTmpSvg } from "@assets/icons/importTemplateProviders.svg";
import { ReactComponent as LevelSvg } from "@assets/icons/levels.svg";
import { ReactComponent as OrganizationSvg } from "@assets/icons/organization.svg";
import { ReactComponent as EditSvg } from "@assets/icons/pencil.svg";
import { ReactComponent as PlusSignSvg } from "@assets/icons/plus-sign.svg";
import { ReactComponent as PositionSvg } from "@assets/icons/positions.svg";
import { ReactComponent as RoleSvg } from "@assets/icons/roles.svg";
import { ReactComponent as RowOrderingSvg } from "@assets/icons/row-ordering.svg";
import { ReactComponent as SeasonsSvg } from "@assets/icons/seasons.svg";
import { ReactComponent as SettingSvg } from "@assets/icons/settings.svg";
import { ReactComponent as SkillSvg } from "@assets/icons/skills.svg";
import { ReactComponent as SportSvg } from "@assets/icons/sports.svg";
import { ReactComponent as SubRoleSvg } from "@assets/icons/subroles.svg";
import { ReactComponent as TrainingProgramsSvg } from "@assets/icons/training-programs.svg";
import { ReactComponent as UserSvg } from "@assets/icons/users.svg";
import { ReactComponent as CannedSvg } from "@assets/icons/canned.svg";
import { ReactComponent as FeedSVG } from "@assets/icons/feed.svg";
import { ReactComponent as ReportSVG } from "@assets/icons/report.svg";
import { ReactComponent as CloseSvg } from "@assets/icons/close.svg";
import { ReactComponent as UserinSGSVG } from "@assets/icons/user-in-sg.svg";
import { ReactComponent as PendingUserSVG } from "@assets/icons/pending user-in-sg.svg";
import { ReactComponent as BannedUserSVG } from "@assets/icons/banned-user-in-sg.svg";
import { ReactComponent as ParentAcceptSVG } from "@assets/icons/parent-accept-in-sg.svg";
import { ReactComponent as ParentAndKidAcceptSVG } from "@assets/icons/parent-and-kid-accept-in-sg.svg";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { ReactComponent as LikeSVG } from "@assets/icons/like.svg";
import { ReactComponent as LoveSVG } from "@assets/icons/love.svg";
import { ReactComponent as ConfusedSVG } from "@assets/icons/confused.svg";
import { ReactComponent as CelebrateSVG } from "@assets/icons/celebrate.svg";
import { ReactComponent as InsightfulSVG } from "@assets/icons/insightful.svg";
import { ReactComponent as NeutralSVG } from "@assets/icons/neutral.svg";
import { ReactComponent as ImportSVG } from "@assets/icons/import.svg";
import { ReactComponent as FixSVG } from "@assets/icons/fix.svg";
import { ReactComponent as DownloadSVG } from "@assets/icons/download.svg";
import { ReactComponent as InfoSVG } from "@assets/icons/info.svg";
import { ReactComponent as LiveSVG } from "@assets/icons/live.svg";
import { ReactComponent as AdvertiserSVG } from "@assets/icons/sponsorship-level.svg";
import { ReactComponent as ReplySVG } from "@assets/icons/replyIcon.svg";
import { ReactComponent as LocationSVG } from "@assets/icons/location.svg";
import { ReactComponent as CalendarSVG } from "@assets/icons/calendarIcon.svg";
import { ReactComponent as BugSVG } from "@assets/icons/bugIcon.svg";
import { ReactComponent as SupportSVG } from "@assets/icons/supportIcon.svg";
import { ReactComponent as QaSVG } from "@assets/icons/qa-features-icon.svg";
import { ReactComponent as TestcaseSVG } from "@assets/icons/testcase-icon.svg";
import { ReactComponent as GanttChartSVG } from "@assets/icons/ganttChart-icon.svg";
import { ReactComponent as QAReleaseSVG } from "@assets/icons/qa-releases-icon.svg";
import { ReactComponent as QATestCaseSVG } from "@assets/icons/qa-testcases-icon.svg";
import { ReactComponent as FeatureSVG } from "@assets/icons/features-icon.svg";
import { ReactComponent as MeetingIconSVG } from "@assets/icons/meetingIcon.svg";
import { ReactComponent as UnLinkIconSVG } from "@assets/icons/unLink.svg";
import { ReactComponent as MeetIconSVG } from "@assets/icons/meetIcon.svg";
import { ReactComponent as CallMergeIconSVG } from "@assets/icons/mergeCallIcon.svg";
import { ReactComponent as CallMadeLoggedSVG } from "@assets/icons/CallMadeLogged.svg";
import { ReactComponent as CallUpdatedSVG } from "@assets/icons/CallUpdatedIcon.svg";
import { ReactComponent as EmailSentLoggedSVG } from "@assets/icons/MailSentLoggedIcon.svg";
import { ReactComponent as EmailUpdatedSVG } from "@assets/icons/MailUpdatedIcon.svg";
import { ReactComponent as MeetingLoggedSVG } from "@assets/icons/MeetingLoggedIcon.svg";
import { ReactComponent as MeetingUpdatedSVG } from "@assets/icons/MeetingUpdatedIcon.svg";
import { ReactComponent as TaskLoggedSVG } from "@assets/icons/TaskLoggedIcon.svg";
import { ReactComponent as TaskUpdatedSVG } from "@assets/icons/TaskUpdatedIcon.svg";
import { ReactComponent as AccountSVG } from "@assets/icons/account-icon.svg";
import { ReactComponent as ActivitiesSVG } from "@assets/icons/activities-icon.svg";
import { ReactComponent as ContactsSVG } from "@assets/icons/contacts-icon.svg";
import { ReactComponent as LeadsSVG } from "@assets/icons/lead-icon.svg";
import { ReactComponent as OpportunitiesSVG } from "@assets/icons/opportunities-icon.svg";
import { ReactComponent as OrdersSVG } from "@assets/icons/orders-icon.svg";
import { ReactComponent as KeyDataForAccountSVG } from "@assets/icons/key-data-icon.svg";
import { ReactComponent as DataSheetSVG } from "@assets/icons/dataSheet.svg";
import { ReactComponent as PDFSVG } from "@assets/icons/pdfIcon.svg";
import { ReactComponent as VideoSVG } from "@assets/icons/VideoFIle.svg";
import { ReactComponent as VirtualMeetingSVG } from "@assets/icons/virtualMeeting.svg";
import { ReactComponent as EmailResendSVG } from "@assets/icons/emailResendIcon.svg";
import { ReactComponent as DeleteIconSVG } from "@assets/icons/deleteIcon.svg";
import { ReactComponent as AdvertiserInvoiceIconSVG } from "@assets/icons/advertiserInvoiceIcon.svg";
import { ReactComponent as CropIconSVG } from "@assets/icons/cropIcon.svg";
import { ReactComponent as PostIconSVG } from "@assets/icons/postIcon.svg";
import { ReactComponent as AlbumIconSVG } from "@assets/icons/albumsIcon.svg";
import { ReactComponent as ClipIconSVG } from "@assets/icons/clipIcon.svg";
import { ReactComponent as OnboardingSVG } from "@assets/icons/Onboarding.svg";
import { ReactComponent as TwilioCallSVG } from "@assets/icons/twilio-call.svg";
import { ReactComponent as OpportunityApprovalsSVG } from "@assets/icons/Opportunities-approvals.svg";
import { ReactComponent as JobsSVG } from "@assets/icons/Jobs.svg";
import { ReactComponent as HelpArticlesSVG } from "@assets/icons/help.svg";
import { ReactComponent as FAQSVG } from "@assets/icons/FAQ.svg";
import { ReactComponent as DepartmentSVG } from "@assets/icons/Department.svg";
import { ReactComponent as CompanyDirectorySVG } from "@assets/icons/Directory.svg";
import { ReactComponent as BillingSVG } from "@assets/icons/billing.svg";
import { ReactComponent as TeamsSVG } from "@assets/icons/Teams.svg";
import { ReactComponent as ClipPresetSVG } from "@assets/icons/preset.svg";
import { ReactComponent as SponsorshipLevelSVG } from "@assets/icons/sponsorship.svg";
import { ReactComponent as MerchantAccountSVG } from "@assets/icons/Merchant.svg";
import { ReactComponent as AuditLogSVG } from "@assets/icons/auditLogIcon.svg";

export const DashboardIcon = (props: SvgIconProps) => (
  <SvgIcon component={DashboardSvg} {...props} />
);
export const OrganizationIcon = (props: SvgIconProps) => (
  <SvgIcon component={OrganizationSvg} {...props} />
);
export const UserIcon = (props: SvgIconProps) => (
  <SvgIcon component={UserSvg} {...props} />
);
export const RoleIcon = (props: SvgIconProps) => (
  <SvgIcon component={RoleSvg} {...props} />
);
export const SubRoleIcon = (props: SvgIconProps) => (
  <SvgIcon component={SubRoleSvg} {...props} />
);
export const ImportTmpIcon = (props: SvgIconProps) => (
  <SvgIcon component={ImportTmpSvg} {...props} />
);
export const SportIcon = (props: SvgIconProps) => (
  <SvgIcon component={SportSvg} {...props} />
);
export const PositionIcon = (props: SvgIconProps) => (
  <SvgIcon component={PositionSvg} {...props} />
);
export const LevelIcon = (props: SvgIconProps) => (
  <SvgIcon component={LevelSvg} {...props} />
);
export const SeasonsIcon = (props: SvgIconProps) => (
  <SvgIcon component={SeasonsSvg} {...props} />
);
export const SkillIcon = (props: SvgIconProps) => (
  <SvgIcon component={SkillSvg} {...props} />
);
export const GameConceptIcon = (props: SvgIconProps) => (
  <SvgIcon component={GameConceptSvg} {...props} />
);
export const GameSystemIcon = (props: SvgIconProps) => (
  <SvgIcon component={GameSystemSvg} {...props} />
);
export const RowOrderingIcon = (props: SvgIconProps) => (
  <SvgIcon component={RowOrderingSvg} {...props} />
);
export const SettingIcon = (props: SvgIconProps) => (
  <SvgIcon component={SettingSvg} {...props} />
);
export const TeamsIcon = (props: SvgIconProps) => (
  <SvgIcon component={TeamsSVG} {...props} />
);
export const TrainingProgramsIcon = (props: SvgIconProps) => (
  <SvgIcon component={TrainingProgramsSvg} {...props} />
);
export const AddIcon = (props: SvgIconProps) => (
  <SvgIcon component={PlusSignSvg} {...props} />
);
export const EditIcon = (props: SvgIconProps) => (
  <SvgIcon component={EditSvg} {...props} />
);

export const CannedIcon = (props: SvgIconProps) => (
  <SvgIcon component={CannedSvg} {...props} />
);
export const ActiveUserIcon = (props: SvgIconProps) => (
  <SvgIcon component={UserinSGSVG} style={{ color: "white" }} {...props} />
);
export const PendingUserIcon = (props: SvgIconProps) => (
  <SvgIcon component={PendingUserSVG} style={{ color: "white" }} {...props} />
);
export const BannedUserIcon = (props: SvgIconProps) => (
  <SvgIcon component={BannedUserSVG} style={{ color: "white" }} {...props} />
);
export const ParentAcceptIcon = (props: SvgIconProps) => (
  <SvgIcon component={ParentAcceptSVG} style={{ color: "white" }} {...props} />
);
export const ParentAndKidAcceptIcon = (props: SvgIconProps) => (
  <SvgIcon
    component={ParentAndKidAcceptSVG}
    style={{ color: "white" }}
    {...props}
  />
);
export const CloseIcon = (props: SvgIconProps) => (
  <SvgIcon component={CloseSvg} {...props} />
);
export const LikeIcon = (props: SvgIconProps) => (
  <SvgIcon component={LikeSVG} {...props} />
);

export const LoveIcon = (props: SvgIconProps) => (
  <SvgIcon component={LoveSVG} {...props} />
);

export const ConfusedIcon = (props: SvgIconProps) => (
  <SvgIcon component={ConfusedSVG} {...props} />
);

export const CelebrateIcon = (props: SvgIconProps) => (
  <SvgIcon component={CelebrateSVG} {...props} />
);

export const InsightfulIcon = (props: SvgIconProps) => (
  <SvgIcon component={InsightfulSVG} {...props} />
);

export const NeutralIcon = (props: SvgIconProps) => (
  <SvgIcon component={NeutralSVG} {...props} />
);
export const InfoIcon = (props: SvgIconProps) => (
  <SvgIcon component={InfoSVG} {...props} />
);
export const FeedIcon = (props: SvgIconProps) => (
  <SvgIcon component={FeedSVG} {...props} />
);
export const ReportIcon = (props: SvgIconProps) => (
  <SvgIcon component={ReportSVG} {...props} />
);

export const ImportIcon = (props: SvgIconProps) => (
  <SvgIcon component={ImportSVG} {...props} />
);

export const FixIcon = (props: SvgIconProps) => (
  <SvgIcon component={FixSVG} {...props} />
);

export const DownloadIcon = (props: SvgIconProps) => (
  <SvgIcon component={DownloadSVG} {...props} />
);

export const LiveStreamIcon = (props: SvgIconProps) => (
  <SvgIcon component={LiveSVG} {...props} />
);

export const AdvertiserIcon = (props: SvgIconProps) => (
  <SvgIcon component={AdvertiserSVG} {...props} />
);

export const ReplyIcon = (props: SvgIconProps) => (
  <SvgIcon component={ReplySVG} {...props} />
);

export const SportLocationIcon = (props: SvgIconProps) => (
  <SvgIcon component={LocationSVG} {...props} />
);

export const CalendarIcon = (props: SvgIconProps) => (
  <SvgIcon component={CalendarSVG} {...props} />
);

export const BugIcon = (props: SvgIconProps) => (
  <SvgIcon component={BugSVG} {...props} />
);

export const SupportIcon = (props: SvgIconProps) => (
  <SvgIcon component={SupportSVG} {...props} />
);

export const QaIcon = (props: SvgIconProps) => (
  <SvgIcon component={QaSVG} {...props} />
);

export const TestcaseIcon = (props: SvgIconProps) => (
  <SvgIcon component={TestcaseSVG} {...props} />
);

export const GanttChartIcon = (props: SvgIconProps) => (
  <SvgIcon component={GanttChartSVG} {...props} />
);

export const MeetingIcon = (props: SvgIconProps) => (
  <SvgIcon component={MeetingIconSVG} {...props} />
);
export const UnLinkIcon = (props: SvgIconProps) => (
  <SvgIcon component={UnLinkIconSVG} {...props} />
);
export const MeetIcon = (props: SvgIconProps) => (
  <SvgIcon component={MeetIconSVG} {...props} />
);

export const CallMergeIcon = (props: SvgIconProps) => (
  <SvgIcon component={CallMergeIconSVG} {...props} />
);
export const CallMadeLoggedIcon = (props: SvgIconProps) => (
  <SvgIcon component={CallMadeLoggedSVG} {...props} />
);
export const CallUpdatedIcon = (props: SvgIconProps) => (
  <SvgIcon component={CallUpdatedSVG} {...props} />
);
export const EmailSentLoggedIcon = (props: SvgIconProps) => (
  <SvgIcon component={EmailSentLoggedSVG} {...props} />
);
export const EmailUpdatedIcon = (props: SvgIconProps) => (
  <SvgIcon component={EmailUpdatedSVG} {...props} />
);
export const MeetingLoggedIcon = (props: SvgIconProps) => (
  <SvgIcon component={MeetingLoggedSVG} {...props} />
);
export const MeetingUpdatedIcon = (props: SvgIconProps) => (
  <SvgIcon component={MeetingUpdatedSVG} {...props} />
);
export const TaskLoggedIcon = (props: SvgIconProps) => (
  <SvgIcon component={TaskLoggedSVG} {...props} />
);
export const TaskUpdatedIcon = (props: SvgIconProps) => (
  <SvgIcon component={TaskUpdatedSVG} {...props} />
);
export const AccountIcon = (props: SvgIconProps) => (
  <SvgIcon component={AccountSVG} {...props} />
);
export const ActivitiesIcon = (props: SvgIconProps) => (
  <SvgIcon component={ActivitiesSVG} {...props} />
);
export const ContactsIcon = (props: SvgIconProps) => (
  <SvgIcon component={ContactsSVG} {...props} />
);
export const LeadsIcon = (props: SvgIconProps) => (
  <SvgIcon component={LeadsSVG} {...props} />
);
export const OpportunitiesIcon = (props: SvgIconProps) => (
  <SvgIcon component={OpportunitiesSVG} {...props} />
);
export const OrdersIcon = (props: SvgIconProps) => (
  <SvgIcon component={OrdersSVG} {...props} />
);
export const KeyDataForAccountIcon = (props: SvgIconProps) => (
  <SvgIcon component={KeyDataForAccountSVG} {...props} />
);
export const DataSheetIcon = (props: SvgIconProps) => (
  <SvgIcon component={DataSheetSVG} {...props} />
);
export const PDFIcon = (props: SvgIconProps) => (
  <SvgIcon component={PDFSVG} {...props} />
);

export const VideoFileIcon = (props: SvgIconProps) => (
  <SvgIcon component={VideoSVG} {...props} />
);

export const VirtualMeetingIcon = (props: SvgIconProps) => (
  <SvgIcon component={VirtualMeetingSVG} {...props} />
);
export const EmailResendIcon = (props: SvgIconProps) => (
  <SvgIcon component={EmailResendSVG} {...props} />
);
export const DeleteIcon = (props: SvgIconProps) => (
  <SvgIcon component={DeleteIconSVG} {...props} />
);

export const AdvertiserInvoiceIcon = (props: SvgIconProps) => (
  <SvgIcon component={AdvertiserInvoiceIconSVG} {...props} />
);

export const CropIcon = (props: SvgIconProps) => (
  <SvgIcon component={CropIconSVG} {...props} />
);

export const PostIcon = (props: SvgIconProps) => (
  <SvgIcon component={PostIconSVG} {...props} />
);

export const QAReleaseIcon = (props: SvgIconProps) => (
  <SvgIcon component={QAReleaseSVG} {...props} />
);

export const FeatureIcon = (props: SvgIconProps) => (
  <SvgIcon component={FeatureSVG} {...props} />
);

export const QATestCaseIcon = (props: SvgIconProps) => (
  <SvgIcon component={QATestCaseSVG} {...props} />
);

export const AlbumsIcon = (props: SvgIconProps) => (
  <SvgIcon component={AlbumIconSVG} {...props} />
);

export const ClipsIcon = (props: SvgIconProps) => (
  <SvgIcon component={ClipIconSVG} {...props} />
);

export const TwilioCallIcon = (props: SvgIconProps) => (
  <SvgIcon component={TwilioCallSVG} {...props} />
);
export const OpportunityApporvalsIcon = (props: SvgIconProps) => (
  <SvgIcon component={OpportunityApprovalsSVG} {...props} />
);
export const OnboardingIcon = (props: SvgIconProps) => (
  <SvgIcon component={OnboardingSVG} {...props} />
);
export const JobsIcon = (props: SvgIconProps) => (
  <SvgIcon component={JobsSVG} {...props} />
);
export const FAQIcon = (props: SvgIconProps) => (
  <SvgIcon component={FAQSVG} {...props} />
);
export const HelpArticleIcon = (props: SvgIconProps) => (
  <SvgIcon component={HelpArticlesSVG} {...props} />
);
export const DepartmentIcon = (props: SvgIconProps) => (
  <SvgIcon component={DepartmentSVG} {...props} />
);
export const CompanyDIrectoryIcon = (props: SvgIconProps) => (
  <SvgIcon component={CompanyDirectorySVG} {...props} />
);
export const BillingIcon = (props: SvgIconProps) => (
  <SvgIcon component={BillingSVG} {...props} />
);
export const ClipPresetIcon = (props: SvgIconProps) => (
  <SvgIcon component={ClipPresetSVG} {...props} />
);
export const SponsorshipLevelIcon = (props: SvgIconProps) => (
  <SvgIcon component={SponsorshipLevelSVG} {...props} />
);
export const MerchantAccountIcon = (props: SvgIconProps) => (
  <SvgIcon component={MerchantAccountSVG} {...props} />
);
export const AuditLogIcon = (props: SvgIconProps) => (
  <SvgIcon component={AuditLogSVG} {...props} />
);
