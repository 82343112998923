import { LicenseInfo } from "@mui/x-license-pro";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";

LicenseInfo.setLicenseKey(
  "7eacd3769a4636889ca1aa858ffa48c5Tz0xMTA1MzksRT0xNzc0ODI4Nzk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI="
);

export default function App(): JSX.Element {
  return (
    <Router>
      <Routes />
    </Router>
  );
}
