import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { ModelSport } from "@sportsgravyengineering/sg-api-react-sdk";

export const SportsAuditLogCreateDelete = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "DELETE";
  before: object | ModelSport;
  after: object | ModelSport;
}) => {
  const sportDetails = (recordType === "CREATE" ? after : before) as ModelSport;

  const getSportDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];

    labelValues.push(
      {
        label: `"Icon" field:`,
        value: [
          {
            value: sportDetails?.icon || "",
            isMedia: true
          }
        ]
      },
      {
        label: `"Name" field:`,
        value: [
          {
            value: sportDetails?.name || ""
          }
        ]
      },
      {
        label: `"Default Portrait Image" field:`,
        value: [
          {
            value: sportDetails?.portraitImage || "",
            isMedia: true
          }
        ]
      },
      {
        label: `"Default Landscape Image" field:`,
        value: [
          {
            value: sportDetails?.landscapeImage || "",
            isMedia: true
          }
        ]
      },
      {
        label: `"Sport has Intervals" field:`,
        value: [
          {
            value: sportDetails?.isIntervalBased ? "On" : "Off"
          }
        ]
      }
    );

    if (sportDetails?.intervalSingular) {
      labelValues.push({
        label: `"Singular Interval" field:`,
        value: [
          {
            value: sportDetails?.intervalSingular || ""
          }
        ]
      });
    }

    if (sportDetails.intervalPlural) {
      labelValues.push({
        label: `"Plural Interval" field:`,
        value: [
          {
            value: sportDetails?.intervalPlural || ""
          }
        ]
      });
    }
    if (sportDetails.intervalAbbreviation) {
      labelValues.push({
        label: `"Interval Abbreviation" field:`,
        value: [
          {
            value: sportDetails?.intervalAbbreviation || ""
          }
        ]
      });
    }

    labelValues.push(
      {
        label: `"Allow Scoring" field:`,
        value: [
          {
            value: sportDetails?.scoringOptions?.length === 1 ? "On" : "Off"
          }
        ]
      },
      {
        label: `"Allow Multiple Scoring Options" field:`,
        value: [
          {
            value:
              (sportDetails?.scoringOptions?.length ?? 0) > 1 ? "On" : "Off"
          }
        ]
      }
    );
    sportDetails?.scoringOptions?.forEach((option, index) => {
      labelValues.push({
        label: `Score ${index + 1} "Scoring Option" field:`,
        value: [
          {
            value: option?.name || ""
          }
        ]
      });

      labelValues.push({
        label: `Score ${index + 1} "Scoring Values" field:`,
        value: [
          {
            value: option?.value?.join(", ") || ""
          }
        ]
      });
    });

    sportDetails?.countries?.forEach((option) => {
      labelValues.push({
        label: `Participating Countries "${option.name}" field:`,
        value: [
          {
            value: option?.isMatch ? "On" : "Off"
          }
        ]
      });

      labelValues.push({
        label: `Participating Countries ${option.name} "Game Type" field:`,
        value: [
          {
            value: option?.isMatch ? "On" : "Off"
          }
        ]
      });
    });

    return labelValues;
  };
  return (
    <AuditLogLabelValueList
      recordType={recordType}
      labelValues={[...getSportDetails()]}
    />
  );
};
