import { ModelSport } from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { SportsAuditLogUpdate } from "./SportsAuditLogUpdate";
import { SportsAuditLogCreateDelete } from "./SportsAuditLogCreateDelete";

export const SportsAuditLogDetails = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelSport;
  after: object | ModelSport;
}) => {
  if (recordType === "UPDATE")
    return <SportsAuditLogUpdate before={before} after={after} />;
  else
    return (
      <SportsAuditLogCreateDelete
        recordType={recordType}
        before={before}
        after={after}
      />
    );
};
