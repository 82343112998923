import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useAdminSkillSkillIdGet,
  useAdminSkillSkillIdPut,
  useAdminPositionGet,
  useAdminSkillGet,
  ModelMedia,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, FormLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import GamePickerImage from "@assets/images/gameSkillPicker.png";
import { MediaSelector } from "@components/MediaSelector";
import { useApiSelectOptions } from "@hooks/useApiSelectOptions";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "../../recoil/auth";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { capitalizeEveryWord } from "@utils/capitalize";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const SkillsEdit = () => {
  const [filestoUpload, setFilesToUpload] = useState<(File | ModelMedia)[]>([]);
  const navigate = useNavigate();
  const { skillId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [files, setFiles] = useState<ModelMedia[]>([]);
  const [sportId, setSportId] = useState<string | undefined>(undefined);
  const organizationId = useRecoilValue(organizationAtom);
  const [mediaUploadLoading, setMediaUploadLoading] = useState(false);
  const {
    data: skill,
    isFetching: isSkillFetching,
    error: error
  } = useAdminSkillSkillIdGet(skillId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );
  const { options: positionOptions } = useApiSelectOptions({
    api: useAdminPositionGet,
    dataField: "positions",
    labelField: "name",
    valueField: "positionId",
    params: {
      sportId: sportId,
      pageSize: 1000
    },
    options: {
      query: {
        enabled: !!sportId
      }
    }
  });
  const { options: parentSkillsOptions } = useApiSelectOptions({
    api: useAdminSkillGet,
    dataField: "skills",
    labelField: "name",
    valueField: "skillId",
    params: {
      sportId: sportId,
      pageSize: 1000
    },
    options: {
      query: {
        enabled: !!sportId
      }
    }
  });

  useEffect(() => {
    if (!skill?.data?.sportId) {
      return;
    }

    setSportId(skill?.data?.sportId);
  }, [skill]);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isDirty },
    reset
  } = useForm({
    mode: "onTouched"
  });

  useEffect(() => {
    if (skill && !isSkillFetching) {
      reset(
        {
          name: skill?.data?.name,
          parent: skill?.data?.parent,
          description: skill?.data?.description || "",
          parentId: skill?.data?.parentId,
          sportId: skill?.data?.sportId,
          positions: skill?.data?.positions?.map(
            (position) => position.positionId
          )
        },
        {
          keepDirtyValues: true
        }
      );
      setFiles(skill?.data?.media || []);
    }
  }, [skill]);

  const { mutate: save, isLoading: isSaving } = useAdminSkillSkillIdPut();
  const saveHandler = async (formValues) => {
    setMediaUploadLoading(true);
    const filesPromises = await Promise.all(
      filestoUpload.map((file) => {
        if (file instanceof File) {
          const promise = uploadMediaUsingPresignedUrl(file);
          return promise;
        } else {
          return file.mediaId;
        }
      })
    );
    const values = {
      ...formValues,
      organizationId
    };
    if (values.positions) {
      values.positions = values.positions.map((position) => ({
        positionId: position
      }));
    }
    if (values.parentId === null) delete values.parentId;
    values["mediaIds"] = filesPromises;
    delete values?.dummy;
    setMediaUploadLoading(false);
    save(
      {
        skillId: skillId as string,
        data: values
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Skill saved successfully!", {
            variant: "success"
          });
          navigate(`/skills/${skillId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to save skill!", { variant: "error" });
        }
      }
    );
  };

  return (
    <Container>
      <Toolbar title="Edit Skill" />
      <Form>
        <Loader isLoading={isSportLoading || isSkillFetching}>
          <Grid data-testid="skill-edit-form" container spacing={3}>
            <Grid data-testid="skill-name" xs={12} md={6}>
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                onChange={(e) => {
                  setValue(
                    "name",
                    capitalizeEveryWord(
                      (e as ChangeEvent<HTMLInputElement>).target.value
                    )
                  );
                }}
                required={true}
                rules={{
                  required: "Name is required"
                }}
              />
            </Grid>
            <Grid data-testid="skill-sport" xs={12} md={6}>
              <FormSelect
                control={control}
                name="sportId"
                label="Sport"
                isLoading={isSportLoading}
                disabled={sportOptions.length === 1}
                required={true}
                options={sportOptions}
                onChange={(e) => {
                  setValue("positions", []);
                  setValue("parentId", null);
                  setSportId(e.target.value);
                }}
                rules={{
                  required: "Sport is required"
                }}
              />
            </Grid>
            <Grid data-testid="skill-parentSkill" xs={12} md={6}>
              <FormSelect
                control={control}
                name="parentId"
                label="Parent Skill"
                required={false}
                options={parentSkillsOptions}
              />
            </Grid>
            <Grid data-testid="skill-position" xs={12} md={6}>
              <FormMultiSelect
                control={control}
                name="positions"
                label="Positions"
                value="positions"
                required={false}
                options={positionOptions}
              />
            </Grid>
            <Grid data-testid="skill-description" xs={12} md={12}>
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Description"
                multiline={true}
              />
            </Grid>
            <Grid xs={12} md={12}>
              <StyledFormLabel>
                <Typography display="inline" variant="formLabel">
                  {"Photo/Video Content"}
                </Typography>
              </StyledFormLabel>
            </Grid>
            <MediaSelector
              setFilesToUpload={(file) => {
                setFilesToUpload(file);
              }}
              uploadedFiles={files}
              imagePlaceHolder={GamePickerImage}
              removeFiles={() =>
                setValue("dummy", sportId, {
                  shouldDirty: true,
                  shouldValidate: true
                })
              }
              onAddFiles={() =>
                setValue("dummy", sportId, {
                  shouldDirty: true,
                  shouldValidate: true
                })
              }
            />
          </Grid>
        </Loader>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler)}
        isDisabled={!isValid || isSaving || mediaUploadLoading || !isDirty}
        isLoading={isSaving || mediaUploadLoading}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/skills")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
