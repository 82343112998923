import { ModelJobTitle } from "@sportsgravyengineering/sg-api-react-sdk";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "../types";
export const JobTitleAuditLogDetails = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelJobTitle;
  after: object | ModelJobTitle;
}) => {
  const job = (recordType !== "DELETE" ? after : before) as ModelJobTitle;
  const oldJob = before as ModelJobTitle;

  const compareArray = (arr1: string[], arr2: string[]): boolean => {
    if (arr1.length !== arr2.length) return false;
    return arr1.sort().join(",") === arr2.sort().join(",");
  };

  console.log("job", job, oldJob);

  const getCreateLabels = (): AuditLogLabelValue[] => {
    const labels: AuditLogLabelValue[] = [];
    labels.push(
      {
        label: `"Name" field:`,
        value: [
          {
            value: job?.name || ""
          }
        ]
      },
      {
        label: `"Abbreviation" field:`,
        value: [
          {
            value: job?.abbreviation || ""
          }
        ]
      },
      {
        label: `"Is Active" field:`,
        value: [
          {
            value: job.isActive ? "On" : "Off"
          }
        ]
      },
      {
        label: `"Department" field:`,
        value: [
          {
            value: job?.department?.name || ""
          }
        ]
      }
    );

    if (job.canSetDemo) {
      labels.push(
        {
          label: `"Responsible for Setting up Demos" field:`,
          value: [
            {
              value: job.canSetDemo ? "On" : "Off"
            }
          ]
        },
        {
          label: `Responsible for Setting up Demos "Max No. of Assigned Accounts" field:`,
          value: [
            {
              value: job?.maxAccountsDemo ? job?.maxAccountsDemo.toString() : ""
            }
          ]
        },
        ...(job.orgRatingDemo && job.orgRatingDemo?.length
          ? [
              {
                label: `Responsible for Setting up Demos "Organization Sales Rating" field:`,
                value: [
                  {
                    value: job.orgRatingDemo.map((org) => org).join(", ")
                  }
                ]
              }
            ]
          : []),
        ...(job.athletesThresholdDemo
          ? [
              {
                label: `Responsible for Setting up Demos "No. of Athlete Registrations Threshold" field:`,
                value: [
                  {
                    value: job.athletesThresholdDemo
                      ? job.athletesThresholdDemo.toString()
                      : ""
                  }
                ]
              }
            ]
          : [])
      );
    }

    if (job.canSellAccount) {
      labels.push(
        {
          label: `"Responsible for Selling New Accounts" field:`,
          value: [
            {
              value: job.canSellAccount ? "On" : "Off"
            }
          ]
        },
        {
          label: `Responsible for Selling New Accounts "Max No. of Open Opportunities Allowed" field:`,
          value: [
            {
              value: job?.maxOpenOppSell ? job?.maxOpenOppSell.toString() : ""
            }
          ]
        },
        ...(job.orgRatingSell && job.orgRatingSell?.length
          ? [
              {
                label: `Responsible for Selling New Accounts "Organization Sales Rating" field:`,
                value: [
                  {
                    value: job.orgRatingSell.map((org) => org).join(", ")
                  }
                ]
              }
            ]
          : []),
        ...(job.athletesThresholdSell
          ? [
              {
                label: `Responsible for Selling New Accounts "No. of Athlete Registrations Threshold" field:`,
                value: [
                  {
                    value: job.athletesThresholdSell
                      ? job.athletesThresholdSell.toString()
                      : ""
                  }
                ]
              }
            ]
          : [])
      );
    }

    if (job.canSupportAccount) {
      labels.push(
        {
          label: `"Responsible for Customer Onboarding Demos" field:`,
          value: [
            {
              value: job.canSupportAccount ? "On" : "Off"
            }
          ]
        },
        {
          label: `Responsible for Customer Onboarding Demos "Max No. of Assigned Accounts" field:`,
          value: [
            {
              value: job?.maxAccountsSupport
                ? job?.maxAccountsSupport.toString()
                : ""
            }
          ]
        },
        ...(job.orgRatingSupport && job.orgRatingSupport?.length
          ? [
              {
                label: `Responsible for Customer Onboarding Demos "Organization Sales Rating" field:`,
                value: [
                  {
                    value: job.orgRatingSupport.map((org) => org).join(", ")
                  }
                ]
              }
            ]
          : []),
        ...(job.athletesThresholdSupport
          ? [
              {
                label: `Responsible for Customer Onboarding Demos "No. of Athlete Registrations Threshold" field:`,
                value: [
                  {
                    value: job.athletesThresholdSupport
                      ? job.athletesThresholdSupport.toString()
                      : ""
                  }
                ]
              }
            ]
          : [])
      );
    }

    if (job.canConductOnboardingDemo) {
      labels.push({
        label: `"Responsible for Customer Onboarding Demos" field:`,
        value: [
          {
            value: job.canConductOnboardingDemo ? "On" : "Off"
          }
        ]
      });
    }

    if (job.supportsCalls) {
      labels.push({
        label: `"Accept Incoming & Make Outgoing Calls" field:`,
        value: [
          {
            value: job.supportsCalls ? "On" : "Off"
          }
        ]
      });
    }

    if (job.supportsCallCenterCalls) {
      labels.push({
        label: `"Accept Incoming Calls (Call Center Only)" field:`,
        value: [
          {
            value: job.supportsCallCenterCalls ? "On" : "Off"
          }
        ]
      });
    }

    labels.push({
      label: `"Description" field:`,
      value: [
        {
          value: job.description || "",
          isRickText: true
        }
      ]
    });

    return labels;
  };

  const getUpdateLabels = (): AuditLogLabelValue[] => {
    const labels: AuditLogLabelValue[] = [];

    if (job.name !== oldJob.name)
      labels.push({
        label: `Updated the "Name" field:`,
        value: [
          {
            value: job?.name || "",
            oldValue: oldJob?.name || ""
          }
        ]
      });

    if (job.abbreviation !== oldJob.abbreviation)
      labels.push({
        label: `Updated the "Abbreviation" field:`,
        value: [
          {
            value: job?.abbreviation || "",
            oldValue: oldJob?.abbreviation || ""
          }
        ]
      });

    if (job.isActive !== oldJob.isActive)
      labels.push({
        label: `Updated the "Is Active" field:`,
        value: [
          {
            value: job?.isActive ? "On" : "Off",
            oldValue: oldJob?.isActive ? "On" : "Off"
          }
        ]
      });

    if (job.departmentId !== oldJob.departmentId)
      labels.push({
        label: `Updated the "Department" field:`,
        value: [
          {
            value: job?.department?.name || "",
            oldValue: oldJob?.department?.name || ""
          }
        ]
      });

    if (job.canSetDemo !== oldJob.canSetDemo)
      labels.push({
        label: `Updated the "Responsible for Setting up Demos" field:`,
        value: [
          {
            value: job.canSetDemo ? "On" : "Off",
            oldValue: oldJob.canSetDemo ? "On" : "Off"
          }
        ]
      });

    if (job.canSetDemo) {
      if (job.maxAccountsDemo !== oldJob.maxAccountsDemo)
        labels.push({
          label: `Updated the "Responsible for Setting up Demos Max No. of Assigned Accounts" field:`,
          value: [
            {
              value: job?.maxAccountsDemo
                ? job?.maxAccountsDemo.toString()
                : "N/A",
              oldValue: oldJob?.maxAccountsDemo
                ? oldJob?.maxAccountsDemo.toString()
                : "N/A"
            }
          ]
        });

      if (!compareArray(job.orgRatingDemo || [], oldJob.orgRatingDemo || []))
        labels.push(
          ...(job.orgRatingDemo && job.orgRatingDemo?.length
            ? [
                {
                  label: `Updated the "Responsible for Setting up Demos Organization Sales Rating" field:`,
                  value: [
                    {
                      value:
                        job.orgRatingDemo && job.orgRatingDemo?.length
                          ? job.orgRatingDemo.map((org) => org).join(", ")
                          : "N/A",
                      oldValue:
                        oldJob.orgRatingDemo && oldJob.orgRatingDemo?.length
                          ? oldJob.orgRatingDemo.map((org) => org).join(", ")
                          : "N/A"
                    }
                  ]
                }
              ]
            : [])
        );

      if (job.athletesThresholdDemo !== oldJob.athletesThresholdDemo)
        labels.push({
          label: `Updated the "Responsible for Setting up Demos No. of Athlete Registrations Threshold" field:`,
          value: [
            {
              value: job.athletesThresholdDemo
                ? job.athletesThresholdDemo.toString()
                : "N/A",
              oldValue: oldJob.athletesThresholdDemo
                ? oldJob.athletesThresholdDemo.toString()
                : "N/A"
            }
          ]
        });
    }

    if (job.canSellAccount !== oldJob.canSellAccount)
      labels.push({
        label: `Updated the "Responsible for Selling New Accounts" field:`,
        value: [
          {
            value: job.canSellAccount ? "On" : "Off",
            oldValue: oldJob.canSellAccount ? "On" : "Off"
          }
        ]
      });
    if (job.canSellAccount) {
      if (job.maxOpenOppSell !== oldJob.maxOpenOppSell)
        labels.push({
          label: `Updated the Responsible for Selling New Accounts "Max No. of Open Opportunities Allowed" field:`,
          value: [
            {
              value: job?.maxOpenOppSell
                ? job?.maxOpenOppSell.toString()
                : "N/A",
              oldValue: oldJob?.maxOpenOppSell
                ? oldJob?.maxOpenOppSell.toString()
                : "N/A"
            }
          ]
        });
      if (!compareArray(job.orgRatingSell || [], oldJob.orgRatingSell || []))
        labels.push(
          ...(job.orgRatingSell && job.orgRatingSell?.length
            ? [
                {
                  label: `Updated the "Responsible for Selling New Accounts Organization Sales Rating" field:`,
                  value: [
                    {
                      value:
                        job.orgRatingSell && job.orgRatingSell?.length
                          ? job.orgRatingSell.map((org) => org).join(", ")
                          : "N/A",
                      oldValue:
                        oldJob.orgRatingSell && oldJob.orgRatingSell?.length
                          ? oldJob.orgRatingSell.map((org) => org).join(", ")
                          : "N/A"
                    }
                  ]
                }
              ]
            : [])
        );
      if (job.athletesThresholdSell !== oldJob.athletesThresholdSell)
        labels.push({
          label: `Updated the "Responsible for Selling New Accounts No. of Athlete Registrations Threshold" field:`,
          value: [
            {
              value: job.athletesThresholdSell
                ? job.athletesThresholdSell.toString()
                : "N/A",
              oldValue: oldJob.athletesThresholdSell
                ? oldJob.athletesThresholdSell.toString()
                : "N/A"
            }
          ]
        });
    }

    if (job.canSupportAccount !== oldJob.canSupportAccount)
      labels.push({
        label: `Updated the "Responsible for Customer Onboarding Demos" field:`,
        value: [
          {
            value: job.canSupportAccount ? "On" : "Off",
            oldValue: oldJob.canSupportAccount ? "On" : "Off"
          }
        ]
      });

    if (job.canSupportAccount) {
      if (job.maxAccountsSupport !== oldJob.maxAccountsSupport)
        labels.push({
          label: `Updated the "Responsible for Customer Onboarding Demos Max No. of Assigned Accounts" field:`,
          value: [
            {
              value: job?.maxAccountsSupport
                ? job?.maxAccountsSupport.toString()
                : "N/A",
              oldValue: oldJob?.maxAccountsSupport
                ? oldJob?.maxAccountsSupport.toString()
                : "N/A"
            }
          ]
        });
      if (
        !compareArray(job.orgRatingSupport || [], oldJob.orgRatingSupport || [])
      )
        labels.push(
          ...(job.orgRatingSupport && job.orgRatingSupport?.length
            ? [
                {
                  label: `Updated the "Responsible for Customer Onboarding Demos Organization Sales Rating" field:`,
                  value: [
                    {
                      value:
                        job.orgRatingSupport && job.orgRatingSupport?.length
                          ? job.orgRatingSupport.map((org) => org).join(", ")
                          : "N/A",
                      oldValue:
                        oldJob.orgRatingSupport &&
                        oldJob.orgRatingSupport?.length
                          ? oldJob.orgRatingSupport.map((org) => org).join(", ")
                          : "N/A"
                    }
                  ]
                }
              ]
            : [])
        );
      if (job.athletesThresholdSupport !== oldJob.athletesThresholdSupport)
        labels.push({
          label: `Updated the "Responsible for Customer Onboarding Demos No. of Athlete Registrations Threshold" field:`,
          value: [
            {
              value: job.athletesThresholdSupport
                ? job.athletesThresholdSupport.toString()
                : "N/A",
              oldValue: oldJob.athletesThresholdSupport
                ? oldJob.athletesThresholdSupport.toString()
                : "N/A"
            }
          ]
        });
    }

    if (job.canConductOnboardingDemo !== oldJob.canConductOnboardingDemo) {
      labels.push({
        label: `Updated the "Responsible for Customer Onboarding Demos" field:`,
        value: [
          {
            value: job.canConductOnboardingDemo ? "On" : "Off",
            oldValue: oldJob.canConductOnboardingDemo ? "On" : "Off"
          }
        ]
      });
    }

    if (job.supportsCalls !== oldJob.supportsCalls) {
      labels.push({
        label: `Updated the "Accept Incoming & Make Outgoing Calls" field:`,
        value: [
          {
            value: job.supportsCalls ? "On" : "Off",
            oldValue: oldJob.supportsCalls ? "On" : "Off"
          }
        ]
      });
    }

    if (job.supportsCallCenterCalls !== oldJob.supportsCallCenterCalls) {
      labels.push({
        label: `Updated "Accept Incoming Calls (Call Center Only)" field:`,
        value: [
          {
            value: job.supportsCallCenterCalls ? "On" : "Off",
            oldValue: oldJob.supportsCallCenterCalls ? "On" : "Off"
          }
        ]
      });
    }

    if (JSON.stringify(job.description) !== JSON.stringify(oldJob.description))
      labels.push({
        label: `Updated the "Description" field:`,
        value: [
          {
            value: job.description || "",
            oldValue: oldJob.description || "",
            isRickText: true
          }
        ]
      });

    return labels;
  };

  return (
    <>
      {recordType === "UPDATE" ? (
        <AuditLogLabelValueList
          recordType="UPDATE"
          labelValues={[...getUpdateLabels()]}
        />
      ) : (
        <AuditLogLabelValueList
          recordType={recordType}
          labelValues={[...getCreateLabels()]}
        />
      )}
    </>
  );
};
