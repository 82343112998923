import {
  Cell,
  Compatible,
  Uncertain,
  getCellProperty,
  CellTemplate,
  UncertainCompatible
} from "@silevis/reactgrid";
import React from "react";

export interface DateTimeCell extends Cell {
  type: "datetime";
  date: Date;
  key: string;
  startDate: string;
}

export class DateTimeTemplate implements CellTemplate<DateTimeCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<DateTimeCell>
  ): Compatible<DateTimeCell> {
    const date = getCellProperty(uncertainCell, "date", "object");
    const key = getCellProperty(uncertainCell, "key", "string");
    const startDate = getCellProperty(uncertainCell, "startDate", "string");

    return {
      ...uncertainCell,
      date,
      key,
      startDate,
      value: date.getTime()
    };
  }

  update(
    cell: Compatible<DateTimeCell>,
    cellToMerge: UncertainCompatible<DateTimeCell>
  ): Compatible<DateTimeCell> {
    return this.getCompatibleCell({ ...cell, ...cellToMerge });
  }

  render(
    cell: Compatible<DateTimeCell>,
    _isInEditMode: boolean,
    onCellChanged: (cell: Compatible<DateTimeCell>, commit: boolean) => void
  ): React.ReactNode {
    const formatDateTime = (date: Date) => date.toISOString().slice(0, 16); // Format YYYY-MM-DDTHH:mm
    const formatToLocalDateTime = (utcDateString: string): string => {
      const date = new Date(utcDateString);

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        console.warn("Invalid date detected, using today's date instead.");
        return formatDateTime(new Date()); // Return today's date if invalid
      }

      const offsetMinutes = date.getTimezoneOffset();
      const localDate = new Date(date.getTime() - offsetMinutes * 60000);

      return localDate.toISOString().slice(0, 16);
    };

    const formatToLocalEndOfDay = (utcDateString: string): string => {
      const date = new Date(utcDateString);

      if (isNaN(date.getTime())) {
        console.warn("Invalid date detected, using today's date instead.");
        return formatDateTime(new Date()); // Return today's date if invalid
      }

      const offsetMinutes = date.getTimezoneOffset();
      const localDate = new Date(date.getTime() - offsetMinutes * 60000);

      // Set the time to 23:59
      localDate.setHours(23, 59, 0, 0);

      return localDate.toISOString().slice(0, 16);
    };

    const now = new Date();
    const minDateTime = formatDateTime(now);
    const localStartDate = formatToLocalDateTime(cell.startDate);
    const localEndOfDay = formatToLocalEndOfDay(cell.startDate);

    return (
      <input
        type="datetime-local"
        defaultValue={formatDateTime(cell.date)}
        min={cell.key === "start" ? minDateTime : localStartDate}
        max={cell.key === "end" ? localEndOfDay : undefined}
        onChange={(e) => {
          const selectedDate = new Date(e.target.value);
          if (selectedDate >= now) {
            onCellChanged(
              {
                ...cell,
                date: selectedDate
              },
              true
            );
          } else {
            e.target.value = minDateTime;
          }
        }}
        onBlur={() => {
          onCellChanged(cell, true);
        }}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          outline: "none",
          font: "inherit",
          background: "transparent"
        }}
        autoFocus
      />
    );
  }
}
