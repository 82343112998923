import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { ModelHelpCategory } from "@sportsgravyengineering/sg-api-react-sdk";

export const HelpCategoriesAuditLogCreateDelete = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "DELETE";
  before: object | ModelHelpCategory;
  after: object | ModelHelpCategory;
}) => {
  const categoryDetails = (
    recordType === "CREATE" ? after : before
  ) as ModelHelpCategory;

  const getCategoryDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    labelValues.push({
      label: `"Name" field:`,
      value: [
        {
          value: categoryDetails?.name || ""
        }
      ]
    });
    return labelValues;
  };

  return (
    <AuditLogLabelValueList
      recordType={recordType}
      labelValues={[...getCategoryDetails()]}
    />
  );
};
