import { ModelStream } from "@sportsgravyengineering/sg-api-react-sdk";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "../types";
import { formatDateForDisplay, formatTimeTo12h } from "@utils/formatDate";

export const LivestreamAuditLog = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelStream;
  after: object | ModelStream;
}) => {
  const livestream = (recordType !== "DELETE" ? after : before) as ModelStream;
  const oldLivestream = before as ModelStream;

  const getCreateLabels = () => {
    const labels: AuditLogLabelValue[] = [];
    labels.push({
      label: `"Thumbnail" field:`,
      value: [
        {
          value: livestream.thumbnail || "",
          isMedia: true
        }
      ]
    });

    if (
      !["TRAINING_SESSION", "PRACTICE", undefined].includes(
        livestream.contestType
      )
    )
      labels.push({
        label: `"Opposing ${livestream.contestType === "EVENT" ? "Organization" : "Team"}" field:`,
        value: [
          {
            value: livestream.opponent || ""
          }
        ]
      });

    labels.push(
      {
        label: `"Home Team" field:`,
        value: [
          {
            value: livestream.isHomeTeam ? "On" : "Off"
          }
        ]
      },
      {
        label: `"Title" field:`,
        value: [
          {
            value: livestream.title || ""
          }
        ]
      },
      {
        label: `"Sport" field:`,
        value: [
          {
            value: livestream.sport?.name || ""
          }
        ]
      },
      {
        label: `"Make available to the Public" field:`,
        value: [
          {
            value: livestream.isPublic ? "On" : "Off"
          }
        ]
      },
      {
        label: `"Event Date" field:`,
        value: [
          {
            value: livestream.scheduledAt
              ? formatDateForDisplay(new Date(livestream.scheduledAt))
              : ""
          }
        ]
      },
      {
        label: `"Start Time" field:`,
        value: [
          {
            value: livestream.scheduledAt
              ? formatTimeTo12h(new Date(livestream.scheduledAt))
              : ""
          }
        ]
      },
      ...(livestream.scheduledEndAt
        ? [
            {
              label: `"End Time" field:`,
              value: [
                {
                  value: livestream.scheduledEndAt
                    ? formatTimeTo12h(new Date(livestream.scheduledEndAt))
                    : ""
                }
              ]
            }
          ]
        : []),
      {
        label: `"Event Time Zone" field:`,
        value: [
          {
            value: livestream.timeZone || ""
          }
        ]
      },
      {
        label: `"Schedule Pre Game" field:`,
        value: [
          {
            value: livestream.preEventAt ? "On" : "Off"
          }
        ]
      }
    );

    if (livestream.preEventAt) {
      labels.push(
        {
          label: `Schedule Pre Game "Start Time" field:`,
          value: [
            {
              value: livestream.preEventAt
                ? formatTimeTo12h(new Date(livestream.preEventAt))
                : ""
            }
          ]
        },
        {
          label: `Schedule Pre Game "End Time" field:`,
          value: [
            {
              value: livestream.preEventEndAt
                ? formatTimeTo12h(new Date(livestream.preEventEndAt))
                : ""
            }
          ]
        }
      );
    }

    labels.push({
      label: `"Schedule Post Game" field:`,
      value: [
        {
          value: livestream.postEventAt ? "On" : "Off"
        }
      ]
    });

    if (livestream.postEventAt) {
      labels.push(
        {
          label: `Schedule Post Game "Start Time" field:`,
          value: [
            {
              value: formatTimeTo12h(new Date(livestream.postEventAt)) || ""
            }
          ]
        },
        {
          label: `Schedule Post Game "End Time" field:`,
          value: [
            {
              value: livestream.postEventEndAt
                ? formatTimeTo12h(new Date(livestream.postEventEndAt))
                : ""
            }
          ]
        }
      );
    }

    if (livestream.tags?.length)
      labels.push({
        label: `"Tags" field:`,
        value: [
          {
            value: livestream.tags?.map((t) => t).join(", ") || ""
          }
        ]
      });

    labels.push({
      label: `"Location" field:`,
      value: [
        {
          value: livestream.sportLocationId
            ? (livestream.sportLocation?.name ?? "")
            : (livestream.location ?? "")
        }
      ]
    });

    if (livestream.description)
      labels.push({
        label: `"Description" field:`,
        value: [
          {
            value: livestream.description || ""
          }
        ]
      });

    return labels;
  };

  const getUpdateLabels = () => {
    const labels: AuditLogLabelValue[] = [];
    if (oldLivestream.thumbnailId !== livestream.thumbnailId)
      labels.push({
        label: `Updated the "Thumbnail" field:`,
        value: [
          {
            oldValue: oldLivestream.thumbnail || "N/A",
            value: livestream.thumbnail || "N/A",
            isMedia: true
          }
        ]
      });

    if (
      !["TRAINING_SESSION", "PRACTICE", undefined].includes(
        livestream.contestType
      ) &&
      oldLivestream.opponent !== livestream.opponent
    )
      labels.push({
        label: `Updated the "Opposing ${livestream.contestType === "EVENT" ? "Organization" : "Team"}" field:`,
        value: [
          {
            value: livestream.opponent || "N/A",
            oldValue: oldLivestream.opponent || "N/A"
          }
        ]
      });

    if (oldLivestream.isHomeTeam !== livestream.isHomeTeam)
      labels.push({
        label: `Updated the "Home Team" field:`,
        value: [
          {
            oldValue: oldLivestream.isHomeTeam ? "On" : "Off",
            value: livestream.isHomeTeam ? "On" : "Off"
          }
        ]
      });

    if (oldLivestream.title !== livestream.title)
      labels.push({
        label: `Updated the "Title" field:`,
        value: [
          {
            oldValue: oldLivestream.title || "N/A",
            value: livestream.title || "N/A"
          }
        ]
      });

    if (oldLivestream.sportId !== livestream.sportId)
      labels.push({
        label: `Updated the "Sport" field:`,
        value: [
          {
            oldValue: oldLivestream.sport?.name || "N/A",
            value: livestream.sport?.name || "N/A"
          }
        ]
      });

    if (oldLivestream.isPublic !== livestream.isPublic)
      labels.push({
        label: `Updated the "Make available to the Public" field:`,
        value: [
          {
            oldValue: oldLivestream.isPublic ? "On" : "Off",
            value: livestream.isPublic ? "On" : "Off"
          }
        ]
      });

    if (oldLivestream.scheduledAt !== livestream.scheduledAt)
      labels.push({
        label: `Updated the "Event Date" field:`,
        value: [
          {
            oldValue: oldLivestream.scheduledAt
              ? formatDateForDisplay(new Date(oldLivestream.scheduledAt))
              : "N/A",
            value: livestream.scheduledAt
              ? formatDateForDisplay(new Date(livestream.scheduledAt))
              : "N/A"
          }
        ]
      });

    if (oldLivestream.scheduledAt !== livestream.scheduledAt)
      labels.push({
        label: `Updated the "Start Time" field:`,
        value: [
          {
            oldValue: oldLivestream.scheduledAt
              ? formatTimeTo12h(new Date(oldLivestream.scheduledAt))
              : "N/A",
            value: livestream.scheduledAt
              ? formatTimeTo12h(new Date(livestream.scheduledAt))
              : "N/A"
          }
        ]
      });

    if (oldLivestream.scheduledEndAt !== livestream.scheduledEndAt)
      labels.push({
        label: `Updated the "End Time" field:`,
        value: [
          {
            oldValue: oldLivestream.scheduledEndAt
              ? formatTimeTo12h(new Date(oldLivestream.scheduledEndAt))
              : "N/A",
            value: livestream.scheduledEndAt
              ? formatTimeTo12h(new Date(livestream.scheduledEndAt))
              : "N/A"
          }
        ]
      });

    if (oldLivestream.timeZone !== livestream.timeZone)
      labels.push({
        label: `Updated the "Event Time Zone" field:`,
        value: [
          {
            oldValue: oldLivestream.timeZone || "N/A",
            value: livestream.timeZone || "N/A"
          }
        ]
      });

    if (oldLivestream.preEventAt !== livestream.preEventAt)
      labels.push({
        label: `Updated the "Schedule Pre Game" field:`,
        value: [
          {
            oldValue: oldLivestream.preEventAt ? "On" : "Off",
            value: livestream.preEventAt ? "On" : "Off"
          }
        ]
      });

    if (oldLivestream.preEventAt !== livestream.preEventAt)
      labels.push({
        label: `Updated the "Schedule Pre Game Start Time" field:`,
        value: [
          {
            oldValue: oldLivestream.preEventAt
              ? formatTimeTo12h(new Date(oldLivestream.preEventAt))
              : "N/A",
            value: livestream.preEventAt
              ? formatTimeTo12h(new Date(livestream.preEventAt))
              : "N/A"
          }
        ]
      });

    if (oldLivestream.preEventEndAt !== livestream.preEventEndAt)
      labels.push({
        label: `Updated the "Schedule Pre Game End Time" field:`,
        value: [
          {
            oldValue: oldLivestream.preEventEndAt
              ? formatTimeTo12h(new Date(oldLivestream.preEventEndAt))
              : "N/A",
            value: livestream.preEventEndAt
              ? formatTimeTo12h(new Date(livestream.preEventEndAt))
              : "N/A"
          }
        ]
      });

    if (oldLivestream.postEventAt !== livestream.postEventAt)
      labels.push({
        label: `Updated the "Schedule Post Game" field:`,
        value: [
          {
            oldValue: oldLivestream.postEventAt ? "On" : "Off",
            value: livestream.postEventAt ? "On" : "Off"
          }
        ]
      });

    if (oldLivestream.postEventAt !== livestream.postEventAt)
      labels.push({
        label: `Updated the "Schedule Post Game Start Time" field:`,
        value: [
          {
            oldValue: oldLivestream.postEventAt
              ? formatTimeTo12h(new Date(oldLivestream.postEventAt))
              : "N/A",
            value: livestream.postEventAt
              ? formatTimeTo12h(new Date(livestream.postEventAt))
              : "N/A"
          }
        ]
      });

    if (oldLivestream.postEventEndAt !== livestream.postEventEndAt)
      labels.push({
        label: `Updated the "Schedule Post Game End Time" field:`,
        value: [
          {
            oldValue: oldLivestream.postEventEndAt
              ? formatTimeTo12h(new Date(oldLivestream.postEventEndAt))
              : "N/A",
            value: livestream.postEventEndAt
              ? formatTimeTo12h(new Date(livestream.postEventEndAt))
              : "N/A"
          }
        ]
      });

    const oldTagSet = new Set();
    const tagSet = new Set();
    oldLivestream.tags?.forEach((t) => oldTagSet.add(t));
    livestream.tags?.forEach((t) => tagSet.add(t));

    const hasTagsChanged =
      tagSet.size !== oldTagSet.size ||
      [...tagSet].some((id) => !oldTagSet.has(id));

    if (hasTagsChanged)
      labels.push({
        label: `Updated the "Tags" field:`,
        value: [
          {
            oldValue: oldLivestream.tags?.length
              ? oldLivestream.tags?.map((t) => t).join(", ")
              : "N/A",
            value: livestream.tags?.length
              ? livestream.tags?.map((t) => t).join(", ")
              : "N/A"
          }
        ]
      });

    if (
      oldLivestream.sportLocationId !== livestream.sportLocationId ||
      oldLivestream.location !== livestream.location
    )
      labels.push({
        label: `Updated the "Location" field:`,
        value: [
          {
            oldValue: oldLivestream.sportLocationId
              ? (oldLivestream.sportLocation?.name ?? "")
              : (oldLivestream.location ?? ""),
            value: livestream.sportLocationId
              ? (livestream.sportLocation?.name ?? "")
              : (livestream.location ?? "")
          }
        ]
      });
    return labels;
  };

  if (recordType === "UPDATE")
    return (
      <AuditLogLabelValueList
        recordType="UPDATE"
        labelValues={[...getUpdateLabels()]}
      />
    );
  else
    return (
      <AuditLogLabelValueList
        recordType={recordType}
        labelValues={[...getCreateLabels()]}
      />
    );
};
