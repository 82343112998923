import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { ModelImportTemplateProvider } from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";

export const ImportTemplateProviderAuditLogCreateDelete = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelImportTemplateProvider;
  after: object | ModelImportTemplateProvider;
}) => {
  const templateDetails = (
    recordType === "CREATE" ? after : before
  ) as ModelImportTemplateProvider;
  const getTemplateDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    labelValues.push({
      label: `"Name" field:`,
      value: [
        {
          value: templateDetails?.name || ""
        }
      ]
    });
    labelValues.push({
      label: `"ERP Type" field:`,
      value: [
        {
          value: templateDetails?.erpType || ""
        }
      ]
    });
    labelValues.push({
      label: `"Description" field:`,
      value: [
        {
          value: templateDetails?.description || ""
        }
      ]
    });
    return labelValues;
  };
  return (
    <AuditLogLabelValueList
      recordType={recordType}
      labelValues={[...getTemplateDetails()]}
    />
  );
};
