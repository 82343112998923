/* eslint-disable @typescript-eslint/no-unused-vars */
import { ModelSport } from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect } from "react";
import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";

export const SportsAuditLogUpdate = ({
  before,
  after
}: {
  before: object | ModelSport;
  after: object | ModelSport;
}) => {
  const currentSport = after as ModelSport;
  const oldSport = before as ModelSport;

  const getSportDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    if (currentSport.icon !== oldSport.icon) {
      labelValues.push({
        label: `Updated the "Icon" field:`,
        value: [
          {
            value: currentSport?.icon || "",
            oldValue: currentSport?.icon || "",
            isMedia: true
          }
        ]
      });
    }

    if (currentSport.name !== oldSport.name) {
      labelValues.push({
        label: `Updated the "Name" field:`,
        value: [
          {
            value: currentSport?.name || "",
            oldValue: currentSport?.name || ""
          }
        ]
      });
    }
    if (currentSport.portraitImage !== oldSport.portraitImage) {
      labelValues.push({
        label: `Updated the "Default Portrait Image" field:`,
        value: [
          {
            value: currentSport?.portraitImage || "",
            oldValue: currentSport?.portraitImage || "",
            isMedia: true
          }
        ]
      });
    }
    if (currentSport.landscapeImage !== oldSport.landscapeImage) {
      labelValues.push({
        label: `Updated the "Default Landscape Image" field:`,
        value: [
          {
            value: currentSport?.landscapeImage || "",
            oldValue: currentSport?.landscapeImage || "",
            isMedia: true
          }
        ]
      });
    }
    if (currentSport.isIntervalBased !== oldSport.isIntervalBased) {
      labelValues.push({
        label: `Updated the "Sport has Intervals" field:`,
        value: [
          {
            value: currentSport?.isIntervalBased ? "On" : "Off",
            oldValue: oldSport?.isIntervalBased ? "On" : "Off"
          }
        ]
      });
    }
    if (currentSport.intervalSingular !== oldSport.intervalSingular) {
      labelValues.push({
        label: `Updated the "Singular Interval" field:`,
        value: [
          {
            value: currentSport?.intervalSingular || "",
            oldValue: oldSport?.intervalSingular || ""
          }
        ]
      });
    }
    if (currentSport.intervalPlural !== oldSport.intervalPlural) {
      labelValues.push({
        label: `Updated the "Plural Interval" field:`,
        value: [
          {
            value: currentSport?.intervalPlural || "",
            oldValue: oldSport?.intervalPlural || ""
          }
        ]
      });
    }
    if (currentSport.intervalAbbreviation !== oldSport.intervalAbbreviation) {
      labelValues.push({
        label: `Updated the "Interval Abbreviation" field:`,
        value: [
          {
            value: currentSport?.intervalAbbreviation || "",
            oldValue: oldSport?.intervalAbbreviation || ""
          }
        ]
      });
    }
    if (
      (currentSport.scoringOptions?.length ?? 0) !==
      (oldSport.scoringOptions?.length ?? 0)
    ) {
      labelValues.push({
        label: `Updated the "Allow Scoring" field:`,
        value: [
          {
            value: currentSport?.scoringOptions?.length === 1 ? "On" : "Off",
            oldValue: oldSport?.scoringOptions?.length === 1 ? "On" : "Off"
          }
        ]
      });
    }
    if (
      (currentSport.scoringOptions?.length ?? 0) !==
      (oldSport.scoringOptions?.length ?? 0)
    ) {
      labelValues.push({
        label: `Updated the "Allow Multiple Scoring Options" field:`,
        value: [
          {
            value:
              (currentSport?.scoringOptions?.length ?? 0) > 1 ? "On" : "Off",
            oldValue: (oldSport?.scoringOptions?.length ?? 0) > 1 ? "On" : "Off"
          }
        ]
      });
    }

    currentSport?.scoringOptions?.forEach((option, index) => {
      if (option?.name !== oldSport?.scoringOptions?.[index]?.name) {
        labelValues.push({
          label: `Updated the Score ${index + 1} "Scoring Option" field:`,
          value: [
            {
              value: option?.name || "",
              oldValue: oldSport?.scoringOptions?.[index]?.name || ""
            }
          ]
        });
      }
      if (
        option?.value?.join(", ") !==
        oldSport?.scoringOptions?.[index]?.value?.join(", ")
      ) {
        labelValues.push({
          label: `Updated the Score ${index + 1} "Scoring Values" field:`,
          value: [
            {
              value: option?.value?.join(", ") || "",
              oldValue:
                oldSport?.scoringOptions?.[index]?.value?.join(", ") || ""
            }
          ]
        });
      }
    });

    currentSport?.countries?.forEach((option) => {
      const oldOption = oldSport?.countries?.find(
        (o) => o.name === option.name
      );
      if (option?.isMatch !== oldOption?.isMatch) {
        labelValues.push({
          label: `Updated the Participating Countries "${option.name}" field:`,
          value: [
            {
              value: option?.isMatch ? "On" : "Off",
              oldValue: oldOption?.isMatch ? "On" : "Off"
            }
          ]
        });
      }
      //TODO: need to update this with image handler
      if (option?.isMatch !== oldOption?.isMatch) {
        labelValues.push({
          label: `Updated the Participating Countries ${option.name} "Game Type" field:`,
          value: [
            {
              value: option?.isMatch ? "On" : "Off",
              oldValue: oldOption?.isMatch ? "On" : "Off"
            }
          ]
        });
      }
    });

    return labelValues;
  };
  return (
    <AuditLogLabelValueList
      recordType="UPDATE"
      labelValues={[...getSportDetails()]}
    />
  );
};
