import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { ModelHelpArticle } from "@sportsgravyengineering/sg-api-react-sdk";

export const HelpArticlesAuditLogCreateDelete = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "DELETE";
  before: object | ModelHelpArticle;
  after: object | ModelHelpArticle;
}) => {
  const articleDetails = (
    recordType === "CREATE" ? after : before
  ) as ModelHelpArticle;

  const getArticleDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    labelValues.push({
      label: `"Headline" field:`,
      value: [
        {
          value: articleDetails?.headline || ""
        }
      ]
    });
    //TODO: do not understand what this is supposed to be
    labelValues.push({
      label: `"Article" field:`,
      value: [
        {
          value: articleDetails?.headline || ""
        }
      ]
    });
    labelValues.push({
      label: `"Category" field:`,
      value: [
        {
          value: articleDetails?.helpCategory?.name || ""
        }
      ]
    });
    labelValues.push({
      label: `"Related To" field:`,
      value: [
        {
          value: articleDetails?.relatedTo || ""
        }
      ]
    });
    labelValues.push({
      label: `"Display Channel" field:`,
      value: [
        {
          value: articleDetails?.displayChannel?.join(", ") || ""
        }
      ]
    });
    return labelValues;
  };

  return (
    <AuditLogLabelValueList
      recordType={recordType}
      labelValues={[...getArticleDetails()]}
    />
  );
};
