import { Container } from "@components/crud/Container";
import { Button, ButtonGroup } from "@mui/material";
import { ModelOrganization } from "@sportsgravyengineering/sg-api-react-sdk";
import { formatPhoneWithCountryCode } from "@utils/phoneFormatters";
import { useState } from "react";
import { Tabs } from "./OrganizationDetailsAuditLogCreateDelete";
import { capitalize } from "@utils/capitalize";
import {
  ORGANIZATION_OFFERINGS_OPTIONS,
  ORGANIZATION_TYPE_OPTIONS
} from "@utils/constants";
import formatFullName from "@utils/formatFullName";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";

export const OrganizationDetailsAuditLogUpdate = ({
  before,
  after
}: {
  before: object | ModelOrganization;
  after: object | ModelOrganization;
}) => {
  const tabs = ["Organization Details", "Sports Details", "Contact Details"];
  const [tab, setTab] = useState("Organization Details");
  const details = after as ModelOrganization;
  const oldDetails = before as ModelOrganization;

  const genOfficeAddress = (org: ModelOrganization) => {
    if (!org) return "";
    const { lines, country, locality, province, postalCode } = org;
    return `${lines?.[0]}|${lines?.[1] || ""}|${locality}|${
      province
    }|${postalCode}|${country}`;
  };

  const getSportsOffered = (orgDetails: ModelOrganization): string => {
    if (!orgDetails?.sports || orgDetails.sports.length === 0) return "";

    return orgDetails.sports
      .sort((a, b) =>
        (a.sport?.name as string).localeCompare(b.sport?.name as string)
      )
      .map((sport) => sport?.sport?.name)
      .join(", ");
  };

  const getOrganizationDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    if (details?.avatarId !== oldDetails?.avatarId) {
      labelValues.push({
        label: `Updated the "Avatar" field:`,
        value: [
          {
            value: details?.avatar || "N/A",
            oldValue: oldDetails?.avatar || "N/A",
            isMedia: true
          }
        ]
      });
    }
    if (details?.name !== oldDetails?.name) {
      labelValues.push({
        label: `Updated the "Name" field:`,
        value: [
          {
            value: details?.name || "N/A",
            oldValue: oldDetails?.name || "N/A"
          }
        ]
      });
    }
    if (details?.abbreviation !== oldDetails?.abbreviation) {
      labelValues.push({
        label: `Updated the "Abbreviation" field:`,
        value: [
          {
            value: details?.abbreviation || "N/A",
            oldValue: oldDetails?.abbreviation || "N/A"
          }
        ]
      });
    }
    if (details?.type !== oldDetails?.type) {
      labelValues.push({
        label: `Updated the "Type" field:`,
        value: [
          {
            value:
              ORGANIZATION_TYPE_OPTIONS.find(
                (option) => option.value === details?.type
              )?.label || "N/A",
            oldValue:
              ORGANIZATION_TYPE_OPTIONS.find(
                (option) => option.value === oldDetails?.type
              )?.label || "N/A"
          }
        ]
      });
    }
    if (details?.offering !== oldDetails?.offering) {
      labelValues.push({
        label: `Updated the "Offering" field:`,
        value: [
          {
            value:
              ORGANIZATION_OFFERINGS_OPTIONS.find(
                (option) => option.value === details?.offering
              )?.label || "N/A",
            oldValue:
              ORGANIZATION_OFFERINGS_OPTIONS.find(
                (option) => option.value === oldDetails?.offering
              )?.label || "N/A"
          }
        ]
      });
    }
    if (details?.phone !== oldDetails?.phone) {
      labelValues.push({
        label: `Updated the "Phone" field:`,
        value: [
          {
            value: formatPhoneWithCountryCode(details?.phone),
            oldValue: formatPhoneWithCountryCode(oldDetails?.phone)
          }
        ]
      });
    }
    if (details?.email !== oldDetails?.email) {
      labelValues.push({
        label: `Updated the "Email" field:`,
        value: [
          {
            value: details?.email || "N/A",
            oldValue: oldDetails?.email || "N/A"
          }
        ]
      });
    }
    if (details?.website !== oldDetails?.website) {
      labelValues.push({
        label: `Updated the "Website" field:`,
        value: [
          {
            value: details?.website || "N/A",
            oldValue: oldDetails?.website || "N/A"
          }
        ]
      });
    }
    if (
      details.importTemplateProvider?.providerId !==
      oldDetails.importTemplateProvider?.providerId
    ) {
      labelValues.push({
        label: `Updated the "Upload Athletes/Players Template Provider" field:`,
        value: [
          {
            value: details.importTemplateProvider?.name || "N/A",
            oldValue: oldDetails.importTemplateProvider?.name || "N/A"
          }
        ]
      });
    }
    if (
      details.importTemplateVersion?.versionId !==
      oldDetails.importTemplateVersion?.versionId
    ) {
      labelValues.push({
        label: `Updated the "Upload Athletes/Players Template Provider Version" field:`,
        value: [
          {
            value: details.importTemplateVersion?.version || "N/A",
            oldValue: oldDetails.importTemplateVersion?.version || "N/A"
          }
        ]
      });
    }
    if (details?.country !== oldDetails?.country) {
      labelValues.push({
        label: `Updated the Office Address "Country" field:`,
        value: [
          {
            value: details?.country || "N/A",
            oldValue: oldDetails?.country || "N/A"
          }
        ]
      });
    }
    if (details?.lines?.[0] !== oldDetails?.lines?.[0]) {
      labelValues.push({
        label: `Updated the Office Address "Address Line 1" field:`,
        value: [
          {
            value: details?.lines?.[0] || "N/A",
            oldValue: oldDetails?.lines?.[0] || "N/A"
          }
        ]
      });
    }
    if (details?.lines?.[1] !== oldDetails?.lines?.[1]) {
      labelValues.push({
        label: `Updated the Office Address "Address Line 2" field:`,
        value: [
          {
            value: details?.lines?.[1] || "N/A",
            oldValue: oldDetails?.lines?.[1] || "N/A"
          }
        ]
      });
    }
    if (details?.locality !== oldDetails?.locality) {
      labelValues.push({
        label: `Updated the Office Address "City" field:`,
        value: [
          {
            value: details?.locality || "N/A",
            oldValue: oldDetails?.locality || "N/A"
          }
        ]
      });
    }
    if (details?.province !== oldDetails?.province)
      labelValues.push({
        label: `Updated Office Address "State/Providence" field:`,
        value: [
          {
            value: details?.province || "N/A",
            oldValue: oldDetails?.province || "N/A"
          }
        ]
      });
    if (details?.postalCode !== oldDetails?.postalCode)
      labelValues.push({
        label: `Updated Office Address "Zip Code" field:`,
        value: [
          {
            value: details?.postalCode || "N/A",
            oldValue: oldDetails?.postalCode || "N/A"
          }
        ]
      });

    if (details?.billingAddress !== oldDetails?.billingAddress) {
      const isNewSameAsOffice =
        details?.billingAddress === genOfficeAddress(details);
      const isOldSameAsOffice =
        oldDetails?.billingAddress === genOfficeAddress(oldDetails);

      if (isNewSameAsOffice !== isOldSameAsOffice) {
        labelValues.push({
          label: `Updated the "Billing Address is the same as Office Address" field:`,
          value: [
            {
              value: isNewSameAsOffice ? "On" : "Off",
              oldValue: isOldSameAsOffice ? "On" : "Off"
            }
          ]
        });
      }
      const addressComponents = {
        country: details?.billingAddress?.split("|")[5],
        addressLine1: details?.billingAddress?.split("|")[0],
        addressLine2: details?.billingAddress?.split("|")[1],
        city: details?.billingAddress?.split("|")[2],
        state: details?.billingAddress?.split("|")[3],
        zipCode: details?.billingAddress?.split("|")[4]
      };
      const oldAddressComponents = {
        country: oldDetails?.billingAddress?.split("|")[5],
        addressLine1: oldDetails?.billingAddress?.split("|")[0],
        addressLine2: oldDetails?.billingAddress?.split("|")[1],
        city: oldDetails?.billingAddress?.split("|")[2],
        state: oldDetails?.billingAddress?.split("|")[3],
        zipCode: oldDetails?.billingAddress?.split("|")[4]
      };
      labelValues.push(
        ...(addressComponents.country !== oldAddressComponents.country
          ? [
              {
                label: `Updated the Billing Address "Country" field:`,
                value: [
                  {
                    value: addressComponents.country || "N/A",
                    oldValue: oldAddressComponents.country || "N/A"
                  }
                ]
              }
            ]
          : []),
        ...(addressComponents.addressLine1 !== oldAddressComponents.addressLine1
          ? [
              {
                label: `Updated the Billing Address "Address Line 1" field:`,
                value: [
                  {
                    value: addressComponents.addressLine1 || "N/A",
                    oldValue: oldAddressComponents.addressLine1 || "N/A"
                  }
                ]
              }
            ]
          : []),
        ...(addressComponents.addressLine2 !== oldAddressComponents.addressLine2
          ? [
              {
                label: `Updated the Billing Address "Address Line 2" field:`,
                value: [
                  {
                    value: addressComponents.addressLine2 || "N/A",
                    oldValue: oldAddressComponents.addressLine2 || "N/A"
                  }
                ]
              }
            ]
          : []),
        ...(addressComponents.city !== oldAddressComponents.city
          ? [
              {
                label: `Updated the Billing Address "City" field:`,
                value: [
                  {
                    value: addressComponents.city || "N/A",
                    oldValue: oldAddressComponents.city || "N/A"
                  }
                ]
              }
            ]
          : []),
        ...(addressComponents.state !== oldAddressComponents.state
          ? [
              {
                label: `Updated the Billing Address "State/Province" field:`,
                value: [
                  {
                    value: addressComponents.city || "N/A",
                    oldValue: oldAddressComponents.city || "N/A"
                  }
                ]
              }
            ]
          : []),
        ...(addressComponents.zipCode !== oldAddressComponents.zipCode
          ? [
              {
                label: `Updated the Billing Address "Zip Code" field:`,
                value: [
                  {
                    value: addressComponents.zipCode || "N/A",
                    oldValue: oldAddressComponents.zipCode || "N/A"
                  }
                ]
              }
            ]
          : [])
      );
    }

    if (details?.shippingAddress !== oldDetails?.shippingAddress) {
      const isNewSameAsOffice =
        details?.shippingAddress === genOfficeAddress(details);
      const isOldSameAsOffice =
        oldDetails?.shippingAddress === genOfficeAddress(oldDetails);

      if (isNewSameAsOffice !== isOldSameAsOffice)
        labelValues.push({
          label: `Updated the "Shipping Address is the same as Office Address" field:`,
          value: [
            {
              value: isNewSameAsOffice ? "On" : "Off",
              oldValue: isOldSameAsOffice ? "On" : "Off"
            }
          ]
        });
      const addressComponents = {
        country: details?.shippingAddress?.split("|")[5],
        addressLine1: details?.shippingAddress?.split("|")[0],
        addressLine2: details?.shippingAddress?.split("|")[1],
        city: details?.shippingAddress?.split("|")[2],
        state: details?.shippingAddress?.split("|")[3],
        zipCode: details?.shippingAddress?.split("|")[4]
      };
      const oldAddressComponents = {
        country: oldDetails?.shippingAddress?.split("|")[5],
        addressLine1: oldDetails?.shippingAddress?.split("|")[0],
        addressLine2: oldDetails?.shippingAddress?.split("|")[1],
        city: oldDetails?.shippingAddress?.split("|")[2],
        state: oldDetails?.shippingAddress?.split("|")[3],
        zipCode: oldDetails?.shippingAddress?.split("|")[4]
      };
      labelValues.push(
        ...(addressComponents.country !== oldAddressComponents.country
          ? [
              {
                label: `Updated the Shipping Address "Country" field:`,
                value: [
                  {
                    value: addressComponents.country || "N/A",
                    oldValue: oldAddressComponents.country || "N/A"
                  }
                ]
              }
            ]
          : []),
        ...(addressComponents.addressLine1 !== oldAddressComponents.addressLine1
          ? [
              {
                label: `Updated the Shipping Address "Address Line 1" field:`,
                value: [
                  {
                    value: addressComponents.addressLine1 || "N/A",
                    oldValue: oldAddressComponents.addressLine1 || "N/A"
                  }
                ]
              }
            ]
          : []),
        ...(addressComponents.addressLine2 !== oldAddressComponents.addressLine2
          ? [
              {
                label: `Updated the Shipping Address "Address Line 2" field:`,
                value: [
                  {
                    value: addressComponents.addressLine2 || "N/A",
                    oldValue: oldAddressComponents.addressLine2 || "N/A"
                  }
                ]
              }
            ]
          : []),
        ...(addressComponents.city !== oldAddressComponents.city
          ? [
              {
                label: `Updated the Shipping Address "City" field:`,
                value: [
                  {
                    value: addressComponents.city || "N/A",
                    oldValue: oldAddressComponents.city || "N/A"
                  }
                ]
              }
            ]
          : []),
        ...(addressComponents.state !== oldAddressComponents.state
          ? [
              {
                label: `Updated the Shipping Address "State/Province" field:`,
                value: [
                  {
                    value: addressComponents.city || "N/A",
                    oldValue: oldAddressComponents.city || "N/A"
                  }
                ]
              }
            ]
          : []),
        ...(addressComponents.zipCode !== oldAddressComponents.zipCode
          ? [
              {
                label: `Updated the Shipping Address "Zip Code" field:`,
                value: [
                  {
                    value: addressComponents.zipCode || "N/A",
                    oldValue: oldAddressComponents.zipCode || "N/A"
                  }
                ]
              }
            ]
          : [])
      );
    }

    if (details?.revenueShare !== oldDetails?.revenueShare) {
      labelValues.push({
        label: `Updated the "Live Streaming Revenue Share" field:`,
        value: [
          {
            value: details?.revenueShare ? `${details?.revenueShare}%` : "N/A",
            oldValue: oldDetails?.revenueShare
              ? `${oldDetails?.revenueShare}%`
              : "N/A"
          }
        ]
      });
    }
    return labelValues;
  };

  const getSportsDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    if (getSportsOffered(details) !== getSportsOffered(oldDetails)) {
      labelValues.push({
        label: `Updated the "Sports Offered" field:`,
        value: [
          {
            value: getSportsOffered(details),
            oldValue: getSportsOffered(oldDetails)
          }
        ]
      });
    }

    labelValues.push(
      ...((details?.governingBodyId && !oldDetails?.governingBodyId) ||
      (!details?.governingBodyId && oldDetails?.governingBodyId)
        ? [
            {
              label: `Updated the "Are you governed by an organization?" field:`,
              value: [
                {
                  value: details?.governingBody ? "On" : "Off",
                  oldValue: oldDetails?.governingBody ? "On" : "Off"
                }
              ]
            }
          ]
        : []),

      ...(details?.governingBody?.name !== oldDetails?.governingBody?.name
        ? [
            {
              label: `Updated the "Who are you governed by?" field:`,
              value: [
                {
                  value: details?.governingBody?.name || "N/A",
                  oldValue: oldDetails?.governingBody?.name || "N/A"
                }
              ]
            }
          ]
        : []),
      ...((details.sports?.[0]?.governingBodyId &&
        !oldDetails.sports?.[0]?.governingBodyId) ||
      (!details.sports?.[0]?.governingBodyId &&
        oldDetails.sports?.[0]?.governingBodyId)
        ? [
            {
              label: `Updated the "Allow governing body by sport?" field:`,
              value: [
                {
                  value: details?.sports?.[0]?.governingBodyId ? "On" : "Off",
                  oldValue: oldDetails?.sports?.[0]?.governingBodyId
                    ? "On"
                    : "Off"
                }
              ]
            }
          ]
        : [])
    );

    details?.sports?.forEach((sport) => {
      const oldSportDetails = oldDetails?.sports?.find(
        (oldSport) => oldSport.sportId === sport.sportId
      );

      const offeredGenders: ("MALE" | "FEMALE")[] =
        sport?.offeredFor?.sort((a, b) => a.localeCompare(b)) || [];
      const oldOfferedGenders =
        oldSportDetails?.offeredFor?.sort((a, b) => a.localeCompare(b)) || [];

      if (offeredGenders.join(", ") !== oldOfferedGenders.join(", ")) {
        labelValues.push({
          label: `Updated ${sport?.sport?.name} "Offered For" field:`,
          value: [
            {
              value:
                offeredGenders.map((g) => capitalize(g)).join(", ") || "N/A",
              oldValue: oldSportDetails
                ? oldOfferedGenders.map((g) => capitalize(g)).join(", ")
                : "N/A"
            }
          ]
        });
      }

      (["MALE", "FEMALE"] as const).forEach((gender) => {
        if (offeredGenders.includes(gender)) {
          const formattedGender = capitalize(gender);

          if (
            gender === "MALE"
              ? sport.maleSingularInterval !==
                oldSportDetails?.maleSingularInterval
              : sport.femaleSingularInterval !==
                oldSportDetails?.femaleSingularInterval
          ) {
            labelValues.push({
              label: `Updated the ${sport?.sport?.name} "${formattedGender} Singular Interval" field:`,
              value: [
                {
                  value:
                    gender === "MALE"
                      ? sport.maleSingularInterval || "N/A"
                      : sport?.femaleSingularInterval || "N/A",
                  oldValue: oldSportDetails
                    ? gender === "MALE"
                      ? oldSportDetails.maleSingularInterval
                      : oldSportDetails.femaleSingularInterval
                    : "N/A"
                }
              ]
            });
          }

          if (
            gender === "MALE"
              ? sport.malePluralInterval !== oldSportDetails?.malePluralInterval
              : sport.femalePluralInterval !==
                oldSportDetails?.femalePluralInterval
          ) {
            labelValues.push({
              label: `Updated the ${sport?.sport?.name} "${formattedGender} Plural Interval" field:`,
              value: [
                {
                  value:
                    gender === "MALE"
                      ? sport.malePluralInterval || "N/A"
                      : sport.femalePluralInterval || "N/A",
                  oldValue: oldSportDetails
                    ? gender === "MALE"
                      ? oldSportDetails.malePluralInterval
                      : oldSportDetails.femalePluralInterval
                    : "N/A"
                }
              ]
            });
          }

          if (
            gender === "MALE"
              ? sport.maleIntervalAbbreviation !==
                oldSportDetails?.maleIntervalAbbreviation
              : sport.femaleIntervalAbbreviation !==
                oldSportDetails?.femaleIntervalAbbreviation
          ) {
            labelValues.push({
              label: `Updated the ${sport?.sport?.name} "${formattedGender} Interval Abbreviation" field:`,
              value: [
                {
                  value:
                    gender === "MALE"
                      ? sport.maleIntervalAbbreviation || "N/A"
                      : sport.femaleIntervalAbbreviation || "N/A",
                  oldValue: oldSportDetails
                    ? gender === "MALE"
                      ? oldSportDetails.maleIntervalAbbreviation
                      : oldSportDetails.femaleIntervalAbbreviation
                    : "N/A"
                }
              ]
            });
          }
        }
      });

      if (sport.governingBody?.name !== oldSportDetails?.governingBody?.name) {
        labelValues.push({
          label: `Updated the ${sport?.sport?.name} "Who are you governed by?" field:`,
          value: [
            {
              value: sport.governingBody?.name || "N/A",
              oldValue: oldSportDetails
                ? oldSportDetails?.governingBody?.name
                : "N/A"
            }
          ]
        });
      }
    });

    return labelValues;
  };

  const getContactDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    if (details?.primaryContactId !== oldDetails?.primaryContactId) {
      labelValues.push({
        label: `Updated the "Primary Contact"`,
        value: [
          {
            value: formatFullName(details?.primaryContact),
            oldValue: formatFullName(oldDetails?.primaryContact)
          }
        ]
      });
    }
    if (details?.secondaryContact) {
      if (
        details?.secondaryContact?.firstName !==
        oldDetails?.secondaryContact?.firstName
      ) {
        labelValues.push({
          label: `Updated the "Secondary Contact"`,
          value: [
            {
              value: `${details?.secondaryContact?.firstName} ${details?.secondaryContact?.lastName}`,
              oldValue: `${oldDetails?.secondaryContact?.firstName} ${oldDetails?.secondaryContact?.lastName}`
            }
          ]
        });
      }
    }

    return labelValues;
  };

  return (
    <Container style={{ marginTop: "10px" }}>
      <ButtonGroup
        variant="outlined"
        style={{ border: "rgba(215, 221, 229, 1)", paddingBottom: "24px" }}
      >
        {tabs.map((t) => {
          return (
            <Button
              key={t}
              variant="outlined"
              style={
                tab === t
                  ? {
                      backgroundColor: "#E8EEFF",
                      color: "#2B337A",
                      fontSize: "14px",
                      fontWeight: 600,
                      textTransform: "none"
                    }
                  : {
                      color: "#666666",
                      fontSize: "14px",
                      fontWeight: 500,
                      textTransform: "none"
                    }
              }
              onClick={() => {
                setTab(t as Tabs);
              }}
            >
              {t}
            </Button>
          );
        })}
      </ButtonGroup>
      {tab === "Organization Details" ? (
        <AuditLogLabelValueList
          key={tab}
          recordType="UPDATE"
          labelValues={[...getOrganizationDetails()]}
        />
      ) : tab === "Sports Details" ? (
        <AuditLogLabelValueList
          key={tab}
          recordType="UPDATE"
          labelValues={[...getSportsDetails()]}
        />
      ) : (
        tab === "Contact Details" && (
          <AuditLogLabelValueList
            key={tab}
            recordType="UPDATE"
            labelValues={[...getContactDetails()]}
          />
        )
      )}
    </Container>
  );
};
