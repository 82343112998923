export const formatDate = (dateObj: Date) => {
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so we add 1 and pad with 0 if necessary
  const day = String(dateObj.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const formatTime = (dateObj: Date) => {
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
  return formattedTime;
};
export const formatDateForDisplay = (dateObj: Date) => {
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so we add 1 and pad with 0 if necessary
  const day = String(dateObj.getDate()).padStart(2, "0");

  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

export const formatTimeTo12h = (dateObj: Date | undefined) => {
  if (!dateObj) return "";
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const formattedHours = hours % 12 || 12;
  const period = hours >= 12 ? "PM" : "AM";
  const formattedTime = `${formattedHours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")} ${period}`;

  return formattedTime;
};

export function convertToAmPm(timeStr: string) {
  const [hourStr, minuteStr] = timeStr.split(":");
  let hour = parseInt(hourStr, 10);
  const minute = minuteStr;
  const ampm = hour >= 12 ? "PM" : "AM";

  hour = hour % 12 || 12;

  return `${hour}:${minute} ${ampm}`;
}
