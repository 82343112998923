import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Form } from "@components/crud/Form";
import Grid from "@mui/material/Unstable_Grid2";
import { organizationAtom } from "@recoil/auth";
import { useAdminSportGet } from "@sportsgravyengineering/sg-api-react-sdk";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useRecoilValue } from "recoil";

export const PresetTagForm = ({ disabled }: { disabled?: boolean }) => {
  const { control } = useFormContext();
  const organizationId = useRecoilValue(organizationAtom);

  const { data: sports, isLoading: isSportsLoading } = useAdminSportGet({
    organizationId
  });

  const sportOptions = useMemo(() => {
    return sports?.data.map((sport) => ({
      label: sport.name as string,
      value: sport.sportId as string
    }));
  }, [sports]);

  return (
    <Form>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <FormInput
            control={control}
            name="name"
            type="text"
            label="Name"
            required={true}
            rules={{
              required: "Name is required"
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid xs={6}>
          <FormSelect
            options={sportOptions || []}
            isLoading={isSportsLoading}
            control={control}
            name="sport"
            label="Sport"
            required={true}
            rules={{
              required: "Sport is required"
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid xs={6}>
          <FormSelect
            options={[
              {
                label: "Active",
                value: "true"
              },
              {
                label: "Inactive",
                value: "false"
              }
            ]}
            control={control}
            name="isActive"
            label="Status"
            required={true}
            rules={{
              required: "Status To is required"
            }}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Form>
  );
};
