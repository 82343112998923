import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { ModelPosition } from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";

export const PositionsAuditLogCreateDelete = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "DELETE";
  before: object | ModelPosition;
  after: object | ModelPosition;
}) => {
  const positionDetails = (
    recordType === "CREATE" ? after : before
  ) as ModelPosition;

  const getPositionDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    labelValues.push({
      label: `"Name" field:`,
      value: [
        {
          value: positionDetails?.name || ""
        }
      ]
    });
    labelValues.push({
      label: `"Abbreviation" field:`,
      value: [
        {
          value: positionDetails?.abbreviation || ""
        }
      ]
    });
    labelValues.push({
      label: `"Sport" field:`,
      value: [
        {
          value: positionDetails?.sport?.name || ""
        }
      ]
    });
    if (positionDetails?.parent)
      labelValues.push({
        label: `"Parent Position" field:`,
        value: [
          {
            value: positionDetails?.parent?.name || ""
          }
        ]
      });
    return labelValues;
  };

  return (
    <AuditLogLabelValueList
      recordType={recordType}
      labelValues={[...getPositionDetails()]}
    />
  );
};
