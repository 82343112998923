import { ModelLead } from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { LeadsAuditLogUpdate } from "./LeadsAuditLogUpdate";
import { LeadsAuditLogCreateDelete } from "./LeadsAuditLogCreateDelete";

export const LeadsAuditLogDetails = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelLead;
  after: object | ModelLead;
}) => {
  if (recordType === "UPDATE")
    return <LeadsAuditLogUpdate before={before} after={after} />;
  else
    return (
      <LeadsAuditLogCreateDelete
        recordType={recordType}
        before={before}
        after={after}
      />
    );
};
