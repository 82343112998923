import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { DeleteIcon } from "@components/Icons";
import { StyledFormLabel } from "@components/StyledFormLabel";
import { Add } from "@mui/icons-material";
import { Button, Grid, InputAdornment } from "@mui/material";
import React, { ChangeEvent, Fragment, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import colors from "theme/colors";

export interface TaxFee {
  name: string;
  type: "TAX" | "FEE";
  calculationMethod: "PERCENTAGE" | "VALUE";
  rate: number;
}

export const LiveStreamTaxFees = ({
  taxFees,
  setTaxFees,
  form,
  disabled
}: {
  taxFees: TaxFee[];
  setTaxFees: (taxFees: TaxFee[]) => void;
  form: UseFormReturn;
  disabled: boolean;
}) => {
  const [toDelete, setToDelete] = useState<
    { index: number; tax: TaxFee } | undefined
  >(undefined);
  const { control, getValues, reset } = form;

  return (
    <Grid container direction="row" columnSpacing={3} rowSpacing={1.5}>
      <Grid
        container
        item
        xs={12}
        style={{
          marginTop: "20px"
        }}
        columnSpacing={3}
      >
        <Grid
          item
          mt="20px"
          xs={6}
          style={{
            paddingTop: "0px"
          }}
        >
          <StyledFormLabel required style={{ fontWeight: 600 }}>
            Label
          </StyledFormLabel>
        </Grid>
        <Grid
          item
          mt="15px"
          xs={taxFees.length <= 1 || disabled ? 1.5 : 1.25}
          style={{
            paddingTop: "0px"
          }}
        >
          <StyledFormLabel required style={{ fontWeight: 600 }}>
            Type
          </StyledFormLabel>
        </Grid>
        <Grid
          item
          mt="20px"
          xs={4}
          style={{
            paddingTop: "0px"
          }}
        >
          <StyledFormLabel required style={{ fontWeight: 600 }}>
            Value
          </StyledFormLabel>
        </Grid>

        <Grid item xs={12} container spacing={3}>
          {taxFees.map((rl, idx) => {
            const foundIdx = idx;
            return (
              <Fragment key={idx}>
                <Grid item xs={6}>
                  <FormInput
                    name={`taxFee[${foundIdx}].label`}
                    label={""}
                    control={control}
                    type="text"
                    onChange={(e) => {
                      const updatedTaxFee = taxFees;
                      updatedTaxFee[foundIdx].name = (
                        e as ChangeEvent<HTMLInputElement>
                      ).target.value;
                      setTaxFees([...updatedTaxFee]);
                    }}
                    disabled={disabled}
                    rules={{
                      required: "Label is required"
                    }}
                  />
                </Grid>
                <Grid item xs={disabled ? 1.5 : 1.25}>
                  <FormSelect
                    control={control}
                    name={`taxFee[${foundIdx}].type`}
                    label=" "
                    options={[
                      {
                        label: "Tax",
                        value: "TAX"
                      },
                      {
                        label: "Fee",
                        value: "FEE"
                      }
                    ]}
                    disabled={disabled}
                    onChange={(e) => {
                      const value = (e as ChangeEvent<HTMLInputElement>).target
                        .value;
                      const updatedTaxFee = taxFees;
                      updatedTaxFee[foundIdx].type = value as "TAX" | "FEE";
                      setTaxFees([...updatedTaxFee]);
                    }}
                    rules={{
                      required: "Type is required"
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormSelect
                    control={control}
                    name={`taxFee[${foundIdx}].calculationMethod`}
                    label=" "
                    options={[
                      {
                        label: "Percentage",
                        value: "PERCENTAGE"
                      },
                      {
                        label: "Currency",
                        value: "VALUE"
                      }
                    ]}
                    disabled={disabled}
                    onChange={(e) => {
                      const value = (e as ChangeEvent<HTMLInputElement>).target
                        .value;
                      const updatedTaxFee = taxFees;
                      updatedTaxFee[foundIdx].calculationMethod = value as
                        | "PERCENTAGE"
                        | "VALUE";
                      setTaxFees([...updatedTaxFee]);
                    }}
                    rules={{
                      required: "Value type is required"
                    }}
                  />
                </Grid>
                <Grid item xs={disabled ? 1.5 : 1.25}>
                  <FormInput
                    control={control}
                    name={`taxFee[${foundIdx}].rate`}
                    label=" "
                    type="text"
                    disabled={disabled}
                    onChange={(e) => {
                      const value = (e as ChangeEvent<HTMLInputElement>).target
                        .value;
                      const updatedTaxFee = taxFees;
                      updatedTaxFee[foundIdx].rate = Number(value);
                      setTaxFees([...updatedTaxFee]);
                    }}
                    rules={{
                      required: "Value is required"
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {rl.calculationMethod === "PERCENTAGE" ? "%" : "$"}
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                {!disabled && taxFees.length > 1 && (
                  <Grid item xs={0.5} padding={0} height="fit-content">
                    <div
                      style={{
                        padding: "8px",
                        borderRadius: "4px",
                        border: "1px solid #E5E5E5",
                        height: "40px",
                        width: "40px",
                        cursor: "pointer",
                        marginTop: "15px"
                      }}
                      onClick={() => {
                        setToDelete({ index: foundIdx, tax: rl });
                      }}
                    >
                      <DeleteIcon height="24px" width="24px" />
                    </div>
                  </Grid>
                )}
              </Fragment>
            );
          })}
        </Grid>
      </Grid>

      {!disabled && (
        <Grid
          style={{
            padding: "24px"
          }}
        >
          <Button
            startIcon={<Add />}
            disabled={disabled}
            style={{
              textTransform: "capitalize",
              color: colors.primary.main
            }}
            onClick={() => {
              const newTaxFee: TaxFee = {
                name: "",
                type: "TAX",
                calculationMethod: "PERCENTAGE",
                rate: 0
              };

              setTaxFees([...taxFees, newTaxFee]);
              reset({
                ...getValues(),
                taxFee: [...taxFees, newTaxFee]
              });
            }}
          >
            Add Tax / Fee
          </Button>
        </Grid>
      )}

      <ConfirmationDialog
        open={!!toDelete}
        title="Delete Tax and Fee"
        body={`Are you sure you want to delete Tax and Fee ${
          toDelete?.tax.name
        } ?`}
        close={() => setToDelete(undefined)}
        onConfirm={() => {
          const slicedTaxFee = taxFees.filter(
            (_, index) => index !== toDelete?.index
          );
          reset({
            ...getValues(),
            taxFee: [...slicedTaxFee]
          });
          setTaxFees([...slicedTaxFee]);
        }}
        onCancel={() => setToDelete(undefined)}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Grid>
  );
};
