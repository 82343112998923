import { ModelCannedMessage } from "@sportsgravyengineering/sg-api-react-sdk";
import { CannedMessageAuditLogCreateDelete } from "./CannedMessageAuditLogCreateDelete";
import { CannedMessageAuditLogUpdate } from "./CannedMessageAuditLogUpdate";

export const CannedMessageAuditLogDetails = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelCannedMessage;
  after: object | ModelCannedMessage;
}) => {
  if (recordType === "UPDATE")
    return <CannedMessageAuditLogUpdate before={before} after={after} />;
  else
    return (
      <CannedMessageAuditLogCreateDelete
        recordType={recordType}
        before={before}
        after={after}
      />
    );
};
