import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { ModelImportTemplateProvider } from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";

export const ImportTemplateProviderAuditLogUpdate = ({
  before,
  after
}: {
  before: object | ModelImportTemplateProvider;
  after: object | ModelImportTemplateProvider;
}) => {
  const currentTemplate = after as ModelImportTemplateProvider;
  const oldTemplate = before as ModelImportTemplateProvider;

  const getTemplateDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    if (currentTemplate.name !== oldTemplate.name) {
      labelValues.push({
        label: `Updated the "Name" field:`,
        value: [
          {
            value: currentTemplate?.name || "",
            oldValue: oldTemplate?.name || ""
          }
        ]
      });
    }
    if (currentTemplate.erpType !== oldTemplate.erpType) {
      labelValues.push({
        label: `Updated the "ERP Type" field:`,
        value: [
          {
            value: currentTemplate?.erpType || "",
            oldValue: oldTemplate?.erpType || ""
          }
        ]
      });
    }
    if (currentTemplate.description !== oldTemplate.description) {
      labelValues.push({
        label: `Updated the "Description" field:`,
        value: [
          {
            value: currentTemplate?.description || "",
            oldValue: oldTemplate?.description || ""
          }
        ]
      });
    }
    return labelValues;
  };

  return (
    <AuditLogLabelValueList
      recordType="UPDATE"
      labelValues={[...getTemplateDetails()]}
    />
  );
};
