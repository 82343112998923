import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import {
  ModelLead,
  ModelLeadAccount
} from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";

export const LeadsAuditLogUpdate = ({
  before,
  after
}: {
  before: object | ModelLead;
  after: object | ModelLead;
}) => {
  const currentLead = after as ModelLead;
  const oldLead = before as ModelLead;
  const getLeadDetails = () => {
    const currentLeadAccount = currentLead.account as ModelLeadAccount;
    const oldLeadAccount = oldLead.account as ModelLeadAccount;
    const labelValues: AuditLogLabelValue[] = [];
    if (currentLead.name !== oldLead.name) {
      labelValues.push({
        label: `Updated the "Name" field:`,
        value: [
          {
            value: currentLead.name || "",
            oldValue: oldLead.name || ""
          }
        ]
      });
    }
    if (currentLead.source?.name !== oldLead.source?.name) {
      labelValues.push({
        label: `Updated the "Source" field:`,
        value: [
          {
            value: currentLead.source?.name || "",
            oldValue: oldLead.source?.name || ""
          }
        ]
      });
    }
    if (currentLead.status !== oldLead.status) {
      labelValues.push({
        label: `Updated the "Status" field:`,
        value: [
          {
            value: currentLead.status || "",
            oldValue: oldLead.status || ""
          }
        ]
      });
    }
    if (currentLead.owner?.name !== oldLead.owner?.name) {
      labelValues.push({
        label: `Updated the "Lead Owner" field:`,
        value: [
          {
            value: currentLead.owner?.name || "",
            oldValue: oldLead.owner?.name || ""
          }
        ]
      });
    }
    if (currentLeadAccount.name !== oldLeadAccount.name) {
      labelValues.push({
        label: `Updated the Account Details "Account Name" field:`,
        value: [
          {
            value: currentLeadAccount.name || "",
            oldValue: oldLeadAccount.name || ""
          }
        ]
      });
    }
    if (currentLeadAccount.parent?.name !== oldLeadAccount.parent?.name) {
      labelValues.push({
        label: `Updated the Account Details "Account Parent" field:`,
        value: [
          {
            value: currentLeadAccount.parent?.name || "",
            oldValue: oldLeadAccount.parent?.name || ""
          }
        ]
      });
    }
    if (currentLeadAccount.industry?.name !== oldLeadAccount.industry?.name) {
      labelValues.push({
        label: `Updated the Account Details "Account Industry" field:`,
        value: [
          {
            value: currentLeadAccount.industry?.name || "",
            oldValue: oldLeadAccount.industry?.name || ""
          }
        ]
      });
    }
    if (currentLeadAccount.type !== oldLeadAccount.type) {
      labelValues.push({
        label: `Updated the Account Details "Account Type" field:`,
        value: [
          {
            value: currentLeadAccount.type || "",
            oldValue: oldLeadAccount.type || ""
          }
        ]
      });
    }
    if (currentLeadAccount.officeAddress !== oldLeadAccount.officeAddress) {
      labelValues.push({
        label: `Updated the Account Details "Account Address" field:`,
        value: [
          {
            value: currentLeadAccount.officeAddress || "",
            oldValue: oldLeadAccount.officeAddress || ""
          }
        ]
      });
    }
    if (currentLeadAccount.email !== oldLeadAccount.email) {
      labelValues.push({
        label: `Updated the Account Details "Account Email" field:`,
        value: [
          {
            value: currentLeadAccount.email || "",
            oldValue: oldLeadAccount.email || ""
          }
        ]
      });
    }
    if (currentLeadAccount.website !== oldLeadAccount.website) {
      labelValues.push({
        label: `Updated the Account Details "Account Website" field:`,
        value: [
          {
            value: currentLeadAccount.website || "",
            oldValue: oldLeadAccount.website || ""
          }
        ]
      });
    }
    if (
      currentLeadAccount.sports?.map((sport) => sport.name).join(",") !==
      oldLeadAccount.sports?.map((sport) => sport.name).join(",")
    ) {
      labelValues.push({
        label: `Updated the Account Details "Sports Offered" field:`,
        value: [
          {
            value:
              currentLeadAccount.sports?.map((sport) => sport.name).join(",") ||
              "",
            oldValue:
              oldLeadAccount.sports?.map((sport) => sport.name).join(",") || ""
          }
        ]
      });
    }
    if (
      currentLeadAccount.numberOfAthletes !== oldLeadAccount.numberOfAthletes
    ) {
      labelValues.push({
        label: `Updated the Account Details "No. of Yearly Athlete Registrations" field:`,
        value: [
          {
            value: currentLeadAccount.numberOfAthletes || "",
            oldValue: oldLeadAccount.numberOfAthletes || ""
          }
        ]
      });
    }
    if (currentLeadAccount.aeOwner !== oldLeadAccount.aeOwner) {
      labelValues.push({
        label: `Updated the Account Details "AE Owner" field:`,
        value: [
          {
            value: currentLeadAccount.aeOwner || "",
            oldValue: oldLeadAccount.aeOwner || ""
          }
        ]
      });
    }
    return labelValues;
  };
  return (
    <AuditLogLabelValueList
      recordType="UPDATE"
      labelValues={[...getLeadDetails()]}
    />
  );
};
