import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { FormSelect } from "@components/FormSelect";
import { LoadingSpinner } from "@components/LoadingSpinner";
import { StyledTabs } from "@components/StyledTabs";
import { Visibility } from "@mui/icons-material";
import { Box, Button, Tab, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import {
  ModelAuditLog,
  ModelPerson,
  useAdminAuditLogGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { LogCreatedBy } from "./components/LogCreatedBy";
import { UserSearch } from "./components/UserSearch";

const PAGE_SIZE = 50;

const formatDate = (dateString) => {
  const inputDate = new Date(dateString);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  if (inputDate.toDateString() === today.toDateString()) {
    return "Today";
  }
  if (inputDate.toDateString() === yesterday.toDateString()) {
    return "Yesterday";
  }
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric"
  };
  return inputDate.toLocaleDateString("en-US", options);
};

export const AuditLogs = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const [pageNo, setPageNo] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [logs, setLogs] = useState<ModelAuditLog[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>(
    undefined
  );
  const [selectedOrgUser, setSelectedOrgUser] = useState<string | undefined>(
    "ALL"
  );

  const organizationData = useRecoilValue(organizationsAtom);
  const [selectedUser, setSelectedUser] = useState<ModelPerson[]>([]);
  const [lastElement, setLastElement] = useState<HTMLElement | null>(null);
  const [activeTab, setActiveTab] = useState<string | undefined>("SportsGravy");
  const [selectedOrg, setSelectedOrg] = useState<string | undefined>(undefined);
  const tabs = ["SportsGravy", "SportsGravy Organizations"];
  useEffect(() => {
    setPageNo(0);
    setLogs([]);
    setHasMore(true);
  }, [selectedUser, selectedFilter]);

  useEffect(() => {
    if (activeTab === "SportsGravy Organizations") {
      setPageNo(0);
      setLogs([]);
      setHasMore(true);
      setSelectedOrgUser("ALL");
    }
  }, [selectedOrg]);

  useEffect(() => {
    if (activeTab === "SportsGravy") {
      setPageNo(0);
      setLogs([]);
      setSelectedOrg(undefined);
      setSelectedFilter(undefined);
    }
  }, [activeTab]);

  const effectiveOrgId = useMemo(() => {
    if (
      activeTab === "SportsGravy Organizations" &&
      selectedOrg &&
      selectedOrg !== "ALL"
    ) {
      const matchingOrg = organizationData?.find(
        (org) => org.name?.toUpperCase() === selectedOrg
      );
      return matchingOrg?.organizationId ?? undefined;
    }

    return organizationId;
  }, [activeTab, selectedOrg, organizationData, organizationId]);

  const effectiveUserIds = useMemo(() => {
    if (
      activeTab === "SportsGravy Organizations" &&
      selectedOrgUser &&
      selectedOrgUser !== "ALL"
    ) {
      return [selectedOrgUser];
    }

    return selectedUser.map((user) => user.userId as string);
  }, [activeTab, selectedOrgUser, selectedUser]);

  const { data: auditLogs, isLoading: isAuditLogLoading } = useAdminAuditLogGet(
    {
      organizationId: effectiveOrgId!,
      userIds: effectiveUserIds,
      pageNo,
      pageSize: PAGE_SIZE,
      ...(selectedFilter ? { filter: selectedFilter } : {})
    },
    {
      query: {
        queryKey: [
          "adminAuditLogGet",
          {
            organizationId: effectiveOrgId!,
            userIds: effectiveUserIds,
            pageNo,
            pageSize: PAGE_SIZE,
            ...(selectedFilter ? { filter: selectedFilter } : {})
          }
        ]
      }
    }
  );

  const organizationOptions = useMemo(() => {
    const options =
      organizationData?.map((org) => ({
        label: org?.name || "Unknown",
        value: org?.name?.toUpperCase() || "UNKNOWN"
      })) || [];

    return [{ label: "All Organizations", value: "ALL" }, ...options];
  }, [organizationData]);

  const organizationUserOptions = useMemo(() => {
    if (!selectedOrg || selectedOrg === "ALL") return [];

    const selectedOrganization = organizationData?.find(
      (org) => org.name?.toUpperCase() === selectedOrg
    );

    const userOptions =
      selectedOrganization?.users?.map((userRole) => {
        const person = userRole.user?.person;
        const role = userRole.role?.name;
        return {
          label:
            `${person?.firstName || ""} ${person?.lastName || ""} (${role})`.trim(),
          value: userRole.user?.userId ?? ""
        };
      }) ?? [];

    return [{ label: "All Users", value: "ALL" }, ...userOptions];
  }, [selectedOrg, organizationData]);

  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (
          first.isIntersecting &&
          hasMore &&
          (!isAuditLogLoading || pageNo == 0)
        ) {
          setPageNo((page) => page + 1);
        }
      },
      {
        rootMargin: "150px",
        threshold: 0.5
      }
    )
  );
  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      if (isAuditLogLoading || !hasMore) {
        currentObserver.disconnect();
      } else {
        currentObserver.observe(currentElement);
      }
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement, isAuditLogLoading, hasMore]);

  useEffect(() => {
    if (auditLogs && !isAuditLogLoading) {
      setLogs((prevLogs) => {
        const existingIds = new Set(prevLogs.map((log) => log.logId));
        const newLogs = (auditLogs.data.logs || []).filter(
          (log) => !existingIds.has(log.logId)
        );
        return [...prevLogs, ...newLogs];
      });

      if (
        auditLogs?.data?.logs &&
        (auditLogs?.data?.logs.length < PAGE_SIZE ||
          auditLogs?.data?.logs.length === 0)
      ) {
        setHasMore(false);
        observer.current.disconnect();
      }
    }
  }, [auditLogs, isAuditLogLoading]);

  const groupedLogs = useMemo(() => {
    return logs.reduce((acc, log) => {
      const date = log?.createdAt?.split("T")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(log);
      return acc;
    }, {});
  }, [logs]);

  return (
    <Container>
      <Toolbar title="Audit Logs" />
      <Grid container paddingBottom="2rem">
        <Grid xs={2}>
          <div
            style={{
              paddingLeft: "24px",
              paddingRight: "16px",
              display: "flex",
              alignItems: "center",
              gap: "8px"
            }}
          >
            <Typography variant="gridToolbarFilterLabel">View</Typography>
            <FormSelect
              name="select"
              options={[
                {
                  label: "All Actions",
                  value: ""
                },
                {
                  label: "All Added",
                  value: "CREATE"
                },
                {
                  label: "All Edited",
                  value: "UPDATE"
                },
                {
                  label: "All Deleted",
                  value: "DELETE"
                }
              ]}
              onChange={(e) => setSelectedFilter(e.target.value)}
            />
          </div>
        </Grid>
        <Grid xs={10} sx={{ paddingRight: "2rem" }}>
          <UserSearch
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          />
        </Grid>
      </Grid>
      {!organizationId ? (
        <Grid
          width="100%"
          style={{
            borderBottom: "1px solid #E5E5E5"
          }}
        >
          <StyledTabs
            sx={{ marginLeft: "32px" }}
            onChange={(e, value) => {
              setActiveTab(value);
            }}
            value={activeTab}
          >
            {!organizationId &&
              tabs.map((tab) => {
                return (
                  <Tab
                    key={tab}
                    label={
                      <Grid
                        container
                        sx={{
                          flex: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "15px"
                        }}
                      >
                        <Typography variant="tableHeader">{tab}</Typography>
                      </Grid>
                    }
                    value={tab}
                  />
                );
              })}
          </StyledTabs>
          {activeTab === "SportsGravy" && (
            <Form sx={{ paddingTop: "0px !important" }}>
              <Loader isLoading={pageNo === 0 && isAuditLogLoading}>
                {Object.keys(groupedLogs || {}).map((date) => (
                  <Fragment key={date}>
                    <div style={{ padding: "24px 0px" }}>
                      <Typography
                        variant="gridToolbarFilterLabel"
                        style={{
                          textTransform: "uppercase",
                          fontSize: "14px",
                          lineHeight: "18px",
                          letterSpacing: "1.5px",
                          fontWeight: 300
                        }}
                      >
                        {formatDate(date)}
                      </Typography>
                    </div>
                    {groupedLogs[date].map((log, index) => (
                      <Fragment key={log.logId}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            zIndex: 2
                          }}
                        >
                          <Box
                            sx={{
                              "& .log-created-by": {
                                padding: "0 0 0 8px",
                                width: "70vw"
                              }
                            }}
                          >
                            <LogCreatedBy log={log} />
                          </Box>
                          <Button
                            style={{ color: "#007AFF" }}
                            variant="text"
                            startIcon={<Visibility />}
                            onClick={() => navigate(`/audit-log/${log.logId}`)}
                          >
                            View
                          </Button>
                        </div>
                        {index !== groupedLogs[date].length - 1 && (
                          <Fragment>
                            <div
                              style={{
                                width: "1px",
                                backgroundColor: "#E5E5E5",
                                height: "34px",
                                marginLeft: "25px",
                                zIndex: 1
                              }}
                            ></div>
                            <hr
                              style={{
                                position: "relative",
                                bottom: "17px",
                                left: "25px",
                                marginRight: "25px",
                                border: "1px solid #E5E5E5",
                                zIndex: 1
                              }}
                            />
                          </Fragment>
                        )}
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </Loader>
              {hasMore && !isAuditLogLoading && <div ref={setLastElement} />}
              {isAuditLogLoading && pageNo !== 0 && (
                <div style={{ textAlign: "center", padding: "2rem" }}>
                  <LoadingSpinner />
                </div>
              )}
            </Form>
          )}
          {activeTab === "SportsGravy Organizations" && (
            <Form sx={{ paddingTop: "0px !important" }}>
              <Grid container sx={{ marginTop: "24px" }} spacing={2}>
                <Grid xs={6}>
                  <FormSelect
                    name="organization"
                    options={organizationOptions}
                    onChange={(e) => setSelectedOrg(e.target.value)}
                  />
                </Grid>
                {selectedOrg?.toUpperCase() !== "ALL" && selectedOrg && (
                  <Grid xs={6}>
                    <FormSelect
                      name="organizationUser"
                      options={organizationUserOptions}
                      onChange={(e) => setSelectedOrgUser(e.target.value)}
                    />
                  </Grid>
                )}
              </Grid>
              <Loader isLoading={pageNo === 0 && isAuditLogLoading}>
                {Object.keys(groupedLogs || {}).map((date) => (
                  <Fragment key={date}>
                    <div style={{ padding: "24px 0px" }}>
                      <Typography
                        variant="gridToolbarFilterLabel"
                        style={{
                          textTransform: "uppercase",
                          fontSize: "14px",
                          lineHeight: "18px",
                          letterSpacing: "1.5px",
                          fontWeight: 300
                        }}
                      >
                        {formatDate(date)}
                      </Typography>
                    </div>
                    {groupedLogs[date].map((log, index) => (
                      <Fragment key={log.logId}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            zIndex: 2
                          }}
                        >
                          <Box
                            sx={{
                              "& .log-created-by": {
                                padding: "0 0 0 8px",
                                width: "70vw"
                              }
                            }}
                          >
                            <LogCreatedBy log={log} />
                          </Box>
                          <Button
                            style={{ color: "#007AFF" }}
                            variant="text"
                            startIcon={<Visibility />}
                            onClick={() => navigate(`/audit-log/${log.logId}`)}
                          >
                            View
                          </Button>
                        </div>
                        {index !== groupedLogs[date].length - 1 && (
                          <Fragment>
                            <div
                              style={{
                                width: "1px",
                                backgroundColor: "#E5E5E5",
                                height: "34px",
                                marginLeft: "25px",
                                zIndex: 1
                              }}
                            ></div>
                            <hr
                              style={{
                                position: "relative",
                                bottom: "17px",
                                left: "25px",
                                marginRight: "25px",
                                border: "1px solid #E5E5E5",
                                zIndex: 1
                              }}
                            />
                          </Fragment>
                        )}
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </Loader>
              {hasMore && !isAuditLogLoading && <div ref={setLastElement} />}
              {isAuditLogLoading && pageNo !== 0 && (
                <div style={{ textAlign: "center", padding: "2rem" }}>
                  <LoadingSpinner />
                </div>
              )}
            </Form>
          )}
        </Grid>
      ) : (
        <Form sx={{ paddingTop: "0px !important" }}>
          <Loader isLoading={pageNo === 0 && isAuditLogLoading}>
            {Object.keys(groupedLogs || {}).map((date) => (
              <Fragment key={date}>
                <div style={{ padding: "24px 0px" }}>
                  <Typography
                    variant="gridToolbarFilterLabel"
                    style={{
                      textTransform: "uppercase",
                      fontSize: "14px",
                      lineHeight: "18px",
                      letterSpacing: "1.5px",
                      fontWeight: 300
                    }}
                  >
                    {formatDate(date)}
                  </Typography>
                </div>
                {groupedLogs[date].map((log, index) => (
                  <Fragment key={log.logId}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        zIndex: 2
                      }}
                    >
                      <Box
                        sx={{
                          "& .log-created-by": {
                            padding: "0 0 0 8px",
                            width: "70vw"
                          }
                        }}
                      >
                        <LogCreatedBy log={log} />
                      </Box>
                      <Button
                        style={{ color: "#007AFF" }}
                        variant="text"
                        startIcon={<Visibility />}
                        onClick={() => navigate(`/audit-log/${log.logId}`)}
                      >
                        View
                      </Button>
                    </div>
                    {index !== groupedLogs[date].length - 1 && (
                      <Fragment>
                        <div
                          style={{
                            width: "1px",
                            backgroundColor: "#E5E5E5",
                            height: "34px",
                            marginLeft: "25px",
                            zIndex: 1
                          }}
                        ></div>
                        <hr
                          style={{
                            position: "relative",
                            bottom: "17px",
                            left: "25px",
                            marginRight: "25px",
                            border: "1px solid #E5E5E5",
                            zIndex: 1
                          }}
                        />
                      </Fragment>
                    )}
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </Loader>
          {hasMore && !isAuditLogLoading && <div ref={setLastElement} />}
          {isAuditLogLoading && pageNo !== 0 && (
            <div style={{ textAlign: "center", padding: "2rem" }}>
              <LoadingSpinner />
            </div>
          )}
        </Form>
      )}
    </Container>
  );
};
