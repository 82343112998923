import { ModelOrganizationAccount } from "@sportsgravyengineering/sg-api-react-sdk";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "../types";
import { CRM_ACCOUNT_CATEGORIES } from "@utils/constants";
import { formatPhoneWithCountryCode } from "@utils/phoneFormatters";
import { capitalize } from "@utils/capitalize";

export const FSOAccountAuditLog = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelOrganizationAccount;
  after: object | ModelOrganizationAccount;
}) => {
  const account = (
    recordType !== "DELETE" ? after : before
  ) as ModelOrganizationAccount;
  const oldAccount = before as ModelOrganizationAccount;

  const getCreateLabels = () => {
    const labels: AuditLogLabelValue[] = [];

    labels.push(
      {
        label: `"Name" field:`,
        value: [
          {
            value: account.name || ""
          }
        ]
      },
      ...(account.parentId
        ? [
            {
              label: `"Parent" field:`,
              value: [
                {
                  value: account.parent?.name || ""
                }
              ]
            }
          ]
        : []),
      {
        label: `"Category" field:`,
        value: [
          {
            value:
              CRM_ACCOUNT_CATEGORIES.find(
                (category) => category.value === account.category
              )?.label || ""
          }
        ]
      },
      ...(account.ownerId
        ? [
            {
              label: `"Account Owner" field:`,
              value: [
                {
                  value: `${account.owner?.person?.firstName} ${account.owner?.person?.lastName}`
                }
              ]
            }
          ]
        : []),
      {
        label: `"Tax Exempt" field:`,
        value: [
          {
            value: account.isTaxExempt ? "Yes" : "Off"
          }
        ]
      },
      {
        label: `Address "Office Address" field:`,
        value: [
          {
            value: account.officeAddress || ""
          }
        ]
      }
    );

    if (account.billingAddress)
      labels.push(
        {
          label: `Address "Billing Address is the same as Office Address" field:`,
          value: [
            {
              value:
                account.officeAddress === account.billingAddress ? "On" : "Off"
            }
          ]
        },
        ...(account.billingAddress !== account.officeAddress
          ? [
              {
                label: `Address "Billing Address" field:`,
                value: [
                  {
                    value: account.billingAddress || ""
                  }
                ]
              }
            ]
          : [])
      );

    if (account.shippingAddress)
      labels.push(
        {
          label: `Address "Shipping Address is the same as Office Address" field:`,
          value: [
            {
              value:
                account.officeAddress === account.shippingAddress ? "On" : "Off"
            }
          ]
        },
        ...(account.shippingAddress !== account.officeAddress
          ? [
              {
                label: `Address "Shipping Address" field:`,
                value: [
                  {
                    value: account.shippingAddress || ""
                  }
                ]
              }
            ]
          : [])
      );

    if (account.phone)
      labels.push({
        label: `Contact "Phone" field:`,
        value: [
          {
            value: formatPhoneWithCountryCode(account.phone)
          }
        ]
      });

    if (account.fax)
      labels.push({
        label: `Contact "FAX" field:`,
        value: [
          {
            value: formatPhoneWithCountryCode(account.fax)
          }
        ]
      });

    if (account.email)
      labels.push({
        label: `Contact "Email" field:`,
        value: [
          {
            value: account.email
          }
        ]
      });

    if (account.website)
      labels.push({
        label: `Contact "Website" field:`,
        value: [
          {
            value: account.website
          }
        ]
      });

    if (account.notes)
      labels.push({
        label: `Contact "Notes" field:`,
        value: [
          {
            value: account.notes
          }
        ]
      });

    if (account.links) {
      const genName = (fieldName: string, index: number) => {
        return account.links && account.links.length > 1
          ? `Related Links ${index + 1} "${fieldName}" field:`
          : `Related Link "${fieldName}" field:`;
      };
      account.links?.map((link, index) => {
        labels.push(
          {
            label: genName("URL", index),
            value: [
              {
                value: link.link || ""
              }
            ]
          },
          {
            label: genName("Type", index),
            value: [
              {
                value: link.type ? capitalize(link.type) : ""
              }
            ]
          },
          ...(link.type === "OTHER"
            ? [
                {
                  label: genName("Other Name", index),
                  value: [
                    {
                      value: link.altName || ""
                    }
                  ]
                }
              ]
            : [])
        );
      });
    }

    return labels;
  };

  const getUpdateLabels = () => {
    const labels: AuditLogLabelValue[] = [];

    if (oldAccount.name !== account.name)
      labels.push({
        label: `Updated the "Name" field:`,
        value: [
          {
            value: account.name || "N/A",
            oldValue: oldAccount.name || "N/A"
          }
        ]
      });

    if (oldAccount.parentId !== account.parentId)
      labels.push({
        label: `Updated the "Parent" field:`,
        value: [
          {
            value: account.parent?.name || "N/A",
            oldValue: oldAccount.parent?.name || "N/A"
          }
        ]
      });

    if (oldAccount.category !== account.category)
      labels.push({
        label: `Updated the "Category" field:`,
        value: [
          {
            value:
              CRM_ACCOUNT_CATEGORIES.find(
                (category) => category.value === account.category
              )?.label || "N/A",
            oldValue:
              CRM_ACCOUNT_CATEGORIES.find(
                (category) => category.value === oldAccount.category
              )?.label || "N/A"
          }
        ]
      });

    if (oldAccount.ownerId !== account.ownerId)
      labels.push({
        label: `Updated the "Account Owner" field:`,
        value: [
          {
            value: `${account.owner?.person?.firstName} ${account.owner?.person?.lastName}`,
            oldValue: `${oldAccount.owner?.person?.firstName} ${oldAccount.owner?.person?.lastName}`
          }
        ]
      });

    if (oldAccount.isTaxExempt !== account.isTaxExempt)
      labels.push({
        label: `Updated the "Tax Exempt" field:`,
        value: [
          {
            value: account.isTaxExempt ? "On" : "Off",
            oldValue: oldAccount.isTaxExempt ? "On" : "Off"
          }
        ]
      });

    if (oldAccount.officeAddress !== account.officeAddress)
      labels.push({
        label: `Updated Address "Office Address" field:`,
        value: [
          {
            value: account.officeAddress || "N/A",
            oldValue: oldAccount.officeAddress || "N/A"
          }
        ]
      });

    if (oldAccount.billingAddress !== account.billingAddress) {
      labels.push(
        {
          label: `Updated Address "Billing Address is the same as Office Address" field:`,
          value: [
            {
              value:
                account.officeAddress === account.billingAddress ? "On" : "Off",
              oldValue:
                oldAccount.officeAddress === oldAccount.billingAddress
                  ? "On"
                  : "Off"
            }
          ]
        },
        {
          label: `Updated Address "Billing Address" field:`,
          value: [
            {
              value: account.billingAddress || "N/A",
              oldValue: oldAccount.billingAddress || "N/A"
            }
          ]
        }
      );
    }

    if (oldAccount.shippingAddress !== account.shippingAddress) {
      labels.push(
        {
          label: `Updated Address "Shipping Address is the same as Office Address" field:`,
          value: [
            {
              value:
                account.shippingAddress === account.billingAddress
                  ? "On"
                  : "Off",
              oldValue:
                oldAccount.shippingAddress === oldAccount.billingAddress
                  ? "On"
                  : "Off"
            }
          ]
        },
        {
          label: `Updated Address "Shipping Address" field:`,
          value: [
            {
              value: account.shippingAddress || "N/A",
              oldValue: oldAccount.shippingAddress || "N/A"
            }
          ]
        }
      );
    }

    if (account.phone !== oldAccount.phone)
      labels.push({
        label: `Updated Contact "Phone" field:`,
        value: [
          {
            value: account.phone
              ? formatPhoneWithCountryCode(account.phone)
              : "N/A",
            oldValue: oldAccount.phone
              ? formatPhoneWithCountryCode(oldAccount.phone)
              : "N/A"
          }
        ]
      });

    if (account.fax !== oldAccount.fax)
      labels.push({
        label: `Updated Contact "FAX" field:`,
        value: [
          {
            value: account.fax
              ? formatPhoneWithCountryCode(account.fax)
              : "N/A",
            oldValue: oldAccount.fax
              ? formatPhoneWithCountryCode(oldAccount.fax)
              : "N/A"
          }
        ]
      });

    if (account.email !== oldAccount.email)
      labels.push({
        label: `Updated Contact "Email" field:`,
        value: [
          {
            value: account.email || "N/A",
            oldValue: oldAccount.email || "N/A"
          }
        ]
      });

    if (account.website !== oldAccount.website)
      labels.push({
        label: `Updated Contact "Website" field:`,
        value: [
          {
            value: account.website || "N/A",
            oldValue: oldAccount.website || "N/A"
          }
        ]
      });

    if (account.notes !== oldAccount.notes)
      labels.push({
        label: `Updated Contact "Notes" field:`,
        value: [
          {
            value: account.notes || "N/A",
            oldValue: oldAccount.notes || "N/A"
          }
        ]
      });

    const newLink = account.links || [];
    const oldLinks = oldAccount.links || [];

    const newLinksAdded = newLink.filter(
      (link) => !oldLinks.some((oldLink) => oldLink.link === link.link)
    );

    const existingEditedLinks = newLink.filter((link) => {
      const oldLinkMatch = oldLinks.find(
        (oldLink) => oldLink.link === link.link
      );
      if (!oldLinkMatch) return false;
      // Compare type/altName with the matched old link
      return (
        oldLinkMatch.type !== link.type || oldLinkMatch.altName !== link.altName
      );
    });

    const deletedLinks = oldLinks.filter(
      (oldLink) => !newLink.some((link) => link.link === oldLink.link)
    );

    const changedLength =
      newLinksAdded.length + existingEditedLinks.length + deletedLinks.length;

    const genName = (fieldName: string, index: number) => {
      return changedLength > 1
        ? `Updated Related Links ${index + 1} "${fieldName}" field:`
        : `Updated Related Link "${fieldName}" field:`;
    };

    if (changedLength > 0) {
      newLinksAdded.map((link, index) => {
        labels.push(
          {
            label: genName("URL", index),
            value: [
              {
                value: link.link || "N/A",
                oldValue: "N/A"
              }
            ]
          },
          {
            label: genName("Type", index),
            value: [
              {
                value: link.type ? capitalize(link.type) : "N/A",
                oldValue: "N/A"
              }
            ]
          },
          ...(link.type === "OTHER"
            ? [
                {
                  label: genName("Other Name", index),
                  value: [
                    {
                      value: link.altName || "N/A",
                      oldValue: "N/A"
                    }
                  ]
                }
              ]
            : [])
        );
      });

      existingEditedLinks.map((link, index) => {
        labels.push(
          {
            label: genName("URL", newLinksAdded.length + index),
            value: [
              {
                value: link.link || "N/A",
                oldValue: oldLinks[index].link || "N/A"
              }
            ]
          },
          {
            label: genName("Type", newLinksAdded.length + index),
            value: [
              {
                value: link.type ? capitalize(link.type) : "N/A",
                oldValue: oldLinks[index].type
                  ? capitalize(oldLinks[index].type)
                  : "N/A"
              }
            ]
          },
          ...(link.type === "OTHER"
            ? [
                {
                  label: genName("Other Name", newLinksAdded.length + index),
                  value: [
                    {
                      value: link.altName || "N/A",
                      oldValue: oldLinks[index].altName || "N/A"
                    }
                  ]
                }
              ]
            : [])
        );
      });

      deletedLinks.map((link, index) => {
        labels.push(
          {
            label: genName(
              "URL",
              newLinksAdded.length + existingEditedLinks.length + index
            ),
            value: [
              {
                value: "N/A",
                oldValue: link.link || "N/A"
              }
            ]
          },
          {
            label: genName(
              "Type",
              newLinksAdded.length + existingEditedLinks.length + index
            ),
            value: [
              {
                value: "N/A",
                oldValue: link.type ? capitalize(link.type) : "N/A"
              }
            ]
          },
          ...(link.type === "OTHER"
            ? [
                {
                  label: genName(
                    "Other Name",
                    newLinksAdded.length + existingEditedLinks.length + index
                  ),
                  value: [
                    {
                      value: "N/A",
                      oldValue: link.altName || "N/A"
                    }
                  ]
                }
              ]
            : [])
        );
      });
    }

    return labels;
  };

  if (recordType === "UPDATE")
    return (
      <AuditLogLabelValueList
        recordType="UPDATE"
        labelValues={[...getUpdateLabels()]}
      />
    );
  else
    return (
      <AuditLogLabelValueList
        recordType={recordType}
        labelValues={[...getCreateLabels()]}
      />
    );
};
