import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import { FormSelect } from "@components/FormSelect";
import {
  ACTIVITY_OUTCOME,
  ACTIVITY_RELATED_TO,
  ACTIVITY_STATUS_TYPES,
  CRM_ACTIVITY_NEXT_ACTIONS,
  CRM_CALL_DIRECTIONS
} from "@utils/constants";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SearchAddAccount } from "../components/SearchAddAccount";
import Grid from "@mui/material/Unstable_Grid2";
import { FormLabel, styled, Typography } from "@mui/material";
import { Footer } from "@components/crud/Footer";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormTimePicker } from "@components/FormTimePicker";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { Editor } from "@components/Editor";
import {
  participant,
  SearchPartipants
} from "../components/SearchParticipants";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useNavigate, useParams } from "react-router-dom";
import { SearchAddContact } from "../contacts/components/SearchAddContact";
import { SearchAddLead } from "../components/SearchAddLead";
import { SearchAddOpportunity } from "../components/SearchAddOpportunity";
import {
  ActivityCreateInputRelatesTo,
  mediaGet,
  useGetAdminCrmActivityActivityId,
  useGetAdminCrmOrgActivityActivityId,
  usePutAdminCrmActivityActivityId,
  usePutAdminCrmOrgActivityActivityId
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import formatFullName from "@utils/formatFullName";
import { containsUrl } from "@utils/containsUrl";
import { base64ImageToFile } from "@utils/file";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { enqueueSnackbar } from "notistack";
import { removeNullValues } from "@utils/cleanObject";
import { arraysEqual } from "@utils/ArrayFunctions";
import { hasPermission } from "@services/Casbin";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { QuickAddContactModal } from "../contacts/components/QuickAddContactForm";
import { findContactEmailInMeeting } from "@utils/activityUtils";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",
  display: "block",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

const timeZoneOptions = Intl.supportedValuesOf("timeZone").map((key) => {
  return {
    label: key,
    value: key
  };
});

export const ActivityEdit = () => {
  const navigate = useNavigate();
  const { activityId } = useParams();
  const organizationId = useRecoilValue(organizationAtom);
  const [showOnHoldReason, setShowOnHoldReason] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [addContact, setAddContact] = useState<boolean>(false);
  const [addContactTo, setAddContactTo] = useState<string>();
  const [selectedActivity, setSelectedActivity] = useState<
    "CALL" | "EMAIL" | "MEETING" | "TASK" | undefined
  >(undefined);
  const [selectedAccount, setSelectedAccount] = useState<string>();
  const [selectedContact, setSelectedContact] = useState<string>();
  const [selectedLead, setSelectedLead] = useState<string>();
  const [selectedOpportunity, setSelectedOpportunity] = useState<string>();
  const [participants, setParticipants] = useState<participant[]>([]);
  const [, setTo] = useState<participant[]>([]);
  const [, setCC] = useState<participant[]>([]);
  const [, setBCC] = useState<participant[]>([]);
  const [relatedTo, setRelatedTo] = useState<string>();
  const [hasCC, setHasCC] = useState(false);
  const [isEmailEditor, setIsEmailEditor] = useState(false);
  const [isMeetingEditable, setIsMeetingEditable] = useState(false);

  const {
    control,
    getValues,
    setValue,
    reset,
    trigger,
    formState: { isValid, isDirty }
  } = useForm({
    mode: "onTouched",
    shouldUnregister: true
  });

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        organizationId ? "ORGANIZATION" : "SYSTEM",
        organizationId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("crm.activities", "EDIT");
      if (!edit) navigate("/not-found");
    };
    fetchPermissions();
  }, []);

  const { data: activityData, isLoading: isLoading } = organizationId
    ? useGetAdminCrmOrgActivityActivityId(activityId!)
    : useGetAdminCrmActivityActivityId(activityId!);

  useEffect(() => {
    if (activityData?.data) {
      const activity = activityData.data.activity;
      reset({
        relatedTo: activity.relatesTo,
        relatedField:
          activity.relatesTo === "ACCOUNT"
            ? activity.account?.name
            : activity.relatesTo === "CONTACT"
              ? formatFullName(activity.contact)
              : activity.relatesTo === "LEAD"
                ? activity.lead?.name
                : activity.relatesTo === "OPPORTUNITY"
                  ? activity.opportunity?.name
                  : activity.order?.name,
        date: new Date(activity.date),
        time: new Date(activity.date),
        timeZone: activity.timezone,
        description:
          selectedActivity === "MEETING" || selectedActivity === "TASK"
            ? activity.notes
            : "",
        managerFeedback: activity.managerFeedback,
        ...(activity.type === "CALL" && {
          callParticipants: [
            ...activity.internalParicipants,
            ...activity.externalParticipants
          ].map((p) => ({
            label: formatFullName(p.contactId ? p : p.person),
            text: "",
            value: p.contactId || p.userId,
            isInternal: !!p.userId
          })),
          nextAction: activity.nextAction,
          nextActionDate: new Date(activity.nextActionDate),
          nextActionTime: new Date(activity.nextActionDate),
          callDirection: activity.call?.type,
          outcome: activity.call?.outcome
        }),
        notes: activity.notes,
        status: activity.status,
        reason: activity.reason || "",
        ...(activity.type === "EMAIL" && {
          to: activity.email?.to.map((t) => ({
            label: t.name,
            text: t.email
          })),
          cc: activity.email?.cc.map((t) => ({
            label: t.name,
            text: t.email
          })),
          bcc: activity.email?.bcc.map((t) => ({
            label: t.name,
            text: t.email
          })),
          ccEnabled: activity.email?.cc.length || activity.email?.bcc.length,
          subject: activity.email?.subject,
          emailContent: activity.email?.body
        }),
        ...(activity.type === "MEETING" && {
          meetingParticipants: [
            ...activity.internalParicipants,
            ...activity.externalParticipants
          ].map((p) => {
            const contactEmail = p.contactId
              ? findContactEmailInMeeting(
                  activity.meeting?.eventDetails?.attendees || [],
                  p
                )
              : p.person?.email || "";
            return {
              label: formatFullName(p.contactId ? p : p.person),
              text: contactEmail,
              value: p.contactId || p.userId,
              emailDetails: {
                email: contactEmail
              },
              isInternal: !!p.userId
            };
          })
        })
      });
      setRelatedTo(activity.relatesTo);
      setSelectedActivity(activity.type);
      setSelectedAccount(activity.accountId);
      setSelectedContact(activity.contactId);
      setSelectedLead(activity.leadId);
      setShowOnHoldReason(activity.status === "ON_HOLD");
      setHasCC(
        activity.email?.cc.length ||
          0 > 0 ||
          (activity.email?.bcc.length || 0) > 0
      );
      setSelectedOpportunity(activity.opportunityId);
      setIsEmailEditor(
        activity.email?.body && typeof activity.email.body !== "string"
      );
      setIsMeetingEditable(
        activity.type === "MEETING" &&
          activity.meeting &&
          new Date(activity.date) > new Date()
      );
      setParticipants(
        [...activity.internalParicipants, ...activity.externalParticipants].map(
          (p) => {
            const contactEmail = p.contactId
              ? findContactEmailInMeeting(
                  activity.meeting?.eventDetails?.attendees || [],
                  p
                )
              : p.person?.email || "";
            return {
              label: formatFullName(p.contactId ? p : p.person),
              text: contactEmail,
              value: p.contactId || p.userId,

              emailDetails: {
                email: contactEmail
              },
              isInternal: !!p.userId
            };
          }
        )
      );
    }
  }, [activityData]);

  const [, setDescValid] = useState(true);
  const validateDescriptionArray = (value) => {
    if (value && value.length >= 2) {
      setDescValid(true);
    } else if (value && value.length == 1 && value[0].insert !== "\n") {
      setDescValid(true);
    } else {
      setDescValid(false);
    }
  };

  const [isUploadingImage, setUploadingImage] = useState(false);
  const { mutate: save, isLoading: isSaving } = organizationId
    ? usePutAdminCrmOrgActivityActivityId()
    : usePutAdminCrmActivityActivityId();

  const onSave = async () => {
    let nextActionDate = getValues().nextActionDate;
    const time = getValues().time;
    let date = getValues().date;
    const nextActionTime = getValues().nextActionTime;
    if (selectedActivity === "CALL" && nextActionTime) {
      const timeDate = new Date(nextActionTime);
      const dateobj = new Date(nextActionDate);
      dateobj.setHours(timeDate.getHours());
      dateobj.setMinutes(timeDate.getMinutes());
      nextActionDate = dateobj;
    }
    if (time) {
      const timeDate = new Date(time);
      const dateobj = new Date(date);
      dateobj.setHours(timeDate.getHours());
      dateobj.setMinutes(timeDate.getMinutes());
      date = dateobj;
    }
    const data = {
      date: date,
      timezone: getValues().timeZone,
      notes: getValues().notes || " ",
      ...(selectedActivity === "CALL" && {
        nextAction: getValues().nextAction,
        nextActionDate: nextActionDate,
        outcome: getValues().outcome
      }),
      ...(selectedActivity === "TASK" && {
        status: getValues().status,
        reason: showOnHoldReason ? getValues().reason : null
      }),
      relatesTo: relatedTo as ActivityCreateInputRelatesTo,
      accountId: selectedAccount,
      contactId: selectedContact,
      opportunityId: selectedOpportunity,
      leadId: selectedLead,
      type: selectedActivity,
      ...(selectedActivity === "EMAIL" && {
        body: getValues().emailContent
      }),
      ...(isMeetingEditable && {
        internalParticipants: [
          ...(participants?.filter((p) => p.isInternal).map((p) => p.value) ||
            [])
        ],
        externalParticipants: participants
          ?.filter((p) => !p.isInternal)
          .map((p) => ({
            contactId: p.value,
            email: p.emailDetails?.email || ""
          }))
      })
    };
    if (selectedActivity === "MEETING" || selectedActivity === "TASK") {
      const content = getValues().description;
      if (getValues().hasMedia) {
        setUploadingImage(true);
        await Promise.all(
          content?.map(async (op, idx) => {
            if (op.insert && Object.keys(op.insert).includes("image")) {
              if (!containsUrl(op.insert.image)) {
                const file = base64ImageToFile(
                  op.insert.image,
                  getValues().headline + "_" + idx
                );
                const filesPromises = await Promise.all(
                  //@ts-ignore
                  [file].map((file) => {
                    if (file instanceof File) {
                      const promise = uploadMediaUsingPresignedUrl(file);
                      return promise;
                    } else {
                      return file.mediaId;
                    }
                  })
                );

                if (filesPromises) {
                  const response = await mediaGet({
                    mediaIds: [filesPromises[0]]
                  });
                  if (
                    response &&
                    response.data.media &&
                    response.data.media.length
                  ) {
                    const media = response.data.media[0];
                    if (
                      media.media &&
                      media.media.baseUrl &&
                      media.media.path
                    ) {
                      let path = media.media.path;
                      if (media.media.state !== "PUBLISHED") {
                        path = path.replace("original/", "");
                      }
                      const urlPath = media.media.baseUrl + path;
                      content[idx].insert.image = urlPath;
                    }
                  }
                }
              }
            }
          })
        );

        setUploadingImage(false);
      }
      data.notes = content;
    }
    if (isMeetingEditable) {
      let date = getValues().date;
      const time = getValues().time;
      if (time) {
        const timeDate = new Date(time);
        const dateobj = new Date(date);
        dateobj.setHours(timeDate.getHours());
        dateobj.setMinutes(timeDate.getMinutes());
        date = dateobj;
      }
      data["date"] = date;
    }
    save(
      {
        data: removeNullValues(data),
        activityId: activityId!
      },
      {
        onSuccess: ({ data }) => {
          enqueueSnackbar("Activity edited successfully!", {
            variant: "success"
          });
          navigate(`/crm/activities/${data.activity?.activityId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to edit Activity!", {
            variant: "error"
          });
        }
      }
    );
  };
  return (
    <Container>
      <Toolbar title="Edit Activity" />
      <Loader isLoading={isLoading}>
        <Form>
          <Grid container spacing={3}>
            <Grid xs={6}>
              <FormSelect
                control={control}
                name="relatedTo"
                options={
                  organizationId
                    ? ACTIVITY_RELATED_TO.filter(
                        (t) => t.value !== "LEAD" && t.value !== "ORDER"
                      )
                    : ACTIVITY_RELATED_TO
                }
                label="Related To"
                onChange={(e) => {
                  setRelatedTo(e.target.value);
                  setSelectedAccount(undefined);
                  setSelectedContact(undefined);
                  setSelectedLead(undefined);
                  setSelectedOpportunity(undefined);
                  setValue("relatedField", "");
                }}
                disabled={isMeetingEditable}
              />
            </Grid>
            <Grid xs={6}>
              {!relatedTo || relatedTo === "ACCOUNT" ? (
                <SearchAddAccount
                  label="Select Account"
                  showAddAccountOption={false}
                  accountSelected={(selectedAccount) => {
                    if (selectedAccount?.accountId)
                      setSelectedAccount(selectedAccount.accountId);
                  }}
                  searchValue={getValues().relatedField}
                  onClear={() => {
                    setSelectedAccount(undefined);
                  }}
                  organizationId={organizationId}
                  disabled={isMeetingEditable}
                />
              ) : relatedTo === "CONTACT" ? (
                <SearchAddContact
                  contactSelected={(contact) => {
                    setSelectedContact(contact?.contactId);
                  }}
                  label="Select Contact"
                  hideAddContactOption
                  onClear={() => {
                    setSelectedContact(undefined);
                  }}
                  searchValue={getValues().relatedField}
                  organizationId={organizationId}
                  disabled={isMeetingEditable}
                />
              ) : relatedTo === "LEAD" ? (
                <SearchAddLead
                  label="Select Lead"
                  showAddLeadOption={false}
                  leadSelected={(selectedLead) => {
                    if (selectedLead?.leadId)
                      setSelectedLead(selectedLead.leadId);
                  }}
                  onClear={() => {
                    setSelectedLead(undefined);
                  }}
                  disabled={isMeetingEditable}
                  searchValue={getValues().relatedField}
                />
              ) : (
                <SearchAddOpportunity
                  label="Select Opportunity"
                  showAddOpportunityOption={false}
                  disabled={isMeetingEditable}
                  opportunitySelected={(selectedOpportunity) => {
                    if (selectedOpportunity?.accountId)
                      setSelectedAccount(selectedOpportunity.accountId);
                    if (selectedOpportunity?.opportunityId)
                      setSelectedOpportunity(selectedOpportunity.opportunityId);
                  }}
                  onClear={() => {
                    setSelectedAccount(undefined);
                    setSelectedOpportunity(undefined);
                  }}
                  searchValue={getValues().relatedField}
                  organizationId={organizationId}
                />
              )}
            </Grid>
            {selectedActivity === "TASK" && (
              <Grid container xs={12}>
                <Grid xs={6}>
                  <FormSelect
                    name="status"
                    control={control}
                    label="Status"
                    options={ACTIVITY_STATUS_TYPES}
                    onChange={(e) => {
                      setShowOnHoldReason(e.target.value === "ON_HOLD");
                    }}
                  />
                </Grid>
                {showOnHoldReason && (
                  <Grid xs={6}>
                    <FormInput
                      name="reason"
                      control={control}
                      label="Reason for On Hold"
                      required
                      type="text"
                      rules={{
                        required: "Reason is required"
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            <Grid xs={3}>
              <FormDatePicker
                name="date"
                required
                control={control}
                label="Date"
                rules={{
                  required: "Date is required"
                }}
                disabled={!isMeetingEditable}
              />
            </Grid>
            <Grid xs={3}>
              <FormTimePicker
                name="time"
                control={control}
                label="Time"
                required
                rules={{
                  required: "Time is required"
                }}
                disabled={!isMeetingEditable}
              />
            </Grid>
            <Grid xs={6}>
              <FormSelect
                control={control}
                name="timeZone"
                label="Time Zone"
                options={timeZoneOptions}
                required
                rules={{
                  required: "Time Zone is required"
                }}
                disabled={!isMeetingEditable}
              />
            </Grid>
            {selectedActivity === "CALL" && (
              <>
                <Grid xs={6}>
                  <SearchPartipants
                    label="Call Participants"
                    control={control}
                    name="callParticipants"
                    required={false}
                    showAddOption={false}
                    labelField="address"
                    type="CALL"
                    onChange={(option) => {
                      setParticipants(option);
                    }}
                    disabled
                  />
                </Grid>
                <Grid xs={3}>
                  <FormSelect
                    label="Call Direction"
                    options={CRM_CALL_DIRECTIONS}
                    name="callDirection"
                    control={control}
                    disabled
                  />
                </Grid>
                <Grid xs={3}>
                  <FormSelect
                    label="Call Outcome"
                    options={ACTIVITY_OUTCOME}
                    name="outcome"
                    control={control}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormSelect
                    label="Next Action"
                    options={CRM_ACTIVITY_NEXT_ACTIONS}
                    name="nextAction"
                    control={control}
                    required
                    rules={{
                      required: "Next Action is required"
                    }}
                  />
                </Grid>
                <Grid xs={3}>
                  <FormDatePicker
                    name="nextActionDate"
                    required
                    control={control}
                    label="Date"
                    rules={{
                      required: "Next Action Date is required"
                    }}
                    onChange={() => trigger("nextActionDate")}
                  />
                </Grid>
                <Grid xs={3}>
                  <FormTimePicker
                    name="nextActionTime"
                    control={control}
                    label="Time"
                    required
                    rules={{
                      required: "Next Action Time is required"
                    }}
                  />
                </Grid>
                <Grid xs={12}>
                  <FormInput
                    name="notes"
                    control={control}
                    label="Call Notes"
                    type="text"
                    multiline
                    rows={4}
                  />
                </Grid>
              </>
            )}
            {selectedActivity == "EMAIL" && (
              <>
                <Grid xs={12}>
                  <SearchPartipants
                    label="Email To"
                    control={control}
                    name="to"
                    required={false}
                    showAddOption={true}
                    labelField="email"
                    type="EMAIL"
                    onChange={(option) => {
                      setTo(option);
                    }}
                    disabled
                  />
                </Grid>
                <Grid xs={12}>
                  <FormCheckbox
                    control={control}
                    name="ccEnabled"
                    label="Add CC/BCC"
                    onChange={(e) => setHasCC(e.target.checked)}
                    disabled
                  />
                </Grid>
                {hasCC && (
                  <>
                    <Grid xs={12}>
                      <SearchPartipants
                        label="Email Cc"
                        control={control}
                        name="cc"
                        required={false}
                        showAddOption={true}
                        labelField="email"
                        onChange={(option) => {
                          setCC(option);
                        }}
                        type="EMAIL"
                        disabled
                      />
                    </Grid>
                    <Grid xs={12}>
                      <SearchPartipants
                        label="Email Bcc"
                        control={control}
                        name="bcc"
                        required={false}
                        showAddOption={true}
                        labelField="email"
                        type="EMAIL"
                        onChange={(option) => {
                          setBCC(option);
                        }}
                        disabled
                      />
                    </Grid>
                  </>
                )}
                <Grid xs={12}>
                  <FormInput
                    control={control}
                    name="subject"
                    label="Subject"
                    type="text"
                    disabled
                  />
                </Grid>
                {isEmailEditor ? (
                  <Grid xs={12}>
                    <StyledFormLabel>
                      <Typography variant="formLabel">Email Content</Typography>
                    </StyledFormLabel>
                    <Editor
                      disabled
                      html={
                        getValues().emailContent
                          ? getValues().emailContent
                          : null
                      }
                      style={{
                        minHeight: "350px"
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid xs={12}>
                    <FormInput
                      label="Email Content"
                      type="text"
                      control={control}
                      multiline
                      rows={15}
                      name="emailContent"
                    />
                  </Grid>
                )}
              </>
            )}
            {selectedActivity == "MEETING" && (
              <>
                <Grid xs={12}>
                  <SearchPartipants
                    label="Participants"
                    control={control}
                    name="meetingParticipants"
                    required={false}
                    showAddOption={!!selectedAccount}
                    labelField="address"
                    type="MEETING"
                    addNewlick={
                      selectedAccount
                        ? () => {
                            setAddContact(true);
                            setAddContactTo("meetingParticipants");
                          }
                        : undefined
                    }
                    onChange={(option) => {
                      setParticipants(option);
                    }}
                    disabled={!isMeetingEditable}
                  />
                </Grid>
                <Grid xs={12}>
                  <StyledFormLabel>
                    <Typography variant="formLabel">Meeting Notes</Typography>
                  </StyledFormLabel>
                  <Editor
                    html={
                      getValues().description ? getValues().description : null
                    }
                    onBlur={() => {
                      validateDescriptionArray(getValues().description);
                    }}
                    onValueChange={(deltaOperations, hasMedia) => {
                      if (
                        !arraysEqual(getValues().description, deltaOperations)
                      ) {
                        setValue("description", deltaOperations, {
                          shouldValidate: true,
                          shouldDirty: true
                        });
                        setValue("hasMedia", hasMedia);
                        validateDescriptionArray(deltaOperations);
                      }
                    }}
                    style={{
                      minHeight: "350px"
                    }}
                  />
                </Grid>
              </>
            )}
            {selectedActivity == "TASK" && (
              <Grid xs={12}>
                <StyledFormLabel>
                  <Typography variant="formLabel">Task Notes</Typography>
                </StyledFormLabel>
                <Editor
                  html={
                    getValues().description ? getValues().description : null
                  }
                  onBlur={() => {
                    validateDescriptionArray(getValues().description);
                  }}
                  onValueChange={(deltaOperations, hasMedia) => {
                    if (
                      !arraysEqual(getValues().description, deltaOperations)
                    ) {
                      setValue("description", deltaOperations, {
                        shouldValidate: true,
                        shouldDirty: true
                      });
                      setValue("hasMedia", hasMedia);
                      validateDescriptionArray(deltaOperations);
                    }
                  }}
                  style={{
                    minHeight: "350px"
                  }}
                />
              </Grid>
            )}
            {!organizationId && (
              <Grid xs={12}>
                <FormInput
                  name="managerFeedback"
                  control={control}
                  label="Manager Feedback"
                  type="text"
                  multiline
                  rows={4}
                  disabled
                />
              </Grid>
            )}
            {selectedAccount && addContactTo && (
              <QuickAddContactModal
                showAddContactModal={addContact}
                setShowAddContactModal={setAddContact}
                selectedAccount={selectedAccount}
                onSave={(resp) => {
                  if (
                    resp.contactId &&
                    (selectedActivity !== "EMAIL" ||
                      resp.email ||
                      resp.accounts?.[0]?.workEmail)
                  ) {
                    const values = getValues()[addContactTo!];
                    const newValues = [...values];
                    if (selectedActivity === "EMAIL") {
                      if (resp.accounts?.[0]?.workEmail) {
                        newValues.push({
                          label: formatFullName(resp),
                          value: resp.contactId,
                          text: resp.accounts?.[0].workEmail,
                          isInternal: false
                        });
                      }
                      if (resp.email) {
                        newValues.push({
                          label: formatFullName(resp),
                          value: resp.contactId,
                          text: resp.email,
                          isInternal: false
                        });
                      }
                    } else {
                      newValues.push({
                        label: formatFullName(resp),
                        value: resp.contactId,
                        text: "",
                        isInternal: false
                      });
                    }
                    setValue(addContactTo, newValues, {
                      shouldDirty: true,
                      shouldValidate: true
                    });
                    switch (addContactTo) {
                      case "cc":
                        setCC(newValues);
                        break;
                      case "bcc":
                        setBCC(newValues);
                        break;
                      case "to":
                        setTo(newValues);
                        break;
                      case "meetingParticipants":
                        setParticipants(newValues);
                        break;
                      case "callParticipants":
                        setParticipants(newValues);
                        break;
                    }
                  }
                }}
              />
            )}
          </Grid>
        </Form>
      </Loader>
      <Footer
        saveBtnClick={onSave}
        cancelBtnClick={() => setOpenCancelDialog(true)}
        isDisabled={!isValid || !isDirty || isSaving}
        isLoading={isLoading || isUploadingImage || isSaving}
      />

      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => window.history.back()}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
