import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import {
  ModelAccount,
  ModelOrder
} from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";

export const OrdersAuditLogUpdate = ({
  before,
  after
}: {
  before: object | ModelOrder;
  after: object | ModelOrder;
  recordType?: "CREATE" | "UPDATE" | "DELETE";
}) => {
  const currentOrder = after as ModelOrder;
  const oldOrder = before as ModelOrder;

  const getOrderDetails = () => {
    const currentOrderAccount = currentOrder.account as ModelAccount;
    const oldOrderAccount = oldOrder.account as ModelAccount;
    const labelValues: AuditLogLabelValue[] = [];

    const compareAndPush = (
      label: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      currentValue: any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      oldValue: any
    ) => {
      if (currentValue !== oldValue) {
        labelValues.push({
          label: `Update the ${label} field:`,
          value: [{ value: currentValue, oldValue }]
        });
      }
    };

    compareAndPush(
      '"Order Submitted By"',
      `${currentOrder?.submittedBy?.person?.firstName} ${currentOrder?.submittedBy?.person?.lastName}`,
      `${oldOrder?.submittedBy?.person?.firstName} ${oldOrder?.submittedBy?.person?.lastName}`
    );
    compareAndPush(
      '"Order Date"',
      currentOrder?.orderDate?.toLocaleDateString(),
      oldOrder?.orderDate?.toLocaleDateString()
    );
    compareAndPush('"Product"', currentOrder?.product, oldOrder?.product);
    compareAndPush(
      '"Order Type"',
      currentOrder?.orderType,
      oldOrder?.orderType
    );
    compareAndPush(
      '"Start Date"',
      currentOrder?.originalStartDate?.toLocaleDateString(),
      oldOrder?.originalStartDate?.toLocaleDateString()
    );
    compareAndPush(
      '"End Date"',
      currentOrder?.originalEndDate?.toLocaleDateString(),
      oldOrder?.originalEndDate?.toLocaleDateString()
    );
    compareAndPush(
      '"No. of Yearly Athlete Registrations" field 1',
      currentOrder?.initialNumberOfAthletes?.toString(),
      oldOrder?.initialNumberOfAthletes?.toString()
    );
    compareAndPush(
      '"No. of Yearly Athlete Registrations" field 2',
      `${currentOrder?.ratePerAthlete || ""} Per Athlete, Per Registration`,
      `${oldOrder?.ratePerAthlete || ""} Per Athlete, Per Registration`
    );
    compareAndPush(
      '"Estimated Yearly Amount"',
      `$${currentOrder?.totalAmount?.toLocaleString()}`,
      `$${oldOrder?.totalAmount?.toLocaleString()}`
    );
    compareAndPush(
      '"Billing Option"',
      currentOrder?.billingType,
      oldOrder?.billingType
    );
    compareAndPush('"Tax Exempt"', currentOrder?.status, oldOrder?.status);
    compareAndPush('"Notes"', currentOrder?.notes, oldOrder?.notes);

    // Account Details
    compareAndPush(
      'Account Details "Account Name"',
      currentOrderAccount?.name,
      oldOrderAccount?.name
    );
    compareAndPush(
      'Account Details "Account Parent"',
      currentOrderAccount?.parent?.name,
      oldOrderAccount?.parent?.name
    );
    compareAndPush(
      'Account Details "Account Category"',
      currentOrderAccount?.category,
      oldOrderAccount?.category
    );
    compareAndPush(
      'Account Details "Account Type"',
      currentOrderAccount?.type,
      oldOrderAccount?.type
    );
    compareAndPush(
      'Account Details "Account Address"',
      currentOrderAccount?.officeAddress,
      oldOrderAccount?.officeAddress
    );
    compareAndPush(
      'Account Details "Account Email"',
      currentOrderAccount?.email,
      oldOrderAccount?.email
    );
    compareAndPush(
      'Account Details "Account Website"',
      currentOrderAccount?.website,
      oldOrderAccount?.website
    );
    compareAndPush(
      'Account Details "Account Owner"',
      `${currentOrderAccount?.aeOwner?.person?.firstName} ${currentOrderAccount?.aeOwner?.person?.lastName}`,
      `${oldOrderAccount?.aeOwner?.person?.firstName} ${oldOrderAccount?.aeOwner?.person?.lastName}`
    );

    return labelValues;
  };

  return (
    <AuditLogLabelValueList
      recordType="UPDATE"
      labelValues={[...getOrderDetails()]}
    />
  );
};
