import { Container } from "@components/crud/Container";
import { AuditLogLabelValue } from "../types";
import { Grid, styled } from "@mui/material";
import ReactPlayer from "react-player";
import ImagePlaceholder from "@assets/images/imagePlaceholder.png";
import { useState } from "react";
import { FeedMediaViewer } from "@components/FeedMediaViewer";
import { ModelMedia } from "@sportsgravyengineering/sg-api-react-sdk";
import { Editor } from "@components/Editor";

const StyledReactPlayer = styled(ReactPlayer)(() => ({
  "& video": {
    objectFit: "cover",
    width: "100%",
    height: "100%"
  }
}));

const StyledEditor = styled("div")`
  .ql-editor {
    padding: 0 !important;
  }
`;

export const AuditLogLabelValueList = ({
  recordType,
  labelValues,
  hideHeader
}: {
  recordType: "CREATE" | "DELETE" | "UPDATE";
  labelValues: AuditLogLabelValue[];
  hideHeader?: boolean;
}) => {
  const [mediaViewer, setMediaViewer] = useState<ModelMedia | undefined>(
    undefined
  );
  const renderValue = (v: AuditLogLabelValue["value"][0]) => {
    return (
      <>
        {v.isMedia &&
        typeof v.value === "object" &&
        (["IMAGE"].includes(v.value.type!) ||
          v.value.path?.split(".").pop() === "m3u8") ? (
          <img
            src={
              v.value.type === "IMAGE"
                ? v.value.baseUrl
                  ? v.value.baseUrl + v.value?.path?.replace("original/", "")
                  : ""
                : v.value.path?.split(".").pop() === "m3u8"
                  ? v.value.path
                      .replace(".m3u8", ".0000001.jpg")
                      .replace("original/", "")
                  : ""
            }
            onError={(e) => {
              e.target.src = ImagePlaceholder;
            }}
            onClick={() =>
              typeof v.value === "object" && setMediaViewer(v.value)
            }
            style={{
              width: "54px",
              height: "54px",
              borderRadius: "4px",
              cursor: "pointer",
              backgroundColor: "#F5F5F5"
            }}
          />
        ) : v.isMedia &&
          typeof v.value === "object" &&
          ["VIDEO"].includes(v.value.type!) ? (
          <StyledReactPlayer
            height="54px"
            width="54px"
            style={{ cursor: "pointer" }}
            url={v.value.baseUrl ? v.value.baseUrl + v.value.path : ""}
            onClick={() =>
              typeof v.value === "object" && setMediaViewer(v.value)
            }
          />
        ) : v.isRickText && typeof v.value === "object" ? (
          <StyledEditor>
            <Editor
              html={v.value as string}
              disabled
              style={{
                cursor: "default",
                width: "100%",
                fontSize: "16px",
                color: "#000 !important",
                borderRadius: "4px",
                marginTop: "-5px",
                border: "none !important",
                boxShadow: "none !important"
              }}
            />
          </StyledEditor>
        ) : typeof v.value === "string" ? (
          <pre
            style={{
              fontFamily: "inter",
              fontSize: "16px",
              color: v.isLink ? "#007AFF" : "#000",
              cursor: v.isLink ? "pointer" : "default",
              textDecoration: v.isLink ? "underline" : "none",
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              overflowWrap: "break-word"
            }}
            onClick={() =>
              v.isLink &&
              typeof v.value === "string" &&
              window.open(v.value, "_blank")
            }
          >
            {v.value}
          </pre>
        ) : null}
      </>
    );
  };

  return (
    <Container>
      {!hideHeader && recordType !== "UPDATE" && (
        <span
          style={{ fontWeight: 700, fontSize: "16px", marginBottom: "24px" }}
        >{`${recordType === "CREATE" ? "Added" : "Deleted"} Records`}</span>
      )}
      <Grid container direction="column" spacing="24px">
        {labelValues.length === 0 && (
          <Grid item container direction="column">
            <span style={{ fontWeight: 500, fontSize: "16px" }}>
              No {recordType === "UPDATE" ? "Edits" : "Changes"} Made
            </span>
          </Grid>
        )}
        {!!labelValues.length &&
          labelValues.map((l) => {
            return (
              <Grid
                item
                container
                direction="column"
                key={l.label}
                spacing="5px"
              >
                <Grid item>
                  <span style={{ fontWeight: 700, fontSize: "16px" }}>
                    {l.label}
                  </span>
                </Grid>

                {l.value.map((v, idx) => {
                  return (
                    <Grid
                      item
                      container
                      direction="column"
                      key={idx}
                      spacing="5px"
                    >
                      {v.subText && (
                        <Grid item marginBottom="4px" marginTop="4px">
                          <span style={{ fontWeight: 500, fontSize: "16px" }}>
                            {v.subText}
                          </span>
                        </Grid>
                      )}

                      <Grid
                        item
                        key={idx}
                        container
                        direction="row"
                        spacing="5px"
                        alignItems="center"
                        marginTop="4px"
                        style={{
                          flexWrap: "nowrap",
                          alignItems: "flex-start"
                        }}
                      >
                        {!l.hideBadge && (
                          <Grid
                            item
                            style={{
                              ...(recordType === "DELETE"
                                ? {
                                    backgroundColor: "#FFEEEE",
                                    color: "#A11212"
                                  }
                                : {
                                    backgroundColor: "#E2FCF7",
                                    color: "#095A4A"
                                  }),
                              padding: "2px 6px 2px 6px",
                              marginLeft: "5px",
                              fontWeight: 500,
                              fontSize: "14px",
                              borderRadius: "4px",
                              minWidth: "fit-content"
                            }}
                          >
                            {recordType === "UPDATE"
                              ? "New Value"
                              : "Current Value"}
                          </Grid>
                        )}
                        <Grid
                          item
                          marginTop="-5px"
                          style={{
                            maxWidth:
                              recordType === "UPDATE" && v.oldValue
                                ? "45%"
                                : "100%"
                          }}
                        >
                          {renderValue(v)}
                        </Grid>
                        {recordType === "UPDATE" && v.oldValue && (
                          <Grid
                            item
                            container
                            direction="row"
                            spacing="5px"
                            alignItems="center"
                            xs={6}
                            style={{
                              flexWrap: "nowrap",
                              alignItems: "flex-start"
                            }}
                          >
                            <Grid
                              item
                              style={{
                                backgroundColor: "#FFEEEE",
                                color: "#A11212",
                                padding: "2px 6px 2px 6px",
                                marginLeft: "10px",
                                fontWeight: 500,
                                fontSize: "14px",
                                borderRadius: "4px",
                                minWidth: "fit-content"
                              }}
                            >
                              Old Value
                            </Grid>

                            <Grid item marginTop="-5px">
                              {renderValue({
                                ...v,
                                value: v.oldValue
                              })}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
      </Grid>
      {mediaViewer && (
        <FeedMediaViewer
          close={() => setMediaViewer(undefined)}
          mediaFiles={[mediaViewer]}
          currentIndex={mediaViewer.mediaId}
        />
      )}
    </Container>
  );
};
