import { ModelHelpCategory } from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { HelpCategoriesAuditLogUpdate } from "./HelpCategoriesAuditLogUpdate";
import { HelpCategoriesAuditLogCreateDelete } from "./HelpCategoriesAuditLogCreateDelete";

export const HelpCategoriesAuditLogDetails = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelHelpCategory;
  after: object | ModelHelpCategory;
}) => {
  if (recordType === "UPDATE")
    return <HelpCategoriesAuditLogUpdate before={before} after={after} />;
  else
    return (
      <HelpCategoriesAuditLogCreateDelete
        recordType={recordType}
        before={before}
        after={after}
      />
    );
};
