import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { ModelPerson } from "@sportsgravyengineering/sg-api-react-sdk";

export const UsersAuditLogCreateDelete = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "DELETE";
  before: object | ModelPerson;
  after: object | ModelPerson;
}) => {
  const userDetails = (recordType === "CREATE" ? after : before) as ModelPerson;

  const getUserDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    if (userDetails.avatar) {
      labelValues.push({
        label: `"Avatar" field:`,
        value: [
          {
            value: userDetails?.avatar || "",
            isMedia: true
          }
        ]
      });
    }

    labelValues.push({
      label: `"First Name" field:`,
      value: [
        {
          value: userDetails?.firstName || ""
        }
      ]
    });
    if (userDetails?.middleName)
      labelValues.push({
        label: `"Middle Name" field:`,
        value: [
          {
            value: userDetails?.middleName || ""
          }
        ]
      });
    labelValues.push({
      label: `"Last Name" field:`,
      value: [
        {
          value: userDetails?.lastName || ""
        }
      ]
    });
    if (userDetails.suffix) {
      labelValues.push({
        label: `"Suffix" field:`,
        value: [
          {
            value: userDetails?.suffix || ""
          }
        ]
      });
    }

    labelValues.push({
      label: `"Email" field:`,
      value: [
        {
          value: userDetails?.email || ""
        }
      ]
    });
    if (userDetails.birthedAt) {
      labelValues.push({
        label: `"Date of Birth" field:`,
        value: [
          {
            value:
              new Date(userDetails?.birthedAt).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric"
              }) || ""
          }
        ]
      });
    }

    if (userDetails.user) {
      userDetails.user?.roles?.forEach((role) => {
        labelValues.push({
          label: `Roles & Sub Roles "Admin" field:`,
          value: [
            {
              value: role.role?.name === "Admin" ? "On" : "Off"
            }
          ]
        });
        labelValues.push({
          label: `Roles & Sub Roles "Jr. Admin" field:`,
          value: [
            {
              value: role.role?.name === "Jr. Admin" ? "On" : "Off"
            }
          ]
        });
      });
    }

    userDetails.guardians?.forEach((guardian) => {
      labelValues.push({
        label: `Parent Guardian Details "First Name" field:`,
        value: [
          {
            value: guardian.guardian?.firstName || ""
          }
        ]
      });
      labelValues.push({
        label: `Parent Guardian Details "Last Name" field:`,
        value: [
          {
            value: guardian.guardian?.lastName || ""
          }
        ]
      });
      labelValues.push({
        label: `Parent Guardian Details "Email" field:`,
        value: [
          {
            value: guardian.guardian?.email || ""
          }
        ]
      });
    });

    return labelValues;
  };
  return (
    <AuditLogLabelValueList
      recordType={recordType}
      labelValues={[...getUserDetails()]}
    />
  );
};
