import { Grid } from "@mui/material";
import { ModelAuditLog } from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";

export const UserSystemDetails = ({ log }: { log: ModelAuditLog }) => {
  return (
    <Grid
      container
      direction="column"
      spacing="15px"
      mt="30px"
      padding="12px"
      style={{
        backgroundColor: "#F3F4F7",
        borderRadius: "8px"
      }}
    >
      <Grid item style={{ padding: "0px" }}>
        <span style={{ fontWeight: 600, fontSize: "16px" }}>
          User System Details:
        </span>
      </Grid>
      <Grid item container direction="row" spacing="12px">
        <Grid
          item
          container
          direction="row"
          xs={6}
          justifyContent="space-between"
          style={{ paddingLeft: 0 }}
        >
          <Grid
            item
            style={{
              fontSize: "16px",
              fontWeight: 500,
              color: "#000000"
            }}
          >
            Operating System:
          </Grid>
          <Grid
            item
            style={{ fontSize: "16px", color: "#666666", fontWeight: 400 }}
          >
            {log.operatingSystem}
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          xs={6}
          justifyContent="space-between"
          style={{ paddingLeft: "24px" }}
        >
          <Grid
            item
            style={{
              fontSize: "16px",
              fontWeight: 500,
              color: "#000000"
            }}
          >
            IP Address:
          </Grid>
          <Grid
            item
            style={{ fontSize: "16px", color: "#666666", fontWeight: 400 }}
          >
            {log.ipAddress}
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing="12px">
        <Grid
          item
          container
          direction="row"
          xs={6}
          justifyContent="space-between"
          style={{ paddingLeft: 0 }}
        >
          <Grid
            item
            style={{
              fontSize: "16px",
              fontWeight: 500,
              color: "#000000"
            }}
          >
            Browser:
          </Grid>
          <Grid
            item
            style={{ fontSize: "16px", color: "#666666", fontWeight: 400 }}
          >
            {log.browser}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
