import { ModelAdvertiser } from "@sportsgravyengineering/sg-api-react-sdk";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "../types";

export const FSOAdvertiserAduitLog = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelAdvertiser;
  after: object | ModelAdvertiser;
}) => {
  const advertiser = (
    recordType !== "DELETE" ? after : before
  ) as ModelAdvertiser;
  const oldAdvertiser = before as ModelAdvertiser;

  const getCreateLabels = () => {
    const labels: AuditLogLabelValue[] = [];

    labels.push(
      {
        label: `"Advertiser Logo" field:`,
        value: [
          {
            value: advertiser.logo!,
            isMedia: true
          }
        ]
      },
      {
        label: `"Search and Select Account" field:`,
        value: [
          {
            value: advertiser.account?.name || ""
          }
        ]
      },
      {
        label: `"Advertiser Display Name" field:`,
        value: [
          {
            value: advertiser.businessName || ""
          }
        ]
      }
    );

    advertiser.contacts
      ?.filter((contact) => contact.isApprovalRequired)
      .map((contact) => {
        labels.push(
          {
            label: `Primary Contact ${contact.contact?.firstName} ${contact.contact?.lastName} "Permission to approve and decline advertisements" field:`,
            value: [
              {
                value: "Yes"
              }
            ]
          },
          {
            label: `Primary Contact ${contact.contact?.firstName} ${contact.contact?.lastName} "Send Approval Emails To" field:`,
            value: [
              {
                value: contact.sendApprovalEmailsToPersonal
                  ? "Personal Email"
                  : "Work Email"
              }
            ]
          }
        );
      });

    return labels;
  };

  const getUpdateLabels = () => {
    const labels: AuditLogLabelValue[] = [];

    if (advertiser.logoId !== oldAdvertiser.logoId)
      labels.push({
        label: `Updated the "Advertiser Logo" field:`,
        value: [
          {
            value: advertiser.logo || "N/A",
            oldValue: oldAdvertiser.logo || "N/A",
            isMedia: true
          }
        ]
      });

    if (advertiser.account?.name !== oldAdvertiser.account?.name)
      labels.push({
        label: `Updated the "Search and Select Account" field:`,
        value: [
          {
            value: advertiser.account?.name || "N/A",
            oldValue: oldAdvertiser.account?.name || "N/A"
          }
        ]
      });

    if (advertiser.businessName !== oldAdvertiser.businessName)
      labels.push({
        label: `Updated the "Advertiser Display Name" field:`,
        value: [
          {
            value: advertiser.businessName || "N/A",
            oldValue: oldAdvertiser.businessName || "N/A"
          }
        ]
      });

    const oldContactsMap = new Map(
      oldAdvertiser.contacts?.map((c) => [c.contactId, c]) || []
    );

    advertiser.contacts
      ?.filter((contact) => {
        const oldContact = oldContactsMap.get(contact.contactId);
        return (
          oldContact &&
          (oldContact.sendApprovalEmailsToPersonal !==
            contact.sendApprovalEmailsToPersonal ||
            oldContact.isApprovalRequired !== contact.isApprovalRequired)
        );
      })
      .forEach((contact) => {
        labels.push(
          ...(contact.isApprovalRequired !==
          oldContactsMap.get(contact.contactId)?.isApprovalRequired
            ? [
                {
                  label: `Updated Primary Contact ${contact.contact?.firstName} ${contact.contact?.lastName} "Permission to approve and decline advertisements" field:`,
                  value: [
                    {
                      value: contact.isApprovalRequired ? "Yes" : "No",
                      oldValue: oldContactsMap.get(contact.contactId)
                        ?.isApprovalRequired
                        ? "Yes"
                        : "No"
                    }
                  ]
                }
              ]
            : []),
          {
            label: `Updated Primary Contact ${contact.contact?.firstName} ${contact.contact?.lastName} "Send Approval Emails To" field:`,
            value: [
              {
                value: contact.isApprovalRequired
                  ? contact.sendApprovalEmailsToPersonal
                    ? "Personal Email"
                    : "Work Email"
                  : "N/A",
                oldValue: oldContactsMap.get(contact.contactId)
                  ?.isApprovalRequired
                  ? oldContactsMap.get(contact.contactId)
                      ?.sendApprovalEmailsToPersonal
                    ? "Personal Email"
                    : "Work Email"
                  : "N/A"
              }
            ]
          }
        );
      });

    return labels;
  };

  if (recordType === "UPDATE")
    return (
      <AuditLogLabelValueList
        recordType="UPDATE"
        labelValues={[...getUpdateLabels()]}
      />
    );
  else
    return (
      <AuditLogLabelValueList
        recordType={recordType}
        labelValues={[...getCreateLabels()]}
      />
    );
};
