/* eslint-disable @typescript-eslint/no-explicit-any */
import { Loader } from "@components/crud/Loader";
import { Column, DataGridTable } from "@components/DataGridTable";
import { FormSelect } from "@components/FormSelect";
import { Divider, Grid, styled, Typography } from "@mui/material";
import { TimeFilter } from "@pages/dashboard/components/TimeFilter";
import {
  adminDirectoryGet,
  useAdminTimeLogGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useQuery } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";
import NoDataIcon from "@assets/icons/no-data-icon.svg";

const convertSecondsToHHMM = (seconds: number): string => {
  const hh = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, "0");
  const mm = Math.floor((seconds % 3600) / 60)
    .toFixed(0)
    .padStart(2, "0");

  return `${hh}:${mm}`;
};

const StyledGrid = styled(Grid)`
  border: 1px solid rgba(0, 0, 0, 0.23);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  margin-top: 16px;
  width: calc(100vw - 340px);

  .MuiTable-root {
    .hidden-col {
      background-color: #ededed !important;
    }
    .expand-btn {
      padding: 0 !important;
      background-color: #ededed !important;
    }
    .expand-btn-body {
      padding: 0 !important;
    }
  }
`;

const StyledValue = styled(Typography)`
  font-size: 14px;
`;

const StyledTotalValue = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
`;

export const TimeLogged = () => {
  const [selectedTimeRange, setSelectedTimeRange] = useState<[any, any]>([
    null,
    null
  ]);
  const [resourceId, setResourceId] = useState<string | undefined>(undefined);
  const [refreshKey, setRefreshKey] = useState(0);
  const [timeFilter, setTimeFilter] = useState<
    "TODAY" | "YESTERDAY" | "CURRENT" | "PREVIOUS"
  >("TODAY");

  const { data: companyDirectory, isLoading: isLoading } = useQuery(
    ["companyDirectory"],
    () =>
      adminDirectoryGet({
        pageSize: 1000
      }),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: true
    }
  );

  const resourcesOptions = useMemo(() => {
    let options: Array<{ label: string; value: string }> = [];
    if (companyDirectory?.data.persons) {
      options = companyDirectory.data.persons
        .filter((resource) => resource.sportsgravyUser)
        .map((resource) => ({
          label: `${resource.firstName} ${resource.lastName}`,
          value: resource?.userId as string
        }));
    }
    options.unshift({ label: "All", value: "ALL" });
    return options;
  }, [companyDirectory?.data]);

  const query = React.useMemo(() => {
    const buildQuery = {} as {
      dtStart?: string;
      dtEnd?: string;
      resourceId?: string;
    };

    if (selectedTimeRange[0] && selectedTimeRange[1]) {
      buildQuery.dtStart = selectedTimeRange[0];
      buildQuery.dtEnd = selectedTimeRange[1];
    }
    if (resourceId && resourceId !== "ALL") buildQuery.resourceId = resourceId;

    return buildQuery;
  }, [selectedTimeRange, resourceId]);

  const { data: timeLogData, isLoading: timeLogLoading } = useAdminTimeLogGet(
    query,
    {
      query: {
        queryKey: ["timeLog", refreshKey, selectedTimeRange, resourceId],
        refetchOnWindowFocus: false
      }
    }
  );

  const COLUMNS: Column[] = [
    {
      field: "name",
      headerName: "Resource",
      width: 350,
      sortable: false,
      align: "left",
      renderCell: (params) => <StyledValue>{params.name}</StyledValue>
    },
    {
      field: "totalTimeSpent",
      headerName: "Total Time Logged",
      width: 150,
      sortable: false,
      borderLeft: "1px solid #E5E5E5",
      align: "center",
      renderCell: (params) => (
        <StyledValue>{convertSecondsToHHMM(params.totalTimeSpent)}</StyledValue>
      )
    },
    {
      field: "storyCardTime",
      headerName: "Story",
      width: 150,
      sortable: false,
      align: "center",
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => (
        <StyledValue>{convertSecondsToHHMM(params.storyCardTime)}</StyledValue>
      )
    },
    {
      field: "bugCardTime",
      headerName: "Bug",
      width: 150,
      sortable: false,
      align: "center",
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => (
        <StyledValue>{convertSecondsToHHMM(params.bugCardTime)}</StyledValue>
      )
    },
    {
      field: "taskCardTime",
      headerName: "Task",
      width: 150,
      sortable: false,
      align: "center",
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => (
        <StyledValue>{convertSecondsToHHMM(params.taskCardTime)}</StyledValue>
      )
    },
    {
      field: "releasesTime",
      headerName: "QA Releases",
      width: 150,
      sortable: false,
      align: "center",
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => (
        <StyledValue>{convertSecondsToHHMM(params.releasesTime)}</StyledValue>
      )
    },
    {
      field: "buildTime",
      headerName: "QA Builds",
      width: 150,
      sortable: false,
      align: "center",
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => (
        <StyledValue>{convertSecondsToHHMM(params.buildTime)}</StyledValue>
      )
    }
  ];

  const TOTAL_VALUES = [
    {
      field: "totalTimeSpent",
      headerName: "Total Time Logged",
      width: 150,
      sortable: false,
      borderLeft: "1px solid #E5E5E5",
      align: "center",
      renderCell: (params) => (
        <StyledTotalValue>
          {convertSecondsToHHMM(
            params.reduce((acc, row) => acc + row.totalTimeSpent, 0)
          )}
        </StyledTotalValue>
      )
    },
    {
      field: "storyCardTime",
      headerName: "Story",
      width: 150,
      sortable: false,
      align: "center",
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => (
        <StyledTotalValue>
          {convertSecondsToHHMM(
            params.reduce((acc, row) => acc + row.storyCardTime, 0)
          )}
        </StyledTotalValue>
      )
    },
    {
      field: "bugCardTime",
      headerName: "Bug",
      width: 150,
      sortable: false,
      align: "center",
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => (
        <StyledTotalValue>
          {convertSecondsToHHMM(
            params.reduce((acc, row) => acc + row.bugCardTime, 0)
          )}
        </StyledTotalValue>
      )
    },
    {
      field: "taskCardTime",
      headerName: "Task",
      width: 150,
      sortable: false,
      align: "center",
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => (
        <StyledTotalValue>
          {convertSecondsToHHMM(
            params.reduce((acc, row) => acc + row.taskCardTime, 0)
          )}
        </StyledTotalValue>
      )
    },
    {
      field: "releasesTime",
      headerName: "QA Releases",
      width: 150,
      sortable: false,
      align: "center",
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => (
        <StyledTotalValue>
          {convertSecondsToHHMM(
            params.reduce((acc, row) => acc + row.releasesTime, 0)
          )}
        </StyledTotalValue>
      )
    },
    {
      field: "buildTime",
      headerName: "QA Builds",
      width: 150,
      sortable: false,
      align: "center",
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => (
        <StyledTotalValue>
          {convertSecondsToHHMM(
            params.reduce((acc, row) => acc + row.buildTime, 0)
          )}
        </StyledTotalValue>
      )
    }
  ];

  console.log(timeFilter);

  return (
    <Loader isLoading={isLoading}>
      <FormSelect
        options={resourcesOptions}
        name="resources"
        sx={{
          maxWidth: "400px",
          marginTop: "-16px"
        }}
        onChange={(e) => {
          setResourceId(e.target.value);
          setRefreshKey((prev) => prev + 1);
        }}
      />
      <StyledGrid
        container
        direction="column"
        padding="0px"
        style={{
          minHeight:
            isLoading || timeLogLoading || !timeLogData?.data ? "400px" : "0px"
        }}
      >
        <Grid
          item
          padding="16px 24px 16px 24px"
          container
          direction="row"
          width="100%"
          justifyContent="space-between"
        >
          <Grid item container direction="column" spacing="2px" xs={6}>
            <Grid item>
              <Typography
                style={{ color: "#1E293B", fontWeight: 700, fontSize: "16px" }}
              >
                Time Logged
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{
                  color: "#64748B",
                  fontWeight: "500",
                  fontSize: "14px"
                }}
              >
                Quick Insights related to SportsGravy
              </Typography>
            </Grid>
          </Grid>
          <Grid item alignSelf="center">
            <TimeFilter
              selectedTimeRange={selectedTimeRange}
              setRefreshKey={setRefreshKey}
              refreshKey={refreshKey}
              setSelectedTimeRange={setSelectedTimeRange}
              isQaMetric={true}
              defaultTimeFilter="TODAY"
              setSelectedTimeFilter={setTimeFilter}
            />
          </Grid>
        </Grid>

        <Grid item marginTop="-5px">
          <Divider />
        </Grid>
        <Loader isLoading={timeLogLoading}>
          {!timeLogLoading && !timeLogData?.data ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <img src={NoDataIcon} style={{ width: "64px" }} />
              <Typography
                style={{
                  color: "#64748b",
                  fontSize: "14px",
                  fontWeight: 500,
                  padding: "16px 24px"
                }}
              >
                No data available
              </Typography>
            </div>
          ) : (
            <DataGridTable
              rows={timeLogData?.data || []}
              columns={COLUMNS}
              totalValues={TOTAL_VALUES}
            />
          )}
        </Loader>
      </StyledGrid>
    </Loader>
  );
};
