import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { ModelFaq } from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";

export const FaqAuditLogUpdate = ({
  before,
  after
}: {
  before: object | ModelFaq;
  after: object | ModelFaq;
}) => {
  const currentFaq = after as ModelFaq;
  const oldFaq = before as ModelFaq;

  const getFaqDetails = () => {
    const labelValues: AuditLogLabelValue[] = [];
    if (currentFaq.question !== oldFaq.question) {
      labelValues.push({
        label: `Updated the "Question" field:`,
        value: [
          {
            value: currentFaq?.question || "",
            oldValue: oldFaq?.question || ""
          }
        ]
      });
    }
    if (currentFaq.answer !== oldFaq.answer) {
      labelValues.push({
        label: `Updated the "Answer" field:`,
        value: [
          {
            value: currentFaq?.answer || "",
            oldValue: oldFaq?.answer || ""
          }
        ]
      });
    }
    if (currentFaq.helpCategory?.name !== oldFaq.helpCategory?.name) {
      labelValues.push({
        label: `Updated the "Category" field:`,
        value: [
          {
            value: currentFaq?.helpCategory?.name || "",
            oldValue: oldFaq?.helpCategory?.name || ""
          }
        ]
      });
    }
    if (currentFaq.relatedTo !== oldFaq.relatedTo) {
      labelValues.push({
        label: `Updated the "Related To" field:`,
        value: [
          {
            value: currentFaq?.relatedTo || "",
            oldValue: oldFaq?.relatedTo || ""
          }
        ]
      });
    }

    const currentDisplayChannel = currentFaq.displayChannel.join(", ");
    const oldDisplayChannel = oldFaq.displayChannel.join(", ");
    if (currentDisplayChannel !== oldDisplayChannel) {
      labelValues.push({
        label: `Updated the "Display Channel" field:`,
        value: [
          {
            value: currentDisplayChannel,
            oldValue: oldDisplayChannel
          }
        ]
      });
    }
    return labelValues;
  };
  return (
    <AuditLogLabelValueList
      recordType="UPDATE"
      labelValues={[...getFaqDetails()]}
    />
  );
};
