import {
  FormLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  styled
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { TimePicker } from "@mui/x-date-pickers-pro";
import { Box } from "@mui/system";
import {
  AdminSettingGetObject,
  ModelConfig,
  ModelMedia,
  mediaGet,
  useAdminSettingsGet,
  useAdminSettingsPut,
  useConfigGet,
  useConfigPut,
  useMediaGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormSwitch } from "@components/FormSwitch";
import { MediaSelector } from "@components/MediaSelector";
import GamePickerImage from "@assets/images/landscapeImagePicker.png";
import { uploadAdminMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { FormSelect } from "@components/FormSelect";
import { FormInput } from "@components/FormInput";
import { hasPermission } from "@services/Casbin";
import { Toolbar } from "@components/crud/Toolbar";
import { Edit } from "@mui/icons-material";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Footer } from "@components/crud/Footer";
import { LiveStreamTaxFees, TaxFee } from "./LiveStreamTaxFees";
// import { CRMOrderTaxFess, TaxFee } from "./crm/CRMOrderTaxFess";

const StyledGrid = styled(Grid)`
  padding: 0 24px 24px 24px;
  .toolbar {
    padding: 24px 0 0 0;
  }
`;

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const LiveStreamingSetting = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const [media, setMedia] = useState<ModelMedia[]>([]);
  const [mediaIds, setMediaIds] = useState<string[]>([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [permission, setPermission] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [imagesExist, setImagesExist] = useState<boolean>(false);
  const [taxFees, setTaxFees] = useState<TaxFee[]>([]);
  const [uploadingMedia, setUploadingMedia] = useState<boolean>(false);
  const organization = organizations.find(
    (org) => org.organizationId === organizationId
  );
  const {
    data: settings,
    isFetching: loading,
    refetch
  } = !organizationId
    ? useConfigGet()
    : useAdminSettingsGet({
        organizationId: organizationId!,
        parentId: "org-live-stream"
      });
  const { mutate: save, isLoading: isSavingFSGO } = useConfigPut();
  const { mutate: FSOSave, isLoading: isSavingFSO } = useAdminSettingsPut();

  const onSave = (key?: string, value?) => {
    let data = {};
    if (key && value) {
      data = [
        {
          key: key,
          value: value
        }
      ];
    } else {
      data = [
        {
          key: "live-stream.go-live-button",
          value: getValues("live-stream.go-live-button")
        },
        {
          key: "live-stream.inactive-stream-cancellation-time",
          value: getValues("live-stream.inactive-stream-cancellation-time")
        },
        {
          key: "live-stream.pre-roll-ad-length",
          value: getValues("live-stream.pre-roll-ad-length")
        },
        {
          key: "live-stream.skip-rule",
          value: getValues("live-stream_skip-rule")
        },
        {
          key: "live-stream.default-live-stream-image",
          value: getValues("live-stream_default-live-stream-image")
        }
      ];
    }
    save(
      {
        data: {
          configs: data as ModelConfig[],
          type: "MOBILE"
        }
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Saved Successfully!", {
            variant: "success"
          });
          setDisabled(true);
        },
        onError: () => {
          enqueueSnackbar("Failed to save !", {
            variant: "error"
          });
        }
      }
    );
  };

  const form = useForm({
    mode: "onTouched"
  });
  const { reset, control, setValue, getValues } = form;

  const onFSOSave = () => {
    const data = getValues().selectedSettings;
    data.find((s) => s.settingId === "org-live-stream.taxes-and-fees").value =
      getValues("taxFee");
    FSOSave(
      {
        data: data,
        params: {
          organizationId: organizationId!
        }
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Saved Successfully!", {
            variant: "success"
          });
          setDisabled(true);
        },
        onError: () => {
          enqueueSnackbar("Failed to save !", {
            variant: "error"
          });
        }
      }
    );
  };

  const onMediaUpload = async (sportId: string, files) => {
    const filesPromises = await Promise.all(
      files.map((file) => {
        if (file instanceof File) {
          const promise = uploadAdminMediaUsingPresignedUrl(file);
          return promise;
        } else {
          return file.mediaId;
        }
      })
    );
    let fileUrl = "";
    if (filesPromises) {
      const response = await mediaGet({
        mediaIds: [filesPromises[0]]
      });
      if (response && response.data.media && response.data.media.length) {
        const media = response.data.media[0];
        if (media.media && media.media.baseUrl && media.media.path) {
          const path = media.media.path;
          fileUrl = media.media.baseUrl + path;
        }
      }
    }

    const idx = getValues().selectedSettings.findIndex(
      (setting) =>
        setting.settingId === "org-live-stream.default-live-stream-images"
    );
    if (idx != -1) {
      const defaultImages = getValues(`selectedSettings[${idx}].value`) as {
        sportId: string;
        mediaId: string;
        url?: string;
      }[];
      if (defaultImages) {
        const sportFound = defaultImages.findIndex(
          (image) => image.sportId === sportId
        );
        if (sportFound !== -1) {
          defaultImages[sportFound].mediaId = filesPromises[0] as string;
          defaultImages[sportFound].url = fileUrl;
        } else {
          defaultImages.push({
            sportId,
            mediaId: filesPromises[0] as string,
            url: fileUrl
          });
        }
        setValue(`selectedSettings[${idx}].value`, defaultImages);
      } else {
        setValue(`selectedSettings[${idx}].value`, [
          { sportId, mediaId: filesPromises[0] as string, url: fileUrl }
        ]);
      }
    }
  };

  const onMediaUploadFSGO = async (files) => {
    setUploadingMedia(true);
    const filesPromises = await Promise.all(
      files.map((file) => {
        if (file instanceof File) {
          const promise = uploadAdminMediaUsingPresignedUrl(file);
          return promise;
        } else {
          return file.mediaId;
        }
      })
    );
    setUploadingMedia(false);
    setValue("live-stream_default-live-stream-image", filesPromises[0]);
  };

  const { data: mediaOutputs } = useMediaGet({
    mediaIds: mediaIds
  });

  useEffect(() => {
    if (mediaOutputs) {
      setMedia(mediaOutputs.data?.media?.map((m) => m.media!) || []);
      setImagesExist(true);
    }
  }, [mediaOutputs]);

  const [liveStreamCancellationTime, setLiveStreamCancellationTime] =
    useState(0);

  useEffect(() => {
    if (settings && organizationId) {
      const settingsMap = settings.data.map((setting) => {
        if (setting.type === "SELECT_IMAGE_MULTIPLE") {
          const imagesExist =
            setting.organizationSettings && setting.organizationSettings.length
              ? setting.organizationSettings[0].value
              : undefined;
          if (imagesExist)
            setMediaIds(imagesExist.map((image) => image.mediaId));
        }
        return {
          settingId: setting.settingId,
          value:
            setting.organizationSettings && setting.organizationSettings.length
              ? setting.organizationSettings[0].value
              : setting.default
        };
      });
      const defaultValues = {
        selectedSettings: settingsMap,
        taxFee:
          ((settings.data as AdminSettingGetObject[])?.find(
            (s) => s.settingId === "org-live-stream.taxes-and-fees"
          )?.value as TaxFee[]) || []
      };
      reset(defaultValues, {
        keepDefaultValues: false
      });
      setTaxFees(
        ((settings.data as AdminSettingGetObject[])?.find(
          (s) => s.settingId === "org-live-stream.taxes-and-fees"
        )?.value as TaxFee[]) || []
      );
    } else if (settings) {
      const imageExists = settings.data.find(
        (setting) =>
          (setting as ModelConfig).key ===
          "live-stream.default-live-stream-image"
      )?.value;
      if (imagesExist) {
        setMediaIds([imageExists as string]);
        setValue("live-stream_default-live-stream-image", imageExists);
      }
      setLiveStreamCancellationTime(
        Number(
          settings?.data.find(
            (item) =>
              (item as ModelConfig).key ===
              "live-stream.inactive-stream-cancellation-time"
          )?.value
        )
      );
      setValue(
        "live-stream_skip-rule",
        settings?.data.find(
          (item) => (item as ModelConfig).key === "live-stream.skip-rule"
        )?.value
      );
    }
  }, [settings]);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("admin.settings", "EDIT");
      setPermission(edit);
    };
    fetchPermissions();
  }, []);

  const getSettingView = (settingId) => {
    const index = settings?.data.findIndex(
      (setting) => setting.settingId === settingId
    );
    if (index !== undefined && index !== -1) {
      const setting = settings!.data[index];
      switch (setting.type) {
        case "BOOL":
          return (
            <Grid
              item
              container
              direction="row"
              spacing="24px"
              key={setting.settingId}
              data-testid={setting.settingId}
            >
              <Grid item marginTop="10px">
                <Typography
                  style={{
                    color: "#1E2941",
                    fontSize: "13px",
                    font: "inter",
                    lineHeight: "normal",
                    width: "212px"
                  }}
                >
                  {setting.name}
                </Typography>
              </Grid>
              <Grid item>
                <FormSwitch
                  disabled={disabled}
                  onChange={(e) => {
                    setValue(
                      `selectedSettings[${index}].value`,
                      e.target.checked
                    );
                  }}
                  name={`selectedSettings[${index}].value`}
                  control={control}
                />
              </Grid>
            </Grid>
          );
        case "SINGLE_SELECT":
          if (
            settingId == "org-live-stream.inactive-stream-cancellation-time"
          ) {
            return (
              <Grid
                item
                container
                direction="row"
                spacing="10px"
                alignItems="center"
                data-testid={(setting as AdminSettingGetObject).settingId}
              >
                <Grid item>
                  <Typography
                    style={{
                      fontSize: "13px",
                      font: "inter",
                      lineHeight: "18px",
                      marginRight: "10px",
                      color: "#1E2941"
                    }}
                  >
                    Automatically Cancel Live Streams after
                  </Typography>
                </Grid>
                <Grid item style={{ maxWidth: "100px" }}>
                  <FormInput
                    disabled={disabled}
                    name={`selectedSettings[${index}].value`}
                    control={control}
                    type="number"
                    label=""
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Hr</InputAdornment>
                      )
                    }}
                    onChange={(e) => {
                      setValue(
                        `selectedSettings[${index}].value`,
                        parseInt(
                          (e as ChangeEvent<HTMLInputElement>).target.value
                        )
                      );
                    }}
                  />
                </Grid>

                <Grid item>
                  <Typography
                    style={{
                      fontSize: "14px",
                      font: "inter",
                      lineHeight: "18px",
                      marginLeft: "10px"
                    }}
                  >
                    of inactivity
                  </Typography>
                </Grid>
              </Grid>
            );
          } else return <></>;
        case "SELECT_IMAGE_MULTIPLE":
          return (
            <>
              {organization!.sports?.map((sport) => {
                const idx =
                  getValues() && getValues().selectedSettings
                    ? getValues().selectedSettings.findIndex(
                        (setting) =>
                          setting.settingId ===
                          "org-live-stream.default-live-stream-images"
                      )
                    : -1;
                let defaultImage = [] as ModelMedia[];
                if (idx != -1) {
                  const defaultImages = getValues(
                    `selectedSettings[${idx}].value`
                  ) as {
                    sportId: string;
                    mediaId: string;
                  }[];
                  if (defaultImages) {
                    const sportFound = defaultImages.findIndex(
                      (image) => image.sportId === sport.sportId
                    );
                    if (sportFound !== -1) {
                      defaultImage = media.filter(
                        (m) => m.mediaId === defaultImages[sportFound].mediaId
                      );
                    }
                  }
                }

                return (
                  <Grid
                    item
                    container
                    spacing="15px"
                    direction={"column"}
                    key={sport.sportId}
                    data-testid={`${setting.settingId}_${sport.sportId}_image_select`}
                  >
                    <Grid item>
                      <StyledFormLabel>
                        <Typography display="inline" variant="formLabel">
                          {setting?.name &&
                            setting?.name.replace(
                              "{sport}",
                              sport.sport?.name as string
                            )}
                        </Typography>
                      </StyledFormLabel>
                    </Grid>
                    <Grid item>
                      <MediaSelector
                        key={sport.sportId}
                        disabled={disabled}
                        setFilesToUpload={(file) => {
                          if (file.length && file[0] instanceof File)
                            onMediaUpload(sport.sportId!, file);
                        }}
                        maxImages={1}
                        uploadedFiles={imagesExist ? defaultImage : []}
                        imagePlaceHolder={GamePickerImage}
                        removeFiles={(files) => {
                          if (files.length === 0) {
                            const idx = getValues().selectedSettings.findIndex(
                              (setting) =>
                                setting.settingId ===
                                "org-live-stream.default-live-stream-images"
                            );
                            if (idx != -1) {
                              const defaultImages = getValues(
                                `selectedSettings[${idx}].value`
                              ) as {
                                sportId: string;
                                mediaId: string;
                              }[];
                              const sportFound = defaultImages.findIndex(
                                (image) => image.sportId === sport.sportId
                              );
                              if (sportFound !== -1) {
                                defaultImages.splice(sportFound, 1);
                              }
                              setValue(
                                `selectedSettings[${idx}].value`,
                                defaultImages
                              );
                            }
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </>
          );
      }
    }
    return <></>;
  };

  return (
    <Loader isLoading={loading}>
      <StyledGrid item container direction="column" rowSpacing="24px">
        {permission && disabled && (
          <Toolbar
            title=""
            addBtnClick={() => setDisabled(false)}
            addBtnLabel="Edit"
            addBtnIcon={<Edit />}
          />
        )}
        {!organizationId && (
          <>
            <Box
              display="flex"
              alignItems="center"
              marginTop="15px"
              marginLeft="30px"
              data-testid="go-live"
            >
              <Typography
                style={{
                  fontSize: "14px",
                  font: "inter",
                  lineHeight: "18px",
                  marginRight: "10px"
                }}
              >
                Show Go Live button
              </Typography>
              <TimePicker
                defaultValue={
                  new Date(
                    new Date().setHours(
                      0,
                      Number(
                        settings?.data.find(
                          (item) =>
                            (item as ModelConfig).key ===
                            "live-stream.go-live-button"
                        )?.value
                      )
                    )
                  )
                }
                sx={{ width: "100px", marginRight: "10px" }}
                views={["minutes"]}
                format="mm"
                onChange={(e) => {
                  if (e) {
                    const min = new Date(e.toString()).getMinutes();
                    setValue("live-stream.go-live-button", min);
                  }
                }}
                disabled={disabled}
                slots={{
                  openPickerIcon: () => (
                    <Typography style={{ fontSize: "14px", color: "#666666" }}>
                      Min
                    </Typography>
                  )
                }}
              />
              <Typography
                style={{ fontSize: "14px", font: "inter", lineHeight: "18px" }}
              >
                before an event’s start time.
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              marginTop="15px"
              marginLeft="30px"
              data-testid="inactivity"
            >
              <Typography
                style={{
                  fontSize: "14px",
                  font: "inter",
                  lineHeight: "18px",
                  marginRight: "10px"
                }}
              >
                Automatically Cancel Live Streams after
              </Typography>
              <TextField
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Hr</InputAdornment>
                  )
                }}
                disabled={disabled}
                style={{
                  maxWidth: "84px"
                }}
                value={liveStreamCancellationTime}
                onChange={(e) => {
                  setLiveStreamCancellationTime(parseInt(e.target.value));
                  if (e.target.value) {
                    setValue(
                      "live-stream.inactive-stream-cancellation-time",
                      parseInt(e.target.value)
                    );
                  }
                }}
              />

              <Typography
                style={{
                  fontSize: "14px",
                  font: "inter",
                  lineHeight: "18px",
                  marginLeft: "10px"
                }}
              >
                of inactivity
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              marginTop="15px"
              marginLeft="30px"
              data-testid="pre-roll-ad"
            >
              <Typography
                style={{
                  fontSize: "14px",
                  font: "inter",
                  lineHeight: "18px",
                  marginRight: "10px"
                }}
              >
                Length of Pre Roll Advertising
              </Typography>
              <TimePicker
                sx={{ width: "120px", marginRight: "10px" }}
                disabled={disabled}
                defaultValue={
                  new Date(
                    new Date().setHours(
                      0,
                      //@ts-ignore
                      settings?.data
                        .find(
                          (item) =>
                            (item as ModelConfig).key ===
                            "live-stream.pre-roll-ad-length"
                        )
                        ?.value.split(":")[0],
                      //@ts-ignore
                      settings?.data
                        .find(
                          (item) =>
                            (item as ModelConfig).key ===
                            "live-stream.pre-roll-ad-length"
                        )
                        ?.value.split(":")[1]
                    )
                  )
                }
                views={["minutes", "seconds"]}
                format="mm:ss"
                onChange={(e) => {
                  if (e) {
                    const min = new Date(e.toString()).getMinutes();
                    const sec = new Date(e.toString()).getSeconds();
                    setValue("live-stream.pre-roll-ad-length", `${min}:${sec}`);
                  }
                }}
              />
            </Box>
            {settings && (
              <Grid
                item
                container
                spacing="15px"
                direction={"column"}
                paddingLeft="30px !important"
              >
                <Grid item>
                  <StyledFormLabel>
                    <Typography display="inline" variant="formLabel">
                      Default Live Stream Image
                    </Typography>
                  </StyledFormLabel>
                </Grid>
                <Grid item data-testid="default-image">
                  <MediaSelector
                    disabled={disabled}
                    setFilesToUpload={(file) => {
                      if (file.length && file[0] instanceof File)
                        onMediaUploadFSGO(file);
                    }}
                    maxImages={1}
                    uploadedFiles={
                      imagesExist && media && media.length === 1 ? media : []
                    }
                    removeFiles={(files) => {
                      if (files.length === 0) {
                        setValue("live-stream_default-live-stream-image", null);
                      }
                    }}
                    imagePlaceHolder={GamePickerImage}
                  />
                </Grid>
              </Grid>
            )}
            <Box
              display="flex"
              alignItems="center"
              marginTop="15px"
              marginLeft="30px"
              data-testid="paid-subscriber-skip-rule"
              sx={{ paddingBottom: "24px" }}
            >
              <Typography
                style={{
                  fontSize: "14px",
                  font: "inter",
                  lineHeight: "18px",
                  marginRight: "10px",
                  minWidth: "185px"
                }}
              >
                Paid Subscriber Skip Rule
              </Typography>
              <Box sx={{ width: "260px" }}>
                <FormSelect
                  control={control}
                  name="live-stream_skip-rule"
                  options={[
                    { label: "1 Intermission", value: 1 },
                    {
                      label: "2 Intermissions",
                      value: 2
                    },
                    {
                      label: "3 Intermissions",
                      value: 3
                    }
                  ]}
                  onChange={(e) => {
                    setValue("live-stream.skip-rule", Number(e.target.value));
                  }}
                  disabled={disabled}
                />
              </Box>
            </Box>
          </>
        )}
        {organizationId && (
          <>
            {settings && (
              <Grid item container direction="column" spacing="20px">
                <Grid item>
                  <Typography
                    style={{
                      fontSize: "12px",
                      font: "inter",
                      lineHeight: "14.52px",
                      fontWeight: 400,
                      opacity: "50%",
                      marginRight: "10px"
                    }}
                  >
                    GENERAL SETTINGS
                  </Typography>
                </Grid>
                {getSettingView("org-live-stream.allow-public-sharing")}
                {getSettingView("org-live-stream.allow-pre-game")}
                {getSettingView("org-live-stream.allow-post-game")}
                {getSettingView(
                  "org-live-stream.inactive-stream-cancellation-time"
                )}
                <Grid item marginTop="20px">
                  <Typography
                    style={{
                      fontSize: "12px",
                      font: "inter",
                      lineHeight: "14.52px",
                      fontWeight: 400,
                      opacity: "50%",
                      marginRight: "10px"
                    }}
                  >
                    ADVERTISERS SETTINGS
                  </Typography>
                </Grid>
                {getSettingView("org-live-stream.advertiser-approval-required")}
                {getSettingView(
                  "org-live-stream.advertisement-live-on-approval"
                )}
                {getSettingView("org-live-stream.default-live-stream-images")}
                <Grid item container xs={12} direction="column">
                  <LiveStreamTaxFees
                    form={form}
                    disabled={disabled}
                    taxFees={taxFees}
                    setTaxFees={setTaxFees}
                  />
                </Grid>
              </Grid>
            )}
          </>
        )}
      </StyledGrid>
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => {
          refetch();
          setDisabled(true);
        }}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
      {!disabled && (
        <Footer
          saveBtnClick={organizationId ? onFSOSave : onSave}
          isLoading={isSavingFSGO || isSavingFSO || uploadingMedia}
          isDisabled={isSavingFSGO || isSavingFSO || uploadingMedia}
          saveBtnLabel="Save"
          cancelBtnClick={() => setOpenCancelDialog(true)}
        />
      )}
    </Loader>
  );
};
