import { Container } from "@components/crud/Container";
import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { ModelAdvertisement } from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalize } from "@utils/capitalize";
import { AD_LAYOUT_OPTIONS, AD_PLACEMENT_OPTIONS } from "@utils/constants";

export const AdvertisementAuditLogUpdate = ({
  before,
  after
}: {
  before: object | ModelAdvertisement;
  after: object | ModelAdvertisement;
}) => {
  const advertisement = after as ModelAdvertisement;
  const oldAdvertisement = before as ModelAdvertisement;

  const getLabels = () => {
    const labels: AuditLogLabelValue[] = [];

    if (advertisement.placement !== oldAdvertisement.placement)
      labels.push({
        label: `Updated the "Ad Placement" field:`,
        value: [
          {
            value:
              AD_PLACEMENT_OPTIONS.find(
                (option) => option.value === advertisement.placement
              )?.label || "N/A",
            oldValue: oldAdvertisement.placement
              ? AD_PLACEMENT_OPTIONS.find(
                  (option) => option.value === oldAdvertisement.placement
                )?.label
              : "N/A"
          }
        ]
      });

    if (
      (advertisement.placement !== "INSTREAM" ||
        oldAdvertisement.placement !== "INSTREAM") &&
      advertisement.layout !== oldAdvertisement.layout
    )
      labels.push({
        label: `Updated the "Ad Layout" field:`,
        value: [
          {
            value:
              AD_LAYOUT_OPTIONS.find(
                (option) => option.value === advertisement.layout
              )?.label || "N/A",
            oldValue:
              AD_LAYOUT_OPTIONS.find(
                (option) => option.value === oldAdvertisement.layout
              )?.label || "N/A"
          }
        ]
      });

    if (
      (advertisement.placement === "INSTREAM" ||
        oldAdvertisement.placement === "INSTREAM") &&
      advertisement.duration !== oldAdvertisement.duration
    )
      labels.push({
        label: `Updated the "Duration" field:`,
        value: [
          {
            value: advertisement.duration
              ? `${advertisement.duration} seconds`
              : "N/A",
            oldValue: oldAdvertisement.duration
              ? `${oldAdvertisement.duration} seconds`
              : "N/A"
          }
        ]
      });

    if (
      ((advertisement.layout &&
        !["Text_Ad_with_Logo", "Text_Ad_without_Logo"].includes(
          advertisement.layout
        )) ||
        advertisement.placement === "INSTREAM" ||
        (oldAdvertisement.layout &&
          !["Text_Ad_with_Logo", "Text_Ad_without_Logo"].includes(
            oldAdvertisement.layout
          )) ||
        oldAdvertisement.placement === "INSTREAM") &&
      advertisement.mediaId !== oldAdvertisement.mediaId
    )
      labels.push({
        label: `Updated the "${advertisement.placement === "INSTREAM" || oldAdvertisement.placement === "INSTREAM" ? "Video" : "Image"}" field:`,
        value: [
          {
            value: advertisement.media || "N/A",
            oldValue: oldAdvertisement.media || "N/A",
            isMedia: true
          }
        ]
      });

    if (advertisement.redirectLink !== oldAdvertisement.redirectLink)
      labels.push({
        label: `Updated the "Ad Redirect Link" field:`,
        value: [
          {
            value: advertisement.redirectLink || "N/A",
            oldValue: oldAdvertisement.redirectLink || "N/A",
            isLink: true
          }
        ]
      });

    if (
      advertisement.placement === "OUTSTREAM" ||
      oldAdvertisement.placement === "OUTSTREAM"
    ) {
      if (advertisement.title !== oldAdvertisement.title)
        labels.push({
          label: `Updated the "Ad Title" field:`,
          value: [
            {
              value: advertisement.title || "N/A",
              oldValue: oldAdvertisement.title || "N/A"
            }
          ]
        });
      if (advertisement.description !== oldAdvertisement.description)
        labels.push({
          label: `Updated the "Ad Description" field:`,
          value: [
            {
              value: advertisement.description || "N/A",
              oldValue: oldAdvertisement.description || "N/A"
            }
          ]
        });
    }

    if (advertisement.status !== oldAdvertisement.status)
      labels.push({
        label: `Updated the "Status" field:`,
        value: [
          {
            value: advertisement.status
              ? capitalize(advertisement.status)
              : "N/A",
            oldValue: oldAdvertisement.status
              ? capitalize(oldAdvertisement.status)
              : "N/A"
          }
        ]
      });

    return labels;
  };

  return (
    <Container style={{ marginTop: "10px" }}>
      <AuditLogLabelValueList recordType="UPDATE" labelValues={getLabels()} />
    </Container>
  );
};
