import { Container } from "@components/crud/Container";
import { Loader } from "@components/crud/Loader";
import { ModelLevel } from "@sportsgravyengineering/sg-api-react-sdk";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { GENDERS } from "@utils/constants";
import { AuditLogLabelValue } from "../types";

export const LevelAuditLogUpdate = ({
  before,
  after
}: {
  before: object | ModelLevel;
  after: object | ModelLevel;
}) => {
  const level = after as ModelLevel;
  const oldLevel = before as ModelLevel;
  const getLabelValues = () => {
    const labelValues: AuditLogLabelValue[] = [];
    if (level.name !== oldLevel.name)
      labelValues.push({
        label: `Updated the "Name" field:`,
        value: [
          {
            value: level.name || "",
            oldValue: oldLevel.name || ""
          }
        ]
      });
    if (level.abbv !== oldLevel.abbv) {
      labelValues.push({
        label: `Updated the "Abbreviation" field:`,
        value: [
          {
            value: level.abbv || "",
            oldValue: oldLevel.abbv || ""
          }
        ]
      });
    }
    const sports = level.sports?.map((s) => s.name).join(", ") || "";
    const oldSports = oldLevel.sports?.map((s) => s.name).join(", ") || "";
    if (sports !== oldSports) {
      labelValues.push({
        label: `Updated the "Sports" field:`,
        value: [
          {
            value: sports,
            oldValue: oldSports
          }
        ]
      });
    }
    const oldGenders =
      oldLevel.genders
        ?.map((g) => GENDERS.find((g1) => g1.value === g)?.label)
        .join(", ") || "";
    const genders =
      level.genders
        ?.map((g) => GENDERS.find((g1) => g1.value === g)?.label)
        .join(", ") || "";
    if (genders !== oldGenders) {
      labelValues.push({
        label: `Updated the "Gender" field:`,
        value: [
          {
            value: genders,
            oldValue: oldGenders
          }
        ]
      });
    }
    if (level.description !== oldLevel.description) {
      labelValues.push({
        label: `Updated the "Description" field:`,
        value: [
          {
            value: level.description || "",
            oldValue: oldLevel.description || ""
          }
        ]
      });
    }
    return labelValues;
  };
  return (
    <Container>
      <Loader isLoading={false}>
        <AuditLogLabelValueList
          recordType="UPDATE"
          labelValues={[...getLabelValues()]}
        />
      </Loader>
    </Container>
  );
};
