import { ModelAccount } from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { AccountsAuditLogUpdate } from "./AccountsAuditLogUpdate";
import { AccountsAuditLogCreateDelete } from "./AccountsAuditLogCreateDelete";

export const AccountsAuditLogDetails = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelAccount;
  after: object | ModelAccount;
}) => {
  if (recordType === "UPDATE")
    return <AccountsAuditLogUpdate before={before} after={after} />;
  else
    return (
      <AccountsAuditLogCreateDelete
        recordType={recordType}
        before={before}
        after={after}
      />
    );
};
