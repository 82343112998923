import { ModelLevel } from "@sportsgravyengineering/sg-api-react-sdk";
import { LevelAuditLogCreateDelete } from "./Levels/LevelAuditLogCreateDelete";
import { LevelAuditLogUpdate } from "./Levels/LevelAuditLogUpdate";

export const LevelAuditLogDetails = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelLevel;
  after: object | ModelLevel;
}) => {
  if (recordType === "UPDATE")
    return <LevelAuditLogUpdate before={before} after={after} />;
  else
    return (
      <LevelAuditLogCreateDelete
        recordType={recordType}
        before={before}
        after={after}
      />
    );
};
