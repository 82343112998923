import { Container } from "@components/crud/Container";
import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { ModelAdvertisement } from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalize } from "@utils/capitalize";
import { AD_LAYOUT_OPTIONS, AD_PLACEMENT_OPTIONS } from "@utils/constants";

export const AdvertisementAuditLogCreateDelete = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "DELETE";
  before: object | ModelAdvertisement;
  after: object | ModelAdvertisement;
}) => {
  const advertisement = (
    recordType === "CREATE" ? after : before
  ) as ModelAdvertisement;

  const getLabels = () => {
    const labels: AuditLogLabelValue[] = [];

    labels.push({
      label: `"Ad Placement" field:`,
      value: [
        {
          value:
            AD_PLACEMENT_OPTIONS.find(
              (option) => option.value === advertisement.placement
            )?.label || ""
        }
      ]
    });

    if (advertisement.placement !== "INSTREAM")
      labels.push({
        label: `"Ad Layout" field:`,
        value: [
          {
            value:
              AD_LAYOUT_OPTIONS.find(
                (option) => option.value === advertisement.layout
              )?.label || ""
          }
        ]
      });

    if (advertisement.placement === "INSTREAM")
      labels.push({
        label: `"Duration" field:`,
        value: [
          {
            value: `${advertisement.duration} seconds`
          }
        ]
      });

    if (
      (advertisement.layout &&
        !["Text_Ad_with_Logo", "Text_Ad_without_Logo"].includes(
          advertisement.layout
        )) ||
      advertisement.placement === "INSTREAM"
    )
      labels.push({
        label: `"${advertisement.placement === "INSTREAM" ? "Video" : "Image"}" field:`,
        value: [
          {
            value: advertisement.media || "N/A",
            isMedia: true
          }
        ]
      });

    if (advertisement.redirectLink)
      labels.push({
        label: `"Ad Redirect Link" field:`,
        value: [
          {
            value: advertisement.redirectLink,
            isLink: true
          }
        ]
      });

    if (advertisement.placement === "OUTSTREAM") {
      labels.push(
        {
          label: `"Ad Title" field:`,
          value: [
            {
              value: advertisement.title || ""
            }
          ]
        },
        {
          label: `"Ad Description" field:`,
          value: [
            {
              value: advertisement.description || ""
            }
          ]
        }
      );
    }

    labels.push({
      label: `"Status" field:`,
      value: [
        {
          value: advertisement.status ? capitalize(advertisement.status) : ""
        }
      ]
    });

    return labels;
  };

  return (
    <Container>
      <AuditLogLabelValueList
        recordType={recordType}
        labelValues={getLabels()}
      />
    </Container>
  );
};
