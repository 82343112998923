import {
  ModelContact,
  ModelOrganizationContact
} from "@sportsgravyengineering/sg-api-react-sdk";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "../types";
import { formatPhoneWithCountryCode } from "@utils/phoneFormatters";
import { capitalize } from "@utils/capitalize";

export const ContactAuditLog = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | (ModelOrganizationContact & ModelContact);
  after: object | (ModelOrganizationContact & ModelContact);
}) => {
  const contact = (
    recordType !== "DELETE" ? after : before
  ) as ModelOrganizationContact & ModelContact;
  const oldContact = before as ModelOrganizationContact & ModelContact;

  const getCreateLabels = (): AuditLogLabelValue[] => {
    const labels: AuditLogLabelValue[] = [];

    labels.push(
      {
        label: `"First Name" field:`,
        value: [
          {
            value: contact.firstName || ""
          }
        ]
      },
      ...(contact.middleName
        ? [
            {
              label: `"Middle Name" field:`,
              value: [
                {
                  value: contact.middleName || ""
                }
              ]
            }
          ]
        : []),
      {
        label: `"Last Name" field:`,
        value: [
          {
            value: contact.lastName || ""
          }
        ]
      },
      ...(contact.suffix
        ? [
            {
              label: `"Suffix" field:`,
              value: [
                {
                  value: contact.suffix || ""
                }
              ]
            }
          ]
        : [])
    );

    if (contact.location)
      labels.push({
        label: `Personal Contact Details "Home Address" field:`,
        value: [
          {
            value: contact.location || ""
          }
        ]
      });

    if (contact.phone)
      labels.push({
        label: `Personal Contact Details "Personal Phone" field:`,
        value: [
          {
            value: contact.phone
              ? formatPhoneWithCountryCode(contact.phone)
              : ""
          }
        ]
      });

    if (contact.email)
      labels.push({
        label: `Personal Contact Details "Personal Email" field:`,
        value: [
          {
            value: contact.email || ""
          }
        ]
      });

    if (contact.whatsappNumber)
      labels.push({
        label: `Personal Contact Details "WhatsApp Number" field:`,
        value: [
          {
            value: contact.whatsappNumber
              ? formatPhoneWithCountryCode(contact.whatsappNumber)
              : ""
          }
        ]
      });

    if (contact.links) {
      const genName = (fieldName: string, index: number) => {
        return contact.links && contact.links.length > 1
          ? `Personal Related Links ${index + 1} "${fieldName}" field:`
          : `Personal Related Link "${fieldName}" field:`;
      };
      contact.links?.map((link, index) => {
        labels.push(
          {
            label: genName("URL", index),
            value: [
              {
                value: link.link || ""
              }
            ]
          },
          {
            label: genName("Type", index),
            value: [
              {
                value: link.type ? capitalize(link.type) : ""
              }
            ]
          },
          ...(link.type === "OTHER"
            ? [
                {
                  label: genName("Other Name", index),
                  value: [
                    {
                      value: link.altName || ""
                    }
                  ]
                }
              ]
            : [])
        );
      });
    }

    if (contact.accounts) {
      contact.accounts?.map((account) => {
        console.log(account.account?.phone);

        labels.push(
          {
            label: `Related Accounts ${account.account?.name} "Account Name" field:`,
            value: [
              {
                value: account.account?.name || ""
              }
            ]
          },
          {
            label: `Related Accounts ${account.account?.name} "Job Title" field:`,
            value: [
              {
                value: account.jobTitle || ""
              }
            ]
          },
          {
            label: `Related Accounts ${account.account?.name} "Primary Contact" field:`,
            value: [
              {
                value: account.isPrimary ? "On" : "Off"
              }
            ]
          },
          ...(account.workPhone
            ? [
                {
                  label: `Related Accounts ${account.account?.name} "Work Phone" field:`,
                  value: [
                    {
                      value: account.workPhone
                        ? formatPhoneWithCountryCode(account.workPhone)
                        : ""
                    }
                  ]
                }
              ]
            : []),
          ...(account.workEmail
            ? [
                {
                  label: `Related Accounts ${account.account?.name} "Work Email" field:`,
                  value: [
                    {
                      value: account.workEmail || ""
                    }
                  ]
                }
              ]
            : []),
          ...(account.account?.officeAddress
            ? [
                {
                  label: `Related Accounts ${account.account?.name} "Account Address" field:`,
                  value: [
                    {
                      value: account.account?.officeAddress || ""
                    }
                  ]
                }
              ]
            : []),
          ...(account.account?.phone
            ? [
                {
                  label: `Related Accounts ${account.account?.name} "Account Phone" field:`,
                  value: [
                    {
                      value: account.account?.phone
                        ? formatPhoneWithCountryCode(account.account?.phone)
                        : ""
                    }
                  ]
                }
              ]
            : []),
          ...(account.account?.email
            ? [
                {
                  label: `Related Accounts ${account.account?.name} "Account Email" field:`,
                  value: [
                    {
                      value: account.account?.email || ""
                    }
                  ]
                }
              ]
            : [])
        );
      });
    }
    return labels;
  };

  const getUpdateLabels = (): AuditLogLabelValue[] => {
    const labels: AuditLogLabelValue[] = [];

    if (oldContact.firstName !== contact.firstName)
      labels.push({
        label: `Updated the "First Name" field:`,
        value: [
          {
            value: contact.firstName || "N/A",
            oldValue: oldContact.firstName || "N/A"
          }
        ]
      });

    if (oldContact.middleName !== contact.middleName)
      labels.push({
        label: `Updated the "Middle Name" field:`,
        value: [
          {
            value: contact.middleName || "N/A",
            oldValue: oldContact.middleName || "N/A"
          }
        ]
      });

    if (oldContact.lastName !== contact.lastName)
      labels.push({
        label: `Updated the "Last Name" field:`,
        value: [
          {
            value: contact.lastName || "N/A",
            oldValue: oldContact.lastName || "N/A"
          }
        ]
      });

    if (oldContact.suffix !== contact.suffix)
      labels.push({
        label: `Updated the "Suffix" field:`,
        value: [
          {
            value: contact.suffix || "N/A",
            oldValue: oldContact.suffix || "N/A"
          }
        ]
      });

    if (oldContact.location !== contact.location)
      labels.push({
        label: `Updated Personal Contact Details "Home Address" field:`,
        value: [
          {
            value: contact.location || "N/A",
            oldValue: oldContact.location || "N/A"
          }
        ]
      });

    if (oldContact.phone !== contact.phone)
      labels.push({
        label: `Updated Personal Contact Details "Personal Phone" field:`,
        value: [
          {
            value: contact.phone
              ? formatPhoneWithCountryCode(contact.phone)
              : "N/A",
            oldValue: oldContact.phone
              ? formatPhoneWithCountryCode(oldContact.phone)
              : "N/A"
          }
        ]
      });

    if (oldContact.email !== contact.email)
      labels.push({
        label: `Updated Personal Contact Details "Personal Email" field:`,
        value: [
          {
            value: contact.email || "N/A",
            oldValue: oldContact.email || "N/A"
          }
        ]
      });

    if (oldContact.whatsappNumber !== contact.whatsappNumber)
      labels.push({
        label: `Updated Personal Contact Details "WhatsApp Number" field:`,
        value: [
          {
            value: contact.whatsappNumber
              ? formatPhoneWithCountryCode(contact.whatsappNumber)
              : "N/A",
            oldValue: oldContact.whatsappNumber
              ? formatPhoneWithCountryCode(oldContact.whatsappNumber)
              : "N/A"
          }
        ]
      });

    if (contact.links && contact.links.length) {
      const genName = (fieldName: string, index: number) => {
        return contact.links && contact.links.length > 1
          ? `Updated Personal Related Links ${index + 1} "${fieldName}" field:`
          : `Updated Personal Related Link "${fieldName}" field:`;
      };

      contact.links?.map((link, index) => {
        const oldLink = oldContact?.links?.[index];

        if (link.link !== oldLink?.link || !oldLink)
          labels.push({
            label: genName("URL", index),
            value: [
              {
                value: link.link || "N/A",
                oldValue: oldLink?.link || "N/A"
              }
            ]
          });

        if (link.type !== oldLink?.type || !oldLink)
          labels.push({
            label: genName("Type", index),
            value: [
              {
                value: link.type ? capitalize(link.type) : "N/A",
                oldValue: oldLink?.type ? capitalize(oldLink.type) : "N/A"
              }
            ]
          });

        if (link.type === "OTHER" && link.altName !== oldLink?.altName)
          labels.push({
            label: genName("Other Name", index),
            value: [
              {
                value: link.altName || "N/A",
                oldValue: oldLink?.altName || "N/A"
              }
            ]
          });
      });
    }

    const newAccounts = contact.accounts || [];
    const oldAccounts = oldContact.accounts || [];

    const newAccountsAdded = newAccounts.filter(
      (account) =>
        !oldAccounts.some(
          (oldAccount) => oldAccount.accountId === account.accountId
        )
    );

    const existingAccounts = newAccounts.filter((account) =>
      oldAccounts.some(
        (oldAccount) => oldAccount.accountId === account.accountId
      )
    );

    const deletedAccounts = oldAccounts.filter(
      (oldAccount) =>
        !newAccounts.some(
          (account) => account.accountId === oldAccount.accountId
        )
    );

    const changedAccountsLength =
      newAccountsAdded.length +
      existingAccounts.length +
      deletedAccounts.length;

    if (changedAccountsLength > 0) {
      [newAccountsAdded, existingAccounts, deletedAccounts].map(
        (account, index) =>
          account.map((acc) => {
            const isNew = index === 0;
            const isExisting = index === 1;
            const isDeleted = index === 2;

            const newExistingAccount = newAccounts.find(
              (oldAccount) => oldAccount.accountId === acc.accountId
            );

            const oldExistingAccount = oldAccounts.find(
              (oldAccount) => oldAccount.accountId === acc.accountId
            );

            labels.push(
              ...(!isExisting ||
              newExistingAccount?.account?.name !==
                oldExistingAccount?.account?.name
                ? [
                    {
                      label: `Updated Related Accounts ${acc.account?.name} "Account Name" field:`,
                      value: [
                        {
                          value: isNew
                            ? acc.account?.name || "N/A"
                            : isExisting
                              ? newExistingAccount?.account?.name || "N/A"
                              : "N/A",
                          oldValue: isNew
                            ? "N/A"
                            : isExisting
                              ? oldExistingAccount?.account?.name || "N/A"
                              : isDeleted
                                ? acc?.account?.name || "N/A"
                                : "N/A"
                        }
                      ]
                    }
                  ]
                : []),
              ...(!isExisting ||
              newExistingAccount?.jobTitle !== oldExistingAccount?.jobTitle
                ? [
                    {
                      label: `Updated Related Accounts ${acc.account?.name} "Job Title" field:`,
                      value: [
                        {
                          value: isNew
                            ? acc.jobTitle || "N/A"
                            : isExisting
                              ? newExistingAccount?.jobTitle || "N/A"
                              : "N/A",
                          oldValue: isNew
                            ? "N/A"
                            : isExisting
                              ? oldExistingAccount?.jobTitle || "N/A"
                              : isDeleted
                                ? acc?.jobTitle || "N/A"
                                : "N/A"
                        }
                      ]
                    }
                  ]
                : []),
              ...(!isExisting ||
              newExistingAccount?.isPrimary !== oldExistingAccount?.isPrimary
                ? [
                    {
                      label: `Updated Related Accounts ${acc.account?.name} "Primary Contact" field:`,
                      value: [
                        {
                          value: isNew
                            ? acc.isPrimary
                              ? "On"
                              : "Off"
                            : isExisting
                              ? newExistingAccount?.isPrimary
                                ? "On"
                                : "Off"
                              : "N/A",
                          oldValue: isNew
                            ? "N/A"
                            : isExisting
                              ? oldExistingAccount?.isPrimary
                                ? "On"
                                : "Off"
                              : isDeleted
                                ? acc?.isPrimary
                                  ? "On"
                                  : "Off"
                                : "N/A"
                        }
                      ]
                    }
                  ]
                : []),
              ...(!isExisting ||
              newExistingAccount?.workPhone !== oldExistingAccount?.workPhone
                ? [
                    {
                      label: `Updated Related Accounts ${acc.account?.name} "Work Phone" field:`,
                      value: [
                        {
                          value: isNew
                            ? acc.workPhone
                              ? formatPhoneWithCountryCode(acc.workPhone)
                              : "N/A"
                            : isExisting
                              ? newExistingAccount?.workPhone
                                ? formatPhoneWithCountryCode(
                                    newExistingAccount?.workPhone
                                  )
                                : "N/A"
                              : "N/A",
                          oldValue: isNew
                            ? "N/A"
                            : isExisting
                              ? oldExistingAccount?.workPhone
                                ? formatPhoneWithCountryCode(
                                    oldExistingAccount?.workPhone
                                  )
                                : "N/A"
                              : isDeleted
                                ? acc?.workPhone
                                  ? formatPhoneWithCountryCode(acc?.workPhone)
                                  : "N/A"
                                : "N/A"
                        }
                      ]
                    }
                  ]
                : []),
              ...(!isExisting ||
              newExistingAccount?.workEmail !== oldExistingAccount?.workEmail
                ? [
                    {
                      label: `Updated Related Accounts ${acc.account?.name} "Work Email" field:`,
                      value: [
                        {
                          value: isNew
                            ? acc.workEmail || "N/A"
                            : isExisting
                              ? newExistingAccount?.workEmail || "N/A"
                              : "N/A",
                          oldValue: isNew
                            ? "N/A"
                            : isExisting
                              ? oldExistingAccount?.workEmail || "N/A"
                              : isDeleted
                                ? acc?.workEmail || "N/A"
                                : "N/A"
                        }
                      ]
                    }
                  ]
                : []),
              ...(!isExisting ||
              newExistingAccount?.account?.officeAddress !==
                oldExistingAccount?.account?.officeAddress
                ? [
                    {
                      label: `Updated Related Accounts ${acc.account?.name} "Account Address" field:`,
                      value: [
                        {
                          value: isNew
                            ? acc.account?.officeAddress || "N/A"
                            : isExisting
                              ? newExistingAccount?.account?.officeAddress ||
                                "N/A"
                              : "N/A",
                          oldValue: isNew
                            ? "N/A"
                            : isExisting
                              ? oldExistingAccount?.account?.officeAddress ||
                                "N/A"
                              : isDeleted
                                ? acc?.account?.officeAddress || "N/A"
                                : "N/A"
                        }
                      ]
                    }
                  ]
                : []),
              ...(!isExisting ||
              newExistingAccount?.account?.phone !==
                oldExistingAccount?.account?.phone
                ? [
                    {
                      label: `Updated Related Accounts ${acc.account?.name} "Account Phone" field:`,
                      value: [
                        {
                          value: isNew
                            ? acc?.account?.phone
                              ? formatPhoneWithCountryCode(acc.account.phone)
                              : "N/A"
                            : isExisting
                              ? newExistingAccount?.account?.phone
                                ? formatPhoneWithCountryCode(
                                    newExistingAccount?.account?.phone
                                  )
                                : "N/A"
                              : "N/A",
                          oldValue: isNew
                            ? "N/A"
                            : isExisting
                              ? oldExistingAccount?.account?.phone
                                ? formatPhoneWithCountryCode(
                                    oldExistingAccount?.account?.phone
                                  )
                                : "N/A"
                              : isDeleted
                                ? acc?.account?.phone
                                  ? formatPhoneWithCountryCode(
                                      acc?.account?.phone
                                    )
                                  : "N/A"
                                : "N/A"
                        }
                      ]
                    }
                  ]
                : []),
              ...(!isExisting ||
              newExistingAccount?.account?.email !==
                oldExistingAccount?.account?.email
                ? [
                    {
                      label: `Updated Related Accounts ${acc.account?.name} "Account Email" field:`,
                      value: [
                        {
                          value: isNew
                            ? acc.account?.email || "N/A"
                            : isExisting
                              ? newExistingAccount?.account?.email || "N/A"
                              : "N/A",
                          oldValue: isNew
                            ? "N/A"
                            : isExisting
                              ? oldExistingAccount?.account?.email || "N/A"
                              : isDeleted
                                ? acc?.account?.email || "N/A"
                                : "N/A"
                        }
                      ]
                    }
                  ]
                : [])
            );
          })
      );
    }

    return labels;
  };

  if (recordType === "UPDATE")
    return (
      <AuditLogLabelValueList
        recordType="UPDATE"
        labelValues={[...getUpdateLabels()]}
      />
    );
  else
    return (
      <AuditLogLabelValueList
        recordType={recordType}
        labelValues={[...getCreateLabels()]}
      />
    );
};
