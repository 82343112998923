import { Container } from "@components/crud/Container";
import { AuditLogLabelValueList } from "@pages/audit-log/components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "@pages/audit-log/types";
import { ACTIVITY_TYPES } from "@pages/crm/activities/ActivityAdd";
import {
  ModelActivity,
  ModelOrganizationActivity
} from "@sportsgravyengineering/sg-api-react-sdk";
import {
  ACTIVITY_RELATED_TO,
  ACTIVITY_STATUS_TYPES,
  CRM_ACTIVITY_CALL_OUTCOMES,
  CRM_ACTIVITY_NEXT_ACTIONS
} from "@utils/constants";
import { formatDateForDisplay, formatTimeTo12h } from "@utils/formatDate";
import formatFullName from "@utils/formatFullName";

export const ActivityAuditLogUpdate = ({
  before,
  after
}: {
  before: object | (ModelOrganizationActivity & ModelActivity);
  after: object | (ModelOrganizationActivity & ModelActivity);
}) => {
  const activity = after as ModelOrganizationActivity & ModelActivity;
  const oldActivity = before as ModelOrganizationActivity & ModelActivity;

  const getLabels = () => {
    const activityType = ACTIVITY_TYPES.find(
      (type) => type.value === activity.type
    )?.label;
    const releatedTo = ACTIVITY_RELATED_TO.find(
      (relatedTo) => relatedTo.value === activity.relatesTo
    )?.label;
    const labels: AuditLogLabelValue[] = [];

    if (activity.relatesTo !== oldActivity.relatesTo) {
      labels.push(
        {
          label: `Updated ${activityType} "Related To" field:`,
          value: [
            {
              oldValue:
                ACTIVITY_RELATED_TO.find(
                  (relatedTo) => relatedTo.value === oldActivity.relatesTo
                )?.label || "",
              value: releatedTo || ""
            }
          ]
        },
        {
          label: `Updated ${activityType} "Select ${releatedTo}" field:`,
          value: [
            {
              oldValue:
                oldActivity.relatesTo === "ACCOUNT"
                  ? oldActivity.account?.name || ""
                  : oldActivity.relatesTo === "CONTACT"
                    ? formatFullName(oldActivity.contact)
                    : oldActivity.relatesTo === "OPPORTUNITY"
                      ? oldActivity.opportunity?.name || ""
                      : oldActivity.relatesTo === "LEAD"
                        ? oldActivity.lead?.name || ""
                        : oldActivity.relatesTo === "ORDER"
                          ? oldActivity?.opportunity?.name || ""
                          : "",
              value:
                activity.relatesTo === "ACCOUNT"
                  ? activity.account?.name || ""
                  : activity.relatesTo === "CONTACT"
                    ? formatFullName(activity.contact)
                    : activity.relatesTo === "OPPORTUNITY"
                      ? activity.opportunity?.name || ""
                      : activity.relatesTo === "LEAD"
                        ? activity.lead?.name || ""
                        : activity.relatesTo === "ORDER"
                          ? activity?.opportunity?.name || ""
                          : ""
            }
          ]
        }
      );
    }

    if (activity.relatesTo === oldActivity.relatesTo) {
      if (activity.relatesTo === "ACCOUNT") {
        if (activity.account?.accountId !== oldActivity.account?.accountId)
          labels.push({
            label: `Updated ${activityType} "Select Account" field:`,
            value: [
              {
                oldValue: oldActivity.account?.name || "N/A",
                value: activity.account?.name || "N/A"
              }
            ]
          });
      }

      if (activity.relatesTo === "CONTACT") {
        if (activity.contactId !== oldActivity.contactId)
          labels.push({
            label: `Updated ${activityType} "Select Contact" field:`,
            value: [
              {
                oldValue: oldActivity.contactId
                  ? formatFullName(oldActivity.contact)
                  : "N/A",
                value: activity.contactId
                  ? formatFullName(activity.contact)
                  : "N/A"
              }
            ]
          });
      }

      if (
        activity.relatesTo === "OPPORTUNITY" ||
        activity.relatesTo === "ORDER"
      ) {
        if (
          activity.opportunity?.opportunityId !==
          oldActivity.opportunity?.opportunityId
        )
          labels.push({
            label: `Updated ${activityType} "Select Opportunity" field:`,
            value: [
              {
                oldValue: oldActivity.opportunity?.name || "N/A",
                value: activity.opportunity?.name || "N/A"
              }
            ]
          });
      }

      if (activity.relatesTo === "LEAD") {
        if (activity.leadId !== oldActivity.leadId)
          labels.push({
            label: `Updated ${activityType} "Select Lead" field:`,
            value: [
              {
                oldValue: oldActivity.lead?.name || "N/A",
                value: activity.lead?.name || "N/A"
              }
            ]
          });
      }
    }

    if (activity.type === "CALL") {
      if (activity.call?.outcome !== oldActivity.call?.outcome)
        labels.push({
          label: `Updated ${activityType} "Outcome" field:`,
          value: [
            {
              oldValue:
                CRM_ACTIVITY_CALL_OUTCOMES.find(
                  (direction) => direction.value === oldActivity.call?.outcome
                )?.label || "N/A",
              value:
                CRM_ACTIVITY_CALL_OUTCOMES.find(
                  (direction) => direction.value === activity.call?.outcome
                )?.label || "N/A"
            }
          ]
        });

      if (
        activity.nextActionDate &&
        oldActivity.nextActionDate &&
        formatDateForDisplay(new Date(activity.nextActionDate)) !==
          formatDateForDisplay(new Date(oldActivity.nextActionDate))
      )
        labels.push({
          label: `Updated ${activityType} "Next Action Date" field:`,
          value: [
            {
              oldValue: oldActivity.nextActionDate
                ? formatDateForDisplay(new Date(oldActivity.nextActionDate))
                : "N/A",
              value: activity.nextActionDate
                ? formatDateForDisplay(new Date(activity.nextActionDate))
                : "N/A"
            }
          ]
        });

      if (
        activity.nextActionDate &&
        oldActivity.nextActionDate &&
        formatTimeTo12h(new Date(activity.nextActionDate)) !==
          formatTimeTo12h(new Date(oldActivity.nextActionDate))
      )
        labels.push({
          label: `Updated ${activityType} "Next Action Time" field:`,
          value: [
            {
              oldValue: oldActivity.nextActionDate
                ? formatTimeTo12h(new Date(oldActivity.nextActionDate))
                : "N/A",
              value: activity.nextActionDate
                ? formatTimeTo12h(new Date(activity.nextActionDate))
                : "N/A"
            }
          ]
        });

      if (activity.nextAction !== oldActivity.nextAction)
        labels.push({
          label: `Updated ${activityType} "Next Action" field:`,
          value: [
            {
              oldValue:
                CRM_ACTIVITY_NEXT_ACTIONS.find(
                  (direction) => direction.value === oldActivity.nextAction
                )?.label || "N/A",
              value:
                CRM_ACTIVITY_NEXT_ACTIONS.find(
                  (direction) => direction.value === activity.nextAction
                )?.label || "N/A"
            }
          ]
        });

      if (activity.notes !== oldActivity.notes)
        labels.push({
          label: `Updated ${activityType} "Notes" field:`,
          value: [
            {
              oldValue: oldActivity.notes || "N/A",
              value: activity.notes || "N/A"
            }
          ]
        });
    }

    if (activity.type === "EMAIL") {
      if (activity.email?.body !== oldActivity.email?.body)
        labels.push({
          label: `Updated ${activityType} "Email Body" field:`,
          value: [
            {
              oldValue: oldActivity.email?.body || "N/A",
              value: activity.email?.body || "N/A"
            }
          ]
        });
    }

    if (activity.type === "TASK") {
      if (activity.status !== oldActivity.status)
        labels.push({
          label: `Updated ${activityType} "Status" field:`,
          value: [
            {
              oldValue:
                ACTIVITY_STATUS_TYPES.find(
                  (status) => status.value === oldActivity.status
                )?.label || "N/A",
              value:
                ACTIVITY_STATUS_TYPES.find(
                  (status) => status.value === activity.status
                )?.label || "N/A"
            }
          ]
        });

      if (activity?.reason !== oldActivity?.reason) {
        labels.push({
          label: `Updated ${activityType} "Reason for Onhold" field:`,
          value: [
            {
              oldValue: oldActivity?.reason || "N/A",
              value: activity?.reason || "N/A"
            }
          ]
        });
      }
    }

    if (activity.type === "MEETING") {
      const oldDate = oldActivity.date
        ? formatDateForDisplay(new Date(oldActivity.date))
        : "N/A";
      const newDate = activity.date
        ? formatDateForDisplay(new Date(activity.date))
        : "N/A";

      const oldTime = oldActivity.date
        ? formatTimeTo12h(new Date(oldActivity.date))
        : "N/A";
      const newTime = activity.date
        ? formatTimeTo12h(new Date(activity.date))
        : "N/A";
      if (oldDate !== newDate)
        labels.push({
          label: `Updated ${activityType} "Date" field:`,
          value: [
            {
              oldValue: oldDate,
              value: newDate
            }
          ]
        });
      if (oldTime !== newTime)
        labels.push({
          label: `Updated ${activityType} "Time" field:`,
          value: [
            {
              oldValue: oldTime,
              value: newTime
            }
          ]
        });
      if (activity.timezone !== oldActivity.timezone)
        labels.push({
          label: `Updated ${activityType} "Time Zone" field:`,
          value: [
            {
              oldValue: oldActivity.timezone || "N/A",
              value: activity.timezone || "N/A"
            }
          ]
        });
    }

    if (JSON.stringify(activity.notes) !== JSON.stringify(oldActivity.notes))
      labels.push({
        label: `Updated ${activityType} "Notes" field:`,
        value: [
          {
            oldValue: oldActivity.notes || "N/A",
            value: activity.notes || "N/A",
            isRickText: true
          }
        ]
      });

    return labels;
  };

  return (
    <Container style={{ marginTop: "10px" }}>
      <AuditLogLabelValueList
        recordType="UPDATE"
        labelValues={[...getLabels()]}
      />
    </Container>
  );
};
