import React from "react";
import {
  Page,
  View,
  Document,
  StyleSheet,
  Image,
  Text,
  Font
} from "@react-pdf/renderer";
import SGLogo from "@assets/images/sg-logo.png";
import { formatCurrency } from "@utils/formatCurrency";
import { AdvertiserInvoiceSchemaStatus } from "@sportsgravyengineering/sg-api-react-sdk";

export type InvoicePDFDetailsProps = {
  invoiceNumber: string;
  invoiceDate: string | Date;
  startDate: string | Date | null;
  endDate: string | Date | null;
  orderDate: string | Date;
  paymentDate: string | Date | undefined | null;
  paymentMode: string;
  terms: string;
  from: { name: string; address: string };
  paymentStatus: AdvertiserInvoiceSchemaStatus;
  billTo: {
    to: string;
    name: string;
    phone: string;
    email: string;
  };
  items: {
    name: string;
    quantity: number;
    desciption: InvoiceDetailsDescription[];
    amount: number;
    unitPrice?: number;
  }[];
  taxDetails?: {
    taxRate: number;
    taxAmount: number;
  };
  feeDetails?: {
    feeRate: number;
    feeAmount: number;
  };
};

type InvoiceDetailsDescription = {
  name: string;
  isBold?: boolean;
  subDescription?: {
    name: string;
    isBold?: boolean;
  }[];
};

const formatDate = (
  iosDateString: string | Date | undefined | null
): string => {
  if (!iosDateString) return "";
  const date = new Date(iosDateString);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "/fonts/Inter400.ttf",
      fontWeight: 400
    },
    {
      src: "/fonts/Inter500.ttf",
      fontWeight: 500
    },
    {
      src: "/fonts/InterItalic.ttf",
      fontStyle: "italic"
    }
  ]
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: 40,
    display: "flex",
    height: "100vh",
    letterSpacing: 0.5
  },
  view: {
    display: "flex",
    flexDirection: "column"
  },
  seperator: {
    marginTop: "22px",
    width: "100%",
    borderBottomWidth: "3px",
    borderBottomColor: "#E5E5E5",
    borderBottomStyle: "solid"
  },
  fromDetails: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  label: {
    fontSize: 16,
    fontWeight: 500
  },
  value: {
    color: "#666666",
    fontSize: "10px",
    width: "50%",
    lineHeight: 1.6,
    fontWeight: 400
  },
  billTo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 10
  },
  invoiceDetailsContainer: {
    border: "1px solid #B3B3B3",
    paddingBottom: 5
  },
  invoiceDetailsContainerTitle: {
    backgroundColor: "#F3F4F7",
    padding: 5,
    fontSize: "10px",
    color: "#000",
    fontWeight: 500,
    borderBottom: "1px solid #B3B3B3"
  },
  invoiceDetails: {
    display: "flex",
    flexDirection: "row",
    gap: 60,
    justifyContent: "space-between",
    paddingLeft: 5,
    paddingRight: 5
  },
  invoiceDetailsTitle: {
    fontSize: "10px"
  },
  invoiceDetailsValue: {
    color: "#666666",
    fontWeight: 400,
    fontSize: "10px",
    fontStyle: "italic"
  },
  table: {
    width: "100%"
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 400,
    border: "1px solid #B3B3B3",
    backgroundColor: "#F3F4F7"
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 400,
    border: "1px solid #B3B3B3",
    borderTop: "none"
  },
  tableCell: {
    padding: 8,
    fontSize: "10px",
    fontWeight: 400
  },
  tableFooter: {
    display: "flex",
    flexDirection: "row"
  },
  tableTotalContainer: {
    border: "1px solid #B3B3B3",
    borderTop: "none"
  },
  tableTotalCell: {
    padding: 8
  },
  footer: {
    position: "absolute",
    textAlign: "center",
    fontSize: "12px",
    color: "#666666",
    bottom: 40,
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto"
  }
});

export const InvoicePDF = ({
  invoiceFor = "ORDER",
  hasHeader,
  hasFooter,
  invoiceDetails,
  showSGLogo = true
}: {
  invoiceFor: "ORDER" | "ADVERTISER";
  hasHeader: boolean;
  hasFooter?: boolean;
  invoiceDetails: InvoicePDFDetailsProps;
  showSGLogo?: boolean;
}) => {
  const renderDescription = (desc: InvoiceDetailsDescription[]) => {
    return (
      <>
        {desc.map((item, i) => (
          <View
            key={i}
            style={{
              display: "flex",
              flexDirection: "column",
              fontWeight: item.isBold ? 500 : 400
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <Text>&nbsp;&nbsp;• &nbsp; </Text>
              <Text>{item.name}</Text>
            </View>
            {item.subDescription && (
              <>
                {item.subDescription.map((subItem, j) => (
                  <View
                    key={j}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontWeight: subItem.isBold ? 500 : 400
                    }}
                  >
                    <Text>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;{" "}
                    </Text>
                    <Text>{subItem.name}</Text>
                  </View>
                ))}
              </>
            )}
          </View>
        ))}
      </>
    );
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.view}>
          {hasHeader && (
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                {showSGLogo && (
                  <Image
                    src={SGLogo}
                    style={{ width: "200px", height: "35px" }}
                  />
                )}
                <Text style={{ fontSize: "26px" }}>Invoice</Text>
              </View>
              <View style={styles.seperator} />
            </View>
          )}
          <View style={[styles.fromDetails, { marginTop: "14px" }]}>
            <View>
              <Text style={styles.label}>{invoiceDetails.from.name}</Text>
              <Text
                style={[styles.value, { marginTop: "5px", maxWidth: "500px" }]}
              >
                {invoiceDetails.from.address}
              </Text>
            </View>
            <View>
              <View style={[styles.flexContainer, { marginTop: 0 }]}>
                <View style={[styles.invoiceDetails, { gap: 25 }]}>
                  <Text style={styles.invoiceDetailsTitle}>Invoice No.</Text>
                  <Text
                    style={[
                      styles.invoiceDetailsValue,
                      { fontStyle: "normal" }
                    ]}
                  >
                    {invoiceDetails.invoiceNumber}
                  </Text>
                </View>
                <View style={[styles.invoiceDetails, { gap: 25 }]}>
                  <Text style={styles.invoiceDetailsTitle}>Invoice Date</Text>
                  <Text
                    style={[
                      styles.invoiceDetailsValue,
                      { fontStyle: "normal" }
                    ]}
                  >
                    {formatDate(invoiceDetails.invoiceDate)}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={[styles.billTo, { marginTop: "32px" }]}>
            <View
              style={[
                styles.flexContainer,
                styles.invoiceDetailsContainer,
                { gap: 3 }
              ]}
            >
              <View>
                <View style={styles.invoiceDetailsContainerTitle}>
                  <Text>Bill To</Text>
                </View>
              </View>
              <View style={styles.invoiceDetails}>
                <Text style={styles.value}>{invoiceDetails.billTo.to}</Text>
              </View>
              {!invoiceDetails.billTo.name.includes("undefined") && (
                <View style={styles.invoiceDetails}>
                  <Text style={styles.value}>{invoiceDetails.billTo.name}</Text>
                </View>
              )}
              {invoiceDetails.billTo.phone &&
                invoiceDetails.billTo.phone.length && (
                  <View style={styles.invoiceDetails}>
                    <Text style={styles.value}>
                      {invoiceDetails.billTo.phone}
                    </Text>
                  </View>
                )}
              <View style={styles.invoiceDetails}>
                <Text style={styles.value}>{invoiceDetails.billTo.email}</Text>
              </View>
            </View>
            <View
              style={[styles.flexContainer, styles.invoiceDetailsContainer]}
            >
              <View style={styles.invoiceDetailsContainerTitle}>
                <Text>
                  {invoiceFor === "ORDER"
                    ? "Yearly Subscription Details"
                    : "Campaign Details"}
                </Text>
              </View>
              <View style={styles.invoiceDetails}>
                <Text style={styles.invoiceDetailsTitle}>
                  {invoiceFor === "ORDER" ? "Subscription" : "Campaign"} Start
                  Date
                </Text>
                <Text style={styles.invoiceDetailsValue}>
                  {formatDate(invoiceDetails.startDate)}
                </Text>
              </View>
              <View style={styles.invoiceDetails}>
                <Text style={styles.invoiceDetailsTitle}>
                  {invoiceFor === "ORDER" ? "Subscription" : "Campaign"} End
                  Date
                </Text>
                <Text style={styles.invoiceDetailsValue}>
                  {formatDate(invoiceDetails.endDate)}
                </Text>
              </View>
              <View style={styles.invoiceDetails}>
                <Text style={styles.invoiceDetailsTitle}>Terms</Text>
                <Text style={styles.invoiceDetailsValue}>
                  {invoiceDetails.terms}
                </Text>
              </View>
              <View style={styles.invoiceDetails}>
                <Text style={styles.invoiceDetailsTitle}>Payment Method</Text>
                <Text
                  style={[
                    styles.invoiceDetailsValue,
                    {
                      minWidth: !invoiceDetails.paymentMode ? "75px" : "0px"
                    }
                  ]}
                >
                  {invoiceDetails.paymentMode}
                </Text>
              </View>
              <View style={styles.invoiceDetails}>
                <Text style={styles.invoiceDetailsTitle}>Payment Date</Text>
                <Text style={styles.invoiceDetailsValue}>
                  {formatDate(invoiceDetails.paymentDate)}
                </Text>
              </View>
              <View style={styles.invoiceDetails}>
                <Text style={styles.invoiceDetailsTitle}>Payment Status</Text>
                <Text
                  style={[
                    styles.invoiceDetailsValue,
                    { color: "#1ABC9C", fontStyle: "normal", fontWeight: 500 }
                  ]}
                >
                  {invoiceDetails.paymentStatus}
                </Text>
              </View>
            </View>
          </View>

          <View style={{ marginTop: 32 }}>
            <View style={styles.table}>
              <View style={styles.tableHeader}>
                <View style={[styles.tableCell, { width: "60%" }]}>
                  <Text>Description</Text>
                </View>
                <View
                  style={[
                    styles.tableCell,
                    { width: "10%", textAlign: "center" }
                  ]}
                >
                  <Text>QTY</Text>
                </View>
                <View
                  style={[
                    styles.tableCell,
                    { width: "20%", textAlign: "center" }
                  ]}
                >
                  <Text>Unit Price</Text>
                </View>
                <View
                  style={[
                    styles.tableCell,
                    {
                      width: "12%",
                      textAlign: "left"
                    }
                  ]}
                >
                  <Text>Amount</Text>
                </View>
              </View>
              {invoiceDetails?.items?.map((item, index) => (
                <View style={styles.tableRow} key={index}>
                  <View
                    style={[
                      styles.tableCell,
                      {
                        width:
                          invoiceDetails.items.length === 1 ? "57%" : "60%",
                        lineHeight: 1.5,
                        display: "flex",
                        flexDirection: "column"
                      }
                    ]}
                  >
                    <Text>{item.name}</Text>
                    {item.desciption && renderDescription(item.desciption)}
                  </View>
                  <View
                    style={[
                      styles.tableCell,
                      { width: "10%", textAlign: "center" }
                    ]}
                  >
                    <Text>{item.quantity}</Text>
                  </View>
                  <View
                    style={[
                      styles.tableCell,
                      { width: "20%", textAlign: "center" }
                    ]}
                  >
                    <Text>{formatCurrency(item.unitPrice || 0)}</Text>
                  </View>

                  <View
                    style={[
                      styles.tableCell,
                      {
                        width: "12%",
                        textAlign: "right",
                        letterSpacing: 0.8
                      }
                    ]}
                  >
                    <Text>{formatCurrency(item.amount)}</Text>
                  </View>
                </View>
              ))}
              <View style={styles.tableFooter}>
                <View
                  style={[
                    styles.tableCell,
                    { width: "10%", textAlign: "center" }
                  ]}
                ></View>
                <View
                  style={[
                    styles.tableCell,
                    {
                      width: "70%",
                      textAlign: "right"
                    }
                  ]}
                >
                  <Text style={[styles.tableTotalCell, { width: "330px" }]}>
                    Sub Total
                  </Text>
                  <Text style={[styles.tableTotalCell, { width: "330px" }]}>
                    {`Tax (${invoiceDetails.taxDetails?.taxRate}%)`}
                  </Text>
                  <Text style={[styles.tableTotalCell, { width: "330px" }]}>
                    {`Fees (${invoiceDetails.feeDetails?.feeRate}%)`}
                  </Text>
                  <Text
                    style={[
                      styles.tableTotalCell,
                      { width: "310px", fontWeight: 500 }
                    ]}
                  >
                    Total
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCell,
                    styles.tableTotalContainer,
                    { width: "20%" }
                  ]}
                >
                  <View>
                    <Text
                      style={[
                        styles.tableTotalCell,
                        {
                          textAlign: "right",
                          letterSpacing: 0.8,
                          paddingRight: 0
                        }
                      ]}
                    >
                      {formatCurrency(
                        invoiceDetails.items.reduce(
                          (total, i) => total + i.amount,
                          0
                        )
                      )}
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={[
                        styles.tableTotalCell,
                        {
                          textAlign: "right",
                          letterSpacing: 0.8,
                          paddingRight: 0
                        }
                      ]}
                    >
                      {formatCurrency(
                        Number(invoiceDetails.taxDetails?.taxAmount)
                      )}
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={[
                        styles.tableTotalCell,
                        {
                          textAlign: "right",
                          letterSpacing: 0.8,
                          paddingRight: 0
                        }
                      ]}
                    >
                      {formatCurrency(
                        Number(invoiceDetails.feeDetails?.feeAmount)
                      )}
                    </Text>
                  </View>
                  <View
                    style={[
                      {
                        borderTop: "1px solid #B3B3B3",
                        right: 8,
                        width: "102px",
                        letterSpacing: 0.8
                      }
                    ]}
                  >
                    <Text
                      style={[
                        styles.tableTotalCell,
                        {
                          textAlign: "right",
                          fontWeight: 500
                        }
                      ]}
                    >
                      {formatCurrency(
                        Number(
                          (
                            invoiceDetails?.items?.reduce(
                              (total, i) => total + i.amount,
                              0
                            ) +
                            (invoiceDetails.taxDetails?.taxAmount ?? 0) +
                            (invoiceDetails.feeDetails?.feeAmount ?? 0)
                          ).toFixed(2)
                        )
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={[styles.footer, { bottom: hasFooter ? 70 : 40 }]}>
          <Text>THANK FOR YOUR BUISNESS!</Text>
        </View>
        {hasFooter && (
          <View
            style={[
              styles.footer,
              {
                bottom: 30,
                fontSize: "10px",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center",
                gap: "5px"
              }
            ]}
          >
            <Text>Powered By</Text>
            <Image
              src={SGLogo}
              style={{
                width: "80px",
                height: "15px"
              }}
            />
          </View>
        )}
      </Page>
    </Document>
  );
};
