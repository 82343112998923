import { Container } from "@components/crud/Container";
import { Button, ButtonGroup } from "@mui/material";
import {
  ModelTeam,
  ModelTrainingProgram,
  useAdminPermissionGet,
  useAdminPositionGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useState } from "react";
import { AuditLogLabelValueList } from ".././components/AuditLogLabelvalueList";
import { GENDERS, TrainingProgramTypeOptions } from "@utils/constants";
import { PlayerOption } from "@pages/teams-programs/components/PlayerSelectionForm";
import { CoachOption } from "@pages/teams-programs/components/CoachSelectionForm";
import { ManagerOption } from "@pages/teams-programs/components/ManagerSelectionForm";
import { AuditLogLabelValue } from ".././types";
import { Loader } from "@components/crud/Loader";
import { generatePermissionValues } from ".././services";
import { capitalize } from "@utils/capitalize";
import { TrainingProgramDate } from "@pages/teams-programs/training-programs/ProgramDetailsForm";
import convertRruleToMessage from "@utils/ConvertRruleToMessage";
import { convertToAmPm, formatDateForDisplay } from "@utils/formatDate";

type Tabs =
  | "Team Details"
  | "Players"
  | "Coaches"
  | "Managers"
  | "Permissions"
  | "Program Details";

export const TeamAuditLogCreateDelete = ({
  recordType,
  before,
  after,
  page
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | (ModelTeam & ModelTrainingProgram);
  after: object | (ModelTeam & ModelTrainingProgram);
  page: "TEAM" | "TRAINING_PROGRAM";
}) => {
  const tabs = [
    page === "TEAM" ? "Team Details" : "Program Details",
    page === "TEAM" ? "Players" : "Athletes",
    "Coaches",
    "Managers",
    "Permissions"
  ];
  const [tab, setTab] = useState(
    page === "TEAM" ? "Team Details" : "Program Details"
  );
  const currentTeam = (recordType === "DELETE" ? before : after) as ModelTeam &
    ModelTrainingProgram;
  const teamRoles = currentTeam.roles;

  const { isLoading: isLoading, data: positionsResponse } = useAdminPositionGet(
    {
      sportId: currentTeam?.sportId
    }
  );
  const { isLoading: isLoadingPermissions, data: permissionsResponse } =
    useAdminPermissionGet();
  const [playersWithValues, setPlayersWithValues] = useState<PlayerOption[]>(
    []
  );
  const [coachesWithValues, setCoachesWithValues] = useState<CoachOption[]>([]);
  const [managerWithValues, setManagerWithValues] = useState<ManagerOption[]>(
    []
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [permissionValues, setPermissionValues] = useState<any>({});

  const getCoachesOrManagers = (alias, team) => {
    if (!team) return;
    const invitedUsers = team.invites?.filter(
      (item) => item.invitedFor![0]?.alias === alias
    );
    const activeUsers = team.userRoles?.filter(
      (item) => item.role?.alias === alias
    );
    let invites: CoachOption[] = [],
      accepted: CoachOption[] = [];

    if (activeUsers) {
      accepted = activeUsers.map((user) => {
        const formattedPerson = {
          label: `${user?.user?.person?.firstName} ${user?.user?.person?.lastName}`,
          id: user!.user!.personId!,
          personId: user!.user!.personId!,
          value: user!.user!.personId!,
          roleId: user.roleId!,
          ...user,
          role: alias,
          permissions: user?.permissionOverrides,
          status: "ACTIVE"
        };
        return formattedPerson;
      });
    }
    if (invitedUsers) {
      invites = invitedUsers
        .filter((iu) => !accepted.find((a) => a.id === iu!.person!.personId))
        .map((user) => {
          const formattedPerson = {
            label: `${user?.person?.firstName} ${user?.person?.lastName}`,
            id: user!.person!.personId!,
            value: user!.person!.personId!,
            personId: user!.person!.personId!,
            roleId: user.invitedFor![0].roleId!,
            ...user,
            role: alias,
            status: "PENDING",
            permissions: Object.keys(user.metadata as object).includes(
              "permissions"
            )
              ? (user.metadata as object)["permissions"]
              : []
          };
          return formattedPerson;
        });
    }
    return [...invites, ...accepted];
  };

  const getPlayers = (team) => {
    if (!team) return;
    const selectedPlayersInvite = team.invites?.filter(
      (item) => item.invitedFor![0]?.alias === "PLAYER"
    );
    const addedPlayers = team.userRoles?.filter(
      (item) => item.role?.alias === "PLAYER"
    );

    const getParentObject = (parentId) => {
      const invitedParent = team.invites?.find(
        (invite) => invite?.personId === parentId
      );
      if (invitedParent)
        return {
          id: invitedParent.personId!,
          personId: invitedParent.personId!,
          value: invitedParent.personId!,
          label: `${invitedParent?.person?.firstName} ${invitedParent?.person?.lastName}`,
          ...invitedParent,
          roleId: invitedParent!.invitedFor![0].roleId!,
          permissions: Object.keys(invitedParent.metadata as object).includes(
            "permissions"
          )
            ? (invitedParent.metadata as object)["permissions"]
            : []
        };
      const addedParent = team.userRoles?.find(
        (player) => player?.user?.personId === parentId
      );
      if (addedParent)
        return {
          id: addedParent.user!.personId!,
          personId: addedParent.user?.person?.personId as string,
          value: addedParent.user!.personId!,
          label: `${addedParent?.user?.person?.firstName} ${addedParent?.user?.person?.lastName}`,
          ...addedParent,
          roleId: addedParent.roleId!,
          permissions: addedParent?.permissionOverrides
        };
    };

    let invites: PlayerOption[] = [],
      accepted: PlayerOption[] = [];
    if (addedPlayers) {
      accepted = addedPlayers.map((player) => {
        const isChild = player?.user?.person?.guardians?.length;
        const formattedPerson = {
          label: `${player?.user?.person?.firstName} ${player?.user?.person?.lastName}`,
          id: player!.user!.personId!,
          personId: player!.user!.personId!,
          value: player!.user!.personId!,
          gender: player?.user?.person?.gender,
          roleId: player.roleId!,
          ...player,
          permissions: player?.permissionOverrides,
          parent: isChild
            ? getParentObject(
                player?.user?.person?.guardians![0].guardian?.personId
              )
            : undefined,
          status: isChild ? "CHILD_ACCEPTED" : "USER_ACCEPTED"
        };
        return formattedPerson;
      });
    }
    if (selectedPlayersInvite) {
      invites = selectedPlayersInvite
        .filter((iu) => !accepted.find((a) => a.id === iu!.person!.personId))
        .map((player) => {
          const checkIfParentAccepted = (parentId) => {
            if (!addedPlayers || !addedPlayers.length) return false;
            const accepted = addedPlayers.findIndex(
              (player) => player!.user!.personId === parentId
            );
            if (accepted !== -1) return true;
            return false;
          };
          const isChild = player?.person?.guardians?.length;
          const formattedPerson = {
            label: `${player?.person?.firstName} ${player?.person?.lastName}`,
            id: player!.person!.personId!,
            value: player!.person!.personId!,
            gender: player?.person?.gender,
            roleId: player.invitedFor![0].roleId!,
            ...player,
            personId: player!.person!.personId!,
            status: isChild
              ? checkIfParentAccepted(
                  player?.person?.guardians![0].guardian?.personId
                )
                ? "PARENT_ACCEPTED"
                : "CHILD_PENDING"
              : "USER_PENDING",
            parent: isChild
              ? getParentObject(
                  player?.person?.guardians![0].guardian?.personId
                )
              : undefined,
            permissions: Object.keys(player.metadata as object).includes(
              "permissions"
            )
              ? ((player.metadata as object)["permissions"] as [])
              : []
          };
          return formattedPerson;
        });
    }
    return [...invites, ...accepted];
  };

  useEffect(() => {
    if (currentTeam) {
      const playersSelected = getPlayers(currentTeam);
      if (playersSelected) setPlayersWithValues([...playersSelected]);
      const coachesSelected = getCoachesOrManagers("COACH", currentTeam);
      if (coachesSelected) setCoachesWithValues([...coachesSelected]);
      const managersSelected = getCoachesOrManagers("MANAGER", currentTeam);
      if (managersSelected) setManagerWithValues([...managersSelected]);
    }
  }, [currentTeam]);

  const getPlayerMetaDataLabels = (players: PlayerOption[]) => {
    const labelvalues = [] as AuditLogLabelValue[];
    const positions = positionsResponse?.data?.positions;
    players.map((p) => {
      const name = p.label;
      const position = positions
        ? p.metadata?.positions
            ?.map((pos) => positions.find((po) => po.positionId === pos)!.name)
            .join(", ")
        : null;
      const height = p.metadata?.height?.split("'");
      const weight = p.metadata?.weight;
      if (position) {
        labelvalues.push({
          label: `${name} "Position" field:`,
          value: [
            {
              value: position
            }
          ]
        });
      }
      if (height) {
        labelvalues.push({
          label: `${name} "Height" field:`,
          value: [
            {
              value: `${height?.[0]}ft ${height?.[1]}in`
            }
          ]
        });
      }
      if (weight) {
        labelvalues.push({
          label: `${name} "Weight" field:`,
          value: [
            {
              value: `${weight}lb`
            }
          ]
        });
      }
    });
    return labelvalues;
  };

  const getCoachesManagersMetaDataLabels = (
    list: CoachOption[] | ManagerOption[]
  ) => {
    const labelvalues = [] as AuditLogLabelValue[];
    list.map((p) => {
      const name = p.label;
      const title = p.metadata?.title;
      const isMainRole = !!teamRoles?.find(
        (r) => r.roleId === p.roleId || r.inheritedFromId === p.roleId
      );
      const subRole = !isMainRole
        ? teamRoles
            ?.filter((r) => r.alias === p.role)?.[0]
            ?.children?.find(
              (r) => r.inheritedFromId === p.roleId || r.roleId === p.roleId
            )?.name
        : null;
      if (title) {
        labelvalues.push({
          label: `${name} "Title" field:`,
          value: [
            {
              value: title
            }
          ]
        });
      }
      if (subRole) {
        labelvalues.push({
          label: `${name} "Sub Role" field:`,
          value: [
            {
              value: subRole
            }
          ]
        });
      }
    });
    return labelvalues;
  };

  useEffect(() => {
    if (permissionsResponse?.data) {
      const permissionsList = () => {
        const children = permissionsResponse.data.find((permission) =>
          page === "TEAM"
            ? permission.permissionId === "team"
            : permission.permissionId === "training-programs"
        )?.children;
        return (
          children?.filter((permission) =>
            permission.permissionId?.includes("associated")
          ) || []
        );
      };
      const coachesSubRoles = teamRoles
        ?.find((item) => item.alias === "COACH")
        ?.children?.map((item) => {
          return {
            ...item,
            label: item?.name,
            value: item?.roleId
          };
        });
      const managerSubRoles = teamRoles
        ?.find((item) => item.alias === "MANAGER")
        ?.children?.map((item) => {
          return {
            ...item,
            label: item?.name,
            value: item?.roleId
          };
        });

      const result = generatePermissionValues({
        permissions: permissionsList(),
        coachesWithValues: coachesWithValues || [],
        coachRole: teamRoles!.find((r) => r.alias === "COACH"),
        coachSubRoles: coachesSubRoles || [],
        managerWithValues: managerWithValues || [],
        managerRole: teamRoles!.find((r) => r.alias === "MANAGER"),
        managerSubRoles: managerSubRoles || [],
        playersWithValues: playersWithValues || [],
        playerRole: teamRoles!.find((r) => r.alias === "PLAYER"),
        parentRole: teamRoles!.find((r) => r.alias === "PARENT")
      });
      setPermissionValues(result);
    }
  }, [
    permissionsResponse,
    coachesWithValues,
    playersWithValues,
    managerWithValues,
    teamRoles
  ]);

  const populatePermissionsByRole = (
    userList,
    isSubRole: boolean,
    alias: string
  ) => {
    const labelvalues = [] as AuditLogLabelValue[];
    userList
      .sort((a, b) => a.label.localeCompare(b.label))
      .map((c) => {
        const permissions = c.permissions;
        const name = c.label;
        const role = isSubRole
          ? teamRoles
              ?.filter((t) => t.alias === alias)?.[0]
              ?.children?.find(
                (t) => t.roleId === c.roleId || t.inheritedFromId === c.roleId
              )?.name
          : capitalize(alias);
        const teamPermissions = permissions.find((p) =>
          page === "TRAINING_PROGRAM"
            ? p.permissionId === "training-programs.associated-programs"
            : p.permissionId === "team.associated-teams"
        );
        const postPermission = permissions.find((p) =>
          page === "TRAINING_PROGRAM"
            ? p.permissionId === "training-programs.associated-post"
            : p.permissionId === "team.associated-post"
        );
        const commentPermission = permissions.find((p) =>
          page === "TRAINING_PROGRAM"
            ? p.permissionId === "training-programs.associated-comment"
            : p.permissionId === "team.associated-comment"
        );
        const sharePermission = permissions.find((p) =>
          page === "TRAINING_PROGRAM"
            ? p.permissionId === "training-programs.associated-share"
            : p.permissionId === "team.associated-share"
        );
        if (teamPermissions?.actions?.length) {
          labelvalues.push({
            label: `${name} ${role} "${
              page === "TEAM" ? "Team" : "Training Program"
            }" permission:`,
            value: teamPermissions?.actions?.map((t) => ({
              subText: capitalize(t),
              value: "On"
            }))
          });
        }
        if (postPermission?.actions?.length) {
          labelvalues.push({
            label: `${name} ${role} "Post and Live Stream on behalf of this ${
              page === "TEAM" ? "team" : "training program"
            }" permission:`,
            value: [
              {
                value: capitalize(postPermission.actions.join(", "))
              }
            ]
          });
        }
        if (commentPermission?.actions?.length) {
          labelvalues.push({
            label: `${name} ${role} "Comment on Posts and Live Streams for this ${
              page === "TEAM" ? "team" : "training program"
            }" permission:`,
            value: [
              {
                value: capitalize(commentPermission.actions.join(", "))
              }
            ]
          });
        }
        if (sharePermission?.actions?.length) {
          labelvalues.push({
            label: `${name} ${role} "Share Posts and Live Streams for this ${
              page === "TEAM" ? "team" : "training program"
            }" permission:`,
            value: [
              {
                value: capitalize(sharePermission.actions.join(", "))
              }
            ]
          });
        }
      });
    return labelvalues;
  };

  const getPermissionLabels = (permissionsList) => {
    const labelvalues = [] as AuditLogLabelValue[];
    const {
      coachesWithNoSubRole,
      coachesWithSubRole,
      managersWithNoSubRole,
      managersWithSubRole,
      parents,
      players
    } = permissionsList;
    labelvalues.push(
      ...populatePermissionsByRole(coachesWithNoSubRole, false, "COACH")
    );
    labelvalues.push(
      ...populatePermissionsByRole(coachesWithSubRole, true, "COACH")
    );
    labelvalues.push(
      ...populatePermissionsByRole(managersWithNoSubRole, false, "MANAGER")
    );
    labelvalues.push(
      ...populatePermissionsByRole(managersWithSubRole, true, "MANAGER")
    );
    labelvalues.push(...populatePermissionsByRole(players, false, "PLAYER"));
    labelvalues.push(...populatePermissionsByRole(parents, false, "PARENT"));
    return labelvalues;
  };

  const getTeamDetaiilsLabels = () => {
    const labels: AuditLogLabelValue[] = [];

    labels.push(
      {
        label: `"Name" field:`,
        value: [
          {
            value: currentTeam?.name || ""
          }
        ]
      },
      ...(page === "TRAINING_PROGRAM"
        ? [
            {
              label: `"Type" field:`,
              value: [
                {
                  value:
                    TrainingProgramTypeOptions.find(
                      (tp) => tp.value === currentTeam.type
                    )?.label || ""
                }
              ]
            }
          ]
        : []),
      {
        label: `"Sport" field:`,
        value: [
          {
            value: currentTeam?.sport?.name || ""
          }
        ]
      },
      ...(page === "TEAM"
        ? [
            {
              label: `"Season" field:`,
              value: [
                {
                  value: currentTeam?.season?.name || ""
                }
              ]
            }
          ]
        : []),
      {
        label: `"Gender" field:`,
        value: [
          {
            value:
              currentTeam?.genders
                ?.map((g) => GENDERS.find((c) => c.value === g)!.label)
                .join(", ") || ""
          }
        ]
      },
      ...(currentTeam.levelId
        ? [
            {
              label: `"Level" field:`,
              value: [
                {
                  value: currentTeam?.level?.name || ""
                }
              ]
            }
          ]
        : [])
    );

    if (page === "TRAINING_PROGRAM") {
      const dates = currentTeam.dates as TrainingProgramDate[];

      dates?.map((date, index) => {
        labels.push(
          {
            label: `Date & Time ${
              dates.length > 1 ? index + 1 : ""
            } "Start Date" field:`,
            value: [
              {
                value: formatDateForDisplay(new Date(date.startDate))
              }
            ]
          },
          {
            label: `Date & Time ${
              dates.length > 1 ? index + 1 : ""
            } "End Date" field:`,
            value: [
              {
                value: formatDateForDisplay(new Date(date.endDate))
              }
            ]
          }
        );
        date.timings.flatMap((timing, i) => {
          const repeat = convertRruleToMessage(timing.customRepeat ?? {});
          const repeatText = repeat.isCustomText
            ? repeat.customText
            : repeat.freqName === "DNR"
              ? "Does not repeat"
              : `Repeats ${repeat.freqName?.toLowerCase()}`;
          labels.push(
            {
              label: `Date & Time ${
                dates.length > 1 ? index + 1 : ""
              } "Start Time${
                date.timings.length > 1 ? ` ${i + 1}` : ""
              }" field:`,
              value: [
                {
                  value: convertToAmPm(timing.startTime)
                }
              ]
            },
            {
              label: `Date & Time ${
                dates.length > 1 ? index + 1 : ""
              } "End Time${date.timings.length > 1 ? ` ${i + 1}` : ""}" field:`,
              value: [
                {
                  value: convertToAmPm(timing.endTime)
                }
              ]
            },
            {
              label: `Date & Time ${dates.length > 1 ? index + 1 : ""} "Repeat${
                date.timings.length > 1 ? ` ${i + 1}` : ""
              }" field:`,
              value: [
                {
                  value: repeatText
                }
              ]
            }
          );
        });
      });
    }

    return labels;
  };

  return (
    <Loader isLoading={isLoading || isLoadingPermissions}>
      <Container>
        <ButtonGroup
          variant="outlined"
          style={{ border: "rgba(215, 221, 229, 1)", paddingBottom: "24px" }}
        >
          {tabs.map((t) => {
            return (
              <Button
                key={t}
                variant="outlined"
                style={
                  tab === t
                    ? {
                        backgroundColor: "#E8EEFF",
                        color: "#2B337A",
                        fontSize: "14px",
                        fontWeight: 600,
                        textTransform: "none"
                      }
                    : {
                        color: "#666666",
                        fontSize: "14px",
                        fontWeight: 500,
                        textTransform: "none"
                      }
                }
                onClick={() => {
                  setTab(t as Tabs);
                }}
              >
                {t}
              </Button>
            );
          })}
        </ButtonGroup>
        {tab === "Team Details" || tab === "Program Details" ? (
          <AuditLogLabelValueList
            recordType={recordType}
            labelValues={[...getTeamDetaiilsLabels()]}
          />
        ) : tab === "Players" || tab === "Athletes" ? (
          <AuditLogLabelValueList
            recordType={recordType}
            labelValues={[
              {
                label:
                  page === "TEAM"
                    ? `${
                        recordType === "CREATE" ? "Added" : "Deleted"
                      } Players:`
                    : `${
                        recordType === "CREATE" ? "Added" : "Deleted"
                      } Athletes:`,
                value: [
                  {
                    value: playersWithValues
                      .sort((a, b) => a.label.localeCompare(b.label))
                      .map((p) => p.label)
                      .join(", ")
                  }
                ],
                hideBadge: true
              },
              ...getPlayerMetaDataLabels(
                playersWithValues.sort((a, b) => a.label.localeCompare(b.label))
              )
            ]}
          />
        ) : tab === "Coaches" ? (
          <AuditLogLabelValueList
            recordType={recordType}
            labelValues={[
              {
                label: `${
                  recordType === "CREATE" ? "Added" : "Deleted"
                } Coaches:`,
                value: [
                  {
                    value: coachesWithValues
                      .sort((a, b) => a.label.localeCompare(b.label))
                      .map((p) => p.label)
                      .join(", ")
                  }
                ],
                hideBadge: true
              },
              ...getCoachesManagersMetaDataLabels(
                coachesWithValues.sort((a, b) => a.label.localeCompare(b.label))
              )
            ]}
          />
        ) : tab === "Managers" ? (
          <AuditLogLabelValueList
            recordType={recordType}
            labelValues={[
              {
                label: `${
                  recordType === "CREATE" ? "Added" : "Deleted"
                } Managers:`,
                value: [
                  {
                    value: managerWithValues
                      .sort((a, b) => a.label.localeCompare(b.label))
                      .map((p) => p.label)
                      .join(", ")
                  }
                ],
                hideBadge: true
              },
              ...getCoachesManagersMetaDataLabels(
                managerWithValues.sort((a, b) => a.label.localeCompare(b.label))
              )
            ]}
          />
        ) : (
          tab === "Permissions" && (
            <AuditLogLabelValueList
              recordType={recordType}
              labelValues={[...getPermissionLabels(permissionValues)]}
            />
          )
        )}
      </Container>
    </Loader>
  );
};
