import { ModelAccount } from "@sportsgravyengineering/sg-api-react-sdk";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "../types";

export const AccountKeyDataAuditLogDetails = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelAccount;
  after: object | ModelAccount;
}) => {
  const account = (recordType !== "DELETE" ? after : before) as ModelAccount;
  const oldAccount = before as ModelAccount;

  const getUpdateLabels = () => {
    const labels: AuditLogLabelValue[] = [];

    if (account.orgSalesRating !== oldAccount.orgSalesRating)
      labels.push({
        label: `Updated "Org Sales Rating" field:`,
        value: [
          {
            oldValue: oldAccount.orgSalesRating || "N/A",
            value: account.orgSalesRating || "N/A"
          }
        ]
      });

    if (account.numberOfAthletes !== oldAccount.numberOfAthletes)
      labels.push({
        label: `Updated "No. of Athletes" field:`,
        value: [
          {
            oldValue: oldAccount.numberOfAthletes
              ? oldAccount.numberOfAthletes.toString()
              : "N/A",
            value: account.numberOfAthletes
              ? account.numberOfAthletes.toString()
              : "N/A"
          }
        ]
      });

    return labels;
  };

  return (
    <>
      {recordType === "UPDATE" ? (
        <AuditLogLabelValueList
          recordType="UPDATE"
          labelValues={[...getUpdateLabels()]}
        />
      ) : null}
    </>
  );
};
