/* eslint-disable @typescript-eslint/no-unused-vars */
import { ToolTip } from "@components/ToolTip";
import { Download, Refresh, Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { CRM_ORDER_PAYMENT_TYPES } from "@utils/constants";
import { formatCurrency } from "@utils/formatCurrency";
import { useEffect, useState } from "react";
import {
  adminBillingInvoiceGet,
  ModelInvoice,
  useAdminBillingInvoiceIdRetryPayment
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useParams } from "react-router-dom";
import { TableView } from "@components/TableView";
import { getBillingInvoices, getOrderBillingHistory } from "@services/Network";
import { capitalize } from "@utils/capitalize";
import { OrderInvoiceModal } from "./OrderInvoiceModal";
import { usePDF } from "@react-pdf/renderer";
import { InvoicePDF } from "@components/InvoicePDF";
import { Loader } from "@components/crud/Loader";
import { downloadPDFFromLink } from "@utils/downloadPdfFromLink";
import { RetryInvoiceModal } from "./RetryInvoiceModal";
import { Form } from "@components/crud/Form";
import { formatPhoneWithCountryCode } from "@utils/phoneFormatters";

const IconStyle = {
  height: "24px",
  width: "24px",
  color: "#666666"
};
const formatDateForDisplay = (dateStr) => {
  const inputDate = new Date(dateStr);
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const day = inputDate.getDate().toString().padStart(2, "0");
  const year = inputDate.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

export const OrderBillingHistory = ({
  organizationId
}: {
  organizationId?: string;
}) => {
  const [invoiceToShow, setInvoiceToShow] = useState<ModelInvoice | null>(null);
  const [invoiceIdDownload, setInvoiceIdDownload] = useState<string | null>(
    null
  );
  const [invoiceInstance, setInvoiceInstance] = usePDF();
  const { orderId } = useParams();
  const [retryInvoiceId, setRetryInvoiceId] = useState<
    ModelInvoice | undefined
  >(undefined);
  const getBillingHitory = (params, options) => {
    return getOrderBillingHistory(orderId, params, options);
  };

  const generateInvoiceInstance = async (invoiceToShow: ModelInvoice) => {
    const accountContact = invoiceToShow.order?.contact?.accounts?.find(
      (a) => a.accountId === invoiceToShow.order?.accountId
    );
    setInvoiceInstance(
      <InvoicePDF
        invoiceFor="ORDER"
        hasFooter={false}
        hasHeader={true}
        invoiceDetails={{
          from: {
            name: "Sports Gravy LLC.",
            address: "9900 Hemingway Ave S, Cottage Grove, MN, 55016, USA"
          },
          invoiceNumber: "SG-" + invoiceToShow.invoiceNumber!,
          invoiceDate: invoiceToShow.createdAt,
          startDate:
            invoiceToShow.order?.revisedStartDate ||
            invoiceToShow.order?.originalStartDate ||
            "",
          endDate:
            invoiceToShow.order?.revisedEndDate ||
            invoiceToShow.order?.originalEndDate ||
            "",
          paymentDate: invoiceToShow?.paymentDetail?.createdAt,
          paymentMode:
            `${
              CRM_ORDER_PAYMENT_TYPES.find(
                (f) => f.value === invoiceToShow.paymentDetail?.paymentType
              )?.label
            } ${invoiceToShow.paymentDetail?.info?.payment?.bin.slice(-4)}` ||
            "",
          terms: "Due Upon Receipt",
          paymentStatus: invoiceToShow.status === "PAID" ? "PAID" : "FAILED",
          billTo: {
            to: invoiceToShow?.order?.account?.name as string,
            name:
              invoiceToShow.order?.contact?.firstName +
              " " +
              invoiceToShow.order?.contact?.lastName,
            email:
              accountContact?.workEmail ||
              invoiceToShow.order?.contact?.email ||
              "",
            phone: accountContact?.workPhone
              ? formatPhoneWithCountryCode(accountContact?.workPhone)
              : invoiceToShow.order?.contact?.phone
                ? formatPhoneWithCountryCode(
                    invoiceToShow.order?.contact?.phone
                  )
                : ""
          },
          items:
            invoiceToShow?.billingItems?.map((bi) => {
              return {
                name: (bi?.description?.description as string) || "",
                amount: bi.amount ?? 0,
                quantity: bi.quantity ?? 1,
                desciption: [],
                unitPrice: bi.unitPrice ?? 0
              };
            }) || [],
          taxDetails: {
            taxRate: invoiceToShow.taxRate ?? 0,
            taxAmount:
              (invoiceToShow.total! * (invoiceToShow.taxRate ?? 0)) / 100
          },
          feeDetails: {
            feeRate: invoiceToShow.feeRate ?? 0,
            feeAmount:
              (invoiceToShow.total! * (invoiceToShow.feeRate ?? 0)) / 100
          }
        }}
      />
    );
  };

  const handleDownloadPDF = async (invoice: ModelInvoice) => {
    await generateInvoiceInstance(invoice);
  };

  useEffect(() => {
    if (invoiceIdDownload && invoiceInstance.url) {
      downloadPDFFromLink(invoiceInstance.url, invoiceIdDownload);
      setInvoiceIdDownload(null);
    }
  }, [invoiceIdDownload, invoiceInstance]);

  const COLUMNS: GridColDef<ModelInvoice>[] = [
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      minWidth: organizationId ? 170 : 120,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ padding: "20px 0px", display: "flex" }}>
            <IconButton
              onClick={async () => {
                await generateInvoiceInstance(params.row!);
                if (!invoiceInstance.loading) {
                  setInvoiceToShow(params.row!);
                }
              }}
            >
              <ToolTip title="View Invoice" placement="top">
                <Visibility style={IconStyle} />
              </ToolTip>
            </IconButton>
            <Loader isLoading={invoiceInstance.loading}>
              <IconButton
                onClick={() => {
                  setInvoiceIdDownload(params.row.invoiceId!);
                  handleDownloadPDF(params.row!);
                }}
              >
                <ToolTip title="Download Invoice" placement="top">
                  <Download style={IconStyle} />
                </ToolTip>
              </IconButton>
            </Loader>
            {organizationId && params.row.status === "FAILED" && (
              <IconButton
                onClick={() => {
                  setRetryInvoiceId(params.row);
                }}
              >
                <ToolTip title="Retry Payment" placement="top">
                  <Refresh style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
          </div>
        );
      }
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      flex: 1,
      minWidth: 170,
      valueGetter: ({ row }) => "SG-" + row.invoiceNumber
    },
    {
      field: "transactionId",
      headerName: "Transaction ID",
      flex: 1,
      minWidth: 250,
      valueGetter: ({ row }) => row.transactionId
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 150,
      valueGetter: ({ row }) => formatCurrency(row.total!)
    },
    {
      field: "paymentType",
      headerName: "Payment Type",
      flex: 1,
      minWidth: 150,

      valueGetter: ({ row }) =>
        CRM_ORDER_PAYMENT_TYPES.find(
          (o) => o.value === row.paymentDetail?.paymentType
        )?.label
    },
    {
      field: "dateSent",
      headerName: "Date Sent",
      flex: 1,
      minWidth: 150,
      valueGetter: ({ row }) => formatDateForDisplay(row.dateSent)
    },
    {
      field: "datePaid",
      headerName: "Date Paid",
      flex: 1,
      minWidth: 150,
      valueGetter: ({ row }) =>
        row.datePaid ? formatDateForDisplay(row.datePaid) : ""
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      valueGetter: ({ row }) => capitalize(row.status!)
    }
  ];
  const [refreshKey, setRefreshKey] = useState(0);
  const { mutate: save, isLoading: isLoading } =
    useAdminBillingInvoiceIdRetryPayment();
  const onRetryPayment = async () => {
    if (retryInvoiceId && save) {
      save(
        {
          invoiceId: retryInvoiceId.invoiceId!
        },
        {
          onSettled: async () => {
            setRetryInvoiceId(undefined);
            const resp = await adminBillingInvoiceGet({
              organizationId: organizationId!
            });
            const latestInvoice = resp.data?.find(
              (i) => i.invoiceId === retryInvoiceId.invoiceId!
            );
            if (latestInvoice) {
              await generateInvoiceInstance(latestInvoice);
              if (!invoiceInstance.loading) {
                setInvoiceToShow(latestInvoice);
              }
            }
          }
        }
      );
    }
  };
  return (
    <div>
      <Form style={{ padding: 0 }}>
        <TableView
          useGet={organizationId ? getBillingInvoices : getBillingHitory}
          columns={COLUMNS}
          getRowId={(row) => row.invoiceId}
          title="Billing History"
          hasActionColumn={false}
          getRowHeight={() => "auto"}
          hideFilter
          hideSecondaryLabelContainer
          hideLabelContainer
          refreshKey={refreshKey}
          hideFooter
          hideFooterPagination
        />
      </Form>
      {invoiceToShow && (
        <OrderInvoiceModal
          open={!!invoiceToShow}
          invoice={invoiceToShow}
          invoiceInstance={invoiceInstance}
          onClose={() => setInvoiceToShow(null)}
        />
      )}
      {organizationId && retryInvoiceId && (
        <RetryInvoiceModal
          invoice={retryInvoiceId}
          organizationId={organizationId}
          onClose={() => {
            setRetryInvoiceId(undefined);
          }}
          onSave={() => {
            onRetryPayment();
            setRefreshKey(refreshKey + 1);
          }}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};
