import {
  ModelPresetTag,
  ModelSport
} from "@sportsgravyengineering/sg-api-react-sdk";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "../types";

export const PresetTagsAuditLog = ({
  recordType,
  before,
  after,
  type
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelPresetTag | ModelSport;
  after: object | ModelPresetTag | ModelSport;
  type: "PRESET_TAG" | "PRESET_TAG_ORDER";
}) => {
  const tag = (recordType !== "DELETE" ? after : before) as ModelPresetTag;
  const oldTag = before as ModelPresetTag;

  const getUpdateLabels = () => {
    const labels: AuditLogLabelValue[] = [];

    if (oldTag.name !== tag.name)
      labels.push({
        label: `Updated the "Name" field:`,
        value: [
          {
            value: tag.name || "",
            oldValue: oldTag.name || ""
          }
        ]
      });

    if (oldTag.sportId !== tag.sportId)
      labels.push({
        label: `Updated the "Sport" field:`,
        value: [
          {
            value: tag.sport?.name || "",
            oldValue: oldTag.sport?.name || ""
          }
        ]
      });

    if (oldTag.isActive !== tag.isActive)
      labels.push({
        label: `Updated the "Status" field:`,
        value: [
          {
            value: tag.isActive ? "Active" : "Inactive",
            oldValue: oldTag.isActive ? "Active" : "Inactive"
          }
        ]
      });

    return labels;
  };

  if (type === "PRESET_TAG") {
    if (recordType === "UPDATE")
      return (
        <AuditLogLabelValueList
          recordType="UPDATE"
          labelValues={[...getUpdateLabels()]}
        />
      );
    else
      return (
        <AuditLogLabelValueList
          recordType={recordType}
          labelValues={[
            {
              label: `"Name" field:`,
              value: [
                {
                  value: tag.name || ""
                }
              ]
            },
            {
              label: `"Sport" field:`,
              value: [
                {
                  value: tag.sport?.name || ""
                }
              ]
            },
            {
              label: `"Status" field:`,
              value: [
                {
                  value: tag.isActive ? "Active" : "Inactive"
                }
              ]
            }
          ]}
        />
      );
  } else {
    const oldOrder = before as ModelSport;
    const newOrder = after as ModelSport;
    console.log(newOrder);
    return (
      <AuditLogLabelValueList
        recordType={recordType}
        labelValues={[
          {
            label: `Updated Sort Order:`,
            value: [
              {
                subText: newOrder.name,
                value: newOrder?.presetTag?.map((t) => t.name).join(", "),
                oldValue: oldOrder?.presetTag?.map((t) => t.name).join(", ")
              }
            ]
          }
        ]}
      />
    );
  }
};
