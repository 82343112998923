import { ModelConfig } from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { CrmSettingAuditLogUpdate } from "./CrmSettingAuditLogUpdate";

export const CrmSettingAuditLogDetails = ({
  before,
  after
}: {
  before: object | ModelConfig;
  after: object | ModelConfig;
}) => {
  return <CrmSettingAuditLogUpdate before={before} after={after} />;
};
