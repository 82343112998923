import { Autocomplete, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { Control, Controller, useFieldArray } from "react-hook-form";

import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { SearchInput } from "@components/SearchInput";
import { getSportsByCountry } from "@services/Network";
import { useAdminOrganizationGoverningBodiesGet } from "@sportsgravyengineering/sg-api-react-sdk";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { FormInput } from "@components/FormInput";
import { Loader } from "@components/crud/Loader";

const yesNoOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" }
];

const genderOptions = [
  { label: "Male", value: "MALE" },
  { label: "Female", value: "FEMALE" }
];

const SportsLabel = styled(Typography)`
  font-size: 16px;
`;

export const OrganizationSportsForm = ({
  disabled,
  control,
  country,
  allowGoverningBodyBySport,
  sportsOffered,
  isGovernedByOrg,
  setValue,
  organizationId
}: {
  disabled: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  country: string;
  allowGoverningBodyBySport: string;
  sportsOffered: string[];
  isGovernedByOrg: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: any;
  organizationId?: string;
}) => {
  const [governingBodySearch, setGoverningBodySearch] = React.useState("");
  const { data: sports, isFetching } = getSportsByCountry(country, {
    staleTime: Infinity
  });
  const sportsOptions = sports?.map((sport) => ({
    label: !sport.name ? sport.sport?.name : sport.name,
    value: sport.sportId,
    hasInterval: sport.sport?.isIntervalBased,
    intervalSingular: sport.sport?.intervalSingular,
    intervalPlural: sport.sport?.intervalPlural,
    intervalAbbreviation: sport.sport?.intervalAbbreviation
  }));

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sportsFields"
  });

  const { data: allGoverningBodiesResponse } =
    useAdminOrganizationGoverningBodiesGet();
  const allGoverningBodies = React.useMemo(() => {
    if (allGoverningBodiesResponse?.data) {
      return allGoverningBodiesResponse.data?.governingBodies
        .filter((gb) => gb?.organizationId !== organizationId)
        .map((governingBody) => ({
          label: governingBody.name,
          value: governingBody.organizationId
        }));
    }
    return [];
  }, [allGoverningBodiesResponse]);
  const { isLoading: isGoverningLoading, data: governingBodiesResponse } =
    useAdminOrganizationGoverningBodiesGet(
      {
        search: encodeURIComponent(governingBodySearch)
      },
      {
        query: {
          enabled: governingBodySearch.length > 0
        }
      }
    );
  const governingBodies = React.useMemo(() => {
    if (governingBodiesResponse?.data) {
      return governingBodiesResponse.data?.governingBodies
        .filter((gb) => gb?.organizationId !== organizationId)
        .map((governingBody) => ({
          label: governingBody.name,
          value: governingBody.organizationId
        }));
    }
    return [];
  }, [governingBodiesResponse]);

  const onSportsOfferedChange = (event) => {
    const changedSportsOffered = event.target.value;
    if (Array.isArray(changedSportsOffered)) {
      const sportsOfferedToAdd = changedSportsOffered.filter(
        (sport) => !sportsOffered.includes(sport)
      );
      sportsOfferedToAdd.forEach((sportId) => {
        const sportInfo = sportsOptions.find(
          (sport) => sport.value === sportId
        );
        const sportField = {
          sportId,
          offeredFor: [],
          governingBody: null,
          maleSingularInterval: sportInfo?.hasInterval
            ? sportInfo.intervalSingular
            : "",
          malePluralInterval: sportInfo?.hasInterval
            ? sportInfo.intervalPlural
            : "",
          maleIntervalAbbreviation: sportInfo?.hasInterval
            ? sportInfo.intervalAbbreviation
            : "",
          femaleSingularInterval: sportInfo?.hasInterval
            ? sportInfo.intervalSingular
            : "",
          femalePluralInterval: sportInfo?.hasInterval
            ? sportInfo.intervalPlural
            : "",
          femaleIntervalAbbreviation: sportInfo?.hasInterval
            ? sportInfo.intervalAbbreviation
            : ""
        };
        append(sportField);
      });
    }
  };

  const onSportOfferedRemove = (value) => {
    const removedIndex = sportsOffered.indexOf(value);
    remove(removedIndex);
  };
  const [sportsField, setSportsField] = React.useState(fields);
  React.useEffect(() => {
    setSportsField(fields);
  }, [fields]);
  return (
    <Loader isLoading={isFetching}>
      <Grid
        data-testid="org-sport-form"
        container
        direction="column"
        spacing="25px"
      >
        <Grid item container direction="row" spacing="24px">
          <Grid data-testid="org-sport" item xs={12}>
            <FormMultiSelect
              name="sportsOffered"
              isLoading={isFetching}
              control={control}
              disabledOptions={sportsField
                .filter((sf) => sf.players?.length > 0)
                .map((s) => s.sportId)}
              rules={{ required: "Sports Offered is required" }}
              label="Sports Offered"
              options={sportsOptions.map((ap) => ({
                label: ap.label as string,
                value: ap.value
              }))}
              required
              disabled={disabled}
              onChange={onSportsOfferedChange}
              onRemove={onSportOfferedRemove}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          spacing="24px"
          alignItems="flex-end"
        >
          <Grid data-testid="org-isGovernedByOrg" item xs={6}>
            <FormSelect
              name="isGovernedByOrg"
              control={control}
              rules={{
                required: "Are you governed by an organization is required"
              }}
              label="Are you governed by an organization?"
              options={yesNoOptions}
              required
              disabled={disabled}
              onChange={(event) => {
                setValue("isGovernedByOrg", event.target.value);
                setValue("allowGoverningBodyBySport", "no");
                setValue("governingBodyId", undefined);
                if (event.target.value === "no") {
                  fields.forEach((field, index) => {
                    setValue(
                      `sportsFields[${index}].governingBodyId`,
                      undefined
                    );
                  });
                }
              }}
            />
          </Grid>
          {isGovernedByOrg === "yes" && (
            <Grid data-testid="org-allowGoverningBodyBySport" item xs={6}>
              <FormSelect
                name="allowGoverningBodyBySport"
                placeholder=" "
                control={control}
                rules={{
                  required: "Allow governing body by sport is required"
                }}
                label="Allow governing body by sport"
                options={yesNoOptions}
                required
                disabled={disabled}
                onChange={(event) => {
                  setValue("allowGoverningBodyBySport", event.target.value);
                  setValue("governingBodyId", undefined);
                  if (event.target.value === "no") {
                    fields.forEach((field, index) => {
                      setValue(
                        `sportsFields[${index}].governingBodyId`,
                        undefined
                      );
                    });
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
        {isGovernedByOrg === "yes" && allowGoverningBodyBySport === "no" && (
          <Grid
            item
            container
            direction="row"
            spacing="24px"
            alignItems="center"
          >
            <Grid data-testid="org-governingBodyId" item xs={6}>
              {disabled ? (
                <FormSelect
                  name="governingBodyId"
                  control={control}
                  label="Who are you governed by?"
                  required={true}
                  options={allGoverningBodies}
                  disabled
                />
              ) : (
                <Controller
                  name="governingBodyId"
                  control={control}
                  rules={{ required: "Who are you governed by is required" }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      autoHighlight
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onChange={(event, value: any) => {
                        field.onChange(value?.value || "");
                      }}
                      value={field?.value || ""}
                      getOptionLabel={(option) =>
                        option?.label ||
                        allGoverningBodies.find(
                          (governingBody) => governingBody.value === option
                        )?.label ||
                        ""
                      }
                      options={
                        governingBodies.length
                          ? governingBodies
                          : allGoverningBodies.length
                            ? allGoverningBodies
                            : []
                      }
                      renderInput={(params) => (
                        <SearchInput
                          {...params}
                          {...fieldState}
                          value={params.inputProps.value}
                          placeholder="Search"
                          onChange={(event) => {
                            setGoverningBodySearch(event.target.value);
                          }}
                          InputProps={{
                            ...params.InputProps,
                            label: "Who are you governed by?"
                          }}
                          label="Who are you governed by?"
                          required={true}
                          onBlur={params.inputProps.onBlur}
                        />
                      )}
                      loading={isGoverningLoading}
                      loadingText={
                        isGoverningLoading ? "Loading..." : undefined
                      }
                    />
                  )}
                />
              )}
            </Grid>
          </Grid>
        )}
        {fields.map((field, index) => {
          const sportId = sportsOffered[index];
          const label = sportsOptions.find(
            (sportOption) => sportOption.value === sportId
          )?.label;
          const offeredFor = sportsField[index]?.offeredFor;
          const hasIntervals = sportsOptions.find(
            (sportOption) => sportOption.value === sportId
          )?.hasInterval;
          const genders =
            (sportsField.length > 0 &&
              sportsField[index]?.players
                ?.map((obj) => obj.person.gender)
                .filter((gender) => gender !== null)) ||
            [];
          const distinctGenders = [...new Set(genders)] as string[];
          return (
            <React.Fragment key={field.id}>
              <Grid
                item
                container
                direction="row"
                spacing="24px"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <SportsLabel variant="caption">{label}</SportsLabel>
                  <HeaderUnderLine />
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                spacing="24px"
                alignItems="center"
              >
                <Grid data-testid={`org-offeredFor-${index}`} item xs={6}>
                  <FormMultiSelect
                    name={`sportsFields[${index}].offeredFor`}
                    control={control}
                    rules={{ required: "Offered for is required" }}
                    label="Offered for"
                    options={genderOptions}
                    required
                    onChange={(e) => {
                      const arr = sportsField;
                      const idx = sportsField.findIndex(
                        (sport) => sport.id === field.id
                      );
                      arr[idx].offeredFor = e.target.value;
                      setSportsField([...arr]);
                    }}
                    onRemove={(value) => {
                      const arr = sportsField;
                      const idx = sportsField.findIndex(
                        (sport) => sport.id === field.id
                      );
                      const valueIdx = arr[idx].offeredFor.findIndex(
                        (s) => s === value
                      );
                      arr[idx].offeredFor.splice(valueIdx, 1);

                      setSportsField([...arr]);
                    }}
                    disabledOptions={distinctGenders}
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
              {hasIntervals &&
                Array.isArray(offeredFor) &&
                offeredFor.map((gender) => {
                  const gen = gender === "MALE" ? "Male" : "Female";
                  return (
                    <Grid
                      key={gender}
                      item
                      container
                      direction="row"
                      spacing="24px"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={4}
                        data-testid={`singularInterval-${gen}-${index}`}
                      >
                        <FormInput
                          name={`sportsFields[${index}].${
                            gen === "Male" ? "male" : "female"
                          }SingularInterval`}
                          control={control}
                          label={gen + " Singular Interval"}
                          type="text"
                          disabled={disabled}
                          required
                          rules={{
                            required: "Male Singular Interval is required"
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        data-testid={`pluralInterval-${gen}-${index}`}
                      >
                        <FormInput
                          name={`sportsFields[${index}].${
                            gen === "Male" ? "male" : "female"
                          }PluralInterval`}
                          control={control}
                          label={gen + " Plural Interval"}
                          disabled={disabled}
                          type="text"
                          rules={{
                            required: "Male Plural Interval is required"
                          }}
                          required
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        data-testid={`intervalAbbreviation-${gen}-${index}`}
                      >
                        <FormInput
                          name={`sportsFields[${index}].${
                            gen === "Male" ? "male" : "female"
                          }IntervalAbbreviation`}
                          control={control}
                          disabled={disabled}
                          label={gen + " Interval Abbreviation"}
                          type="text"
                          rules={{
                            required: "Male Interval Abbreviation is required"
                          }}
                          required
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              {allowGoverningBodyBySport === "yes" && (
                <Grid
                  data-testid={`org-governingbody-Sport-${index}`}
                  item
                  container
                  direction="row"
                  spacing="24px"
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    {disabled ? (
                      <FormSelect
                        name={`sportsFields[${index}].governingBodyId`}
                        control={control}
                        label="Who are you governed by?"
                        required={true}
                        options={allGoverningBodies}
                        disabled
                      />
                    ) : (
                      <Controller
                        name={`sportsFields[${index}].governingBodyId`}
                        control={control}
                        rules={{ required: "Who are you governed by" }}
                        render={({ field, fieldState }) => (
                          <Autocomplete
                            {...field}
                            autoHighlight
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onChange={(event, value: any) => {
                              field.onChange(value?.value || "");
                            }}
                            value={field?.value || ""}
                            getOptionLabel={(option) =>
                              option?.label ||
                              allGoverningBodies.find(
                                (governingBody) =>
                                  governingBody.value === option
                              )?.label ||
                              ""
                            }
                            options={
                              governingBodies.length
                                ? governingBodies
                                : allGoverningBodies.length
                                  ? allGoverningBodies
                                  : []
                            }
                            renderInput={(params) => (
                              <SearchInput
                                {...params}
                                {...fieldState}
                                value={params.inputProps.value}
                                placeholder="Search"
                                onChange={(event) => {
                                  setGoverningBodySearch(event.target.value);
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  label: "Who are you governed by?"
                                }}
                                label="Who are you governed by?"
                                required={true}
                                onBlur={params.inputProps.onBlur}
                              />
                            )}
                            loading={isGoverningLoading}
                            loadingText="Loading..."
                          />
                        )}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </Grid>
    </Loader>
  );
};
