import { ModelSeason } from "@sportsgravyengineering/sg-api-react-sdk";
import { SeasonAuditLogCreateDelete } from "./SeasonAuditLogCreateDelete";
import { SeasonAuditLogUpdate } from "./SeasonAuditLogUpdate";

export const SeasonAuditLogDetails = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelSeason;
  after: object | ModelSeason;
}) => {
  if (recordType === "UPDATE")
    return <SeasonAuditLogUpdate before={before} after={after} />;
  else
    return (
      <SeasonAuditLogCreateDelete
        recordType={recordType}
        before={before}
        after={after}
      />
    );
};
