import { AdminSponsorshipLevelIdGetResponse } from "@sportsgravyengineering/sg-api-react-sdk";
import { SponsorshipLevelAuditCreateDelete } from "./SponsorshipLevelAuditCreateDelete";
import { SponsorshipLevelAuditUpdate } from "./SponsorshipLevelAuditUpdate";

export const SponsorshipLevelAuditLogDetails = ({
  recordType,
  before,
  after
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | AdminSponsorshipLevelIdGetResponse;
  after: object | AdminSponsorshipLevelIdGetResponse;
}) => {
  if (recordType === "UPDATE")
    return <SponsorshipLevelAuditUpdate before={before} after={after} />;
  else
    return (
      <SponsorshipLevelAuditCreateDelete
        recordType={recordType}
        before={before}
        after={after}
      />
    );
};
