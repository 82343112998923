/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CellTemplate,
  Cell,
  Compatible,
  Uncertain,
  getCellProperty,
  isFunctionKey,
  isAlphaNumericKey,
  keyCodes,
  getCharFromKey,
  isNavigationKey
} from "@silevis/reactgrid";
import React from "react";

export interface WrappedTextCell extends Cell {
  type: "wrapped-text";
  text: string;
  characterLimit: number;
  placeholder?: string;
  validator?: (text: string) => boolean;
  errorMessage?: string;
}

export class WrappedTextCellTemplate implements CellTemplate<WrappedTextCell> {
  private wasEscKeyPressed = false;

  getCompatibleCell(
    uncertainCell: Uncertain<WrappedTextCell>
  ): Compatible<WrappedTextCell> {
    const text = getCellProperty(uncertainCell, "text", "string");
    const characterLimit = getCellProperty(
      uncertainCell,
      "characterLimit",
      "number"
    );
    let placeholder: string | undefined;
    try {
      placeholder = getCellProperty(uncertainCell, "placeholder", "string");
    } catch {
      placeholder = "";
    }
    return { ...uncertainCell, text, value: text, characterLimit, placeholder };
  }

  update(
    cell: Compatible<WrappedTextCell>,
    cellUpdate: Partial<WrappedTextCell>
  ): Compatible<WrappedTextCell> {
    return this.getCompatibleCell({ ...cell, ...cellUpdate });
  }

  handleKeyDown(
    cell: Compatible<WrappedTextCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean,
    key?: string,
    capsLock?: boolean
  ): { cell: Compatible<WrappedTextCell>; enableEditMode: boolean } {
    if (isFunctionKey(keyCode)) {
      if (keyCode === keyCodes.F2) return { cell, enableEditMode: true };
      return { cell, enableEditMode: false };
    }

    const char = key ? getCharFromKey(key, shift, capsLock ?? false) : "";

    if (!ctrl && !alt && isAlphaNumericKey(keyCode)) {
      return {
        cell: this.getCompatibleCell({ ...cell, text: char }),
        enableEditMode: true
      };
    }

    return {
      cell,
      enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER
    };
  }

  handleCompositionEnd(
    cell: Compatible<WrappedTextCell>,
    eventData: any
  ): { cell: Compatible<WrappedTextCell>; enableEditMode: boolean } {
    return { cell: { ...cell, text: eventData }, enableEditMode: true };
  }

  render(
    cell: Compatible<WrappedTextCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<WrappedTextCell>, commit: boolean) => void
  ): React.ReactNode {
    const style: React.CSSProperties = {
      width: "100%",
      height: "100%",
      whiteSpace: "pre-wrap",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      display: "flex",
      alignItems: "center",
      padding: "4px",
      boxSizing: "border-box",
      userSelect: "text",
      cursor: "text"
    };

    if (!isInEditMode) {
      const isValid = cell.validator ? cell.validator(cell.text) : true;
      const cellText = cell.text || cell.placeholder || "";
      const textToDisplay =
        !isValid && cell.errorMessage ? cell.errorMessage : cellText;
      return <div style={style}>{textToDisplay}</div>;
    }

    return (
      <input
        type="text"
        className="rg-input"
        autoFocus
        ref={(input) => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);

            // Attach a document-level event listener for scrolling
            const handleScroll = (e: WheelEvent) => {
              if (Math.abs(e.deltaY) > Math.abs(e.deltaX)) {
                e.preventDefault();
                onCellChanged(
                  this.getCompatibleCell({ ...cell, text: input.value }),
                  true
                );
                document.removeEventListener("wheel", handleScroll, {
                  passive: false
                });
              }
            };

            document.addEventListener("wheel", handleScroll, {
              passive: false
            });

            // Cleanup function to remove listener when input unmounts
            input.onblur = () => {
              document.removeEventListener("wheel", handleScroll, {
                passive: false
              });
            };
          }
        }}
        defaultValue={cell.text}
        onChange={(e) =>
          onCellChanged(
            this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
            false
          )
        }
        onBlur={(e) => {
          onCellChanged(
            this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
            !this.wasEscKeyPressed
          );
          this.wasEscKeyPressed = false;
        }}
        onKeyDown={(e) => {
          if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
            e.stopPropagation();
          if (e.keyCode === keyCodes.ESCAPE) this.wasEscKeyPressed = true;
        }}
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        //onPointerUp={(e) => e.stopPropagation()}
        maxLength={cell.characterLimit}
        placeholder={cell.placeholder}
        style={{ ...style, border: "none", outline: "none" }}
      />
    );
  }
}
