import {
  ModelRole,
  PermissionAction,
  useAdminPermissionGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { AuditLogLabelValueList } from "../components/AuditLogLabelvalueList";
import { AuditLogLabelValue } from "../types";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { Loader } from "@components/crud/Loader";
import { capitalize } from "@utils/capitalize";

const compareActions = (
  oldActions: PermissionAction[] = [],
  newActions: PermissionAction[] = []
) => {
  const added = newActions
    .filter((action) => !oldActions.includes(action))
    .filter((type) => type !== "ALL");
  const removed = oldActions
    .filter((action) => !newActions.includes(action))
    .filter((type) => type !== "ALL");
  return { added, removed };
};

export const RoleAuditLogDetails = ({
  recordType,
  before,
  after,
  page
}: {
  recordType: "CREATE" | "UPDATE" | "DELETE";
  before: object | ModelRole;
  after: object | ModelRole;
  page: "ROLE" | "SUB_ROLE";
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const { data: permissionsRequest, isFetching: isPermissionsFetching } =
    useAdminPermissionGet({
      organizationId
    });

  const role = (recordType !== "DELETE" ? after : before) as ModelRole;
  const oldRole = before as ModelRole;

  const reducedPermissions = permissionsRequest?.data
    ?.map((permission) => {
      return permission.children?.filter((c) =>
        role?.permissions?.some((rp) => rp.permissionId === c.permissionId)
      );
    })
    .flat();

  const getCreateLabels = (): AuditLogLabelValue[] => {
    const labels: AuditLogLabelValue[] = [];

    labels.push(
      {
        label: `"Name" field:`,
        value: [
          {
            value: role.name || ""
          }
        ]
      },
      ...(!organizationId
        ? [
            {
              label: `"Display Text" field:`,
              value: [
                {
                  value: role.displayText || ""
                }
              ]
            }
          ]
        : []),
      {
        label: `"Role" field:`,
        value: [
          {
            value: role.alias ? capitalize(role.alias) : ""
          }
        ]
      },
      ...(!organizationId
        ? [
            {
              label: `"Role Type" field:`,
              value: [
                {
                  value: role.type ? capitalize(role.type) : ""
                }
              ]
            }
          ]
        : []),
      {
        label: `"Description" field:`,
        value: [
          {
            value: role.description || ""
          }
        ]
      }
    );

    reducedPermissions?.map((rp) => {
      const newRole = role.permissions?.find(
        (p) => p.permissionId === rp?.permissionId
      );
      const permission = permissionsRequest?.data?.find(
        (p) => rp?.parentId === p.permissionId
      );

      const newValue = newRole?.actions || [];
      const { added } = compareActions([], newValue);
      if (added.length > 0)
        labels.push({
          label: `${permission?.name} ${!permission?.name?.includes("Permissions") ? "Permissions" : ""} "${rp?.name}" permission:`,
          value: [
            ...added.map((a) => {
              return {
                ...(rp?.type === "MULTI" && { subText: capitalize(a) }),
                value: "On"
              };
            })
          ]
        });
    });

    return labels;
  };

  const getUpdateLabels = (): AuditLogLabelValue[] => {
    const labels: AuditLogLabelValue[] = [];
    if (page === "SUB_ROLE") {
      if (role.name !== oldRole.name)
        labels.push({
          label: `Updated the "Name" field:`,
          value: [
            {
              value: role.name || "N/A",
              oldValue: oldRole.name || "N/A"
            }
          ]
        });
    }

    if (!organizationId && role.displayText !== oldRole.displayText)
      labels.push({
        label: `Updated the "Display Text" field:`,
        value: [
          {
            value: role.displayText || "N/A",
            oldValue: oldRole.displayText || "N/A"
          }
        ]
      });

    if (role.description !== oldRole.description)
      labels.push({
        label: `Updated the "Description" field:`,
        value: [
          {
            value: role.description || "N/A",
            oldValue: oldRole.description || "N/A"
          }
        ]
      });

    reducedPermissions?.map((rp) => {
      const updatedRole = role.permissions?.find(
        (p) => p.permissionId === rp?.permissionId
      );
      const existingRole = oldRole?.permissions?.find(
        (p) => p.permissionId === rp?.permissionId
      );
      const permission = permissionsRequest?.data?.find(
        (p) => rp?.parentId === p.permissionId
      );

      const updatedValue = updatedRole?.actions || [];
      const existingValue = existingRole?.actions || [];
      const { added, removed } = compareActions(existingValue, updatedValue);
      if (added.length > 0 || removed.length > 0)
        labels.push({
          label: `Updated ${permission?.name} ${!permission?.name?.includes("Permissions") ? "Permissions" : ""} "${rp?.name}" permission:`,
          value: [
            ...added.map((a) => {
              return {
                ...(rp?.type === "MULTI" && { subText: capitalize(a) }),
                value: "On",
                oldValue: "Off"
              };
            }),
            ...removed.map((r) => {
              return {
                ...(rp?.type === "MULTI" && { subText: capitalize(r) }),
                value: "Off",
                oldValue: "On"
              };
            })
          ]
        });
    });

    return labels;
  };

  return (
    <Loader isLoading={isPermissionsFetching}>
      {recordType === "UPDATE" ? (
        <AuditLogLabelValueList
          recordType="UPDATE"
          labelValues={[...getUpdateLabels()]}
        />
      ) : (
        <AuditLogLabelValueList
          recordType={recordType}
          labelValues={[...getCreateLabels()]}
        />
      )}
    </Loader>
  );
};
